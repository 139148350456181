import React from 'react';
import useToken from '../../hooks/useToken';
import useUser from '../../hooks/useUser';
import { useState, useEffect } from 'react';
import PatternUtils from "../../generator/PatternUtils";
import { LANGS } from "../../generator/PatternUtils";
import Image from "../../components/Image";
import { useParams, useHistory, useLocation } from "react-router-dom";
import strings from "../../config/strings";
import Utils from '../../utils/utils';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import PicUpload from "../../components/generator/PicUpload";

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

export default function UploadPicture(props) {
  const [pattern, setPattern] = useState(props.pattern)
  const [isLoading, setIsLoading] = useState(false)
  const showUpload = props.showUpload
  const onUploaded = props.onUploaded




 useEffect(() => {
  
    console.log("pattern passed 1", props.pattern)
    const pattern = props.pattern
    setPattern(pattern)

  }, [isLoading])

  const renderLoading = () => {
    return (
      <View style={{width: "100%", justifyContent:"center", alignItems:"center", padding: 50}}>
        <Loader
          type="ThreeDots"
          color="#2ba5f7"
          height={100}
          width={100}
          // timeout={8000} //3 secs
        />
      </View>
    )
  }

  
  return(
 

    <View
    style={{
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      paddingTop: 120,
      justifyContent: "flex-start",
      alignItems:'center',
      zIndex: 5000,
      backgroundColor: "rgba(128, 128, 128, 0.7)",
    }}
    >
      <View
      style={{
        width: 800, 
        height: 400,
        backgroundColor: "#266D9C", 
        justifyContent: "flex-start",
        alignItems: "center",
        borderRadius: 12,
      }}>
        <TouchableOpacity
        onPress={() => showUpload(false)}
        style={{width: "100%", justifyContent: "center", alignItems: "flex-end"}}>
        <Image style={{width: 24, height: 24, margin: 12,}} urlOnServer={'/imgs/close.png'} />
        </TouchableOpacity>
        <p className="DetailTitle" style={{margin: 36}}>{strings.patternCreator.uploadPic}</p>
        <View style={{flex: 1, justifyContent: "center"}}>
          {
            isLoading ? renderLoading() : 
            <PicUpload 
            className="Upload" 
            projectId={"1"} 
            pattern={pattern} 
            onLoading={setIsLoading}
            type={props.type}
            onSuccess={(response) => {
              onUploaded(response.data.id)
            }} />
          }
        </View>
        
      </View>
    </View>
  );
}

const styles = StyleSheet.create({


});