import React from 'react';
import useToken from '../../hooks/useToken';
import useUser from '../../hooks/useUser';
import { useState, useEffect } from 'react';
import PatternUtils from "../../generator/PatternUtils";
import { LANGS } from "../../generator/PatternUtils";
import Image from "../../components/Image";
import { useParams, useHistory, useLocation } from "react-router-dom";
import strings from "../../config/strings";
import Utils from '../../utils/utils';

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

export default function EditTechnics(props) {
  const [createdPattern, setCreatedPattern] = useState(props.createdPattern)
  const [technics, setTechnics] = useState((props.createdPattern && props.createdPattern.technics) ? PatternUtils.getStringAsArray(props.createdPattern.technics) : [])
  const [showAddTechnic, setShowAddTechnic] = useState(false)
  const [newName, setNewName] = useState()
  const [newText, setNewText] = useState()
  const [editMode, setEditMode] = useState(false)
  const [indexEdit, setIndexEdit] = useState(false)

  useEffect(() => {
  
    console.log("props", technics)



  }, [])

  const onSave = () => {
    var updatedPattern = createdPattern
    var updatedTechnics = PatternUtils.createStringFromArray(technics)
    updatedPattern.technics = updatedTechnics
    updatedPattern.updatedAtOnClient = Utils.getTime()
    //console.log("Updated Pattern", updatedPattern)
    props.onSave(updatedPattern)
    PatternUtils.updatePatternOnServer(updatedPattern, () => console.log(createdPattern))
    props.showCollapse(false)
    
  }

  const addNewTechnic = (name, text) => {
    var newTech = [name, text]
    var updatedTechs = [...technics]
    updatedTechs.push(newTech)
    setTechnics(updatedTechs)
    setNewName("")
    setNewText("")
    setShowAddTechnic(false)
  } 

  const onEditItem = (name,text, index) => {
    setNewName(name)
    setNewText(text)
    setEditMode(true)
    setIndexEdit(index)
  }

  const saveUpdate = (name,text) => {
    var updatedItem = [name, text]
    var allItems = [...technics]
    allItems[indexEdit] = updatedItem
    setTechnics(allItems)
    setNewName("")
    setNewText("")
    setEditMode(false)
    setIndexEdit(false)
  }

  const renderOneTechnic = (item, index) => {
    return (
      <View style={{flexDirection: "row", width: "100%", justifyContent: "flex-start", alignItems:"flex-start", borderBottomWidth: 1, paddingVertical: 12, borderColor: "rgba(255,255,255,0.6)"}}>
        <Text style={{color: "white", fontWeight: "bold", width: 100}}>
          {item[0]}:
        </Text>
        <Text style={{color: "rgba(255,255,255,0.8)", flex: 1, marginLeft: 12}}>
          {item[1]}
        </Text>
        <TouchableOpacity 
          onPress={() => onEditItem(item[0], item[1], index)}
          style={{marginLeft: 24,  width: 60}}>
            <Text style={{fontWeight: "bold", color: "#FECB2E", fontSize: 12}}>{strings.edit}</Text>
          </TouchableOpacity>
      </View>
    )
  }

  const renderAllTechnics = (technics) => {
    return (
      <div className="ScrollableContent">
        {technics.map((oneTechnic, index) => {
          return(
            renderOneTechnic(oneTechnic, index)
          )
        })}
      </div>
    )
  }

  const renderAddBtn = () => {
    return (
      <TouchableOpacity 
      onPress={() => setShowAddTechnic(true)}
      style={{margin: 12, width: 48, height: 48,padding: 12, borderRadius: 30, backgroundColor: "white"}}>
        <Image style={{width: 24, height: 24, tintColor: "#FECB2E", marginRight: 4}} urlOnServer={'/imgs/plus-new-b.png'} />
      </TouchableOpacity>
    )
  }

  const renderAddNewTechnic = () => {
    return (
      <View style={{flexDirection: "row", marginTop: 12}}>
        <View>
          <Text style={[styles.title, {marginTop:16}]}>{strings.name}:</Text>
          <TextInput
                  containerStyle={{height: 60,flex: 1}}
                  style={[styles.input, {height: 40, borderRadius: 20, width: 100 }]}
                  multiline={false}
                  value={newName}
                  onChangeText={(newName) => setNewName(newName)}
                  returnKeyType="done"
                />
        </View>
        <View style={{marginLeft: 12, flex: 1}}>
          <Text style={[styles.title, {marginTop:16}]}>{strings.description}:</Text>
          <View style={{flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
          <TextInput
                  containerStyle={{height: 60,flex: 1}}
                  style={[styles.input, {height: 40, borderRadius: 20, flex: 1, width: "100%" }]}
                  multiline={false}
                  value={newText}
                  onChangeText={(newText) => setNewText(newText)}
                  returnKeyType="done"
                />
          <TouchableOpacity 
          onPress={() => {
            if (editMode) {
              saveUpdate(newName, newText)
            } else {
              addNewTechnic(newName, newText)
            }
          }}
          style={{marginLeft: 24, marginTop: 12, width: 60}}>
            <Text style={{fontWeight: "bold", color: "#FECB2E"}}>{editMode ? strings.patternCreator.update : strings.add }</Text>
          </TouchableOpacity>
          </View>

        </View>
      </View>
    )
  }
   
  return(
 

    <View style={{flex: 1, paddingHorizontal: 12}}>
        <p className="PatternOptionTitle">{strings.technics}</p>
        <View style={{flex: 1, width: "100%",}}>
        {renderAllTechnics(technics)}
        {!showAddTechnic && renderAddBtn()}
        {(showAddTechnic || editMode) && renderAddNewTechnic()}
        </View>
        { !showAddTechnic && !editMode &&
      <div className="NewPatternBtns" >
      <button className="ImportPatternBtn" onClick={() => onSave()}>
        Save
      </button> 
    </div>  
        }

    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginTop: 12,
    backgroundColor: "rgba(255,255,255,0.15)" , 
    color: "white",
    paddingLeft: 16,
    textAlign: "left"
  },
  title: {
    color: "rgba(255,255,255,0.8)", 
    textAlign: "left",
    selfAlign: "flex-start",
  },

});