import React from 'react';
import useToken from '../../hooks/useToken';
import useUser from '../../hooks/useUser';
import { useState, useEffect } from 'react';
import PatternUtils from "../../generator/PatternUtils";
import { LANGS } from "../../generator/PatternUtils";
import Image from "../../components/Image";
import { useParams, useHistory, useLocation } from "react-router-dom";
import strings from "../../config/strings";
import Utils from '../../utils/utils';
import { GLOSSARY_KNIT } from "../../config/glossary_knit";
import { GLOSSARY_CRO } from "../../config/glossary_cro";

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

export default function EditGlossary(props) {
  const [createdPattern, setCreatedPattern] = useState(props.createdPattern)
  const [glossary, setGlossary] = useState((props.createdPattern && props.createdPattern.glossary) ? PatternUtils.getStringAsArray(props.createdPattern.glossary) : [])
  const [showAddItem, setShowAddItem] = useState(false)
  const [showAddFromList, setShowAddFromList] = useState(false)
  const [newName, setNewName] = useState()
  const [newText, setNewText] = useState()
  const [editMode, setEditMode] = useState(false)
  const [indexEdit, setIndexEdit] = useState(false)
  const [selectedGlossary, setSelectedGlossary] = useState(GLOSSARY_KNIT)

  useEffect(() => {
  
    console.log("props", glossary)
  }, [])

  const onSave = () => {
    var updatedPattern = createdPattern
    var updatedGlossary = PatternUtils.createStringFromArray(glossary)
    updatedPattern.glossary = updatedGlossary
    updatedPattern.updatedAtOnClient = Utils.getTime()
    //console.log("Updated Pattern", updatedPattern)
    props.onSave(updatedPattern)
    PatternUtils.updatePatternOnServer(updatedPattern, () => console.log(createdPattern))
    props.showCollapse(false)
    
  }

  const addNewItem = (name, text) => {
    var newItem = [name, text]
    var updatedGlossary = [...glossary]
    updatedGlossary.push(newItem)
    setGlossary(updatedGlossary)
    setNewName("")
    setNewText("")
    setShowAddItem(false)
    setShowAddFromList(false)
  } 

  const onEditItem = (name,text, index) => {
    setNewName(name)
    setNewText(text)
    setEditMode(true)
    setIndexEdit(index)
  }

  const saveUpdate = (name,text) => {
    var updatedItem = [name, text]
    var allItems = [...glossary]
    allItems[indexEdit] = updatedItem
    setGlossary(allItems)
    setNewName("")
    setNewText("")
    setEditMode(false)
    setIndexEdit(false)
  }

  const renderOneItem = (item, index, object = false) => {
    var title = object ? item[strings.lang].abbr : item[0]
    var desc = object ? item[strings.lang].txt : item[1]
    return (
      <View style={{flexDirection: "row", width: "100%", justifyContent: "flex-start", alignItems:"flex-start", borderBottomWidth: 1, paddingVertical: 12, borderColor: "rgba(255,255,255,0.6)"}}>
        <Text style={{color: "white", fontWeight: "bold", width: 100}}>
          {title}:
        </Text>
        <Text style={{color: "rgba(255,255,255,0.8)", flex: 1, marginLeft: 12}}>
          {desc}
        </Text>
        {
          object ?           
          <TouchableOpacity 
          onPress={() => addNewItem(title, desc)}
          style={{marginLeft: 24,  width: 60}}>
            <Text style={{fontWeight: "bold", color: "#FECB2E", fontSize: 12}}>{strings.add}</Text>
          </TouchableOpacity> : 
          <TouchableOpacity 
          onPress={() => onEditItem(title, desc, index)}
          style={{marginLeft: 24,  width: 60}}>
            <Text style={{fontWeight: "bold", color: "#FECB2E", fontSize: 12}}>{strings.edit}</Text>
          </TouchableOpacity>
        }
      </View>
    )
  }

  const renderAllItems = (glossary, object = false) => {
    return (
      <div className="ScrollableContent">
        {glossary.map((oneItem, index) => {
          return(
            renderOneItem(oneItem, index, object)
          )
        })}
      </div>
    )
  }

  const renderAddBtn = () => {
    return (
      <View style={{justifyContent: "center", alignItems: "center"}}>
        <TouchableOpacity 
        onPress={() => setShowAddItem(true)}
        style={{margin: 12, width: 48, height: 48,padding: 12, borderRadius: 30, backgroundColor: "white"}}>
          <Image style={{width: 24, height: 24, tintColor: "#FECB2E", marginRight: 4}} urlOnServer={'/imgs/plus-new-b.png'} />
        </TouchableOpacity>
        <Text style={{color: "white"}}>
        {strings.patternCreator.manual}
        </Text>
      </View>

    )
  }

  const renderAddFromListBtn = () => {
    return (
      <View style={{justifyContent: "center", alignItems: "center"}}>
        <TouchableOpacity 
        onPress={() => setShowAddFromList(true)}
        style={{margin: 12, width: 48, height: 48,padding: 12, borderRadius: 30, backgroundColor: "white"}}>
          <Image style={{width: 24, height: 24, tintColor: "#FECB2E", marginRight: 4}} urlOnServer={'/imgs/option.png'} />
        </TouchableOpacity>
        <Text style={{color: "white"}}>
        {strings.patternCreator.fromList}
        </Text>
      </View>

    )
  }

  const renderaddNewItem = () => {
    return (
      <View style={{flexDirection: "row", marginTop: 12}}>
        <View>
          <Text style={[styles.title, {marginTop:16}]}>{strings.name}:</Text>
          <TextInput
                  containerStyle={{height: 60,flex: 1}}
                  style={[styles.input, {height: 40, borderRadius: 20, width: 100 }]}
                  multiline={false}
                  value={newName}
                  onChangeText={(newName) => setNewName(newName)}
                  returnKeyType="done"
                />
        </View>
        <View style={{marginLeft: 12, flex: 1}}>
          <Text style={[styles.title, {marginTop:16}]}>{strings.description}:</Text>
          <View style={{flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
          <TextInput
                  containerStyle={{height: 60,flex: 1}}
                  style={[styles.input, {height: 40, borderRadius: 20, flex: 1, width: "100%" }]}
                  multiline={false}
                  value={newText}
                  onChangeText={(newText) => setNewText(newText)}
                  returnKeyType="done"
                />
          <TouchableOpacity 
          onPress={() => {
            if (editMode) {
              saveUpdate(newName, newText)
            } else {
              addNewItem(newName, newText)
            }
          }}
          style={{marginLeft: 24, marginTop: 12, width: 60}}>
            <Text style={{fontWeight: "bold", color: "#FECB2E"}}>{editMode ? strings.patternCreator.update :  strings.add }</Text>
          </TouchableOpacity>
          </View>

        </View>
      </View>
    )
  }

  const renderSelectedGlossary = () => {
    return (
      <View style={{flexDirection: "row", justifyContent: "space-around", alignItems: "center"}}>
        <TouchableOpacity 
        onPress={() => setSelectedGlossary(GLOSSARY_KNIT)}
        style={styles.glossaryBtnContainer}>
          <Text style={[styles.glossaryBtn, {color: selectedGlossary ==  GLOSSARY_KNIT ? "#FECB2E" : "white"}]} >{strings.patternCreator.kniGlossary}</Text>
        </TouchableOpacity>
        <TouchableOpacity 
        onPress={() => setSelectedGlossary(GLOSSARY_CRO)}
        style={styles.glossaryBtnContainer}>
          <Text style={[styles.glossaryBtn, {color: selectedGlossary ==  GLOSSARY_KNIT ? "white" : "#FECB2E"}]} >{strings.patternCreator.croGlossary}</Text>
        </TouchableOpacity>
      </View>
    )
  }
   
  
  return(
 

    <View style={{flex: 1, paddingHorizontal: 12}}>
        <p className="PatternOptionTitle">{strings.glossary}</p>
        <View style={{flex: 1, width: "100%",}}>
        {showAddFromList && renderSelectedGlossary()}
        {!showAddFromList ? renderAllItems(glossary) : renderAllItems(selectedGlossary, true)}
        {!showAddItem && !showAddFromList && !editMode && 
          <View style={{flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
          {renderAddBtn()}
          {renderAddFromListBtn()}
        </View>
        }
        {(showAddItem || editMode) && renderaddNewItem()}
        </View>
        { !showAddItem && !editMode &&
      <div className="NewPatternBtns" >
        <button className="ImportPatternBtn" onClick={() => onSave()}>
        {strings.save}
        </button> 
      </div>  
        }

    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginTop: 12,
    backgroundColor: "rgba(255,255,255,0.15)" , 
    color: "white",
    paddingLeft: 16,
    textAlign: "left"
  },
  title: {
    color: "rgba(255,255,255,0.8)", 
    textAlign: "left",
    selfAlign: "flex-start",
  },
  glossaryBtn: {
    fontWeight: "bold", 
    fontSize: 12, 
  },
  glossaryBtnContainer: {
    paddingHorizontal: 16, 
    paddingVertical: 8, 
    borderColor: "rgba(255,255,255,0.8)",
    borderRadius: 24,
    borderWidth: 1,
  }

});