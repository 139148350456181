import React, { Component } from "react";
import strings from "../config/strings";
import Image from "../components/Image"
import ImageBackground from "../components/ImageBackground"
import Button from "../components/Button"
import Utils from "../utils/utils";
import {SECONDARY_COLOR} from "../config/styles";
import { BrowserRouter, Route, Switch, useRouteMatch, Link, useParams, useHistory } from 'react-router-dom';
import TitledTextInput from "../components/TitledTextInput";
import { HEADER_HEIGHT } from '../config/Constants'
import OptionList from "../components/OptionList";


import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions,
  Picker
} from 'react-native';

const MARGIN = 6;

const MARGIN_OUT = 6;

const { width, height } = Dimensions.get("window");
const gutter = 0;



const UNITS = [
  { id: 1, text: "Metric 📏" },
  { id: 2, text: "UK 🇬🇧"},
  { id: 3, text: "US 🇺🇸"},
];


const NEEDLES_METRIC = [
"1,5 mm", "1,75 mm", "2 mm", "2,25 mm", "2,5 mm", "2,75 mm", "3 mm", "3,25 mm", "3,5 mm", "3,75 mm", "4 mm", "4,5 mm", "5 mm", "5,5 mm", "6 mm", "6,5 mm", "7 mm", "8 mm", "9 mm", "10 mm"
]

const NEEDLES_UK = [
"16", "15", "14", "13", "12", "11", "11", "10", "9", "8", "8", "7", "6", "5", "4", "3", "2", "0", "0", "0"
]

const NEEDLES_US = [
"0", "0", "0", "1", "1 ½", "2", "2 ½", "3", "4", "5", "6", "7", "8", "9", "10", "10 ½", "10 ¾", "11", "13", "15"
]



const WOOL_METRIC = [ 
"1,5 mm", "1,75 mm", "2 mm", "2,25 mm", "2,5 mm", "2,75 mm", "3 mm", "3,25 mm", "3,5 mm", "3,75 mm", "4 mm", "4,5 mm", "5 mm", "5,5 mm", "6 mm", "6,5 mm", "7 mm", "8 mm", "9 mm", "10 mm"
]

const WOOL_UK = [ 
"1 ply", "1 ply", "1 ply", "2 ply", "2 ply", "3 ply", "3 ply", "3 ply", "4 ply", "4 ply", "DK", "DK", "DK", "Aran", "Aran", "Aran", "Chunky", "Chunky", "Super Chunky", "Super Chunky"
]

const WOOL_US = [ 
"Laceweight", "Laceweight", "Laceweight", "Fingering", "Fingering", "Sock", "Sock", "Sock", "Sport", "Sport", "Light Worsted", "Light Worsted", "Light Worsted", "Worsted", "Worsted", "Worsted", "Bulky", "Bulky", "Super Bulky", "Super Bulky"
]


export default class ConvertSizes extends Component {
  handler = dims => this.setState(dims);

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }


  getInitialState() {
    return {output: null, typeConvertion: 0, typeIn: UNITS[0], selectedSize: -1, selectedSizeIndex: -1, typeOut: UNITS[2] }
  }

  componentDidMount() {
    Dimensions.addEventListener("change", this.handler);
  }

 

  componentWillUnmount() {
    // Important to stop updating state after unmount
    Dimensions.removeEventListener("change", this.handler);
  }

 


  back = () => {

    if (this.state.typeConvertion != 0) {
      this.setState(this.getInitialState())
      return
    }
    this.props.navigation.goBack();
  };


  renderSelect() {
    var width = Math.min(Dimensions.get("window").width / 4, 400)

    if (width < 300)
      width = 300

    const imgWidth = width / 2 - MARGIN
    return (
     <ImageBackground urlOnServer={'/imgs/background_knitting2.jpg'}
        style={{ flex: 1, height: Dimensions.get("window").height - HEADER_HEIGHT,  alignItems:'center', justifyContent:'center' }}
      
      >

      <View style={{ width:width }}>


       <View style={{flexGrow:1, justifyContent:'center', alignItems:'center'}}>

       <Text style={{textAlign:'center', fontWeight:'bold', fontSize:15}}>{strings.convert_what}</Text>
       </View>
      

      <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
       <TouchableOpacity onPress={() => this.setState({typeConvertion: 1})}
      >
        <View
        style={[ styles.toolContainer,  { width: imgWidth,  height: imgWidth } ]}
            >
          <Image
            style={[
              styles.imgItem,
              {
                marginTop: 5,
                width: imgWidth - 45,
                height: imgWidth - 45,
                alignSelf: 'center',
                tintColor:'#fff'
              }
            ]}
            urlOnServer={"/imgs/aiguilles.png"}
          />

          <Text style={styles.textItem}>
           {strings.needle_size.replace(':', '')}
          </Text>
        
        </View>
      </TouchableOpacity>


      <TouchableOpacity onPress={() => this.setState({typeConvertion: 2}) }
      >
        <View
        style={[ styles.toolContainer,  { width: imgWidth,  height: imgWidth } ]}
            >
          <Image
            style={[
              styles.imgItem,
              {
                marginTop: 5,
                width: imgWidth - 45,
                height: imgWidth - 45,
                alignSelf: 'center',
                tintColor:'#fff'
              }
            ]}
            urlOnServer={"/imgs/projet.png"}
          />

          <Text style={styles.textItem}>
            {strings.wool_size.replace(':', '')}
          </Text>
        
        </View>
      </TouchableOpacity>

      </View>


      </View>
      </ImageBackground>


      )
  }


  renderPicker() {

    var width = Math.min(Dimensions.get("window").width / 4, 400)

    if (width < 300)
      width = 300

    if (this.state.typeConvertion == 1)
      var items = this.state.typeIn.id == 1 ? NEEDLES_METRIC : this.state.typeIn.id == 2 ? NEEDLES_UK : NEEDLES_US;
    else
      var items = this.state.typeIn.id == 1 ? WOOL_METRIC : this.state.typeIn.id == 2 ? WOOL_UK : WOOL_US;
    if (this.state.selectedSizeIndex == -1) {
      this.state.selectedSize = items[0]
      this.state.selectedSizeIndex = 0;

      this.state.selectedSizeTmp = items[0]
      this.state.selectedSizeIndexTmp = 0;
    }
    return (


      <ImageBackground urlOnServer={'/imgs/background_knitting2.jpg'} style={{flex:1, height: Dimensions.get("window").height - HEADER_HEIGHT, justifyContent:'center', alignItems:'center'}}>

      <View style={{width:width, justifyContent:'center'}}>
        <Picker
          style={{flex:1}}
          selectedValue={this.state.selectedSizeTmp}
          onValueChange={(itemValue, itemIndex) =>
            this.setState({selectedSizeTmp: itemValue, selectedSizeIndexTmp: itemIndex})
          }>
          {items.map(item => <Picker.Item label={item} value={item} />)}
        </Picker>
        </View>

        <Button style={{margin:20, alignSelf:'center'}} title="OK" onPress={() =>  
          this.setState({selectSize: false, selectedSize: this.state.selectedSizeTmp, selectedSizeIndex: this.state.selectedSizeIndexTmp})} />
      </ImageBackground>

      )


  }

  renderConvert() {
    var width = Math.min(Dimensions.get("window").width / 4, 400)

    if (width < 300)
      width = 300

    var output = ''
    if (this.state.typeConvertion == 1) {
      if (this.state.selectedSizeIndex != -1) {
        if (this.state.typeOut.id == 1) {
          var output = NEEDLES_METRIC[this.state.selectedSizeIndex]
        }
        else if (this.state.typeOut.id == 2) {
          var output = 'UK: ' +  NEEDLES_UK[this.state.selectedSizeIndex]
        }
        else if (this.state.typeOut.id == 3) {
          var output = 'US: ' +  NEEDLES_US[this.state.selectedSizeIndex]
        }
      }
    }
    else {
      if (this.state.selectedSizeIndex != -1) {
        if (this.state.typeOut.id == 1) {
          var output = WOOL_METRIC[this.state.selectedSizeIndex]
        }
        else if (this.state.typeOut.id == 2) {
          var output = 'UK: ' +  WOOL_UK[this.state.selectedSizeIndex]
        }
        else if (this.state.typeOut.id == 3) {
          var output = 'US: ' +  WOOL_US[this.state.selectedSizeIndex]
        }
      }
    }
//alert(JSON.stringify(this.state.typeOut))
    return (
      <ImageBackground urlOnServer={'/imgs/background_knitting2.jpg'}
        style={{ flex: 1, height: Dimensions.get("window").height - HEADER_HEIGHT, alignItems:'center', justifyContent:'center' }}
      >


         <View style={{ width: width }}>
  
         <Text style={{color:"#888", textAlign:'center', marginTop: 30}}>{strings.from}</Text>

        <OptionList

          width={width}
          nbColumns={3}
          options={UNITS}
          onOptionSelected={option => this.setState({typeIn: option})}
          selected={this.state.typeIn}
        />

       
     

       <Text style={{color:"#888", marginTop:30, textAlign:'center'}}>{strings.select_size}</Text>
       <TouchableOpacity onPress={() => this.setState({selectSize: true})} style={{marginHorizontal:20, padding:5, borderColor:'#ccc', borderBottomWidth:1, flexDirection:'row'}}>
        
          <Image urlOnServer={"/imgs/triangle.png"} style={{width:20, height:20}}/>
          <Text style={{textAlign:'center', paddingRight:20, flex:1, color:'#555', fontWeight:'bold'}}>{this.state.selectedSizeIndex == -1 ? '' : this.state.selectedSize}</Text>
       </TouchableOpacity>
     

       <Text style={{color:"#888", textAlign:'center', marginTop: 30}}>{strings.to}</Text>
        <OptionList
          width={width}
          nbColumns={3}
          options={UNITS}
          onOptionSelected={option => this.setState({typeOut: option})}
          selected={this.state.typeOut}
        />


 

        <View
          style={{
            borderBottomWidth: 1,
            borderColor: "#bbb",
            marginHorizontal:20,
            marginTop: 30
          }}
        >
          <Text
            style={{ padding: 2, textAlign: "center", 
            color:'#555', fontWeight:'bold'
            }}
          >{output}</Text>
        </View>

        </View>


      </ImageBackground>
    );
  }



  render() {
   

    if (this.state.typeConvertion == 0)
      return this.renderSelect();

    if (this.state.selectSize)
      return this.renderPicker();
    return this.renderConvert();
  }
}

const styles = StyleSheet.create({
  toolContainer: {
    margin: MARGIN,
    shadowColor: "#000",
    shadowOpacity: 0.6,
    shadowRadius: 3,
    elevation: 8,
    backgroundColor: SECONDARY_COLOR,
    borderRadius: 8
  },
  textItem: {
    textAlign: "center",
    color: "white",
    backgroundColor: "transparent"
  },

   item : {
    borderWidth: 1,
    borderRadius: 5,
    margin: MARGIN,
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight:1,
    paddingLeft: 1,
    borderColor: "#ccc",

    textAlign:'center',
     fontSize: 16,
     overflow:'hidden',

     shadowColor: "#000",
     shadowOpacity: 0.3,
    shadowRadius: 2,
    shadowOffset: { width: 1, height: 1 },
    elevation: 3,
  },
  itemSelected: {
    
    backgroundColor:'#2ba5f7',
   color:'white',
  },
  itemNotSelected: {
    backgroundColor:'white',
    color:'#888',

  },
});
