export const GLOSSARY_KNIT = [
  {
    id: 1,
    en: {abbr: "'", txt: 'inches'},
    fr: {abbr: "'", txt: 'Inches'},
    de: {
      abbr: "'",
      txt: 'Zoll',
    },
  },
  {
    id: 2,
    en: {
      abbr: '*',
      txt: 'repeat instructions from the single asterix as directed',
    },
    fr: {
      abbr: '*',
      txt:
        "Répéter les instructions entre les étoiles autant de fois qu'indiqué",
    },
    de: {
      abbr: '*',
      txt: 'Die Anweisungen zwischen den Asteriken/Sternchen wiederholen',
    },
  },
  {
    id: 3,
    en: {abbr: 'alt', txt: 'Alternate'},
    fr: {abbr: 'alt', txt: 'Alterner'},
    de: {txt: 'Abwechselnd'},
  },
  {
    id: 4,
    en: {abbr: 'approx', txt: 'Approximatly'},
    fr: {abbr: 'approx', txt: 'Approximativement'},
    de: {txt: 'Ungefähr'},
  },
  {
    id: 5,
    en: {abbr: 'beg', txt: 'Begin'},
    fr: {abbr: 'com.', txt: 'Commencer'},
    de: {abbr: 'beg.', txt: 'Beginnen Sie'},
  },
  {
    id: 6,
    en: {abbr: 'bet', txt: 'Between'},
    fr: {abbr: 'ent.', txt: 'Entre'},
    de: {abbr: 'Zwis.', txt: 'Zwischen'},
  },
  {
    id: 7,
    en: {abbr: 'bl (ou) blo', txt: 'Back loop'},
    fr: {abbr: 'b. arr.', txt: "Brin arrière d'une maille"},
    de: {txt: 'hintere Maschenbein'},
  },
  {
    id: 8,
    en: {abbr: 'BO', txt: 'Bind off'},
    fr: {abbr: 'rab.', txt: 'Rabattre'},
    de: {abbr: 'abk.', txt: 'abketten'},
  },
  {
    id: 9,
    en: {abbr: 'CC', txt: 'Contrasting Color'},
    fr: {abbr: 'CC', txt: 'Couleur contrastante, différente'},
    de: {abbr: 'KF', txt: 'Kontrastfarbe'},
  },
  {
    id: 10,
    en: {abbr: 'CN', txt: 'Cable needle'},
    fr: {abbr: 'aig. aux.', txt: 'Aiguille auxiliaire, aiguille à torsade'},
    de: {abbr: 'ZopfN', txt: 'Zopfnadel'},
  },
  {
    id: 11,
    en: {abbr: 'CO', txt: 'Cast on'},
    fr: {abbr: 'mo. X m.', txt: 'Monter X mailles'},
    de: {abbr: 'anschl.', txt: 'anschlagen'},
  },
  {
    id: 12,
    en: {abbr: 'cont', txt: 'Continue'},
    fr: {abbr: 'cont', txt: 'Continuer'},
    de: {txt: 'Weiter gehen'},
  },
  {
    id: 13,
    en: {abbr: 'Cross 2 L', txt: 'Cross 2 stitches on the left'},
    fr: {abbr: 'crois. 2 G', txt: 'Croiser 2 mailles vers la gauche'},
    de: {txt: '2 Maschen links kreuzen'},
  },
  {
    id: 14,
    en: {abbr: 'Cross 2 R', txt: 'Cross 2 stitches on the right'},
    fr: {abbr: 'crois. 2 D', txt: 'Croiser 2 mailles vers la droite'},
    de: {txt: '2 Stiche rechts kreuzen'},
  },
  {
    id: 15,
    en: {abbr: 'dd', txt: 'Double Decrease'},
    fr: {abbr: 'double dim.', txt: 'Double diminution'},
    de: {abbr: 'dopp. abn.', txt: 'doppelte abnahme'},
  },
  {
    id: 16,
    en: {abbr: 'dec', txt: 'Decrease'},
    fr: {abbr: 'dim.', txt: 'Diminution'},
    de: {abbr: 'abn.', txt: 'abnehmen'},
  },
  {
    id: 17,
    en: {abbr: 'dpn', txt: 'Double pointed needles'},
    fr: {abbr: 'Aig. dp', txt: 'Aiguilles doubles pointes'},
    de: {abbr: 'Ndlsp', txt: 'Nadelspiel'},
  },
  {
    id: 18,
    en: {abbr: 'EON', txt: 'End of Needle'},
    fr: {abbr: "Bout de l'aig.", txt: "bout de l'aiguille"},
    de: {txt: 'Ende der Nadel'},
  },
  {
    id: 19,
    en: {abbr: 'Even Row', txt: 'Even Row'},
    fr: {abbr: 'rg pairs', txt: 'Rangs pairs'},
    de: {abbr: 'gerade R', txt: 'gerade Reihe'},
  },
  {
    id: 20,
    en: {abbr: 'FC', txt: 'Front cross = Cross 2 L'},
    fr: {abbr: 'crois. 2 av.', txt: 'Mettre en attente 2 mailles en avant'},
    de: {txt: 'Vorderes Kreuz = Kreuz 2 L'},
  },
  {
    id: 21,
    en: {abbr: 'fl', txt: 'Front Loop'},
    fr: {abbr: 'b. av.', txt: "Brin avant d'une maille"},
    de: {txt: 'vordere Maschenbein'},
  },
  {
    id: 22,
    en: {abbr: 'foll', txt: 'Following'},
    fr: {abbr: 'suiv.', txt: 'Suivant'},
    de: {txt: 'folgende'},
  },
  {
    id: 23,
    en: {abbr: 'g st', txt: 'Garter Stitch'},
    fr: {abbr: 'point mousse', txt: 'Point mousse'},
    de: {abbr: 'kraus re', txt: 'kraus rechts: HinR re, RückR re'},
  },
  {
    id: 24,
    en: {abbr: 'grp', txt: 'Group'},
    fr: {abbr: 'ens.', txt: 'Grouper, mettre ensemble'},
    de: {abbr: 'Zus.', txt: 'Zusammen'},
  },
  {
    id: 25,
    en: {abbr: 'inc (ou) incr', txt: 'Increase'},
    fr: {abbr: 'augm.', txt: 'Augmenter, augmentation'},
    de: {
      abbr: 'M1',
      txt:
        ' 1 Masche zunehmen, indem man den Arbeitsfaden einmal verdreht um die rechte Nadel wickelt (kann durch rechts- oder linksgelehnte Zunahmen ersetzt werden)',
    },
  },
  {
    id: 26,
    en: {abbr: 'K', txt: 'Knit'},
    fr: {abbr: 'm. end.', txt: "Tricoter à l'endroit"},
    de: {abbr: 're.', txt: 'rechts stricken'},
  },
  {
    id: 27,
    en: {abbr: 'k-b', txt: 'Knit stitch in row below'},
    fr: {
      abbr: '1 m. end. rg. prec.',
      txt: "Tricoter à l'endroit dans la maille du rang précédent",
    },
    de: {txt: 'Masche in der folgenden Reihe stricken'},
  },
  {
    id: 28,
    en: {abbr: 'k-wise', txt: 'Knit wise'},
    fr: {
      abbr: 'end.',
      txt:
        "Tricoter à l'endroit, souvent dit lorsqu’on est à l'arrière du travail",
    },
    de: {txt: 'rechts stricken'},
  },
  {
    id: 29,
    en: {abbr: 'K1', txt: 'Knit One'},
    fr: {abbr: '1 m. end.', txt: '1 Maille endroit'},
    de: {abbr: '1 re.', txt: '1 Masche rechts'},
  },
  {
    id: 30,
    en: {abbr: 'k1 f&b (ou) kfb', txt: 'Knit front and back Loop'},
    fr: {
      abbr: 'm. end. brin av. & arr.',
      txt: "Tricoter à l'endroit le brin avant et le brin arrière de la maille",
    },
    de: {
      abbr: 'aus 1 M 2 M herausstr',
      txt:
        'Eine Masche rechts abstricken, Masche auf der Nadel lassen und noch einmal rechts verschränkt abstricken.',
    },
  },
  {
    id: 31,
    en: {abbr: 'K1P1', txt: 'k1p1, knit one, purl one'},
    fr: {abbr: '1 m. end, 1 m. env.', txt: '1 maille endroit, 1 maille envers'},
    de: {abbr: '1 re. 1 li.', txt: '1 Masche rechts, 1 Masche link'},
  },
  {
    id: 32,
    en: {
      abbr: 'K2SP',
      txt:
        'K2SP or Knit 2 stitches together, then sl st back to LH needle back over, return stitch on RH needle',
    },
    fr: {
      abbr: '2 m. ens, KSP',
      txt: 'Tricoter 2 mailles ensemble plus faire KSP',
    },
    de: {
      txt:
        '2 Maschen zusammenstricken, dann Kettmasche zurück auf die linke Nadel, Masche auf die rechte Nadel zurücklegen',
    },
  },
  {
    id: 33,
    en: {abbr: 'K2tog', txt: 'Knit 2 stitches together'},
    fr: {
      abbr: '2 m.ens',
      txt: "Tricoter 2 mailles ensemble à l'endroit",
    },
    de: {abbr: '2 re.Zus.', txt: 'zwei Maschen rechts zusammenstricken'},
  },
  {
    id: 34,
    en: {abbr: 'kll', txt: 'Knit left loop (increase)'},
    fr: {
      abbr: 'Aug. int. G',
      txt:
        "Avec l'aiguille gauche, prendre avec l'aiguille gauche, prendre le brin gauche de la maille du rang précédent, sous l'aiguille de droite, et tricoter à l'endroit dans le brin arrière de la maille",
    },
    de: {txt: 'Linke Masche stricken (zunehmen)'},
  },
  {
    id: 35,
    en: {abbr: 'Kr', txt: 'Knit row'},
    fr: {abbr: 'rg. end.', txt: "Rang tricoté à l'endroit"},
    de: {abbr: '1 R re.', txt: '1 Reihe rechts stricken'},
  },
  {
    id: 36,
    en: {abbr: 'krl', txt: 'Knit right loop'},
    fr: {
      abbr: 'Aug. int. D',
      txt:
        "À l'endroit : avec l'aiguille droite, prendre le brin droit de la maille du rang précédent sur l'aiguille gauche et le remettre sur l'aiguille de gauche, le tricoter à l'endroit, puis tricoter la maille du rang présent.",
    },
    de: {txt: 'Rechte Masche stricken'},
  },
  {
    id: 37,
    en: {
      abbr: 'KRPR',
      txt:
        'KRPR ou Knit 1 stitch then slip stitch back to the left needle, back over, return stitch on RH needle',
    },
    fr: {
      txt:
        "Tricoter 1 maille endroit, la glisser sur l'aiguille gauche par-dessus le 1ere maille, remettre la maille sur l'aiguille droite",
    },
    de: {
      txt:
        '1 Masche stricken, dann die Masche auf die linke Nadel zurückschieben, umschlagen und auf die rechte Nadel zurückschieben',
    },
  },
  {
    id: 38,
    en: {
      abbr: 'KSP',
      txt:
        'KSP ou KRPR ou Knit 1 stitch then slip stitch back to the left needle, back over, return stitch on RH needle',
    },
    fr: {
      abbr: 'KSP',
      txt:
        "Tricoter 1 maille endroit, la glisser sur l'aiguille gauche par-dessus le 1ere maille, remettre la maille sur l'aiguille droite",
    },
    de: {
      abbr: 'KSP',
      txt:
        '1 Masche stricken, dann eine Masche auf die linke Nadel abheben, umdrehen und auf der rechten Nadel zurückstricken',
    },
  },
  {
    id: 39,
    en: {abbr: 'LC', txt: 'Left cross'},
    fr: {abbr: 'crois. G', txt: 'Croisement à gauche'},
    de: {txt: 'Linkes Kreuz'},
  },
  {
    id: 40,
    en: {abbr: 'left lp', txt: 'Left Loop'},
    fr: {abbr: 'b. G', txt: "Brin gauche d'une maille"},
    de: {txt: 'Linke Maschenbein'},
  },
  {
    id: 41,
    en: {abbr: 'LH', txt: 'Left Hand'},
    fr: {abbr: 'main G', txt: 'Main gauche'},
    de: {abbr: 'Linke H', txt: 'Linke Hand'},
  },
  {
    id: 42,
    en: {abbr: 'LHN', txt: 'LH Needle'},
    fr: {abbr: 'aig. G', txt: 'Aiguille de gauche. Left Hand Needle'},
    de: {abbr: 'Linke N', txt: 'Linke Nädel'},
  },
  {
    id: 43,
    en: {abbr: 'lp', txt: 'Loop'},
    fr: {abbr: 'b.', txt: 'Brin, boucle, bride'},
    de: {txt: 'Maschenbein'},
  },
  {
    id: 44,
    en: {abbr: 'LT', txt: 'Left twist'},
    fr: {txt: 'Laisser les mailles se croiser'},
    de: {txt: 'Linksdrall'},
  },
  {
    id: 45,
    en: {
      abbr: 'M 1k',
      txt:
        'M 1k ou Make 1 stitch, pickup horizontal strand between stitch just worked and next stitch',
    },
    fr: {
      abbr: 'aug. int. D',
      txt:
        "Augmentation intercalaire : tricoter 1 maille à l'endroit dans le brin horizontal, entre la maille tricotée et la prochaine maille= aug, intercalaire endroit",
    },
    de: {
      abbr: 're. Zun.',
      txt:
        'rechte Zunahme: den Faden zwischen den Maschen von hinten aufnehmen und die Masche abstricken',
    },
  },
  {
    id: 46,
    en: {abbr: 'M1', txt: 'Make one'},
    fr: {abbr: 'aug.', txt: 'Augmenter, créer une maille'},
    de: {
      abbr: 'M1',
      txt:
        '1 Masche zunehmen, indem man den Arbeitsfaden einmal verdreht um die rechte Nadel wickelt (kann durch rechts- oder linksgelehnte Zunahmen ersetzt werden)',
    },
  },
  {
    id: 47,
    en: {abbr: 'M1A', txt: 'Make 1 away'},
    fr: {txt: 'Faire une maille coulée endroit'},
    de: {txt: '1 Masche wegstricken'},
  },
  {
    id: 48,
    en: {
      abbr: 'M1B',
      txt:
        'M1B make a back right, an increase. From the back, lift loop between stitches with left needle, knit into the front of the loop',
    },
    fr: {
      abbr: 'aug. int. D',
      txt:
        "Avec l'aig. gauche, prendre par derrière le brin horizontal entre la maille tricotée et la suivante et la tricoter comme une maille endroit..",
    },
    de: {
      txt:
        '1 Masche hinten rechts stricken, eine Zunahme. Von hinten mit der linken Nadel die Schlaufe zwischen den Maschen anheben, in die Vorderseite der Schlaufe stricken',
    },
  },
  {
    id: 49,
    en: {
      abbr: 'M1F ou m1L',
      txt:
        'M1F (m1L) make a front left, an increase. From front, lift loop between stitches with needle, knit into back of loop',
    },
    fr: {
      abbr: 'aug. int. G',
      txt:
        "Avec l'aig. gauche, prendre par devant le brin horizontal entre la maille tricotée et la suivante, et la tricoter à l'endroit en la prenant par l'arrière de la maille = 1 aug, intercalaire torse.",
    },
    de: {
      abbr: 'li Zun',
      txt:
        'linke Zunahme: den Faden zwischen den Maschen von vorne aufnehmen und die Masche verschränkt abstricken (hinteres Maschenglied)',
    },
  },
  {
    id: 50,
    en: {abbr: 'M1T', txt: 'Make 1 toward'},
    fr: {abbr: 'MC env.', txt: 'Faire une maille coulée envers'},
    de: {txt: '1 zu machen'},
  },
  {
    id: 51,
    en: {abbr: 'MB', txt: 'Make Bobbles'},
    fr: {abbr: 'B', txt: 'Faire des nopes'},
    de: {txt: 'Bobbles machen'},
  },
  {
    id: 52,
    en: {abbr: 'MC', txt: 'Main Color'},
    fr: {abbr: 'CP', txt: 'Couleur principale'},
    de: {abbr: 'HF', txt: 'Hauptfarbe'},
  },
  {
    id: 53,
    en: {abbr: 'Nº or #', txt: 'Number, no'},
    fr: {abbr: 'Nº ou #', txt: 'Numéro, nombre'},
    de: {abbr: 'Num.', txt: 'Nummer'},
  },
  {
    id: 54,
    en: {abbr: 'Odd row', txt: 'Odd row'},
    fr: {abbr: 'rgs imp.', txt: 'Rangs impairs'},
    de: {abbr: 'ungerade R.', txt: 'ungerade (Reihen)'},
  },
  {
    id: 55,
    en: {abbr: 'P', txt: 'Purl'},
    fr: {abbr: 'm. env.', txt: 'Tricoter à l’envers'},
    de: {abbr: 'li.', txt: 'linke Masche stricken'},
  },
  {
    id: 56,
    en: {abbr: 'p-b', txt: 'Purl stitch in row below'},
    fr: {
      abbr: '1 m. env. ds rg prec.',
      txt: "Tricoter à l'envers dans la maille du rang précédent",
    },
    de: {txt: 'Masche in der unteren Reihe li stricken'},
  },
  {
    id: 57,
    en: {abbr: 'P1', txt: 'Purl One'},
    fr: {abbr: '1 m. env.', txt: '1 Maille envers'},
    de: {abbr: '1 M li.', txt: '1 Masche links stricken'},
  },
  {
    id: 58,
    en: {abbr: 'P2tog', txt: 'Purl 2 stitches together'},
    fr: {abbr: '2 m. ens. env.', txt: "Tricoter 2 mailles ensemble à l'envers"},
    de: {abbr: '2 M li. zus.', txt: 'zwei Maschen links zusammen stricken'},
  },
  {
    id: 59,
    en: {
      abbr: 'p2tog tbl',
      txt: 'Purl 2 stitches together through the back loops',
    },
    fr: {
      abbr: '1 m. ens. env. b. arr.',
      txt:
        "À l'envers, tricotez 2 mailles ensemble dans les brins arrière des 2 mailles = 2 mailles torses ensemble dim. 1 maille",
    },
    de: {
      abbr: '2 M li. V zus.',
      txt: '2 Maschen links verschränkt zusammenstricken',
    },
  },
  {
    id: 60,
    en: {abbr: 'PM', txt: 'Place marker'},
    fr: {abbr: 'PM', txt: 'Insérer un anneau marqueur'},
    de: {abbr: 'MM setzen', txt: 'Maschenmarkierer setzen'},
  },
  {
    id: 61,
    en: {abbr: 'pnso', txt: 'Pass Next stitch over'},
    fr: {txt: 'Passer la maille suivante par-dessus'},
    de: {txt: 'Nächste Masche überziehen'},
  },
  {
    id: 62,
    en: {abbr: 'PR', txt: 'Purl row'},
    fr: {abbr: 'rg. env.', txt: 'Rang envers'},
    de: {abbr: 'li. R', txt: '1 Reihe links stricken'},
  },
  {
    id: 63,
    en: {abbr: 'psso', txt: 'Pass slip stitch over the last one'},
    fr: {txt: 'passer la maille glissée par-dessus la maille tricotée'},
    de: {txt: 'die abgehobenen M darüber ziehen'},
  },
  {
    id: 64,
    en: {abbr: 'pu', txt: 'Pick up stitches'},
    fr: {abbr: 'Rel. m.', txt: 'Relever les mailles'},
    de: {txt: 'Maschen aufnehmen'},
  },
  {
    id: 65,
    en: {abbr: 'R', txt: 'Right'},
    fr: {abbr: 'D', txt: 'Droit'},
    de: {txt: 'Recht'},
  },
  {
    id: 66,
    en: {abbr: 'RC', txt: 'Right Cross'},
    fr: {abbr: 'crois. D', txt: 'Croisement à droite'},
    de: {txt: 'Rechtes Kreuz'},
  },
  {
    id: 67,
    en: {abbr: 'rem', txt: 'Remaining'},
    fr: {abbr: 'rest.', txt: 'Restant/restante'},
    de: {txt: 'bleibende'},
  },
  {
    id: 68,
    en: {abbr: 'rep', txt: 'Repeat'},
    fr: {abbr: 'rep.', txt: 'Répéter'},
    de: {abbr: 'wdh', txt: 'wiederholen'},
  },
  {
    id: 69,
    en: {abbr: 'Rev st st', txt: 'Reverse stockinette stitch'},
    fr: {txt: 'Jersey envers'},
    de: {abbr: 'gl li.', txt: 'glatt links stricken'},
  },
  {
    id: 70,
    en: {abbr: 'RH', txt: 'Right Hand'},
    fr: {abbr: 'main D', txt: 'Main droite'},
    de: {abbr: 'Rechte H', txt: 'Rechte Hand'},
  },
  {
    id: 71,
    en: {abbr: 'RHN', txt: 'RH Needle'},
    fr: {abbr: 'aig. D', txt: 'Aiguille de droite. Right Hand Needle'},
    de: {abbr: 'Rechte N', txt: 'Rechte Nädel'},
  },
  {
    id: 72,
    en: {abbr: 'rib', txt: 'Ribbing'},
    fr: {txt: 'Tricoter en côtes'},
    de: {txt: 'Rippung'},
  },
  {
    id: 73,
    en: {abbr: 'right lp', txt: 'Right Loop'},
    fr: {abbr: 'b. D', txt: "Brin droit d'une maille"},
    de: {txt: 'Rechte Maschenbein'},
  },
  {
    id: 74,
    en: {abbr: 'rnd', txt: 'Round'},
    fr: {abbr: 'T', txt: 'Tour (désigne un rang quand on tricote en rond)'},
    de: {abbr: 'Rd', txt: 'Runde'},
  },
  {
    id: 75,
    en: {abbr: 'RS', txt: 'Front or Right side (of work)'},
    fr: {abbr: 'Coté end.', txt: "Endroit (de l'ouvrage)"},
    de: {abbr: 'HinR', txt: 'Hinreihe'},
  },
  {
    id: 76,
    en: {abbr: 'RS', txt: 'Right Side'},
    fr: {abbr: 'Coté end.', txt: 'Côté endroit'},
    de: {abbr: 'HinR', txt: 'Hinreihe'},
  },
  {
    id: 77,
    en: {abbr: 'RT', txt: 'Right Twist'},
    fr: {txt: 'Laisser les mailles se croiser à droite'},
    de: {txt: 'Rechtsdrall'},
  },
  {
    id: 78,
    en: {abbr: 'Rw', txt: 'Row'},
    fr: {abbr: 'rg.', txt: 'Rang'},
    de: {abbr: 'R', txt: 'Reihe'},
  },
  {
    id: 79,
    en: {abbr: 'S/S', txt: 'Stocking stitch s/s'},
    fr: {txt: 'Point de jersey'},
    de: {abbr: 'gl re.', txt: 'glatt rechts'},
  },
  {
    id: 80,
    en: {abbr: 'S1K1P (ou) skp (ou) skpo', txt: 'Slip, knit, pass over'},
    fr: {
      abbr: 'Surj. s.',
      txt:
        "Surjet simple : glisser 1 maille, tricoter 1 maille à l'endroit, passer la maille glissée par-dessus la maille tricotée",
    },
    de: {txt: 'Abnehmen, stricken, überziehen'},
  },
  {
    id: 81,
    en: {abbr: 'sk', txt: 'Skip'},
    fr: {txt: 'Passer, ignorer, ne pas tenir compte de…'},
    de: {txt: 'Überspringen'},
  },
  {
    id: 82,
    en: {
      abbr: 'sk2p',
      txt: 'Slip 1, knit 2 together , pass slip stitch over knitted stitch',
    },
    fr: {
      abbr: 'Surj. d.',
      txt:
        "À l'endroit : 1 maille glissée, 2 mailles tricotées ensemble à l'endroit, passer la maille glissée par-dessus la maille tricotée",
    },
    de: {
      txt:
        '1 abnehmen, 2 zusammenstricken, Schlupfmasche über die gestrickte Masche legen',
    },
  },
  {
    id: 83,
    en: {abbr: 'sl', txt: 'Slip'},
    fr: {abbr: 'glis.', txt: 'Glisser'},
    de: {abbr: 'abh.', txt: 'abheben'},
  },
  {
    id: 84,
    en: {abbr: 'sl k-wise', txt: 'Slip 1 stitch knit wise'},
    fr: {
      abbr: '1 m. glis. end.',
      txt: "Faire glisser 1 maille comme pour à l'endroit",
    },
    de: {abbr: '1 M re. abh.', txt: '1 Maschen wie zum Rechtsstricken abheben'},
  },
  {
    id: 85,
    en: {abbr: 'sl p-wise', txt: 'Slip 1 stitch purl wise'},
    fr: {
      abbr: '1 m. glis. env.',
      txt: "Faire glisser 1 maille comme pour à l'envers",
    },
    de: {abbr: '1 M li. abh.', txt: '1 Maschen wie zum Linkssstricken abheben'},
  },
  {
    id: 86,
    en: {abbr: 'sl st', txt: 'Slip stitch'},
    fr: {abbr: '1 m. glis.', txt: 'Maille glissée'},
    de: {abbr: '1 M abh.', txt: '1 Masche abheben'},
  },
  {
    id: 87,
    en: {abbr: 'sm', txt: 'Slip marker'},
    fr: {abbr: 'glis. M', txt: 'Glisser le marqueur'},
    de: {abbr: 'Markierer abh.', txt: 'Maschenmarkierer abheben'},
  },
  {
    id: 88,
    en: {
      abbr: 'SSK',
      txt:
        'Slip, slip, knit = slip 2 stitches as if to knit, slip both back on left needle, knit these 2 stitches together',
    },
    fr: {
      txt:
        "Faire glisser 2 mailles séparément comme pour les tricoter à l'endroit, les remettre ensemble sur l'aiguille gauche, tricoter les 2 mailles ensemble à l'endroit= 2 mailles torses ensemble à l'endroit",
    },
    de: {
      abbr: 'SSK',
      txt:
        'links geneigte Abnahme: zwei Maschen wie zum rechts Stricken abheben, eine nach der anderen, die linke Nadel von vorne in die Masche einführen, und dieselben rechts zusammenstricken',
    },
  },
  {
    id: 89,
    en: {abbr: 'SSP', txt: 'Slip, slip, purl, a decrease on purl-wise'},
    fr: {
      txt:
        "Faire glisser 2 mailles séparément comme pour à l'envers, remettre les 2 mailles ensemble sur l'aiguille de gauche, et tricoter les 2 mailles ensemble à l'envers",
    },
    de: {
      abbr: '2 abh. 1 li.',
      txt: '2 Maschen abheben, 1 Masche links stricken',
    },
  },
  {
    id: 90,
    en: {
      abbr: 'sssk',
      txt: 'Slip, slip, slip ,knit the 3 slipped stitches together,',
    },
    fr: {
      txt:
        "Faire glisser 3 mailles séparément comme pour à l'endroit les remettre ensemble sur l'aiguille gauche, tricoter les 3 mailles ensemble à l'endroit = 3 mailles torses ensemble endroit.",
    },
    de: {
      abbr: '3 abh. 1 re.',
      txt: '3 Maschen abheben, 1 Masche rechts stricken',
    },
  },
  {
    id: 91,
    en: {abbr: 'sssp', txt: 'Slip, slip, slip, a double decrease on purl-wise'},
    fr: {
      txt:
        "Faire glisser 3 mailles séparément comme pour à l'envers, remettre les 3 mailles ensemble sur l'aiguille de gauche et tricoter les 3 mailles ensemble à l'envers",
    },
    de: {
      abbr: '3 abh. 1 li.',
      txt: '3 Maschen abheben, 1 Masche links stricken',
    },
  },
  {
    id: 92,
    en: {abbr: 'st', txt: 'Stitch'},
    fr: {abbr: 'm.', txt: 'Maille'},
    de: {abbr: 'M', txt: 'Masche'},
  },
  {
    id: 93,
    en: {abbr: 'st st', txt: 'Stockinette stitch'},
    fr: {txt: 'Jersey endroit'},
    de: {abbr: 'gl re.', txt: 'glatt rechts stricken'},
  },
  {
    id: 94,
    en: {abbr: 't.', txt: 'Time'},
    fr: {abbr: 'x', txt: 'Fois'},
    de: {txt: 'mal'},
  },
  {
    id: 95,
    en: {abbr: 'tbl', txt: 'Through back of loop'},
    fr: {abbr: 'b. arr.', txt: "Dans l'arrière de la maille"},
    de: {abbr: 'verschr', txt: 'verschränkt'},
  },
  {
    id: 96,
    en: {abbr: 'tog ou altog', txt: 'Together (stitches)'},
    fr: {abbr: 'ens.', txt: 'Ensemble (mailles)'},
    de: {abbr: 'Zus.', txt: 'Zusammen'},
  },
  {
    id: 97,
    en: {abbr: 'WS', txt: 'Wrong side'},
    fr: {abbr: 'env.', txt: "Envers (de l'ouvrage)"},
    de: {abbr: 'RückR', txt: 'Rücken Reihe'},
  },
  {
    id: 98,
    en: {abbr: 'wyib', txt: 'With yarn in back'},
    fr: {txt: "Avec le fil sur l'arrière de l'ouvrage"},
    de: {abbr: 'mFhA', txt: 'mit Faden hinter der Arbeit'},
  },
  {
    id: 99,
    en: {abbr: 'wyif', txt: 'With yarn in front'},
    fr: {txt: "Avec le fil sur le devant de l'ouvrage"},
    de: {abbr: 'mFvA', txt: 'mit Faden vor der Arbeit'},
  },
  {
    id: 100,
    en: {abbr: 'yfrn', txt: 'Yarn forward, or yarn around the needle or over'},
    fr: {txt: 'Jeté'},
    de: {abbr: 'U', txt: 'Umschlag'},
  },
  {
    id: 101,
    en: {abbr: 'yo (ou) yon', txt: 'Yarn over'},
    fr: {txt: 'Jeté'},
    de: {abbr: 'U', txt: 'Umschlag'},
  },
  {
    id: 102,
    en: {abbr: 'yo2 ou yo twice', txt: 'Yarn over twice'},
    fr: {txt: 'Jeté double'},
    de: {abbr: 'doppelte U', txt: 'doppelte Umschlag'},
  },
]
