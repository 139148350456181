

export const SYMBOLS_DE = [

  {
      "id": 0,
      "rs": "Nichts",
      "ws": "Nichts",
      "rs_long": "Nichts",
      "ws_long": "Nichts",
      "img": "/imgs/symbols/0_null.png",
      "t_img": "/imgs/symbols/transparent/0_null.png",
  },
  {
      "id": 1,
      "rs": "re.",
      "ws": "li.",
      "rs_long": "Rechts",
      "ws_long": "Links",
      "img": "/imgs/symbols/1_k_p.png",
      "t_img": "/imgs/symbols/transparent/1_k_p.png"
  },
  
  {
      "id": 2,
      "rs": "li.",
      "ws": "re.",
      "rs_long": "Links",
      "ws_long": "Rechts",
      "img": "/imgs/symbols/2_p_k.png",
      "t_img": "/imgs/symbols/transparent/2_p_k.png"
  },
  
  {
      "id": 3,
      "rs": "Umschlag",
      "ws": "Umschlag",
      "rs_long": "Umschlag",
      "ws_long": "Umschlag",
     // "img": "/imgs/symbols/3_yo.png",
      "t_img": "/imgs/symbols/transparent/3_yo.png"
  },
  
  {
      "id": 4,
      "rs": "2 M re. zus.",
      "ws": "2 M li. zus.",
      "rs_long": " 2 M rechts zusammenstricken",
      "ws_long": " 2 M links zusammenstricken",
     // "img": "/imgs/symbols/4_k2tog_p2tog.png",
      "t_img": "/imgs/symbols/transparent/4_k2tog_p2tog.png"
  },
  
  {
      "id": 5,
      "rs": "2 M li. zus.",
      "ws": "2 M re. zus.",
      "rs_long": "2 M links zusammenstricken",
      "ws_long": " 2 M rechts zusammenstricken",
      //"img": "/imgs/symbols/5_p2tog_k2tog.png",
      "t_img": "/imgs/symbols/transparent/5_p2tog_k2tog.png"
  },
  
  {
      "id": 6,
      "rs": "2 M abh., 1 M re.",
      "ws": "2 M abh., 1 M li.",
      "rs_long": "2 M abheben, 1M rechts",
      "ws_long": "2 M abheben, 1M links",
     // "img": "/imgs/symbols/6_SSK_SSP.png",
      "t_img": "/imgs/symbols/transparent/6_SSK_SSP.png"
  },
  
  {
      "id": 7,
      "rs": "2 M abh., 1 M li.",
      "ws": "2 M abh., 1 M re.",
      "rs_long": "2 M abheben, 1 M links",
      "ws_long": "2 M abheben, 1 M rechts",
      //"img": "/imgs/symbols/7_SSP_SSK.png",
      "t_img": "/imgs/symbols/transparent/7_SSP_SSK.png",
  },
  
  {
      "id": 8,
      "rs": "1 M re. V&H",
      "ws": "1 M li. V&H",
      "rs_long": "Rechtsmaschen aus dem vorderen und hinteren Maschenglied herausstricken",
      "ws_long": "Linkssmaschen aus dem vorderen und hinteren Maschenglied herausstricken",
     // "img": "/imgs/symbols/8_K1fb_p1fb.png",
      "t_img": "/imgs/symbols/transparent/8_K1fb_p1fb.png",
  },
  
  {
      "id": 9,
      "rs": "1 M re. zun.",
      "ws": "1 M li. zun.",
      "rs_long": "1 Masche rechts zunehmen",
      "ws_long": "1 Masche links zunehmen",
    //  "img": "/imgs/symbols/9_m1.png",
      "t_img": "/imgs/symbols/transparent/9_m1.png"
  },
  
  {
      "id": 10,
      "rs": "Make 1 (M1) purlwise",
      "ws": "make 1 (M1) knitwise",
      "rs_long": "1 Masche links zunehmen",
      "ws_long": "1 Masche rechts zunehmen",
    //  "img": "/imgs/symbols/10_m1.png",
      "t_img": "/imgs/symbols/transparent/10_m1.png"
  },
  
  {
      "id": 11,
      "rs": "1 M zun. R.",
      "ws": "1 M zun. R.",
      "rs_long": "1 Masche nach rechts gedreht rechts zunehmen",
      "ws_long": "1 Masche nach rechts gedreht links zunehmen",
    //  "img": "/imgs/symbols/11_Rslanting.png",
      "t_img": "/imgs/symbols/transparent/11_Rslanting.png"
  },
  
  {
      "id": 12,
      "rs": "1 M zun. L.",
      "ws": "1 M zun. L.",
      "rs_long": "1 Masche nach links gedreht rechts zunehmen",
      "ws_long": "1 Masche nach links gedreht links zunehmen",
     // "img": "/imgs/symbols/12_Lslanting.png",
      "t_img": "/imgs/symbols/transparent/12_Lslanting.png"
  },
  
  {
      "id": 13,
      "rs": "1 M re. zun. R.",
      "ws": "1 M li. zun. R.",
      "rs_long": "1 Masche nach rechts gedreht & angehoben rechts zunehmen",
      "ws_long": "1 Masche nach rechts gedreht & angehoben links zunehmen",
     // "img": "/imgs/symbols/13_RslantingLift.png",
      "t_img": "/imgs/symbols/transparent/13_RslantingLift.png"
  },
  
  
  {
      "id": 14,
      "rs": "1 M re. zun. L.",
      "ws": "1 M li. zun. L.",
      "rs_long": "1 Masche nach links gedreht & angehoben rechts zunehmen",
      "ws_long": "1 Masche nach links gedreht & angehoben links zunehmen",
      "t_img": "/imgs/symbols/transparent/14_LslantingLift.png"
  },
  
  {
      "id": 15,
      "rs": "1 M li. abh. Fdv",
      "ws": "1 M li. abh. Fdh",
      "rs_long": "Masche wie zum Linkstricken abheben, Arbeitsfaden vorne der Arbeit",
      "ws_long": "Masche wie zum Linkstricken abheben, Arbeitsfaden hinter der Arbeit",
      "t_img": "/imgs/symbols/transparent/15_sl1purl.png"
  },
  {
      "id": 16,
      "rs": "1 M re. abh. Fdv",
      "ws": "1 M re. abh. Fdh",
      "rs_long": "Masche wie zum Rechtstricken abheben, Arbeitsfaden vorne der Arbeit",
      "ws_long": "Masche wie zum Rechtstricken abheben, Arbeitsfaden hinter der Arbeit",
      "t_img": "/imgs/symbols/transparent/16_sl1purl.png"
  },
  
  
  {
      "id": 17,
      "rs": "3 M re. zus",
      "ws": "3 M li. zus",
      "rs_long": "3 Maschen rechts zusammenstricken",
      "ws_long": "3 Maschen links zusammenstricken",
      "t_img": "/imgs/symbols/transparent/17_K3tog_P3tog.png"
  },
  
  {
      "id": 18,
      "rs": "3 M li. zus.",
      "ws": "3 M re. zus",
      "rs_long": "3 Maschen links zusammenstricken",
      "ws_long": "3 Maschen rechts zusammenstricken",
      "t_img": "/imgs/symbols/transparent/18_P3tog_k3tog.png"
  },
  
  
  {
      "id": 19,
      "rs": "SK2P",
      "ws": "SSSP",
      "rs_long": "1 doppelter Überzug (d.h. 1 Masche abheben, 2 Maschen rechts zusammenstricken, die abgehobene Masche über die gestrickte Masche ziehen)",
      "ws_long": "3 Maschen nacheinander rechts abheben und dann links zusammenstricken",
      "t_img": "/imgs/symbols/transparent/19_SK2P_SSSK_SSSP.png"
  },
  
  
  {
      "id": 20,
      "rs": "SSSP",
      "ws": "SSSK",
      "rs_long": "3 Maschen nacheinander rechts abheben und dann links zusammenstricken",
      "ws_long": "3 Maschen nacheinander rechts abheben und dann rechts zusammenstricken",
      "t_img": "/imgs/symbols/transparent/20_SSSP_SSSK.png"
  },
  
  
  {
      "id": 21,
      "rs": "S2KP2",
      "ws": "SSPP2",
      "rs_long": "2 doppelter Überzug (d.h. 1 Masche abheben, 2 Maschen rechts zusammenstricken, die abgehobene Masche über die gestrickte Masche ziehen)",
      "ws_long": "1 doppelter Überzug (d.h. 1 Masche abheben, 2 Maschen links zusammenstricken, die abgehobene Masche über die gestrickte Masche ziehen)",
      "t_img": "/imgs/symbols/transparent/21_S2KP2_SSPP2.png"
  },
  
  {
      "id": 22,
      "rs": "1 M re. tbl",
      "ws": "1 M li. tbl",
      "rs_long": "Rechts in den hinteren Maschenbogen eistechen",
      "ws_long": "Links in den hinteren Maschenbogen eistechen",
      "t_img": "/imgs/symbols/transparent/22_K1_tbl_p1_tbl.png"
  },
  
  {
      "id": 23,
      "rs": "1 M li. tbl",
      "ws": "1 M re. tbl",
      "rs_long": "Links in den hinteren Maschenbogen eistechen",
      "ws_long": "Rechts in den hinteren Maschenbogen eistechen",
      "t_img": "/imgs/symbols/transparent/23_P1_tbl_k1_tbl.png"
  },
  
  
  {
      "id": 24,
      "rs": "Bobble",
      "ws": "Bobble",
      "rs_long": "Knötchen",
      "ws_long": "Knötchen",
      "t_img": "/imgs/symbols/transparent/24_Bobble.png"
  },
  
  {
      "id": 25,
      "rs": "1 zu 3 erweitern",
      "ws": "1 zu 3 erweitern",
      "rs_long": "1 zu 3 erweitern",
      "ws_long": "1 zu 3 erweitern",
      "t_img": "/imgs/symbols/transparent/25_Inc1-3.png"
  },
  
  {
      "id": 26,
      "rs": "1 zu 4 erweitern",
      "ws": "1 zu 4 erweitern",
      "rs_long": "1 zu 4 erweitern",
      "ws_long": "1 zu 4 erweitern",
      "t_img": "/imgs/symbols/transparent/26_Inc1-4.png"
  },
  
  {
      "id": 27,
      "rs": "1 zu 5 erweitern",
      "ws": "1 zu 5 erweitern",
      "rs_long": "1 zu 5 erweitern",
      "ws_long": "1 zu 5 erweitern",
      "t_img": "/imgs/symbols/transparent/27_Inc1-5.png"
  },
  
  {
      "id": 28,
      "rs": "4 zu 1 abn. (nach re. gedr.)",
      "ws": "4 zu 1 abn. (nach re. gedr.)",
      "rs_long": "4 zu 1 abnehmen (nach rechts gedreht)",
      "ws_long": "4 zu 1 abnehmen (nach rechts gedreht)",
      "t_img": "/imgs/symbols/transparent/28_Dec4-1_right_slanting.png"
  },
  
  {
      "id": 29,
      "rs": "4 zu 1 abn. (nach li. gedr.)",
      "ws": "4 zu 1 abn. (nach li. gedr.)",
      "rs_long": "4 zu 1 abnehmen (nach links gedreht)",
      "ws_long": "4 zu 1 abnehmen (nach links gedreht)",
      "t_img": "/imgs/symbols/transparent/29_Dec4-1_left_slanting.png"
  },
  
  {
      "id": 30,
      "rs": "4 zu 1 abn. (mitte)",
      "ws": "4 zu 1 abn. (mitte)",
      "rs_long": "4 zu 1 abnehmen (mitte)",
      "ws_long": "4 zu 1 abnehmen (mitte)",
      "t_img": "/imgs/symbols/transparent/30_Dec4-1_center.png"
  },
  
  {
      "id": 31,
      "rs": "5 zu 1 abn.",
      "ws": "5 zu 1 abn.",
      "rs_long": "5 zu 1 abnehmen",
      "ws_long": "5 zu 1 abnehmen",
      "t_img": "/imgs/symbols/transparent/31_Dec5-1.png"
  },
  
  
  {
      "id": 32,
      "rs": "1 M rechts, Wolle um die Nadel herum",
      "ws": "1 M rechts, Wolle um die Nadel herum",
      "rs_long": "1 M rechts, Wolle um die Nadel herum",
      "ws_long": "1 M rechts, Wolle um die Nadel herum",
      "t_img": "/imgs/symbols/transparent/32_K1_wrapping_twice.png"
  },
  
  {
      "id": 33,
      "rs": "abketten",
      "ws": "abketten",
      "rs_long": "abketten",
      "ws_long": "abketten",
      "t_img": "/imgs/symbols/transparent/33_Bind_off.png"
  },
  
  {
      "id": 34,
      "rs": "M bleibt nach dem letzten abgek. M auf der re. Nad.",
      "ws": "M bleibt nach dem letzten abgek. M auf der re. Nad.",
      "rs_long": "M bleibt nach dem letzten abgeketteten M auf der rechten Nadel",
      "ws_long": "M bleibt nach dem letzten abgeketteten M auf der rechten Nadel",
      "t_img": "/imgs/symbols/transparent/34_Strem.png"
  },
  
  {
      "id": 35,
      "rs": "anschlagen",
      "ws": "anschlagen",
      "rs_long": "anschlagen",
      "ws_long": "anschlagen",
      "t_img": "/imgs/symbols/transparent/35_Cast_on.png"
  },
  
  {
      "id": 36,
      "rs": "2M Verzopfung nach recht",
      "ws": "",
      "rs_long": "2M Verzopfung nach rechts",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/36_2st_RC.png",
      "width":2,
      "height":1
  },
  {
      "id": 37,
      "rs": "2M Verzopfung nach links",
      "ws": "",
      "rs_long": "2M Verzopfung nach links",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/37_2st_LC.png",
      "width":2,
      "height":1
  },
  {
      "id": 38,
      "rs": "2 M RPC",
      "ws": "",
      "rs_long": "1 Masche auf die Kabelnadel legen und nach hinten halten, 1 M rechts, 1 M links von der Kabelnadel",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/38_2stRPC.png",
      "width":2,
      "height":1
  },
  {
      "id": 39,
      "rs": "2 M LPC",
      "ws": "",
      "rs_long": "1 Masche auf die Kabelnadel legen und nach vorne halten, 1 M rechts, 1 M links von der Kabelnadel",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/39_2stLPC.png",
      "width":2,
      "height":1
  },
  {
      "id": 40,
      "rs": "3 M RC",
      "ws": "",
      "rs_long": "1 Masche auf die Kabelnadel legen und nach hinten halten, 2 M rechts, 1 M links von der Kabelnadel",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/40_3st_RC.png",
      "width":3,
      "height":1
  },
  {
      "id": 41,
      "rs": "3 M LC",
      "ws": "",
      "rs_long": "2 Maschen auf die Kabelnadel legen und nach vorne halten, 1 M links, 2 M rechts von der Kabelnadel",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/41_3st_LC.png",
      "width":3,
      "height":1
  },
  {
      "id": 42,
      "rs": "3 M RPC",
      "ws": "",
      "rs_long": "1 Masche auf die Kabelnadel legen, nach hinten halten, 2 M rechts, 1 M links von der Kabelnadel",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/42_3st_RPC.png",
      "width":3,
      "height":1
  },
  {
      "id": 43,
      "rs": "3 M LPC",
      "ws": "",
      "rs_long": "2 Maschen auf die Kabelnadel legen, nach vorne halten, 1 M links, 2 M rechts von der Kabelnadel",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/43_3_stLPC.png",
      "width":3,
      "height":1
  },
  {
      "id": 44,
      "rs": "4 M RC",
      "ws": "",
      "rs_long": "2 Maschen auf die Kabelnadel legen und nach hinten halten, 2 M rechts, 2 M rechts von der Kabelnadel",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/44_4st_RC.png",
      "width":4,
      "height":1
  },
  {
      "id": 45,
      "rs": "4 M LC",
      "ws": "",
      "rs_long": "2 Maschen auf die Kabelnadel legen und nach vorne halten, 2 M rechts, 2 M rechts von der Kabelnadel",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/45_4st_LC.png",
      "width":4,
      "height":1
  },
  {
      "id": 46,
      "rs": "4 M RPC",
      "ws": "",
      "rs_long": "2 Maschen auf die Zopfnadel legen und nach hinten halten, 2 M rechts, 2 M links von der Zopfnadel",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/46_4st_RPC.png",
      "width":4,
      "height":1
  },
  {
      "id": 47,
      "rs": "4 M LPC",
      "ws": "",
      "rs_long": "2 Maschen auf die Zopfnadel legen und nach vorne halten, 2 M links, 2 M rechts von der Zopfnadel",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/47_4st_LPC.png",
      "width":4,
      "height":1
  },
  {
      "id": 48,
      "rs": "5 M RPC",
      "ws": "",
      "rs_long": "2 Maschen auf die Kabelnadel abheben, nach hinten halten, 1 Masche auf die zweite Kabelnadel abheben, nach hinten halten, 2 M rechts, 1 M links von der zweiten Kabelnadel, 2 M rechts von der ersten Kabelnadel",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/48_5st_RPC.png",
      "width":5,
      "height":1
  },
  {
      "id": 49,
      "rs": "5 M LPC",
      "ws": "",
      "rs_long": "2 Maschen auf die Kabelnadel abheben, nach vorne halten, 1 Masche auf die zweite Kabelnadel abheben, nach hinten halten, 2 M rechts, 1 M links von der hinteren Kabelnadel, 2 M rechts von der vorderen Kabelnadel",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/49_5st_LPC.png",
      "width":5,
      "height":1
  },
  
  
  
  
  //CROCHET
  {
      "id": 50,
      "rs": "LM",
      "ws": "",
      "rs_long": "Luftmasche",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/50_chain.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  {
      "id": 51,
      "rs": "KM",
      "ws": "",
      "rs_long": "Kettmasche",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/51_slip.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  {
      "id": 52,
      "rs": "FM",
      "ws": "",
      "rs_long": "feste Masche",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/52_single_crochet.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  {
      "id": 53,
      "rs": "hStb",
      "ws": "",
      "rs_long": "halbes Stäbchen",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/53_half_double_crochet.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 54,
      "rs": "Stb",
      "ws": "",
      "rs_long": "Stäbchen",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/54_double_crochet.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 55,
      "rs": "DStb",
      "ws": "",
      "rs_long": "Doppelstäbchen",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/55_treble_crochet.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 56,
      "rs": "3er-Stb",
      "ws": "",
      "rs_long": "Dreifachstäbchen",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/56_double_treble_crochet.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 57,
      "rs": "2 Fm zsm. (abm.)",
      "ws": "",
      "rs_long": "2 feste Maschen zusammenhäkeln",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/57_sc2tog.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  
  {
      "id": 58,
      "rs": "3 Fm zsm. (abm.)",
      "ws": "",
      "rs_long": "3 feste Maschen zusammenhäkeln",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/58_sc3tog.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  
  {
      "id": 59,
      "rs": "2 Stb zsm. (abm.)",
      "ws": "",
      "rs_long": "2 Stäbchen zusammenhäkeln",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/59_dc2tog.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 60,
      "rs": "3 Stb zsm. (abm.)",
      "ws": "",
      "rs_long": "3 Stäbchen zusammenhäkeln",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/60_dc3tog.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 61,
      "rs": "3 Stb Maschengruppe",
      "ws": "",
      "rs_long": "3 Stäbchen Maschengruppe",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/61_3dc_cluster.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 62,
      "rs": "3 hStb Maschengruppe",
      "ws": "",
      "rs_long": "3 halbes Stäbchen Maschengruppe",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/62_3hdc_cluster.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 63,
      "rs": "5 Stb Popcorn",
      "ws": "",
      "rs_long": "5 Stäbchen Popcorn",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/63_5_dc_popcorn.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 64,
      "rs": "5 Stb Shell",
      "ws": "",
      "rs_long": "5 Stäbchen Shell",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/64_5_dc_shell.png",
      "width":2,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 65,
      "rs": "3 LM picot",
      "ws": "",
      "rs_long": "3 Luftmasche picot",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/65_ch_3_picot.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  
  {
      "id": 66,
      "rs": "(v)RStb",
      "ws": "",
      "rs_long": "(vorderes) Reliefstäbchen",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/66_front_post.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 67,
      "rs": "hinteres RStb",
      "ws": "",
      "rs_long": "hinteres Reliefstäbchen",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/67_back_post.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 68,
      "rs": "nur hMg",
      "ws": "",
      "rs_long": "nur ins hintere Maschenglied arbeiten",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/68_back_loop.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  
  {
      "id": 69,
      "rs": "nur vMg",
      "ws": "",
      "rs_long": "nur in vorderes Maschenglied arbeiten",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/69_front_loop.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  ]
  
  
  
//   export function searchSymbol(text) {
  
//     var newArray = []
//     for (var i = 0; i < SYMBOLS.length; i++) {
//       if (SYMBOLS[i].rs.indexOf(text) != -1 || SYMBOLS[i].ws.indexOf(text) != -1 || SYMBOLS[i].rs_long.indexOf(text) != -1
//        || SYMBOLS[i].ws_long.indexOf(text) != -1) {
//         newArray.push(SYMBOLS[i])
//       }
//     }
  
//     return newArray
//   }
  