import React, { PureComponent } from "react";

import strings from "../config/strings";




import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions,
  Image
} from 'react-native';

var moment = require('moment');

const MARGIN_HORIZONTAL = 16
const SECONDARY_COLOR = '#2ba5f7'


export default class ChatItem extends PureComponent {


  renderItemTitle(item) {
    var picSize = 20
    var picRadius = 10
    var timeAgoColor = this.props.forum ? "#666" : "#999"
    var usernameColor = this.props.forum ? "#266D9C" : SECONDARY_COLOR

    return (

      <TouchableOpacity style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems:'center', marginVertical:8} }
        onPress={() => {}}
      >

      { item.user.avatar_url_small && <Image
           source={item.user.avatar_url_small}
           resizeMode="cover"
           style={{ height: picSize, width: picSize, borderRadius:picRadius, overflow: "hidden" }}

         />}

         { !item.userPic &&  <Image style={{width: 24, height: 24}} urlOnServer={'../imgs/default_user_img.png'} />}

     <Text
        style={{
          paddingHorizontal:4,
          color:usernameColor,
          fontSize: 12 }}>
             {item.user.name}<Text style={{paddingHorizontal:4, color:timeAgoColor, fontStyle:'italic', }}> {moment(item.time * 1000).locale(strings.lang).fromNow()} </Text></Text>
     </TouchableOpacity>
    )
  }


  renderImg(item) {
    var picSize = 130
    var picStyle = { width: "100%", minWidth: picSize, height: picSize, borderRadius: 8, overflow: "hidden" }
    return (
      <TouchableOpacity
          onPress={() => this.props.onImgClicked(item)}
          onLongPress={() => {
            if ( item.status == 0)
              this.props.onLongPress(item)}
          }
          style={{ flexDirection: "row", height: picSize, marginVertical:8, }}>
              <TouchableOpacity
              style={{color: SECONDARY_COLOR, opacity: 0.8, position:'absolute', top: 8, right: 8, zIndex: 9999999}}
              onPress={() => this.props.onImgClicked(item)}>
                 <Text>+</Text>
              </TouchableOpacity>

             <Image
                style={picStyle}
                imageStyle={picStyle}
                source={item.img_url_thumbnail}
                //source={require('../../imgs/knitting-ph.jpeg')}
                //thumbnailSource={require('../../imgs/knitting-ph.jpeg')}
                resizeMode="cover"/>

      </TouchableOpacity>


    )
  }
  renderMessage(item) {

    const isDarkMode = this.props.isDarkMode

    var myReplyColor = isDarkMode ? "#f6f6f6" : "#333"
    var otherReplyColor = isDarkMode ? "#111" : "#555"
    //console.log(item)
    return (
      <View >
          <Text
            style={{
              marginhorizontal: 8,
              fontSize: 12,
              letterSpacing: 0.5,
              textAlign: 'left',
              color: item.status ? '#fff' : otherReplyColor,
            }}>
           {!item.status ? 'message_moderated' : item.msg}
          </Text>
          </View>
    )
          }
  
  render() {


    const item = this.props.item
    var opacityLevel =  0.5;
    var isMine = item.userIdOnServer === this.props.myUserId;

    var alignment = 'flex-start'




//alert(JSON.stringify(item.reactions)  )
    return (

      <View >


        {!item.doNotShowUserInfo && this.renderItemTitle(item, isMine)


        }
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: alignment,
          padding: 2,
        }}
      >


        <View style={{ justifyContent: 'center', alignItems: "flex-start" }}>


          <View
          style={{
            backgroundColor:  `rgba(43,165,247,${opacityLevel})`,
            borderRadius: 20,
            borderTopRightRadius: 20,
            borderTopLeftRadius: 0,
            marginTop: 2,
            padding: 8,
            paddingHorizontal: 16,
            marginLeft:20,

          }} >

            {item.img_url_thumbnail && this.renderImg(item)}
      


          { this.renderMessage(item) }


          </View>
      
        </View>


      </View>
      </View>
    )
  }
}
