import React from 'react';
import useToken from '../../hooks/useToken';
import useUser from '../../hooks/useUser';
import { useState, useEffect } from 'react';
import PatternUtils from "../../generator/PatternUtils";
import RavelryNeedlesUtils from "../../generator/RavelryNeedlesUtils";
import { LANGS } from "../../generator/PatternUtils";
import Image from "../../components/Image";
import { useParams, useHistory, useLocation } from "react-router-dom";
import strings from "../../config/strings";
import Utils from '../../utils/utils';
import Dropdown from 'react-bootstrap/Dropdown'
import { getSizesFromIds, getCatFromSize } from "../../config/sizes"

import { YARN_WEIGHT_RAVELRY, COLORS_RAVELRY } from '../../config/RavelryConstants'

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions,
  ViewBase
} from 'react-native';

export default function EditMaterial(props) {
  const [createdPattern, setCreatedPattern] = useState(props.createdPattern)

  const [showAddNeedle, setShowAddNeedle] = useState(false)
  const [showAddYarn, setShowAddYarn] = useState(false)

  const [allNeedles, setAllNeedles] = useState( (props.createdPattern && props.createdPattern.pattern_needles) ? props.createdPattern.pattern_needles : [])
  const [allYarn, setAllYarn] = useState( (props.createdPattern && props.createdPattern.pattern_yarns) ? props.createdPattern.pattern_yarns : [])

  const [yarnName, setYarnName] = useState("")
  const [yarnCompanyName, setYarnCompanyName] = useState("")
  const [yarnWeight, setYarnWeight] = useState("")
  const [yarnColor, setYarnColor] = useState("")
  const [allColors, setAllColors] = useState([...COLORS_RAVELRY])
  const [yarnQuantity, setYarnQuantity] = useState([])
  
  const [needleType, setNeedleType] = useState()
  const [needleLength, setNeedleLength] = useState()
  const [needleSize, setNeedleSize] = useState()

  const [editYarn, setEditYarn] = useState(false)
  const [editNeedle, setEditNeedle] = useState(false)

  const [showAlertMissingInfo, setShowAlertMissingInfo] = useState(false)

  //createNeedle(sizeIdOnRavelry, length, typeNeedle, sizeUS, sizeMetric)

  const allNeedleTypes = [
    {
      nameOnRavelry: "straight",
      name: strings.straight,
      pic: "/imgs/straight.png"
    },
    {
      nameOnRavelry: "dp",
      name: strings.double,
      pic: "/imgs/double.png"
    },
    {
      nameOnRavelry: "circular",
      name: strings.circular,
      pic: "/imgs/circular.png"
    },
    {
      nameOnRavelry: "hook",
      name: strings.hook,
      pic: "/imgs/hook.png"
    },
    {
      nameOnRavelry: "steel",
      name: strings.steel, 
      pic: "/imgs/steel.png"
    },
  ]

  const resetQuantity = () => {
    var sizeIds = props.createdPattern.sizeIds
    var quantityArr = []
    sizeIds.forEach((id) => {
      var newObj = {
        sizeId: id,
        quantity:0,
      }
      quantityArr.push(newObj)
    })
    setYarnQuantity(quantityArr)
  }

  useEffect(() => {
  
    console.log("props", props)
    resetQuantity()
  }, [])

  useEffect(() => {
  
    console.log("update!")

  }, [needleType])

  // ACTIONS
  const onSave = () => {
    var updatedPattern = createdPattern
    updatedPattern.needles = allNeedles
    updatedPattern.yarns = allYarn
    updatedPattern.pattern_needles = allNeedles
    updatedPattern.pattern_yarns = allYarn
    updatedPattern.updatedAtOnClient = Utils.getTime()
    //console.log("Updated Pattern", updatedPattern)
    props.onSave(updatedPattern)
    props.showCollapse(false)
    PatternUtils.updatePatternOnServer(updatedPattern, () => console.log("added material saved", updatedPattern.pattern_needles))
    
  }

  const onEditYarn = (yarn, index) => {
    console.log("yarnToEdit", yarn)
    setYarnName(yarn.name)
    setYarnCompanyName(yarn.companyName)
    
    var toEditWeight = YARN_WEIGHT_RAVELRY.filter((weight) => 
      weight.ravelryId == yarn.yarnWeightRavelryId
    )[0]
    setYarnWeight(toEditWeight)
    
    var toEditColor = COLORS_RAVELRY.filter((color) => 
      color.value == yarn.color
    )[0]

    var selectedColors = [...COLORS_RAVELRY]
    selectedColors.forEach((color) => {
      if (color.ravelryId == toEditColor.ravelryId) {
        color.selected = true
      }
    })
    setYarnColor(toEditColor)
    setAllColors(selectedColors)
    setYarnQuantity(JSON.parse(yarn.quantities))
    setEditYarn({index:index})
  }

  const onDelete = () => {
    if (editYarn) {
      var updatedYarn = [...allYarn]
      updatedYarn[editYarn.index].deleted = 1
      setAllYarn(updatedYarn)
      resetAddYarn()
    } else if (editNeedle) {
      var updatedNeedles = [...allNeedles]
      updatedNeedles[editNeedle.index].deleted = 1
      setAllNeedles(updatedNeedles)
      resetAddNeedles()
    }
  }
  const onEditNeedle = (needle, index) => {
    console.log("needle to edit", needle)
    setNeedleType(needle.typeNeedle)
    
    var allLengths = RavelryNeedlesUtils.getAllNeedleLength(needle.typeNeedle)
    var currentLength = allLengths.filter((lg) => lg.length == needle.length)[0]
    setNeedleLength(currentLength)

    var allSizes = RavelryNeedlesUtils.getAllNeedleSizes(needle.typeNeedle, currentLength.length)
    var currentSize = allSizes.filter((size) => size.needle_size_id == needle.sizeIdOnRavelry)[0]
    setNeedleSize(currentSize)
    setEditNeedle({index:index})
  }

  const selectColor = (selectedColor) => {
    setYarnColor(selectedColor)
    var toUpdateColors = [...allColors]
    toUpdateColors.forEach((color) => {
      if (color.ravelryId == selectedColor.ravelryId) {
        color.selected = !color.selected
      } else {
        color.selected = false
      }
    })
    setAllColors(toUpdateColors)
  }

  const selectQuantity = (id, quantity) => {
    var allQuant = [...yarnQuantity]
    allQuant.forEach((quant) => {
      if (quant.sizeId == id) {
        quant.quantity = quantity
      }
    })
    setYarnQuantity(allQuant)
  }

  const onAddYarn = () => {
    if (yarnName == "" || yarnCompanyName == "" || yarnWeight == "" || yarnColor == "" || yarnQuantity[0].quantity == 0) {
      setShowAlertMissingInfo(true)
    } else if (editYarn) {
      var updatedYarn = [...allYarn]
      console.log("updatedYarn", updatedYarn)
      updatedYarn[editYarn.index].name = yarnName
      updatedYarn[editYarn.index].companyName = yarnCompanyName
      updatedYarn[editYarn.index].yarnWeightRavelryId = yarnWeight.ravelryId
      updatedYarn[editYarn.index].color = yarnColor.value
      updatedYarn[editYarn.index].quantities = JSON.stringify(yarnQuantity)
      setAllYarn(updatedYarn)
      resetAddYarn()
      setShowAlertMissingInfo(false)
    } else {
      var newYarn = PatternUtils.createYarn(yarnName, yarnCompanyName, yarnWeight.ravelryId, yarnColor.value, JSON.stringify(yarnQuantity))
      var updatedYarn = [...allYarn]
      updatedYarn.push(newYarn)
      setAllYarn(updatedYarn)
      resetAddYarn()
      setShowAlertMissingInfo(false)
    }

  }

  const checkNum = (input, callBack) => {
    const re = /^[0-9\b]+\W?[0-9\b]?$/;
    if (re.test(input[1])) {
      callBack(input[0],input[1])
    } else {
      alert(strings.patternCreator.alertNumbers)
    }
  }
  
  //createNeedle(sizeIdOnRavelry, length, typeNeedle, sizeUS, sizeMetric)
  const onAddNeedle = () => {
    if (!needleSize || !needleLength || !needleType) {
      setShowAlertMissingInfo(true)
    } else if (editNeedle) {
      var updatedNeedles = [...allNeedles]
      updatedNeedles[editNeedle.index].sizeIdOnRavelry = needleSize.needle_size_id
      updatedNeedles[editNeedle.index].sizeMetric = needleSize.metric_name
      updatedNeedles[editNeedle.index].sizeUS = needleSize.name
      updatedNeedles[editNeedle.index].typeNeedle = needleType
      updatedNeedles[editNeedle.index].length = needleLength.length
      setAllNeedles(updatedNeedles)
      resetAddNeedles()
      setShowAlertMissingInfo(false)
    } else {
      var newNeedle = PatternUtils.createNeedle(needleSize.needle_size_id, needleLength.length, needleType, needleSize.name, needleSize.metric_name)
      var updatedNeedles = [...allNeedles]
      updatedNeedles.push(newNeedle)
      setAllNeedles(updatedNeedles)
      resetAddNeedles()
      setShowAlertMissingInfo(false)
    }
  }

  const resetAddYarn = () => {
    setShowAddYarn(false)
    setEditYarn(false)
    setYarnName("")
    setYarnCompanyName("")
    setYarnWeight("")
    setYarnColor("")
    setAllColors([...COLORS_RAVELRY])
    resetQuantity()
  }

  const resetAddNeedles = () => {
    setShowAddNeedle(false)
    setEditNeedle(false)
    setNeedleType(false)
    setNeedleLength(false)
    setNeedleSize(false)
  }

// BTNS
  const renderAddNeedleBtn = () => {
    return (
      <View style={{justifyContent: "center", alignItems: "center", marginHorizontal: 8}}>
        <TouchableOpacity 
        onPress={() => setShowAddNeedle(true)}
        style={{margin: 12, width: 48, height: 48,padding: 12, borderRadius: 30, backgroundColor: "white"}}>
          <Image style={{width: 24, height: 24, tintColor: "#FECB2E", marginRight: 4}} urlOnServer={'/imgs/plus-new-b.png'} />
        </TouchableOpacity>
        <Text style={{color: "white"}}>
          {strings.patternCreator.addTool}
        </Text>
      </View>

    )
  }

  const renderAddYarnBtn = () => {
    return (
      <View style={{justifyContent: "center", alignItems: "center", marginHorizontal: 8}}>
        <TouchableOpacity 
        onPress={() => setShowAddYarn(true)}
        style={{margin: 12, width: 48, height: 48,padding: 12, borderRadius: 30, backgroundColor: "white"}}>
          <Image style={{width: 24, height: 24, tintColor: "#FECB2E", marginRight: 4}} urlOnServer={'/imgs/plus-new-b.png'} />
        </TouchableOpacity>
        <Text style={{color: "white"}}>
        {strings.patternCreator.addYarn}
        </Text>
      </View>

    )
  }

  const renderSaveBtn = () => {
    if (showAddYarn || editYarn) {
      return (
      <View style={{flex:1, flexDirection: "row", justifyContent: "center", alignItems:"center"}}>
        <TouchableOpacity 
        onPress={() => resetAddYarn()}
        style={{flex:1, paddingTop: 18, marginRight: 24}}>
          <Text style={{color: "white", textDecoration: "underline"}}>{strings.cancel}</Text>
        </TouchableOpacity>

        <button className="ImportPatternBtn" onClick={() => onAddYarn()}>
        {editYarn ? strings.patternCreator.update : strings.patternCreator.addYarn}
      </button>
      
      </View>
 
      )
    } else if (showAddNeedle || editNeedle) {
      return (
        
            <View style={{flex:1, flexDirection: "row", justifyContent: "center", alignItems:"center", zIndex: 0}}>
            <TouchableOpacity 
            onPress={() => resetAddNeedles()}
            style={{flex:1, paddingTop: 18, marginRight: 24}}>
              <Text style={{color: "white", textDecoration: "underline"}}>Cancel</Text>
            </TouchableOpacity>
            <button className="ImportPatternBtn" onClick={() => onAddNeedle()}>
            {editNeedle ? strings.patternCreator.update : strings.patternCreator.addTool}
            </button> 
            {editYarn && renderDelete()}
          </View>
      )
    } else {
      return (
        <button className="ImportPatternBtn" onClick={() => onSave()}>
        {strings.save}
      </button> 
      )
    }
  }

  const renderDelete = () => {
    return (
        <View
          style={{justifyContent: "center", alignItems: "flex-start",flex: 1,}}>
            <View style={{justifyContent: "center", alignItems: "center",}}>
            <TouchableOpacity 
              onPress={() => onDelete()}
              style={{ width: 32, height: 32, borderRadius: 24, backgroundColor: "white", justifyContent: "center", alignItems: "center",}}>
              <Image style={{width: 18, height: 18, margin: 4, tintColor: "#FF5056"}} urlOnServer={"/imgs/bin.png"}/>
            </TouchableOpacity>
            <Text style={{color: "rgba(255,255,255,0.8)", textAlign: "center", marginTop: 6, fontSize: 10}}>{strings.delete}</Text>
            </View>

        </View>
    )
  }

// RENDERS YARN RELATED
  const renderAddYarnCol1 = () => {
    return (
      <View style={{flex: 1}}>
       {editYarn && renderDelete()}
      <Text style={[styles.title, {marginTop:16}]}>{strings.patternCreator.yarnName}</Text>
      <TextInput
              containerStyle={{height: 60,flex: 1}}
              style={[styles.input, {height: 40, borderRadius: 20, width: 400 }]}
              multiline={false}
              value={yarnName}
              onChangeText={(name) => setYarnName(name)}
              returnKeyType="done"
            />

      <Text style={[styles.title, {marginTop:16}]}>{strings.patternCreator.yarnCompanyName}</Text>
      <TextInput
              containerStyle={{height: 60,flex: 1}}
              style={[styles.input, {height: 40, borderRadius: 20, width: 400 }]}
              multiline={false}
              value={yarnCompanyName}
              onChangeText={(name) => setYarnCompanyName(name)}
              returnKeyType="done"
            />

      <Text style={[styles.title, {marginTop:16}]}>{strings.patternCreator.yarnWeight}</Text>
      <Dropdown >
          <Dropdown.Toggle style={{width:400, marginTop: 12, zIndex: 10000, backgroundColor:"rgba(255,255,255,0.15)", borderColor: "rgba(255,255,255,0.6)", color:"rgba(255,255,255,0.8)", borderWidth: 1}} id="dropdown-custom-1">{yarnWeight ? yarnWeight.value : strings.patternCreator.selectWeight}</Dropdown.Toggle>
          <Dropdown.Menu  className="dropdown-init-pattern">
          {
            YARN_WEIGHT_RAVELRY.map((weight) => {
              return (<Dropdown.Item onClick={() => setYarnWeight(weight)}>{weight.value}</Dropdown.Item>)
            })
          }
          </Dropdown.Menu>
        </Dropdown>

    </View>
    )
  }

  const renderColor = (color) => {
    return (
      <TouchableOpacity
        onPress={() => selectColor(color) } >
          {
            (color.value == "multicolored" || color.value == "undyed" || color.value == "rainbow") ?
            <Image 
            urlOnServer={`/imgs/${color.value}.png`}
            style={{width: 30, height: 30, borderRadius: 15, margin: 6, borderColor: color.selected ? "#FECB2E" : "rgba(255,255,255,0.6)", borderWidth:  color.selected ? 3 : 1 }} /> : 
            <View style={{backgroundColor: color.value, width: 30, height: 30, borderRadius: 15, margin: 6, borderColor: color.selected ? "#FECB2E" : "rgba(255,255,255,0.6)", borderWidth:  color.selected ? 3 : 1 }} />
          }
      </TouchableOpacity>
    )
  }

  const renderChooseQuantity = (sizeQuantity) => {
      //"[{"sizeId":"newbornOneSize","quantity":4}]"
      var text = getSizesFromIds([sizeQuantity.sizeId])[0].text
      var cat = getCatFromSize(sizeQuantity.sizeId).name
      return (
        <View style={{flexDirection: "row", alignItems: "center", justifyContent: "center", marginRight: 24, marginVertical: 12}}>
          <Text style={{color: "rgba(255,255,255,0.8)", fontSize: 12, width: 60}}>{ cat + ": \n" + text}</Text>
          <TextInput
              containerStyle={{height: 30}}
              style={[styles.input, {height: 30, borderRadius: 20, width: 40, marginHorizontal: 4, marginTop: 0 }]}
              multiline={false}
              value={sizeQuantity.quantity == 0 ? "" : sizeQuantity.quantity}
              onChangeText={(quantity) => checkNum([sizeQuantity.sizeId, quantity],selectQuantity)}
              returnKeyType="done"
            />
            <Text style={{color: "rgba(255,255,255,0.6)", fontSize: 12, marginHorizontal: 4}}>Yarn skein(s)</Text>
        </View>
      )

  }

  const renderAddYarnCol2 = () => {
    return (
      <View style={{flex: 1 }}>

        <Text style={[styles.title, {marginTop:16}]}>{strings.patternCreator.yarnColor}</Text>
        <FlatList style={{flex: 1, marginTop: 8}}
          data={allColors}
          extraData={yarnColor}
          numColumns={10}
          keyExtractor={(item, index) => index}
          renderItem={(item) => renderColor(item.item)} />
        <Text style={[styles.title]}>Yarn quantity:</Text>
        <FlatList style={{flex: 1, marginTop: 8}}
          data={yarnQuantity}
          extraData={yarnQuantity}
          numColumns={2}
          keyExtractor={(item, index) => index}
          renderItem={(item) => renderChooseQuantity(item.item)} />
      </View>
    )
  }

  const renderAddedYarn = (yarn, index) => {
    if (yarn.deleted != 1) {
      return (
        <TouchableOpacity 
          onPress={() => onEditYarn(yarn, index)}
          style={{justifyContent: "center", alignItems: "center", marginHorizontal: 12, marginBottom: 12}}>
          <Image style={{width: 40, height: 40, tintColor:yarn.color}} urlOnServer={"/imgs/yarn.png"} />
          <Text style={{marginTop:8, fontSize: 14, color: "#FFF", fontWeight: "100"}}>{yarn.name}</Text>
          <Text style={{marginTop:2, fontSize: 12, color: "rgba(255,255,255,0.8)", fontStyle: "italic"}}>{yarn.companyName}</Text>
        </TouchableOpacity>
      )
    }

  }

  const renderAllAddedYarn = () => {
    if (allYarn && allYarn[0]) {
      return (
        <View style={{marginHorizontal: 18, marginBottom: 12, borderBottomWidth: 1, paddingBottom: 12, borderColor:"rgba(255,255,255,0.8)"}}>
           <Text style={[styles.title, {marginTop:16, marginBottom: 8}]}>{strings.patternCreator.neededYarn}</Text>
          <FlatList style={{flex: 1, marginTop: 8}}
            data={allYarn}
            extraData={allYarn}
            numColumns={5}
            keyExtractor={(item, index) => index}
            renderItem={({item, index}) => renderAddedYarn(item, index)} />
        </View>
      )
    }
  }

// RENDERS NEEDLES RELATED
  const renderChooseNeedleType = (type) => {
    var isSelected = needleType == type.nameOnRavelry ? true : false
    return (
      <TouchableOpacity
        onPress={() => {
          setNeedleLength(false)
          setNeedleSize(false)
          setNeedleType(type.nameOnRavelry)
        } }
        style={{margin: 8, justifyContent: "center", alignItems: "center"}} >
          <View 
            style={{padding: 24, borderRadius: 50,  borderColor: "#FECB2E", borderWidth: isSelected ? 2 : 0, backgroundColor: isSelected ? "rgba(0,0,0,0.2)" : "rgba(255,255,255,0.2)"}} >
          <Image
          style={{width: 32, height: 32}}
          urlOnServer={type.pic} />
          </View>
        <Text style={{color: isSelected ? "#FECB2E" : "rgba(255,255,255,0.6)", marginTop: 4}}>{type.name}</Text>
      </TouchableOpacity>
    )
  }

  const renderAddNeedleCol1 = () => {
    return (
      <View style={{flex: 1, marginRight: 24}}>
      <Text style={[styles.title, {marginTop:16}]}>{strings.patternCreator.materialType}</Text>
      <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
      <FlatList style={{flex: 1, marginTop: 12}}
          data={allNeedleTypes}
          extraData={needleType}
          numColumns={3}
          keyExtractor={(item, index) => index}
          renderItem={(item) => renderChooseNeedleType(item.item)} />
      </View>
    </View>
    )
  }

  const renderAddNeedleCol2 = () => {
    if (needleType && needleType[0]) {

      var allLengths = RavelryNeedlesUtils.getAllNeedleLength(needleType)

      if (needleLength)
      var allSizes = RavelryNeedlesUtils.getAllNeedleSizes(needleType, needleLength.length)

      return (
        <div style={{flex: 1, zIndex: 1000}}>
  
        <Text style={[styles.title, {marginTop:16}]}>{strings.length}</Text>
        <Dropdown >
            <Dropdown.Toggle 
              style={{width:400, marginTop: 12, zIndex: 10000, backgroundColor:"rgba(255,255,255,0.15)", borderColor: "rgba(255,255,255,0.6)", color:"rgba(255,255,255,0.8)", borderWidth: 1}} 
              id="dropdown-custom-1" >
                {needleLength ? needleLength.length : strings.patternCreator.selectLength}
            </Dropdown.Toggle>
            <Dropdown.Menu  className="dropdown-init-pattern">
            {
              allLengths.map((length) => {
                return (<Dropdown.Item style={{zIndex: 1000}} onClick={() => setNeedleLength(length)}>{length.length + " inches - " + length.length * 2.54 + " cm"}</Dropdown.Item>)
              })
            }
            </Dropdown.Menu>
        </Dropdown>
        {
          allSizes &&
          <div style={{zIndex: 1000, flex: 1, marginTop: 12}}>
          <Text style={[styles.title, {marginTop:16}]}>{strings.popupsize.size}</Text>
          <View style={{zIndex: 10000}}>
          <Dropdown >
              <Dropdown.Toggle 
                style={{width:400, marginTop: 12, zIndex: 1000, backgroundColor:"rgba(255,255,255,0.15)", borderColor: "rgba(255,255,255,0.6)", color:"rgba(255,255,255,0.8)", borderWidth: 1}} 
                id="dropdown-custom-1" >
                  {needleSize ? "US " + needleSize.name + " - " + needleSize.metric_name + " mm" : strings.select_size}
              </Dropdown.Toggle>
              <Dropdown.Menu  className="dropdown-init-pattern">
              {
                allSizes.map((size) => {
                  return (<Dropdown.Item style={{zIndex: 1000}} onClick={() => setNeedleSize(size)}>{"US " + size.name + " - " + size.metric_name + " mm"}</Dropdown.Item>)
                })
              }
              </Dropdown.Menu>
          </Dropdown>
          </View>
        </div>
        }
        {editNeedle && 
        <View style={{marginTop: 20}} >
          {renderDelete()}
        </View>}

      </div>
      )
    } else {
      return (
        <View style={{flex: 1}} />
      )
    }
  }

  const renderAddedNeedle = (needle, index) => {
    if (needle.deleted != 1) {
      var needlePic = allNeedleTypes.filter((oneType) => oneType.nameOnRavelry == needle.typeNeedle)[0].pic
      var needleName = allNeedleTypes.filter((oneType) => oneType.nameOnRavelry == needle.typeNeedle)[0].name
      var needleSize = "US " + needle.sizeUS + " - " + needle.sizeMetric + " mm" 
  
      return (
        <TouchableOpacity 
          onPress={() => onEditNeedle(needle, index)}
          style={{justifyContent: "center", alignItems: "center", marginHorizontal: 12, marginBottom: 12}}>
          <Image style={{width: 40, height: 40}} urlOnServer={needlePic} />
          <Text style={{marginTop:8, fontSize: 14, color: "#FFF", fontWeight: "100"}}>{needleName}</Text>
          <Text style={{marginTop:2, fontSize: 12, color: "rgba(255,255,255,0.8)", fontStyle: "italic"}}>{needleSize}</Text>
      </TouchableOpacity>
      )
    }
  }

  const renderAllAddedNeedles = () => {
    if (allNeedles && allNeedles[0]) {
      return (
        <View style={{marginHorizontal: 18, marginBottom: 12, borderBottomWidth: 1, paddingBottom: 12, borderColor:"rgba(255,255,255,0.8)"}}>
           <Text style={[styles.title, {marginTop:16, marginBottom: 8}]}>{strings.patternCreator.neededTools}</Text>
          <FlatList style={{flex: 1, marginTop: 8}}
            data={allNeedles}
            extraData={allNeedles}
            numColumns={3}
            keyExtractor={(item, index) => index}
            renderItem={({item, index}) => renderAddedNeedle(item, index)} />
        </View>
      )
    }
  }

  // RENDER ALERTS

  const renderAlertMissingInfo = () => {
    return (
      <View style={{marginBottom: -24, paddingVertical: 4, paddingHorizontal:18, borderRadius: 20, backgroundColor: "rgba(255,255,255,0.3)"}}>
      <Text style={{ color:"#DE373C", fontWeight: "bold" }}>{strings.patternCreator.alertAllInfos}</Text>
      </View>

    )
  }
  
  return(
 

    <View style={{flex: 1, marginTop: 12}}>
        <p className="PatternOptionTitle">{showAddYarn ? "Yarn" : showAddNeedle ? "Tool" : Utils.capitalizeFirstLetter(strings.material)}</p>
        
        <View style={{flex: 1, width: "100%"}}>
        {
          !showAddYarn && !showAddNeedle && !editYarn && !editNeedle &&
        <View style={{flex: 1, width: "100%",flexDirection:"row"}}>
          <View style={{flex: 1}}>
            {renderAllAddedYarn()}
            {renderAddYarnBtn()}

          </View>
          <View style={{flex: 1}}>
            {renderAllAddedNeedles()}
            {renderAddNeedleBtn()}

          </View>
        </View>
        }

        {
          (showAddYarn || editYarn) && 
          <View style={{flex: 1, flexDirection: "row", marginHorizontal: 24}}>
            {renderAddYarnCol1()}
            {renderAddYarnCol2()}
          </View> 
        }

        {
          (showAddNeedle || editNeedle) && 
          <View style={{flex: 1, flexDirection: "row", marginHorizontal: 24}}>
            {renderAddNeedleCol1()}
            {renderAddNeedleCol2()}
          </View> 
        }



        </View>
      <div className="NewPatternBtns" >
        {showAlertMissingInfo && renderAlertMissingInfo() }
        {renderSaveBtn()}
      </div>  
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginTop: 12,
    backgroundColor: "rgba(255,255,255,0.15)" , 
    color: "white",
    paddingLeft: 16,
    textAlign: "left"
  },
  title: {
    color: "rgba(255,255,255,0.8)", 
    textAlign: "left",
    selfAlign: "flex-start",
  },

});