import fetchClient from "../utils/http-common";
import { SERVER_API_URL } from '../config/Constants'

const sendUpdateToServer = (project, onUploadProgress) => {


  /*if (project) {
    delete project.pdf_pattern
    delete project.pattern_url
  }*/

  var projToSend = {"user_project": project}
  return fetchClient.put(`${SERVER_API_URL}/user_projects/${project.id}.json`, projToSend, {
    onUploadProgress,
  });
};


const createProject = (project, onUploadProgress) => {


  if (project) {
    delete project.pdf_pattern
    delete project.pattern_url
  }

  var projToSend = {"user_project": project}
  return fetchClient.post(`${SERVER_API_URL}/user_projects.json`, projToSend, {
    onUploadProgress,
  });
};

export default {
  sendUpdateToServer,
  createProject
};
