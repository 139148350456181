import React from 'react';
import useToken from '../../hooks/useToken';
import useUser from '../../hooks/useUser';
import { useState, useEffect, useRef } from 'react';
import { SERVER_API_URL, APP_NAME } from '../../config/Constants'

import { getSizesFromIds, getCatFromSize } from "../../config/sizes"
import PatternUtils from "../../generator/PatternUtils";
import { LANGS } from "../../generator/PatternUtils";
import Image from "../../components/Image";
import Switch from "react-switch";
import AlertModal from "../../components/generator/AlertModal";
import UploadPicture from "../../components/generator/UploadPicture";

import { useParams, useHistory, useLocation } from "react-router-dom";
import strings from "../../config/strings";
import Utils from '../../utils/utils';
import Dropdown from 'react-bootstrap/Dropdown'
import { getAllSizes } from "../../config/sizes"
import CheckBox from "../../components/CheckBox"


import SizeSpecBox from "../../components/generator/SizeSpecBox";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown,faSortUp, faTrashAlt, faCopy, faEdit } from '@fortawesome/free-solid-svg-icons'


import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

export default function CreateSection() {

  //const myRef = useRef(null)

  const history = useHistory();
  const location = useLocation();
  const [createdPattern, setCreatedPattern] = useState(location.state.pattern)
  const [type, setType] = useState(location.state.type)
  const [section, setSection] = useState(location.state.section)

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const [textInstructions, setTextInstructions] = useState()
  const [row, setRow] = useState()
  const [fromRow, setFromRow] = useState()
  const [toRow, setToRow] = useState()
  const [isRange, setIsRange] = useState(false)
  const [hasRows, setHasRows] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [maxPosition, setMaxPosition] = useState()

  const [showAlertDelete, setShowAlertDelete] = useState(false)
  const [toDeleteIndex, setToDeleteIndex] = useState()

  const [showAddText, setShowAddText] = useState()

  const [showAddPic, setShowAddPic] = useState(false)

  const [showSizeSpec, setShowSizeSpec] = useState(false)
  const [addTextHeight, setAddTextHeight] = useState(500)
  const [picChanged, setPicChanged] = useState(false)
  const [editSection, setEditSection] = useState(location.state.edit)




  // useEffect(() => {

  //   if (showAddText && editMode) {
  //     console.log("scrolling")
  //     myRef.current.scrollIntoView()
  //   }

  // }, [showAddText, editMode])


  useEffect(() => {
    var maxPosition = -1
    if (section.subsections && section.subsections[0]) {
      maxPosition = 0
      var subSections = section.subsections
      for (var i = 0; i < subSections.length; i++) {
        if (subSections[i].position > maxPosition)
          maxPosition = subSections[i].position
      }
    }
    setMaxPosition(maxPosition)
    //console.log("section being edited", section)
    if (picChanged) {
      saveChanges()
    }
  }, [section])

  const onSizeSpecSave = () => {

  }

  const scrollTo = (ref) => {
    console.log("scrolling")
    const section = document.querySelector( '#SaveBtn' );
    if (section /* + other conditions */) {
      console.log("scrolling in if")

      section.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }

  const moveItem = (item, direction) => {
    var currentPosition = item.position
    var newPosition = currentPosition + direction

    var tmpSection = {...section}
    var itemIndex = tmpSection.subsections.findIndex(subsection => subsection.position == currentPosition)
    var otherItemIndex = tmpSection.subsections.findIndex(subsection => subsection.position == newPosition)

    tmpSection.subsections[otherItemIndex].position = currentPosition
    tmpSection.subsections[itemIndex].position = newPosition

    tmpSection.subsections[otherItemIndex].updatedAtOnClient = Utils.getTime()
    tmpSection.subsections[itemIndex].updatedAtOnClient = Utils.getTime()

    setSection(tmpSection)
    saveChanges()
  }

  const duplicateItem = (item, index) => {
    var tmpSection = {...section}
    var myItem = {...item}
    var maxPosition = 0
    tmpSection.subsections.forEach((sub) => {
      if (sub.position > maxPosition) {
        maxPosition = sub.position
      }
    })
    myItem.position = maxPosition + 1
    myItem.id = 0
    myItem.uniqueIdOnClient = Math.random().toString(36).slice(2)
    myItem.updatedAtOnClient = Utils.getTime()
    tmpSection.subsections.push(myItem)

    tmpSection.subsections = tmpSection.subsections
    setSection(tmpSection)
    saveChanges()
  }
  const addOrEditSubsection = () => {
    var sectionType = hasRows ? "r" : "t"
    var rowStruct = ""
    if (fromRow && fromRow != "") {
      rowStruct += fromRow
      if (toRow && toRow != "") {
        rowStruct += "-" + toRow
        console.log("rowStruct:", rowStruct)
      }
    }

    if (editMode) {
      section.subsections[editMode.index].content = textInstructions
      section.subsections[editMode.index].rowStruct = rowStruct
      section.subsections[editMode.index].updatedAtOnClient = Utils.getTime()
    } else {

      var maxPosition = -1
      if (section.subsections && section.subsections[0]) {
        maxPosition = 0
        var subSections = section.subsections
        for (var i = 0; i < subSections.length; i++) {
          if (subSections[i].position > maxPosition)
            maxPosition = subSections[i].position
        }
      }

      var subsection = PatternUtils.createSubsection(section, "", textInstructions, rowStruct, sectionType, maxPosition + 1)
      if (!section.subsections) {
        section.subsections = []
      }
      section.subsections.push(subsection)
    }
    section.subsections = section.subsections
    if (maxPosition != -1) {
      setMaxPosition(maxPosition)
    }

    setEditMode(false)
    setShowAddText(false)
    setTextInstructions("")
    setFromRow("")
    setToRow("")
    setHasRows(false)
    setSection({...section})


    saveChanges()

  }

  const setSidePic = (id) => {
    setPicChanged(true)
    var updatedSection = {...section}
    updatedSection.imgAttachmentId = id
    updatedSection.imgVersion += 1
    //console.log("updated Section", updatedSection)
    setSection(updatedSection)

  }

    const onBack = () => {
      var path = location.state.backPath ? location.state.backPath : `/edit-pattern/`
    history.push({
      pathname: path,
      state: { pattern: createdPattern }
    })
  }
  const handleDelete = (index) => {
    setToDeleteIndex(index)
    setShowAlertDelete(true)

  }
  const deleteItem = (index) => {
    var tmpSection = {...section}
    tmpSection.subsections[index].deleted = 1
    var deletedPosition = tmpSection.subsections[index].position
    tmpSection.subsections.forEach((item) => {
      if (item.position > deletedPosition) {
        item.position = item.position - 1
      }
    })
    tmpSection.subsections = tmpSection.subsections
    setSection(tmpSection)
    setShowAlertDelete(false)
    saveChanges()

  }

  const saveChanges = () => {
    var updatedPattern = {...createdPattern}
    if (section.subsections && section.subsections[0]) {
      if (!editSection) {
        updatedPattern.sections.push(section)
        updatedPattern.sections = updatedPattern.sections
        setEditSection(true)
      } else {
        var sections = updatedPattern.sections
        var sectionIndex = sections.findIndex(oldSection => oldSection.id == section.id)
        console.log("Sections", sections)
        console.log("sectionIndex", sectionIndex)
        sections[sectionIndex].subsections = section.subsections
        sections[sectionIndex].subsections = section.subsections
        sections[sectionIndex].imgAttachmentId = section.imgAttachmentId
        sections[sectionIndex].imgAttributes = section.imgAttributes
        sections[sectionIndex].imgVersion = section.imgVersion
        sections[sectionIndex].updatedAtOnClient = Utils.getTime()
        updatedPattern.sections = sections
        updatedPattern.sections = sections
      }

      PatternUtils.updatePatternOnServer(updatedPattern, (response) => {
        setCreatedPattern(response)
        if (section.id) {
          var returnedSection = response.sections.find(item => item.id == section.id)
          setSection(returnedSection)
        } else {
          setSection(response.sections.at(-1))
        }
      })
    }
    // var path = location.state.backPath ? location.state.backPath : `/edit-pattern/`
    // history.push({
    //   pathname: path,
    //   state: { pattern: updatedPattern }
    // })
  }

  const onOpenEditItem = (subsection, index) => {
    setTextInstructions(subsection.content)
    if (subsection.rowStruct && subsection.rowStruct != "") {
      setHasRows(true)
      if (subsection.rowStruct.includes("-")) {
        var rows = subsection.rowStruct.split("-")
        setFromRow(rows[0])
        setToRow(rows[1])
        setIsRange(true)
      } else {
        setFromRow(subsection.rowStruct)
        setIsRange(false)
      }
    }
    setEditMode({index: index})
  }

  const renderAlertDelete = () => {
    return (
      <AlertModal
      title={strings.patternCreator.alertDeleteContentTitle}
      text={strings.patternCreator.alertDeleteContentText}
      onOk={() => deleteItem(toDeleteIndex)}
      onDelete={() => setShowAlertDelete(false)}
      />
    )

  }

  const renderGoBack = () => {
    return (
      <TouchableOpacity
      style={{justifyContent: "center", alignItems: "center", width: 32, marginBottom: 8}}
      onPress={() => onBack()}>
        <Image style={{width: 24, height: 24, margin: 4}} urlOnServer={"/imgs/back.png"}/>
        <Text style={{color: "rgba(255,255,255,0.8)", textAlign: "center", fontSize: 10}}>{strings.back}</Text>
      </TouchableOpacity>
    )
}
  const renderActions = (item, index) => {
    var btnSize = 10
    var color = "rgba(255,255,255,0.8)"
    return (
      <View style={{flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
        {
          item.position != 0 &&
          <TouchableOpacity
            onPress={() => moveItem(item, -1)}>
            <FontAwesomeIcon style={{color: color, marginRight: 8}} size={btnSize} icon={ faSortUp } />
          </TouchableOpacity>
        }
        { item.position < maxPosition &&
          <TouchableOpacity
            onPress={() => moveItem(item, 1)}>
            <FontAwesomeIcon style={{color: color, marginRight: 16}} size={btnSize} icon={ faSortDown } />
          </TouchableOpacity>
        }
        <TouchableOpacity
        onPress={() => duplicateItem(item, index)}>
          <FontAwesomeIcon style={{color: color, marginRight: 8}} size={btnSize} icon={ faCopy } />
        </TouchableOpacity>
        <TouchableOpacity
        onPress={() => handleDelete(index)}>
          <FontAwesomeIcon style={{color: color, marginRight: 8}} size={btnSize} icon={ faTrashAlt } />
        </TouchableOpacity>

      </View>
    )
  }

  const renderIcon = (subsection) => {
    var iconSize = 20
    var iconViewSize = 40
    if (subsection.rowStruct && subsection.rowStruct != "" ) {
      var rows = []
      if (subsection.rowStruct.includes("-")) {
        rows = subsection.rowStruct.split("-")
      } else {
        rows[0] = subsection.rowStruct
      }
      return (
        <View style={{marginRight: 8, flexDirection: "row", width: 80}}>
          <Text style={{color: "white", fontStyle: "italic"}}>{ rows[1] ? strings.patternCreator.fromRow : strings.patternCreator.onRow} <Text style={{fontWeight: "bold", color:"#FECB2E"}}>{rows[0]}</Text> { rows[1] && "to" + " "}{ rows[1] && <Text style={{fontWeight: "bold", color:"#FECB2E"}}>{rows[1]}</Text>}</Text>
      </View>
      )
    }
  }


  const renderOneSubsection = (subsection, index) => {

    var iconSize = 20
    var iconViewSize = 40

    var btnSize = 10
    var color = "rgba(255,255,255,0.8)"
    if (subsection.deleted == 1) {
      return
    }
    return (

      <View style={[styles.row,{width:"100%"}]}>
          <TouchableOpacity
            onPress={() => onOpenEditItem(subsection, index)}>
            <FontAwesomeIcon style={{color: color, marginRight: 12}} size={btnSize} icon={ faEdit } />
          </TouchableOpacity>
          {renderIcon(subsection) }
          {/* <Text style={styles.title}>{"Position: " + subsection.position + " " }</Text> */}
          {/* <Text style={styles.title}>{subsection.content[0] ? subsection.content.substring(0,45) + "..." :"..." }</Text> */}
          <View style={{width:"100%" ,marginRight: 8, paddingVertical: 8, flex: 1,}}>
            <Text style={[styles.title, {}]}>{subsection.content[0] && subsection.content }</Text>
          </View>
          {renderActions(subsection, index)}
      </View>
    )
  }
  const renderAllContent = () => {
    var width = (section && section.imgAttachmentId && section.imgAttachmentId != 0) ? "80%" : "100%"
    return (
      <View
      style={{width: width}} >
        { section.subsections.map((subsection, index) => {
          return (
            renderOneSubsection(subsection, index)
          )
        })}
      </View>
      )
  }

  const renderChooseRows = () => {
    return (
      <View style={{width: "100%", marginTop: 24}}>
        <View style={styles.rowInfosContainer}>
          <Text style={styles.rowText}>{strings.patternCreator.RangeOfRows}</Text>
          <Switch
          onChange={() => {
            setIsRange(!isRange)
            setToRow("")
          }}
          checked={isRange}
          offColor={"#448DBF"}
          offHandleColor={"#D7D7D7"}
          uncheckedIcon={false}
          checkedIcon={false}
          handleDiameter={24}
          onColor={"#528FA2"}
          height={16}
          width={40}
          onHandleColor={"#FECB2E"} />
        </View>
        <View style={styles.rowInfosContainer}>
          <Text style={styles.rowText}>{isRange ? strings.patternCreator.fromRow : strings.patternCreator.onRow}</Text>
          <TextInput
              style={[styles.input, { marginRight: 12}]}
              multiline={false}
              value={fromRow}
              onChangeText={(fromRow) => checkNum(fromRow, setFromRow)}
              returnKeyType="done"
            />
            {
              isRange &&
              <View style={{flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                <Text style={styles.rowText}>{strings.patternCreator.toRow}</Text>
                <TextInput
                    style={styles.input}
                    multiline={false}
                    value={toRow}
                    onChangeText={(toRow) => checkNum(toRow, setToRow)}
                    returnKeyType="done"
                  />
              </View>
            }
        </View>
      </View>
    )
  }
  const checkNum = (input, callBack) => {
    const re = /^[0-9\b]+$/;
    if (re.test(input)) {
      callBack(input)
    } else {
      alert(strings.patternCreator.alertNumbers)
    }
  }
  const renderAddText = (row = false) => {

    return (
      <View style= {{backgroundColor: "#2D7BAE", width: "100%", height: addTextHeight, borderRadius: 12, paddingVertical: 12, marginBottom: 24, paddingHorizontal: 42, justifyContent: "center", alignItems:"center"}}>
            {hasRows && renderChooseRows()}
            <Text style={[styles.title, {margin:16}]}>{strings.patternCreator.enterInstructions}</Text>
            <TextInput
                    containerStyle={{height: 60,flex: 1}}
                    style={[styles.input, { borderRadius: 12, width: "100%", flex: 1 , padding: 12}]}
                    multiline={true}
                    value={textInstructions}
                    onChangeText={(textInstructions) => setTextInstructions(textInstructions)}
                    returnKeyType="done"
                  />
              {
                showSizeSpec &&
                <SizeSpecBox
                  pattern={createdPattern}
                  onSizeSpecSave={onSizeSpecSave}
                />
              }
              {
                !showSizeSpec &&
                <div id="SaveBtn" ref={scrollTo} className="NewPatternBtns" >
                <button className="ImportPatternBtn" onClick={() => addOrEditSubsection()}>
                 {editMode ? "Edit" : "Save"}
                </button>
              </div>
              }

      </View>
    )
  }

  const renderAddPictureBtn = () => {
    var text = (section && section.imgAttachmentId && section.imgAttachmentId != 0) ? strings.patternCreator.updatePic : strings.patternCreator.addPic
    return (
      <TouchableOpacity
        onPress={() => setShowAddPic(true)}
        style={{flexDirection: "row", marginTop: 20, justifyContent: "flex-start", alignItems: "center", marginRight: 32 }}>
        <Image style={{width: 38, height: 38}} urlOnServer={'/imgs/image.png'} />
        <Text style= {{color:"rgba(255,255,255,0.8)", marginLeft: 12, fontSize: 12, width: 150}}>{text}</Text>
      </TouchableOpacity>
    )
  }

  const renderAddContentBtns = () => {
    return (
      <View
      style={{flexDirection: "row", justifyContent: "space-between", alignItems: "center", flex: 1}}>
        {section && section.subsections && renderAddPictureBtn()}
        <View
      style={{flexDirection: "row", justifyContent: "space-between", alignItems: "center", flex: 1}}>
        <button className="AddElementBtn" onClick={() => setShowAddText(!showAddText)}>
          {strings.patternCreator.addText}
        </button>
        <button className="AddElementBtn" onClick={() => {
          setShowAddText(!showAddText)
          setHasRows(true)
        }}>
          {strings.patternCreator.addRowText}
        </button>
        <View/>
        </View>
    </View>
    )
  }

  const renderPicUpload = () => {
    return (
      <UploadPicture
      pattern={createdPattern}
      type={"img"}
      showUpload={setShowAddPic}
      onUploaded={(picId) => {
        setSidePic(picId)
        setShowAddPic(false)
      }} />
    )
  }

  const renderPicPreview = (id) => {
    if (section && section.imgAttachmentId && section.imgAttachmentId != 0) {

      return (
          <Image
          style={{width: 200,  borderRadius: 12, marginTop: 12}}
          urlOnServer={Utils.getAttachementUrl(id)} />
      )
    }

  }



  return(


    <View style={{flexDirection:'column', backgroundColor: "#266D9C", width: "100%", minHeight: height - 72, paddingBottom: 42}}>
        <div className="container mt-5 rcpattern-container ">
        {renderGoBack()}
        {showAlertDelete && renderAlertDelete()}
        {showAddPic && renderPicUpload()}
        <h2 className="text-white" style={{textAlign: "center"}}>{strings.patternCreator.textSection}</h2>
        <div className="mt-4" >
          {renderAddContentBtns()}
          {false && section && section.subsections && renderAddPictureBtn()}
          <div className="container mt-5 rcpattern-container ">
          {(showAddText || editMode) && renderAddText()}
          </div>
          <View
          style={{flexDirection: "row", flex: 1}}>
            {
              renderPicPreview(section.imgAttachmentId)
            }
          {
            section && section.subsections &&
            renderAllContent(section.subsections.sort((a, b) => a.position - b.position))
          }
          </View>
        </div>
      </div>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    backgroundColor: "rgba(255,255,255,0.15)" ,
    color: "white",
    textAlign: "left",
    padding: 32,
    borderRadius: 15,
    width: 60,
    height: 30,
    padding: 12
  },
  title: {
    color: "rgba(255,255,255,0.8)",
    textAlign: "left",

  },
  editImg: {
    tintColor:'rgba(255,255,255,0.8)',
    width:8,
    height:20
  },
  row: {
    flexDirection:'row',
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    borderColor:'rgba(255,255,255,0.6)',
    borderBottomWidth:0.5,
    paddingVertical:12,
    marginHorizontal:12,
  } ,
  rowText: {
    color: "rgba(255,255,255,0.8)",
    marginRight: 12,
  },
  rowInfosContainer: {
    flexDirection: "row",
    justifyContent:"flex-start",
    alignItems: "center",
    marginBottom: 12,
  }

});
