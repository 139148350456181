import React from 'react';
import useToken from '../hooks/useToken';
import { useState, useEffect} from 'react';
import { SERVER_URL } from '../config/Constants'
import { BrowserRouter, Route, Switch, useRouteMatch, Link, useParams } from 'react-router-dom';
import Utils from '../utils/utils'
import Image from "../components/Image"
import "../config/styles.css"
import strings from "../config/strings";

export default class ShowComment extends React.Component {


  render() {
    //alert(JSON.stringify(this.props))
    const renderComments = (currentComments) => {
      console.log("ShowComment", currentComments[0])
        var toRender = currentComments.map( comment => <p style={{margin: 0, padding: 0}}>{comment.txt}</p> )
        return toRender
    }
    return (
      <div className="CommentContainer" >
            <div className="CommentTitle">
                <h3>{strings.comments}</h3>
            </div>
            <div>
                {renderComments(this.props.currentComments[0])}
            </div>
      </div>
      );
  }
}
