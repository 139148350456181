import React from 'react';
import useToken from '../hooks/useToken';
import { useState, useEffect } from 'react';
import { SERVER_API_URL } from '../config/Constants'
import ViewProject from './ViewProject';
import ProjectUtils from '../utils/projectUtils'
import { BrowserRouter, Route, Switch, useRouteMatch, Link } from 'react-router-dom';
import ProjectService from "../services/ProjectService";
import { useHistory } from "react-router-dom";
import Image from "../components/Image"
import ImageBackground from "../components/ImageBackground"
import { HEADER_HEIGHT } from '../config/Constants'
import Button from "../components/Button"
import Utils from "../utils/utils";
import {SECONDARY_COLOR, SECONDARY_COLOR_RGB, PURPLE_COLOR_PLAIN} from "../config/styles";
import strings from "../config/strings";

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions,
  Picker
} from 'react-native';



export default function NewProject() {

  const history = useHistory();
  const [projectName, setProjectName] = useState();
  const [projectTool, setProjectTool] = useState(0);

  const tools = [
    {name: strings.knitting_mode, img: '/imgs/knit-thumb.png', type: 1},
    {name: strings.crochet_mode, img: '/imgs/crochet-thumb.png', type: 2},
    {name: strings.machine, img: '/imgs/machine-thumb.png', type: 3},
    {name: strings.loom_knitting, img: '../imgs/loom-thumb.png', type: 4},
  ]

  const handleSubmit = async () => {

    ProjectService.createProject(ProjectUtils.createEmptyProject(projectName, projectTool), (event) => {
      //setProgress(Math.round((100 * event.loaded) / event.total));
    })
    .then((response) => {

      const project = response.data
    //  alert(JSON.stringify(response))
      //setMessage(response.data.message);
     // props.onSuccess(response)
      history.push(`/ViewProject/${project.id}`);
    })

    .catch((error) => {

      alert(error.message)

    });

  }

  const renderProjectTools = () => {

    var notSelectedColor =  '0,0,0'
    return (
      <View style={{ flex: 1, marginTop: 16, marginBottom: 90 }}>
        <Text style={[styles.title, { marginTop: 8 }]}>{strings.type}:</Text>

        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: "space-around", marginVertical: 12 }}>
          {
            tools.map((tool) => {
              var isSelected = projectTool === tool.type
              return (
                <TouchableOpacity
                  key={tool.name}
                  onPress={() =>  setProjectTool(isSelected ? 0 : tool.type)}
                  style={{ justifyContent: "flex-start", alignItems: "center" }}>
                  <Image
                    style={{ width: 40, height: 40 , tintColor: isSelected ? SECONDARY_COLOR : "#a0a0a0"}}
                    urlOnServer={tool.img} />
                  <Text
                    style={{textAlign: "center", alignSelf: "center", letterSpacing: 1, color: isSelected ? '#2ba5f7' : '#555', marginTop: 8, fontSize: 12 }}>{tool.name}</Text>
                </TouchableOpacity>
              )
          })}
        </View>
      </View>
    )
  }


  var width = Math.min(400, Dimensions.get('window').width)

  var marginTop = 5
  var containerWidth = width / 3
  var imgPadding = containerWidth / 7
  var imgWidth = containerWidth / 3


  return(

        <ImageBackground urlOnServer={'/imgs/background_knitting2.jpg'}
          style={{ width:'100%', height: Dimensions.get("window").height - HEADER_HEIGHT, justifyContent:'center', alignItems:'center' }}
        >

         <View style={{width:width, justifyContent:'center'}}>

            <Text style={{color:"#888"}}>
            {strings.project_name}:
            </Text>
              <TextInput
                style={{color:'#555', borderRadius:16, backgroundColor:'#FFF', padding:8, marginTop:8}}
                multiline={false}

                onChangeText={(text) => setProjectName(text)}
                maxLength={30}
              />


            {renderProjectTools()}

          {/* <View style={{flexDirection:'row', justifyContent:'space-around', padding:16, marginTop:20}}>

          <TouchableOpacity onPress={() => setProjectTool(projectTool == 1 ? 0 : 1)} >
           <View style={{width: containerWidth, height: containerWidth, backgroundColor: projectTool == 1 ? '#2ba5f7' : '#A0D9FF', borderRadius:8, justifyContent:"flex-end"}}>

             <View style={{position:"absolute", top:0, right:0, left:0, bottom:0, alignItems:'center', justifyContent:'center'}}>
              <Image urlOnServer={'/imgs/type_tricot.png'} style={{width: imgWidth, height: imgWidth, alignSelf:'center'}} />
             </View>
            <Text style={{color: '#fff', marginBottom:8, textAlign:'center', fontSize:12, fontWeight:700}}>
            {strings.knitting_mode}
            </Text>

            </View>
        </TouchableOpacity>

          <TouchableOpacity onPress={() => setProjectTool(projectTool == 2 ? 0 : 2)} >
           <View style={{width: containerWidth, height: containerWidth, backgroundColor: projectTool == 2 ? '#2ba5f7' : '#A0D9FF', borderRadius:8, justifyContent:"flex-end"}}>

             <View style={{position:"absolute", top:0, right:0, left:0, bottom:0, alignItems:'center', justifyContent:'center'}}>
              <Image urlOnServer={'/imgs/type_crochet.png'} style={{width: imgWidth, height: imgWidth, alignSelf:'center'}} />
             </View>
            <Text style={{color: '#fff', marginBottom:8, textAlign:'center', fontSize:12, fontWeight:700}}>
            {strings.crochet_mode}
            </Text>

            </View>
        </TouchableOpacity>




         </View> */}

           <Button title={strings.create} style={{alignSelf:'center', marginTop:50, minWidth:120}} onPress={() => handleSubmit()}/>

        </View>







        </ImageBackground>

  )
}
const styles = StyleSheet.create({
  input: {
    marginTop: 12,
    backgroundColor: `rgba(${SECONDARY_COLOR_RGB},0.15)` ,
    color: PURPLE_COLOR_PLAIN,
    paddingLeft: 16,
    textAlign: "left"
  },
  title: {
    color: "#555",
    textAlign: "left",
    letterSpacing: 1,
    //fontFamily: "OpenSans-Light",
  },
});
