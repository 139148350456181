import React from 'react';
import useToken from '../../hooks/useToken';
import useUser from '../../hooks/useUser';
import { useState, useEffect } from 'react';
import PatternUtils from "../../generator/PatternUtils";
import { LANGS } from "../../generator/PatternUtils";
import Image from "../../components/Image";
import { useParams, useHistory, useLocation } from "react-router-dom";
import strings from "../../config/strings";
import Utils from '../../utils/utils';

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

export default function EditGauge(props) {
  const [createdPattern, setCreatedPattern] = useState(props.createdPattern)

  const [point, setPoint] = useState( (props.createdPattern && props.createdPattern.gaugePoint) ? props.createdPattern.gaugePoint : "")
  const [nbStitches, setNbStitches] = useState( (props.createdPattern && props.createdPattern.gaugeNbStitches) ? props.createdPattern.gaugeNbStitches : "")
  const [nbRows, setNbRows] = useState( (props.createdPattern && props.createdPattern.gaugeNbRows) ? props.createdPattern.gaugeNbRows : "")

  useEffect(() => {
  
    console.log("props", props)



  }, [])

  const onSave = () => {
    var updatedPattern = createdPattern

    updatedPattern.gaugePoint = point
    updatedPattern.gaugeNbStitches = nbStitches
    updatedPattern.gaugeNbRows = nbRows
    updatedPattern.updatedAtOnClient = Utils.getTime()
    //console.log("Updated Pattern", updatedPattern)
    props.onSave(updatedPattern)
    props.showCollapse(false)
    PatternUtils.updatePatternOnServer(updatedPattern, () => console.log(createdPattern))
    
  }

  const checkNum = (input, callBack) => {
    const re = /^[0-9\b]+$/;
    if (re.test(input)) {
      callBack(input)
    } else {
      alert("Please only enter numbers")
    }
  }
  
  return(
 

    <View style={{flex: 1}}>
        <p className="PatternOptionTitle">{strings.gauge}</p>
        <View style={{flex: 1, flexDirection: "row"}}>

          <View style={{flex: 1, justifyContent: "center", alignItems:"center"}}>
            <Image style={{width: 200, height: 200}} urlOnServer={'/imgs/gauge.png'} />
          </View>

          <View style={{flex: 1}}>

            <Text style={[styles.title, {marginTop:16}]}>{strings.worked_in}:</Text>
            <TextInput
                    containerStyle={{height: 60,flex: 1}}
                    style={[styles.input, {height: 40, borderRadius: 20, width: 300 }]}
                    multiline={false}
                    value={point}
                    onChangeText={(point) => setPoint(point)}
                    returnKeyType="done"
                  />

            <Text style={[styles.title, {marginTop:16}]}>{strings.patternCreator.gaugeStitch}</Text>
            <TextInput
                    containerStyle={{height: 60,flex: 1}}
                    style={[styles.input, {height: 40, borderRadius: 20, width: 300 }]}
                    multiline={false}
                    value={nbStitches}
                    onChangeText={(point) => checkNum(point, setNbStitches)}
                    returnKeyType="done"
                  />

            <Text style={[styles.title, {marginTop:16}]}>{strings.patternCreator.gaugeRow}</Text>
            <TextInput
                    containerStyle={{height: 60,flex: 1}}
                    style={[styles.input, {height: 40, borderRadius: 20, width: 300 }]}
                    multiline={false}
                    value={nbRows}
                    onChangeText={(point) => checkNum(point, setNbRows)}
                    returnKeyType="done"
                  />

          </View>
        </View>
      <div className="NewPatternBtns" >
        <button className="ImportPatternBtn" onClick={() => onSave()}>
          Save
        </button> 
      </div>  
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginTop: 12,
    backgroundColor: "rgba(255,255,255,0.15)" , 
    color: "white",
    paddingLeft: 16,
    textAlign: "left"
  },
  title: {
    color: "rgba(255,255,255,0.8)", 
    textAlign: "left",
    selfAlign: "flex-start",
  },

});