import React from 'react';
import useToken from '../../hooks/useToken';
import useUser from '../../hooks/useUser';
import { useState, useEffect } from 'react';
import PatternUtils from "../../generator/PatternUtils";
import { LANGS } from "../../generator/PatternUtils";
import Image from "../../components/Image";
import { useParams, useHistory, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import strings from "../../config/strings";
import Utils from '../../utils/utils';


import PicUpload from "../../components/generator/PicUpload";

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

export default function AlertModal(props) {

  const title = props.title
  const text = props.text
  const onOk = props.onOk
  const onCancel = props.onDelete
  const innerContent = props.innerContent





 useEffect(() => {

  }, [])

  
  return(
 

    <View
    style={{
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      paddingTop: 120,
      justifyContent: "flex-start",
      alignItems:'center',
      zIndex: 5000,
      backgroundColor: "rgba(128, 128, 128, 0.7)",
    }}
    >
      <View
      style={{
        width: 500, 
        minHeight: 300,
        backgroundColor: "white", 
        justifyContent: "space-around",
        alignItems: "center",
        borderRadius: 12,
        paddingVertical: 24,
        paddingHorizontal: 40,
      }}>
      <p className="ModalTitle">{title}</p>
      <p className="ModalText">{parse(text)}</p>
      {innerContent && innerContent()}
        <View style={{flexDirection: "row", justifyContent: "center", alignItems: "space-around"}}>
        {onCancel &&
          <button className="ModalBtn BtnSec" onClick={() => onCancel()}>
         Cancel
        </button>
        }
        <button className="ModalBtn BtnPrimary" onClick={() => onOk()}>
          Ok
        </button>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({


});