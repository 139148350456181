export const SYMBOLS_EN = [

  {
      "id": 0,
      "rs": "Null",
      "ws": "Null",
      "rs_long": "Nothing",
      "ws_long": "Nothing",
      "img": "/imgs/symbols/0_null.png",
      "t_img": "/imgs/symbols/transparent/0_null.png",
  },
  {
      "id": 1,
      "rs": "K",
      "ws": "P",
      "rs_long": "Knit",
      "ws_long": "Purl",
      "img": "/imgs/symbols/1_k_p.png",
      "t_img": "/imgs/symbols/transparent/1_k_p.png"
  },
  
  {
      "id": 2,
      "rs": "P",
      "ws": "K",
      "rs_long": "Purl",
      "ws_long": "Knit",
      "img": "/imgs/symbols/2_p_k.png",
      "t_img": "/imgs/symbols/transparent/2_p_k.png"
  },
  
  {
      "id": 3,
      "rs": "yo",
      "ws": "yo",
      "rs_long": "Yarn over",
      "ws_long": "Yarn over",
     // "img": "/imgs/symbols/3_yo.png",
      "t_img": "/imgs/symbols/transparent/3_yo.png"
  },
  
  {
      "id": 4,
      "rs": "k2tog",
      "ws": "p2tog",
      "rs_long": "Knit 2 together",
      "ws_long": "Purl 2 together",
     // "img": "/imgs/symbols/4_k2tog_p2tog.png",
      "t_img": "/imgs/symbols/transparent/4_k2tog_p2tog.png"
  },
  
  {
      "id": 5,
      "rs": "p2tog",
      "ws": "k2tog",
      "rs_long": "Purl 2 together",
      "ws_long": "Knit 2 together",
      //"img": "/imgs/symbols/5_p2tog_k2tog.png",
      "t_img": "/imgs/symbols/transparent/5_p2tog_k2tog.png"
  },
  
  {
      "id": 6,
      "rs": "SSK",
      "ws": "SSP",
      "rs_long": "Slip slip knit",
      "ws_long": "Slip slip purl",
     // "img": "/imgs/symbols/6_SSK_SSP.png",
      "t_img": "/imgs/symbols/transparent/6_SSK_SSP.png"
  },
  
  {
      "id": 7,
      "rs": "SSP",
      "ws": "SSK",
      "rs_long": "Slip slip purl",
      "ws_long": "Slip slip knit",
      //"img": "/imgs/symbols/7_SSP_SSK.png",
      "t_img": "/imgs/symbols/transparent/7_SSP_SSK.png",
  },
  
  {
      "id": 8,
      "rs": "K1fb",
      "ws": "p1fb",
      "rs_long": "Knit 1 front & back",
      "ws_long": "p1fb",
     // "img": "/imgs/symbols/8_K1fb_p1fb.png",
      "t_img": "/imgs/symbols/transparent/8_K1fb_p1fb.png",
  },
  
  {
      "id": 9,
      "rs": "Make 1 (M1) knitwise",
      "ws": "make 1 (M1) purlwise",
      "rs_long": "Make 1 (M1) knitwise",
      "ws_long": "make 1 (M1) purlwise",
    //  "img": "/imgs/symbols/9_m1.png",
      "t_img": "/imgs/symbols/transparent/9_m1.png"
  },
  
  {
      "id": 10,
      "rs": "Make 1 (M1) purlwise",
      "ws": "make 1 (M1) knitwise",
      "rs_long": "Make 1 (M1) purlwise",
      "ws_long": "make 1 (M1) knitwise",
    //  "img": "/imgs/symbols/10_m1.png",
      "t_img": "/imgs/symbols/transparent/10_m1.png"
  },
  
  {
      "id": 11,
      "rs": "Right-slanting make 1",
      "ws": "Right-slanting make 1",
      "rs_long": "Right-slanting make 1",
      "ws_long": "Right-slanting make 1",
    //  "img": "/imgs/symbols/11_Rslanting.png",
      "t_img": "/imgs/symbols/transparent/11_Rslanting.png"
  },
  
  {
      "id": 12,
      "rs": "Left-slanting make 1",
      "ws": "Left-slanting make 1",
      "rs_long": "Left-slanting make 1",
      "ws_long": "Left-slanting make 1",
     // "img": "/imgs/symbols/12_Lslanting.png",
      "t_img": "/imgs/symbols/transparent/12_Lslanting.png"
  },
  
  {
      "id": 13,
      "rs": "Right-slanting lifted inc",
      "ws": "Right-slanting lifted inc",
      "rs_long": "Right-slanting lifted inc",
      "ws_long": "Right-slanting lifted inc",
     // "img": "/imgs/symbols/13_RslantingLift.png",
      "t_img": "/imgs/symbols/transparent/13_RslantingLift.png"
  },
  
  
  {
      "id": 14,
      "rs": "Left-slanting lifted inc",
      "ws": "Left-slanting lifted inc",
      "rs_long": "Left-slanting lifted inc",
      "ws_long": "Left-slanting lifted inc",
      "t_img": "/imgs/symbols/transparent/14_LslantingLift.png"
  },
  
  {
      "id": 15,
      "rs": "Sl 1 purlwise wyb",
      "ws": "sl 1 purlwise wyf",
      "rs_long": "Slip 1 purlwise with yarn in back",
      "ws_long": "Slip 1 purlwise with yarn in front",
      "t_img": "/imgs/symbols/transparent/15_sl1purl.png"
  },
  {
      "id": 16,
      "rs": "Sl 1 purlwise wyf",
      "ws": "sl 1 purlwise wyb",
      "rs_long": "Slip 1 purlwise with yarn in front",
      "ws_long": "Slip 1 purlwise with yarn in back",
      "t_img": "/imgs/symbols/transparent/16_sl1purl.png"
  },
  
  
  {
      "id": 17,
      "rs": "K3tog",
      "ws": "p3tog",
      "rs_long": "Knit 3 together",
      "ws_long": "Purl 3 together",
      "t_img": "/imgs/symbols/transparent/17_K3tog_P3tog.png"
  },
  
  {
      "id": 18,
      "rs": "P3tog",
      "ws": "K3tog",
      "rs_long": "Purl 3 together",
      "ws_long": "Knit 3 together",
      "t_img": "/imgs/symbols/transparent/18_P3tog_k3tog.png"
  },
  
  
  {
      "id": 19,
      "rs": "SK2P",
      "ws": "SSSP",
      "rs_long": "Knit the Slip, Knit Two, Pass Double Decrease",
      "ws_long": "Slip, Slip, Slip, Purl",
      "t_img": "/imgs/symbols/transparent/19_SK2P_SSSK_SSSP.png"
  },
  
  
  {
      "id": 20,
      "rs": "SSSP",
      "ws": "SSSK",
      "rs_long": "Slip, Slip, Slip, Purl",
      "ws_long": "SSSK",
      "t_img": "/imgs/symbols/transparent/20_SSSP_SSSK.png"
  },
  
  
  {
      "id": 21,
      "rs": "S2KP2",
      "ws": "SSPP2",
      "rs_long": "Slip 2, Knit, Pass 2",
      "ws_long": "SSPP2",
      "t_img": "/imgs/symbols/transparent/21_S2KP2_SSPP2.png"
  },
  
  {
      "id": 22,
      "rs": "K1 tbl",
      "ws": "p1 tbl",
      "rs_long": "Knit 1 through back loop",
      "ws_long": "Purl 1 through back loop",
      "t_img": "/imgs/symbols/transparent/22_K1_tbl_p1_tbl.png"
  },
  
  {
      "id": 23,
      "rs": "p1 tbl",
      "ws": "K1 tbl",
      "rs_long": "Purl 1 through back loop",
      "ws_long": "Knit 1 through back loop",
      "t_img": "/imgs/symbols/transparent/23_P1_tbl_k1_tbl.png"
  },
  
  
  {
      "id": 24,
      "rs": "Bobble",
      "ws": "Bobble",
      "rs_long": "Bobble",
      "ws_long": "Bobble",
      "t_img": "/imgs/symbols/transparent/24_Bobble.png"
  },
  
  {
      "id": 25,
      "rs": "Increase 1 to 3",
      "ws": "Increase 1 to 3",
      "rs_long": "Increase 1 to 3",
      "ws_long": "Increase 1 to 3",
      "t_img": "/imgs/symbols/transparent/25_Inc1-3.png"
  },
  
  {
      "id": 26,
      "rs": "Increase 1 to 4",
      "ws": "Increase 1 to 4",
      "rs_long": "Increase 1 to 4",
      "ws_long": "Increase 1 to 4",
      "t_img": "/imgs/symbols/transparent/26_Inc1-4.png"
  },
  
  {
      "id": 27,
      "rs": "Increase 1 to 5",
      "ws": "Increase 1 to 5",
      "rs_long": "Increase 1 to 5",
      "ws_long": "Increase 1 to 5",
      "t_img": "/imgs/symbols/transparent/27_Inc1-5.png"
  },
  
  {
      "id": 28,
      "rs": "Decrease 4 to 1 (right slanting)",
      "ws": "Decrease 4 to 1 (right slanting)",
      "rs_long": "Decrease 4 to 1 (right slanting)",
      "ws_long": "Decrease 4 to 1 (right slanting)",
      "t_img": "/imgs/symbols/transparent/28_Dec4-1_right_slanting.png"
  },
  
  {
      "id": 29,
      "rs": "Decrease 4 to 1 (left slanting)",
      "ws": "Decrease 4 to 1 (left slanting)",
      "rs_long": "Decrease 4 to 1 (left slanting)",
      "ws_long": "Decrease 4 to 1 (left slanting)",
      "t_img": "/imgs/symbols/transparent/29_Dec4-1_left_slanting.png"
  },
  
  {
      "id": 30,
      "rs": "Decrease 4 to 1 (center)",
      "ws": "Decrease 4 to 1 (center)",
      "rs_long": "Decrease 4 to 1 (center)",
      "ws_long": "Decrease 4 to 1 (center)",
      "t_img": "/imgs/symbols/transparent/30_Dec4-1_center.png"
  },
  
  {
      "id": 31,
      "rs": "Decrease 5 to 1",
      "ws": "Decrease 5 to 1",
      "rs_long": "Decrease 5 to 1",
      "ws_long": "Decrease 5 to 1",
      "t_img": "/imgs/symbols/transparent/31_Dec5-1.png"
  },
  
  
  {
      "id": 32,
      "rs": "K1, wrapping yarn twice around needle",
      "ws": "K1, wrapping yarn twice around needle",
      "rs_long": "K1, wrapping yarn twice around needle",
      "ws_long": "K1, wrapping yarn twice around needle",
      "t_img": "/imgs/symbols/transparent/32_K1_wrapping_twice.png"
  },
  
  {
      "id": 33,
      "rs": "Bind off",
      "ws": "Bind off",
      "rs_long": "Bind off",
      "ws_long": "Bind off",
      "t_img": "/imgs/symbols/transparent/33_Bind_off.png"
  },
  
  {
      "id": 34,
      "rs": "St rem on right needle after last BO st",
      "ws": "St rem on right needle after last BO st",
      "rs_long": "Stitch remains on right needle after last Binded off stitch",
      "ws_long": "Stitch remains on right needle after last Binded off stitch",
      "t_img": "/imgs/symbols/transparent/34_Strem.png"
  },
  
  {
      "id": 35,
      "rs": "Cast on",
      "ws": "Cast on",
      "rs_long": "Cast on",
      "ws_long": "Cast on",
      "t_img": "/imgs/symbols/transparent/35_Cast_on.png"
  },
  
  {
      "id": 36,
      "rs": "2-st RC",
      "ws": "",
      "rs_long": "2 stitches right cross: slip 1 stitch to cable needle and hold to back, knit 1, knit 1 from cable needle",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/36_2st_RC.png",
      "width":2,
      "height":1
  },
  {
      "id": 37,
      "rs": "2-st LC",
      "ws": "",
      "rs_long": "2 stitches left cross: slip 1 stitch to cable needle and hold to front, knit 1, knit 1 from cable needle",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/37_2st_LC.png",
      "width":2,
      "height":1
  },
  {
      "id": 38,
      "rs": "2-st RPC",
      "ws": "",
      "rs_long": "Slip 1 stitch to cable needle and hold to back, k1, p1 from cable needle",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/38_2stRPC.png",
      "width":2,
      "height":1
  },
  {
      "id": 39,
      "rs": "2-st LPC",
      "ws": "",
      "rs_long": "Slip 1 stitch to cable needle and hold to front, k1, p1 from cable needle",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/39_2stLPC.png",
      "width":2,
      "height":1
  },
  {
      "id": 40,
      "rs": "3-st RC",
      "ws": "",
      "rs_long": "Slip 1 stitch to cable needle and hold to back, k2, p1 from cable needle",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/40_3st_RC.png",
      "width":3,
      "height":1
  },
  {
      "id": 41,
      "rs": "3-st LC",
      "ws": "",
      "rs_long": "Slip 2 stitch to cable needle and hold to front, p1, k2 from cable needle",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/41_3st_LC.png",
      "width":3,
      "height":1
  },
  {
      "id": 42,
      "rs": "3-st RPC",
      "ws": "",
      "rs_long": "Slip 1 stitch to cable needle, hold to back, k2, p1 from cable needle",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/42_3st_RPC.png",
      "width":3,
      "height":1
  },
  {
      "id": 43,
      "rs": "3-st LPC",
      "ws": "",
      "rs_long": "Slip 2 stitches to cable needle, hold to front, p1, k2 from cable needle",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/43_3_stLPC.png",
      "width":3,
      "height":1
  },
  {
      "id": 44,
      "rs": "4-st RC",
      "ws": "",
      "rs_long": "Slip 2 stitches to cable needle and hold to back, k2, k2 from cable needle",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/44_4st_RC.png",
      "width":4,
      "height":1
  },
  {
      "id": 45,
      "rs": "4-st LC",
      "ws": "",
      "rs_long": "Slip 2 stitches to cable needle and hold to front, k2, k2 from cable needle",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/45_4st_LC.png",
      "width":4,
      "height":1
  },
  {
      "id": 46,
      "rs": "4-st RPC",
      "ws": "",
      "rs_long": "Slip 2 stitches to cable needle and hold to back, k2, p2 from cable needle",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/46_4st_RPC.png",
      "width":4,
      "height":1
  },
  {
      "id": 47,
      "rs": "4-st LPC",
      "ws": "",
      "rs_long": "Slip 2 stitches to cable needle and hold to front, p2, k2 from cable needle",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/47_4st_LPC.png",
      "width":4,
      "height":1
  },
  {
      "id": 48,
      "rs": "5-st RPC",
      "ws": "",
      "rs_long": "Slip 2 stitches to cable needle, hold to back, Slip 1 stitch to second cable needle, hold to back, k2, p1 from 2nd cable needle, k2 from first cable needle",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/48_5st_RPC.png",
      "width":5,
      "height":1
  },
  {
      "id": 49,
      "rs": "5-st LPC",
      "ws": "",
      "rs_long": "Slip 2 stitches to cable needle, hold to front, Slip 1 stitch to second cable needle, hold to back, k2, p1 from back cable needle, k2 from front cable needle",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/49_5st_LPC.png",
      "width":5,
      "height":1
  },
  
  
  
  
  //CROCHET
  {
      "id": 50,
      "rs": "Chain",
      "ws": "",
      "rs_long": "Chain",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/50_chain.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  {
      "id": 51,
      "rs": "Slip stitch",
      "ws": "",
      "rs_long": "Slip stitch",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/51_slip.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  {
      "id": 52,
      "rs": "Single crochet",
      "ws": "",
      "rs_long": "Single crochet",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/52_single_crochet.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  {
      "id": 53,
      "rs": "Half Double crochet",
      "ws": "",
      "rs_long": "Half Double crochet",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/53_half_double_crochet.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 54,
      "rs": "Double crochet",
      "ws": "",
      "rs_long": "Double crochet",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/54_double_crochet.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 55,
      "rs": "Treble crochet",
      "ws": "",
      "rs_long": "Treble crochet",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/55_treble_crochet.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 56,
      "rs": "Double Treble crochet",
      "ws": "",
      "rs_long": "Double Treble crochet",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/56_double_treble_crochet.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 57,
      "rs": "sc2tog",
      "ws": "",
      "rs_long": "Single crochet 2 together",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/57_sc2tog.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  
  {
      "id": 58,
      "rs": "sc3tog",
      "ws": "",
      "rs_long": "Single crochet 3 together",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/58_sc3tog.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  
  {
      "id": 59,
      "rs": "dc2tog",
      "ws": "",
      "rs_long": "Double crochet 2 together",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/59_dc2tog.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 60,
      "rs": "dc3tog",
      "ws": "",
      "rs_long": "Double crochet 3 together",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/60_dc3tog.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 61,
      "rs": "3dc cluster",
      "ws": "",
      "rs_long": "3-double crochet cluster",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/61_3dc_cluster.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 62,
      "rs": "3hdc cluster",
      "ws": "",
      "rs_long": "3-half double crochet cluster",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/62_3hdc_cluster.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 63,
      "rs": "5-dc popcorn",
      "ws": "",
      "rs_long": "5-double crochet popcorn",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/63_5_dc_popcorn.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 64,
      "rs": "5-dc_shell",
      "ws": "",
      "rs_long": "5-double crochet shell",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/64_5_dc_shell.png",
      "width":2,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 65,
      "rs": "ch 3 picot",
      "ws": "",
      "rs_long": "Chain 3 picot",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/65_ch_3_picot.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  
  {
      "id": 66,
      "rs": "front post dc (FPdc)",
      "ws": "",
      "rs_long": "front post double crochet",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/66_front_post.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 67,
      "rs": "back post dc (BPdc)",
      "ws": "",
      "rs_long": "back post double crochet",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/67_back_post.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 68,
      "rs": "worked in back loop only",
      "ws": "",
      "rs_long": "worked in back loop only",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/68_back_loop.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  
  {
      "id": 69,
      "rs": "worked in front loop only",
      "ws": "",
      "rs_long": "worked in front loop only",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/69_front_loop.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  ]