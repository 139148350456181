import React from 'react';
import useToken from '../hooks/useToken';
import useUser from '../hooks/useUser';
import { useState, useEffect } from 'react';
import { SERVER_API_URL } from '../config/Constants'
import ViewProject from './ViewProject';
import ProjectsList from '../components/ProjectsList';
import { BrowserRouter, Route, Switch, useRouteMatch, Link } from 'react-router-dom';
import ImageBackground from "../components/ImageBackground"
import Image from "../components/Image";
import ProfileHeader from "../components/ProfileHeader"
import { useHistory } from "react-router-dom";
import fetchClient from "../utils/http-common";
import strings from "../config/strings";
import Utils from '../utils/utils'
import { HEADER_HEIGHT } from '../config/Constants'
import { GLOSSARY_KNIT } from "../config/glossary_knit";
import { GLOSSARY_CRO } from "../config/glossary_cro";


import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';



export default function RcPatterns() {

  const [selectedLang, setSelectedLang] = useState(strings.lang);
  const [selectedItem, setSelectedItem] = useState();
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState();
  const [filteredGlossary, setFilteredGlossary] = useState();
  const [chosenType, setChosenType] = useState("knit")
  const [chosenGlossaire, setChosenGlossaire] = useState(GLOSSARY_KNIT)
  const SECONDARY_COLOR = "#2ba5f7"


  const setType = (type) => {
    setChosenType(type)
    setFilteredGlossary([])
    setSearchText(false)
    if (type == "knit") {
      setChosenGlossaire(GLOSSARY_KNIT)
    }
    if (type == "crochet") {
      setChosenGlossaire(GLOSSARY_CRO)
    }
  }

  const searchTerm = (term, meth) => {
    // var filtered = GLOSSAIRE.filter((item) => {
    //   item[selectedLang].abbr.search(term) > -1
    // })

    var filtered = []
    if (term[0]) {
      chosenGlossaire.forEach((item) => {
        if (item[selectedLang][meth] && item[selectedLang][meth].toLowerCase().includes(term.toLowerCase())) {
          filtered.push(item)
        }
      })
    }
    setFilteredGlossary(filtered)
  }

  const renderLang = (lang, setLang, selected) => {
    const chosen = lang == selected ? true : false
    switch(lang) {
      case "en":
        var flag = "/imgs/flags/england.png";
        break
        case "fr":
          var flag = "/imgs/flags/france.png";
          break
        case "de":
          var flag = "/imgs/flags/germany.png";
          break
    }
    return (
      <TouchableOpacity
        onPress={() => setLang(lang)}
        style={{width: 30, height: 30,  marginRight:8, padding:2, borderWidth: chosen ? 3 : 0,borderColor: SECONDARY_COLOR,borderRadius:15, justifyContent:"center", alignItems:"center"}}>
        <Image 
        urlOnServer={flag}
        style={{width: 24, height: 24,  alignSelf: "center", borderRadius:12}}/>
      </TouchableOpacity>

    )
  }

  const renderOneAbb = (lang, langName) => {
    return (
      <View style={{flex: 1, flexDirection:"row", opacity: langName == selectedLang ? 1 : 0.5}}>
      <Text
        style={{
          color: SECONDARY_COLOR,
          fontWeight: 'bold',
          width: 100,
        }}>
        {lang.abbr}
      </Text>
      <Text
        style={{
          color:'#525252',
          flex:1,
          paddingHorizontal: 12,
        }}>
        {lang.txt}
      </Text>
      </View>
    )
  }
  
  const renderItem = (item) => {
    console.log(item)
      return (
        <div className="col-12">
          <View style={{flexDirection:"row", paddingVertical:12, borderBottomWidth: 1, borderColor: "rgba(0,0,0,0.15)"}}>
          {renderOneAbb(item["en"], "en")}
          {renderOneAbb(item["fr"], "fr")}
          {renderOneAbb(item["de"], "de")}
          </View>

        </div>
      );
   
  };

  const renderAllItems = (items) => {
    return(
    items.map((item) => {
      return(
        renderItem(item)
      ) 
    })
    )
  }


  const renderLangTitle = (title) => {
    var selected = title.toLowerCase() == selectedLang ? true : false
    switch(title.toLowerCase()) {
      case "en":
        var flag = "/imgs/flags/england.png";
        break
        case "fr":
          var flag = "/imgs/flags/france.png";
          break
        case "de":
          var flag = "/imgs/flags/germany.png";
          break
    }
    return (
      <View style={{flex: 1, flexDirection:"row", justifyContent: "center", alignItems:"center"}}>
      <Text
        style={{
          color: selected ? "rgba(0,0,0,0.4)" : "rgba(0,0,0,0.2)",
          fontWeight: 'bold',
          textAlign: "center",
          fontSize:22,
        }}>
        {title}
      </Text>
      <Image 
        style={{width:24, height: 24, marginLeft: 8, opacity: selected ? 1 : 0.5}}
        urlOnServer={flag} />
    </View>
    )
  }
  const renderOneFilter = (tool) => {
    var border = {flex:1,marginVertical:10,marginHorizontal:5, borderRadius:20, borderColor:"#A0A0A0",borderWidth:1}
    var text = {fontSize:16, textAlign:"center",marginHorizontal:24, color:"#545454",marginVertical:5}
    if (chosenType == tool) {
      border = {flex:1,marginVertical:10,marginHorizontal:5, borderRadius:20,  borderColor:"#2ba5f7",borderWidth:2}
      text = {fontSize:16, textAlign:"center",marginHorizontal:24, color:"#2ba5f7", fontWeight:"bold", marginVertical:5}
    }
    let toolName = tool == "knit" ? strings.knitting_mode : strings.crochet_mode
    return (
      <TouchableOpacity 
      style= {border}
      onPress= {() => setType(tool) }>
       <Text
            style={text}>{toolName}</Text>
      </TouchableOpacity>
    )
  }
  const renderAllFilters = () => {
    return (
      <FlatList
        contentContainerStyle={{flexGrow: 1, alignItems: "center", justifyContent: "center"}}
        data={["knit","crochet"]}
        extraData={chosenType}
        numColumns={1}
        horizontal={true}
        key={'ToolList_ ' + 3}
        renderItem={item => renderOneFilter(item.item)}
        keyExtractor={(item, index) => item.id}
      />
    )
  }

  const renderSearch = (placeHolder, meth) => {
    return (
      <View
      style={{flexDirection:"row", flex: 1, justifyContent:"space-between", alignItems:"center"}}>
        <TextInput
          contentContainerStyle={{flex: 1, justifyContent:"flex-start", alignItems:"flex-start"}}
          style={{ flex:1, marginVertical: 8, color: SECONDARY_COLOR, paddingHorizontal: 18, textAlign:"left", paddingVertical: 10, backgroundColor:"rgba(0,0,0,0.05)", borderRadius:25, fontSize: 14, marginRight: 16 }}
          placeholderTextColor="#a0a0a0"
          multiline={false}
          defaultValue={ undefined }
          placeholder={placeHolder}
          onChangeText={(text) => searchTerm(text, meth)}
          returnKeyType="done"
        />
      </View>   
    )
  }

  return(
 

    <View style={{flexDirection:'column', backgroundColor: "#E0E1E3", width: "100%", minHeight: Dimensions.get('window').height-72}}>
     <div className="container mt-5 rcpattern-container">
       <h2>{strings.glossaryP.glossary}</h2>
       <h5>{strings.glossaryP.subtitle}</h5>
       <div className="mt-4 filters">
      {renderAllFilters()}
       </div>
       <View
              style={{flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
              {renderSearch(strings.glossaryP.searchPh,"abbr")}
              {renderLang("en", setSelectedLang, selectedLang)}
              {renderLang("fr", setSelectedLang, selectedLang)}
              {renderLang("de", setSelectedLang, selectedLang)}
            </View>
      <div className="mt-4" >
        <div className="row">
          <div className="col-12">
            <View style={{flexDirection: "row"}}>
              {renderLangTitle("EN")}
              {renderLangTitle("FR")}
              {renderLangTitle("DE")}
            </View>
          </div>
          {(filteredGlossary && filteredGlossary[0]) ? renderAllItems(filteredGlossary) : renderAllItems(chosenGlossaire)}
        </div>

        </div>       
      </div>

    </View>
  );
}