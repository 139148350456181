import React, { Component } from "react";
import strings from "../../config/strings";
import Image from "../../components/Image"
import Utils from "../../utils/utils";
import PatternUtils from "../../generator/PatternUtils";
import MyTextInput from '../../components/MyTextInput'
import {SECONDARY_COLOR} from "../../config/styles";
import { getAllSizes, getSizesFromIds, getCatFromSize } from "../../config/sizes"
import ChartSizes from "../../components/generator/ChartSizes"
import { withRouter } from "react-router";

import {
  ScrollView,
  View,
  TextInput,
  ImageBackground,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Keyboard,
  SafeAreaView,
  DeviceEventEmitter,
  PanResponder,
  BackHandler,
  Dimensions
} from 'react-native';


const MARGIN_HORIZONTAL = 16

class ChartList extends Component {

  constructor(props) {
    super(props);

    this.patternUtils = new PatternUtils(null);
    var params = this.props.history.location.state
    this.pattern = params.pattern
    this.section = PatternUtils.getSection(this.pattern, params.section.uniqueIdOnClient) 
    this.state = {
      charts: this.getCharts()
    };
    //alert(JSON.stringify(this.pattern.sections.length))
  }



  getCharts() {
    return this.section.subsections.filter(obj => {
      return obj.deleted != 1
    })
  }



  renderItem(item) {

     var strSizes = '';

     var sizes = getSizesFromIds([...item.sizeIds].sort())

     var lastCat = ''
     for (var i = 0; i < sizes.length; i++) {
        var size = sizes[i]
        var cat = getCatFromSize(size.id).name

        var addComma = true
        if (cat != lastCat) {
          strSizes += (lastCat != '' ? '\n' : '' ) + cat + ': '
          lastCat = cat
          addComma = false
        }

        strSizes += (addComma ? ', ' : '' ) +  size.text
     }

     var iconSize = 20
     var iconViewSize = 40
     console.log("chart url", PatternUtils.getSectionFromSectionType("chart").img)


     return (<TouchableOpacity style={{flexDirection:'row', alignItems:'center', marginLeft:16, borderBottomWidth:0.5
      , borderColor:'#ccc', marginRight:16, paddingTop:8, paddingBottom:12, marginBottom:5, justifyContent:'center'}}
      onPress={() => {
        this.props.history.push({
        pathname: '/edit-chart',
        state: { patternId: this.pattern.id, sectionUniqueId:this.section.uniqueIdOnClient, subsectionUniqueId: item.uniqueIdOnClient }
      })
      }
}>
       <View style={{width: iconViewSize, height: iconViewSize, backgroundColor:SECONDARY_COLOR, borderRadius: iconViewSize / 2
        , justifyContent:'center', alignItems:'center', opacity:0.7}}>

        <Image urlOnServer={PatternUtils.getSectionFromSectionType("chart").img} style={{width:iconSize, height:iconSize}}
        resizeMode='contain'/>
        </View>
        <View style={{ marginHorizontal:MARGIN_HORIZONTAL, flex:1 }}>
            <Text style={{fontWeight:'bold', color:'white'}}>{strings.chart}</Text>
            <Text style={{color:'rgba(255,255,255,0.6)'}}>{strSizes}</Text>


           
            </View>

             <TouchableOpacity style={styles.editBtn} onPress={() => this.setState({showSubsectionMenu: item})}>

         <Image style={[styles.editImg, {marginRight:16, tintColor: "white"}]} urlOnServer={'/imgs/menu.png'}/>
         </TouchableOpacity>
            </TouchableOpacity>
        )
  }

  renderDuplicate() {


    //alert(JSON.stringify(this.patternUtils.getUnavailableSizeIds(this.section, null)))
    return (
      <ChartSizes onClose={() => this.setState({showDuplicateItem: null})}
            description={strings.duplicate_chart_size}
            selectedSizeIds={[]} allSizeIds={this.pattern.sizeIds}
            unavailableSizeIds={this.patternUtils.getUnavailableSizeIds(this.section, null)}
            onSizesSelected={(sizes) => {

            if (sizes.length == 0) {
              this.setState({showDuplicateItem: null})
              return
            }
          
            var newSubsection = this.patternUtils.duplicateSubsection(this.section, this.state.showDuplicateItem)

            newSubsection.sizeIds = sizes
            
            //this.section.subsections.push(newSubsection)


            this.setState({showDuplicateItem: null, charts: this.getCharts()}, () => {

              this.props.history.replace({
                pathname: '/chart-list',
                state: { pattern: this.pattern, section:this.section }
              })

              PatternUtils.updatePatternOnServer(this.pattern, (patternUpdated) => {
          
                this.pattern = patternUpdated;
                this.props.history.push({
                  pathname: '/edit-chart',
                  state: { patternId: this.pattern.id, sectionUniqueId:this.section.uniqueIdOnClient, subsectionUniqueId: newSubsection.uniqueIdOnClient }
                })

            })


            })

            
             

            

           
          }

          }
            />

      )
  }

  renderEditSize() {
    //alert(JSON.stringify(this.state.editSizeItem))
    return <ChartSizes onClose={() => this.setState({editSizeItem: null})}
            description={strings.show_chart_sizes}
            selectedSizeIds={this.state.editSizeItem.sizeIds} allSizeIds={this.pattern.sizeIds}
            unavailableSizeIds={this.patternUtils.getUnavailableSizeIds(this.section, this.state.editSizeItem)}
            onSizesSelected={(sizes) => {



           
              var subsection = this.section.subsections.filter((item) => item.uniqueIdOnClient === this.state.editSizeItem.uniqueIdOnClient)[0]

              subsection.sizeIds = sizes

               PatternUtils.updatePatternOnServer(this.pattern, (patternUpdated) => {
              
                this.pattern = patternUpdated;
                this.props.history.replace({
                pathname: '/chart-list',
                state: { pattern: this.pattern, section:this.section }
              })
            })



             

            this.setState({editSizeItem: null, charts: this.getCharts()})
          }

          }
            />
  }


  renderNewChart() {
    //alert(JSON.stringify(this.state.editSizeItem))
    return <ChartSizes onClose={() => this.setState({showNewChart: false})}
            description={strings.show_chart_sizes}
            selectedSizeIds={[]} allSizeIds={this.pattern.sizeIds}
            unavailableSizeIds={this.patternUtils.getUnavailableSizeIds(this.section, null)}
            onSizesSelected={(sizes) => {


            //section, name, content, rowStruct, type, position
            var subsection = PatternUtils.createSubsection(this.section, this.section.name, null, null, null, -1);


            //Utils.realmWrite(() => {
              subsection.sizeIds = sizes
              subsection.position = -1
            //})
            this.section.subsections.push(subsection)


            //alert(JSON.stringify(subsection))
            //return 
            PatternUtils.updatePatternOnServer(this.pattern, (patternUpdated) => {


              this.pattern = patternUpdated;
              this.setState({showNewChart: false, charts: this.getCharts()})


              this.props.history.replace({
                pathname: '/chart-list',
                state: { pattern: this.pattern, section:this.section }
              })

              this.props.history.push({
                pathname: '/edit-chart',
                state: { patternId: this.pattern.id, sectionUniqueId:this.section.uniqueIdOnClient, subsectionUniqueId: subsection.uniqueIdOnClient }
              })

            })


            
          }

          }
            />
  }

  renderMenu() {
    return (
      <TouchableOpacity
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          justifyContent: "center",
          alignItems:'center',
          backgroundColor: "rgba(128, 128, 128, 0.7)"
        }}
        onPress={() => this.setState({ showSubsectionMenu: null })}
      >
        <View
          style={{
            backgroundColor: "white",
            borderWidth: 1,
            borderColor: "#ccc",
            borderRadius: 8,
            margin:16
          }}
        >
         
          <TouchableOpacity
              style={styles.menuElem}
              onPress={() => {
                this.setState({editSizeItem: this.state.showSubsectionMenu, showSubsectionMenu: null})
              }}
            >
              <Text style={styles.menuOptionText}>{strings.select_size}</Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.menuElem}
              onPress={() => {
                  this.setState({showDuplicateItem: this.state.showSubsectionMenu, showSubsectionMenu: null})
               
              }}
            >
              <Text style={styles.menuOptionText}>{strings.duplicate}</Text>
            </TouchableOpacity>


        
        </View>


      </TouchableOpacity>
    );
  }
  renderGoBack(){
    return (
      <TouchableOpacity
      style={{
        margin: 12,
        width: 32}}
      onPress={() => this.props.history.goBack()}>
        <Image style={{width: 24, height: 24, margin: 4}} urlOnServer={"/imgs/back.png"}/>
      </TouchableOpacity>
    )
}

  render() {
    
    return (

        <View style={{backgroundColor: "#266D9C", minHeight: Dimensions.get('window').height - 72, flex:1}}
        title={strings.pattern_content}
        disableSafeArea={true}
        onBack={() => this.props.navigation.goBack()}
  
        >
          {this.renderGoBack()}


           <FlatList
              style={{flex:1}}
              data={this.state.charts}
              extraData={this.state}
              numColumns={1}
              keyExtractor={(item, index) => "listchart_" + index.toString()}
              renderItem={item => this.renderItem(item.item)}
            />
     
   
        
        <View colors={['rgba(255,255,255,0.1)', 'rgba(255,255,255,1)']}
          style={{position:'absolute', top:200, left:0, right:0, width:'100%', flexDirection:'row', justifyContent:'space-between', paddingBottom:40, paddingHorizontal:16}}
          >

          <View style={{flex:1}}/>

          <TouchableOpacity style={{ flexDirection:'row', alignItems:'center'}}
          onPress={() => this.setState({showNewChart: true})}>
            <Text style={{color: "white", opacity: 0.8, marginHorizontal:8, width: (Dimensions.get('window').width - (40 * 2) - (8 * 4) - (16 * 2)) / 2, textAlign:'right'}}>
            {strings.add_chart_variation}</Text>
            <Image style={{width:40, height:40}} urlOnServer={'/imgs/plus_bleu.png'}/> 
          </TouchableOpacity>
        
         

        </View>


        {this.state.showSubsectionMenu && this.renderMenu()}
       {this.state.editSizeItem && this.renderEditSize()}

       {this.state.showDuplicateItem && this.renderDuplicate()}

       {this.state.showNewChart && this.renderNewChart()}
        </View>

      )
  
  }
}

export const ChartListWithRouter = withRouter(ChartList);


const styles = StyleSheet.create({
  input: {
    borderBottomWidth:0.5, borderColor:"#888",
    color:'#555',
    padding:0
  },
  title: {
    color:"#888",
    marginHorizontal:8,
    flex:1
  },

  row: {
    flexDirection:'row', marginRight:8, marginTop:0
        , alignItems:'center', borderColor:'#ccc', borderBottomWidth:0.5, paddingVertical:12
  } ,

  editBtn: {},
  editImg: {tintColor:'#888', width:8, height:20, margin:20},
  menuOptionText: {
    color:'#888',
    textAlign:'center'
  },

  menuElem: {
    borderBottomWidth:0.5, padding:12, borderColor:"#ccc"
  }
});
