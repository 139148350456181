import React, { Component } from "react";
import strings from "../config/strings";
import Image from "../components/Image"
import ImageBackground from "../components/ImageBackground"
import Button from "../components/Button"
import Utils from "../utils/utils";
import {SECONDARY_COLOR} from "../config/styles";
import { BrowserRouter, Route, Switch, useRouteMatch, Link, useParams, useHistory } from 'react-router-dom';
import TitledTextInput from "../components/TitledTextInput";
import { HEADER_HEIGHT } from '../config/Constants'
import OptionList from "../components/OptionList";


import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

const MARGIN = 6;

const MARGIN_OUT = 6;

const { width, height } = Dimensions.get("window");
const gutter = 0;

const BORDER_RADIUS = 6;

const UNITS = [
  { id: 1, text: strings.meters.toUpperCase(), multiple: 1 },
  { id: 2, text: strings.yards.toUpperCase(), multiple: 0.9144 },
  { id: 3, text: strings.grams.toUpperCase(), multiple: 1 }
];

export default class ComputeWool extends Component {
  handler = dims => this.setState(dims);

  constructor(props) {
    super(props);

    this.patternNbBalls = 0
    this.patternBalls = 0
      this.myBalls = 0 

    this.state = { nbWoolResult: null, selectedMyWoolOption: UNITS[1], selectedPatternWoolOption: UNITS[0] };
  }

  stitchesNbPatternChanged(value) {
    this.stitchesNbPattern = parseInt(value);
  }

  rowsNbPatternChanged(value) {
    this.rowsNbPattern = parseInt(value);
  }

  stitchesNbProjChanged(value) {
    this.stitchesNbProj = parseInt(value);
  }

  rowsNbProjChanged(value) {
    this.rowsNbProj = parseInt(value);
  }

  stitchesNbEchChanged(value) {
    this.stitchesNbEch = parseInt(value);
  }

  rowsNbEchChanged(value) {
    this.rowsNbEch = parseInt(value);
  }

  compute() {
    if (
      this.patternBalls == 0 ||
      this.myBalls == 0 ||
      this.patternNbBalls == 0 ||
      !this.state.selectedPatternWoolOption ||
      !this.state.selectedMyWoolOption
    ) {
      alert(strings.fill_all_fields);
      return;
    }

    if (isNaN(this.myBalls) || isNaN(this.patternBalls)) {
      alert(strings.number_required);
      return;
    }
    var adjust = (parseFloat(this.patternBalls) *  this.state.selectedPatternWoolOption.multiple) / (parseFloat(this.myBalls) * this.state.selectedMyWoolOption.multiple)

   // alert(this.state.selectedPatternWoolOption.multiple + "  " + this.state.selectedMyWoolOption.multiple + " " + adjust * parseFloat(this.patternNbBalls));

    this.setState({ nbWoolResult: adjust * parseFloat(this.patternNbBalls) });
  }

  back = () => {
    this.props.navigation.goBack();
  };

  onClose() {
    this.setState({ nbWoolResult: null });
  }


  isInt(value) {
    return (
      !isNaN(value) &&
      parseInt(Number(value)) == value &&
      !isNaN(parseInt(value, 10))
    );
  }

  onPatternNbBallsChanged(value) {
    if (value == "") return;
    if (!this.isInt(value)) {
      alert(strings.number_required);
      return;
    }
    this.patternNbBalls = parseInt(value);
  }

  onPatternOptionSelected(item) {
    
    this.state.selectedPatternWoolOption = item;
    if (item.id == 3) {
      
      this.myOptionsRef.select(item)
      this.state.selectedMyWoolOption = item
      //this.setState({ selectedMyWoolOption: UNITS[2] });
     // this.myOptionSelected = UNITS[2];
    }
    else if (this.state.selectedMyWoolOption.id == 3) {
      this.myOptionsRef.select(UNITS[0])
      this.state.selectedMyWoolOption = UNITS[0]
    }
    
  }


  onMyOptionSelected(item) {
      this.state.selectedMyWoolOption = item;
     if (item.id == 3) {
        this.patternOptionsRef.select(item)
        this.state.selectedPatternWoolOption = item
      //this.setState({ selectedPatternWoolOption: UNITS[2] });
    //  this.myOptionSelected = UNITS[2];
    } else if (this.state.selectedPatternWoolOption.id == 3) {
      this.patternOptionsRef.select(UNITS[0])
      this.state.selectedPatternWoolOption = UNITS[0]

    }

    
  }
  onPatternBallsChanged(value) {
    
    this.patternBalls = value.replace(",", ".");
  }

  onMyBallsChanged(value) {
    
    this.myBalls = value.replace(",", ".");
  }



  renderResult() {
    return (
      <View style={[styles.containerResult, {  }]}>
        <View
          style={[
            styles.container,
            {
              marginLeft: 8,
              marginRight: 8,
              width: Math.min(500, Dimensions.get('window').width)
            }
          ]}
        >
          <View style={[styles.header, { backgroundColor: SECONDARY_COLOR }]}>
            <Text style={[styles.headerText, { color: "white" }]}>
              {strings.your_wool.toUpperCase()}
            </Text>

            <TouchableOpacity
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                width: 38,
                height: 38,
                justifyContent: "center"
              }}
              onPress={() => this.onClose()}
            >
              <Image style={styles.closeImg} urlOnServer={'../imgs/close.png'} />
            </TouchableOpacity>
          </View>

          <View
            style={{ flexDirection: "row", alignItems: "center", padding: 20 }}
          >
          
              <Text style={styles.titleResult}>{strings.wool_nb_required}</Text>
              <Text style={styles.nbText}>{Math.ceil(this.state.nbWoolResult)}</Text>
                <Image
                  style={[{ height: 80, width: 80 }]}
                  resizeMode="contain"
                  urlOnServer={'/imgs/echantillon/ball-of-wool.png'}
                />
            
              </View>
           </View>
             
      </View>
    );
  }

  render() {


    console.debug(`pingoulog: this.state.selectedMyWoolOption =${JSON.stringify(this.state.selectedMyWoolOption )}`);


    return (
      <ImageBackground urlOnServer={'/imgs/background_knitting2.jpg'}
        style={{ flex: 1, height: Dimensions.get('window').height - HEADER_HEIGHT, justifyContent: "center", alignItems: "center" }}
      >
        <View style={{ width:  Math.min(500, Dimensions.get('window').width)}}>
          <View
            style={[
              styles.container,
              {
                marginLeft: 8,
                marginRight: 8,
              }
            ]}
          >
            <View style={styles.header}>
              <Text style={styles.headerText}>{strings.pattern}</Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: 15,
                paddingRight: 25,
                paddingTop: 10
              }}
            >
              <View>
                <Text style={styles.textTitle}>{strings.wool_nb_required}</Text>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <View
                    style={{
                      width: 60,
                      flexDirection: "column",
                      borderBottomWidth: 1,
                      borderColor: "#AAA",
                      alignContent: "center",
                      paddingBottom: 0,
                      marginBottom: 0
                    }}
                  >
                    <TextInput
                      style={{ padding: 2, paddingBottom: 0, color:'#555' }}
                      keyboardType="numeric"
                      
                      onChangeText={text => this.onPatternNbBallsChanged(text)}
                      returnKeyType="done"
                    />
                  </View>
                  <Text>{strings.woolballs}</Text>
                </View>
              </View>

              <Image
                style={[{ height: 60, width: 60 }]}
                resizeMode="contain"
                urlOnServer={"/imgs/echantillon/ball-of-wool.png"}
              />
            </View>

            <Text style={[styles.textTitle, { paddingLeft: 15 }]}>
              {strings.woolball_size}
            </Text>

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 15
              }}
            >
              <View
                style={{
                  width: 60,
                  flexDirection: "column",
                  borderBottomWidth: 1,
                  borderColor: "#AAA",
                  alignContent: "center",
                  paddingBottom: 0,
                  marginBottom: 0,
                  marginLeft: 15
                }}
              >
                <TextInput
                  style={{ padding: 2, paddingBottom: 0, color:'#555' }}
                  keyboardType="numeric"
                  
                  onChangeText={text => this.onPatternBallsChanged(text)}
                  returnKeyType="done"
                />
              </View>

              <OptionList
                style={{ paddingLeft: 5 }}
                nbColumns={3}
                options={UNITS}
                itemWidth={80}
                fontSize={12}
                ref={(ref) => (this.patternOptionsRef = ref)}
                onOptionSelected={option => this.onPatternOptionSelected(option)}
                selected={this.state.selectedPatternWoolOption}
              />
            </View>
          </View>

          <View
            style={[
              styles.container,
              {
                marginLeft: 8,
                marginRight: 8,
                marginBottom: 50
              }
            ]}
          >
            <View style={styles.header}>
              <Text style={styles.headerText}>
                {strings.your_wool.toUpperCase()}
              </Text>
            </View>

            <Text style={[styles.textTitle, { paddingLeft: 15, marginTop:10 }]}>
              {strings.woolball_size}
            </Text>

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: 15,
                paddingTop: 0
              }}
            >
              <View
                style={{
                  width: 60,
                  flexDirection: "column",
                  borderBottomWidth: 1,
                  borderColor: "#AAA",
                  alignContent: "center",
                  paddingBottom: 0,
                  marginBottom: 0,
                  marginLeft: 15
                }}
              >
                <TextInput
                  style={{ padding: 2, paddingBottom: 0, color:'#555' }}
                  keyboardType="numeric"
                  
                  onChangeText={text => this.onMyBallsChanged(text)}
                  returnKeyType="done"
                />
              </View>

              <OptionList
                style={{ marginLeft: 5 }}
                nbColumns={3}
                options={UNITS}
                itemWidth={80}
                fontSize={12}
                onOptionSelected={option => this.onMyOptionSelected(option)}
                selected={this.state.selectedMyWoolOption}
                ref={(ref) => (this.myOptionsRef = ref)}
              />
            </View>
          </View>
        </View>


        <Button title={'OK'} style={{alignSelf:'center'}} onPress={() => this.compute()}/>

        {this.state.nbWoolResult != null && this.renderResult()}
      </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  toolContainer: {
    margin: MARGIN,
    shadowColor: "#000",
    shadowOpacity: 0.6,
    shadowRadius: 3,
    elevation: 8,
    backgroundColor: SECONDARY_COLOR,
    borderRadius: 8
  },
  textItem: {
    textAlign: "center",
    color: "white",
    backgroundColor: "transparent"
  },
  textTitle: {
    fontSize: 12,
    fontStyle: "italic",
    color: "#999",
    margin: 2
  },
  containerResult: {
    padding: 20,
    width: "100%",
    height: "100%",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(52, 52, 52, 0.8)"
  },

  header: {
    paddingTop: 5,

    overflow: "hidden",
    borderTopRightRadius: BORDER_RADIUS,
    borderTopLeftRadius: BORDER_RADIUS
  },
  headerText: {
    textAlign: "center",
    color: "#AAA",
    fontSize: 18,
    borderBottomWidth: 1,
    borderColor: "#CCC",
    paddingBottom: 5
  },
  container: {
    backgroundColor: "white",
    shadowOffset: { width: 0, height: 1 },
    shadowColor: "#000",
    shadowOpacity: 0.6,
    shadowRadius: 3,
    elevation: 8,

    borderRadius: BORDER_RADIUS,

    marginTop: 15,
    marginBottom: 2,

    flexDirection: "column",
    justifyContent: "space-around"
  },
  touchableOk: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 10,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row"
  },
  closeImg: {
    width: 20,
    height: 20,
    alignSelf: "center"
  },
  nbText: {
    color: "red",
    fontSize:32,
    fontWeight: "bold",
    marginLeft: 5,
    marginRight: 15
  },
  titleResult: {
    fontSize: 12,
    fontStyle: "italic",
    color: "#888",
    width: 100,
  }
});
