import React, { Component } from "react";

import strings from "../../config/strings";

import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../config/styles";

import Utils from "../../utils/utils";
import CheckBox from "../../components/CheckBox"
import { getAllSizes, getSizesFromIds, getCatFromSize } from "../../config/sizes"
import PatternUtils from "../../generator/PatternUtils";


import {
  ScrollView,
  View,
  TextInput,
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Keyboard,
  SafeAreaView,
  DeviceEventEmitter,
  PanResponder,
  BackHandler,
  Dimensions
} from 'react-native';



export default class ChartSizes extends Component {
  constructor(props) {
    super(props);

    this.patternUtils = new PatternUtils(null);


    var availableSizes = []


    for (var i = 0; i < this.props.allSizeIds.length; i++) {
      var size = this.props.allSizeIds[i]

      //alert(JSON.stringify(this.props.unavailableSizeIds) + '\n' + size + '\n' + this.props.unavailableSizeIds.includes(size))
      if (!this.props.unavailableSizeIds.includes(size)) {
        availableSizes.push(size)
      }

    }
  
    var selectedSizeIds = [...this.props.selectedSizeIds]
    var sizes = getSizesFromIds(availableSizes.sort())
    for (var i = 0; i < sizes.length; i++) {
      var size = sizes[i]
      if (!selectedSizeIds.includes(size.id)) {
        size.selected = false
      }
      else
        size.selected = true
    }
    
    this.state = {

      sizes: sizes
    
    }

    //Utils.getSQLSize()
  }

  onSizesSelected() {
    
    var sizes = []

    for (var i = 0; i < this.state.sizes.length; i++) {
      if (this.state.sizes[i].selected)
        sizes.push(this.state.sizes[i].id)
    }

    if (sizes.length == 0) {
      alert(strings.select_size)
      return
    }
    this.props.onSizesSelected(sizes)
  }


  changeSize(size) {
    size.selected = !size.selected
    this.setState({sizes: this.state.sizes})
  }

  renderCats() {
    if (!this.state.sizes.length) {

      return (
        <View style={{ justifyContent:'center', alignItems:'center', marginTop:12}}>
          <Text style={{color:'#555'}}>{strings.all_sizes_done}</Text>
          </View>
        )
    }

    var lastCat = ''
    return (
     <View style={{ justifyContent:'center', alignItems:'center', marginTop:12}}>
     <View>
      {this.state.sizes.map(size => {

        var cat = getCatFromSize(size.id).name
        if (cat != lastCat) {
          lastCat = cat

          return (<View>
              <Text style={{fontWeight:'bold', color:'#555'}}>{cat}:</Text>
              <TouchableOpacity onPress={() => this.changeSize(size)} style={styles.containerSize}>
              <CheckBox style={styles.checkbox} checked={size.selected} onPress={() => this.changeSize(size)}/>
              <Text style={{color:'#555'}}>{size.text}</Text></TouchableOpacity>
            </View>)
        }
        else
        return (<TouchableOpacity onPress={() => this.changeSize(size)} style={styles.containerSize}>
          <CheckBox style={styles.checkbox} checked={size.selected} onPress={() => this.changeSize(size)}/>
              <Text style={{color:'#555'}}>{size.text}</Text></TouchableOpacity>)
    }
        )}

      </View>
     </View>
    )

   
  }

  render() {

//const allsizes = strings.popupsize.allsizes


 // alert(JSON.stringify(this.state.sizes))
    var imgSize = Math.min(Dimensions.get("window").height / 5, 100)
    return (
          <TouchableOpacity
          onPress={() => this.props.onClose()} 
          style={{position:"absolute", top:0, bottom:0, left:0, right:0, flexDirection:'row', alignItems: "center", flex:1, justifyContent:'center', backgroundColor:'rgba(128, 128, 128, 0.8)', zIndex:1000}}>
              <TouchableOpacity onPress={() =>  {} } style={{
                  backgroundColor:'white',
                  backgroundColor: "white",
                  shadowColor: "#000",
                  shadowOpacity: 0.3,
                  shadowRadius: 2,
                  shadowOffset: { width: 1, height: 1 },
                  elevation: 3,
                  width: 350,
                  margin: 30,
                  paddingHorizontal:Dimensions.get('window').width > 500 ? 20 : 10,
                  zIndex:9900}}>

                  <Text style={{fontSize:20, color:SECONDARY_COLOR, textAlign:'center', marginTop:30, fontWeight:'bold'}}>{strings.sizes}</Text>
           
                  <Text style={{color:'#555', textAlign:'center', marginTop:20}}>{this.props.description}</Text>
           
                  {this.renderCats()}

                 
                  <View style={{flex:0, flexDirection:"row",justifyContent:"flex-end",alignSelf:"flex-end",paddingHorizontal:15,paddingVertical:25}}>
                  <TouchableOpacity onPress={() => this.props.onClose()}>
                      <Text style={{marginRight:20, fontSize:16, color: "#c3c3c3", fontWeight: "bold", alignSelf:'center', textAlign:"center"}}>{strings.cancel.toUpperCase()}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => this.onSizesSelected()}>
                      <Text style={{marginHorizontal:0, fontSize:16, color: "#2ba5f7", fontWeight: "bold", alignSelf:'center', textAlign:"center"}}>{strings.ok.toUpperCase()}</Text>
                  </TouchableOpacity>
                  </View>
            </TouchableOpacity>
          </TouchableOpacity>
      )
  }
}

const styles = StyleSheet.create({
checkbox: {
  margin:8
},
containerSize: {
  flexDirection:'row',
  alignItems:'center'
}
});
