import React, { Component } from "react";
import {
  Image,
} from 'react-native';


export default class MyImage extends React.Component {
  
  render() {
  
    if (this.props.urlOnServer) {
      return (
           <Image {...this.props} source={{uri: this.props.urlOnServer}}/>
        )
    }
    return (
      <Image {...this.props}/>
      );
  }
}