import React, { Component } from 'react';


import {
  ScrollView,
  View,
  TextInput,
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Keyboard,
  SafeAreaView,
  DeviceEventEmitter,
  PanResponder,
  BackHandler,
  Dimensions
} from 'react-native';


const { width, height } = Dimensions.get("window");
var __DEV__ = true;

export default class Cell extends Component {


  shouldComponentUpdate(nextProps, nextState) {                                     
    const { item, size, isFaded, color } = nextProps                                          
    const { item: oldItem, size:oldSize, isFaded: oldIsFaded, color: oldColor } = this.props                 

    // If "liked" or "likeCount" is different, then update                          
    return item !== oldItem || item.t_img != oldItem.t_img || size !== oldSize || isFaded !== oldIsFaded || color !== oldColor              
  }     

 

  render() {
    var color = this.props.color ? this.props.color : '#fff'
    const borderWidth = 0.5
    if ((this.props.item.symbol.width > 1 && this.props.item.partWidth > 0) || (this.props.item.symbol.height > 1 && this.props.item.partHeight > 0))
      return (<View><TouchableOpacity style={{width:this.props.size, height:this.props.size,  borderColor:'#555'
      , borderBottomWidth: this.props.item.partHeight == this.props.item.symbol.height - 1 ? borderWidth : 0
      , borderLeftWidth: this.props.item.partWidth == 0 || this.props.item.partWidth == this.props.item.symbol.width ? borderWidth : 0
      , borderRightWidth: this.props.item.partWidth == this.props.item.symbol.width ? borderWidth : 0,
       backgroundColor: this.props.isFaded ? "rgba(52, 52, 52, 0.9)" : undefined
    }}

          onPress={() => {
            
            this.props.onPress()
          }

            }
          /></View>)
    //return (<View style={{width:this.props.size, height:this.props.size, backgroundColor:'red'}}/>)
     

     

     if(__DEV__) console.log('color:', color)
     if (color && (color.toLowerCase() == '#000' || color.toLowerCase() == '#008' || color.toLowerCase() == '#088' || color.toLowerCase() == '#808' || color.toLowerCase() == '#888'))
      var tintColor = '#fff'
     else
      var tintColor = undefined

    var offsetLeft = 0
    var offsetTop = 0
      if (this.props.item.symbol.width > 1 && this.props.item.partWidth) {
        offsetLeft = (this.props.item.partWidth) * this.props.size

      }
      if (this.props.item.symbol.height > 1 && this.props.item.partHeight) {
        offsetTop = (this.props.item.partHeight) * this.props.size

      }
      if(__DEV__) console.log('render cell', this.props.item, offsetLeft)
//alert(JSON.stringify(this.props.symbol))
     return (<TouchableOpacity style={{width:this.props.size, height:this.props.size, borderColor:'#555'
      , borderTopWidth:borderWidth, borderBottomWidth:borderWidth, borderLeftWidth: this.props.item.partWidth == this.props.item.symbol.width - 1 ? borderWidth : 0
      , borderRightWidth: this.props.item.partWidth == this.props.item.symbol.width - 1 ? borderWidth : 0
      , opacity: this.props.isFaded && (!this.props.item.symbol.width || this.props.item.symbol.width == 1) && (!this.props.item.symbol.height || this.props.item.symbol.height == 1) ? 0.3 : 1.0, backgroundColor: color}}

          onPress={() => {
            
            this.props.onPress()
          }

            }
          >
          {((!this.props.item.symbol.width || this.props.item.symbol.width == 1) && (!this.props.item.symbol.height || this.props.item.symbol.height == 1)) &&
            <Image source={this.props.item.symbol.t_img} style={{width:this.props.size - (borderWidth * 2), height:this.props.size - (borderWidth * 2), tintColor: tintColor}}/>}

           {(this.props.item.symbol.width > 1 || this.props.item.symbol.height > 1) && (

            <View style={{overflow: 'hidden',
    position: 'absolute',
    left: 0,
    width: this.props.item.symbol.width * this.props.size - 1,
    height:this.props.item.symbol.height * this.props.size - 1,
    backgroundColor: color,
    borderLeftWidth:borderWidth,
    borderTopWidth: borderWidth
    }}>
   <Image source={this.props.item.symbol.t_img} 
   resizeMode={'stretch'}
   style={{marginLeft: - offsetLeft, marginTop: -offsetTop,
    width:this.props.item.symbol.width * this.props.size - (borderWidth * 2), height: this.props.item.symbol.height * this.props.size - (borderWidth * 2), tintColor: tintColor}}/>
    {this.props.isFaded && <View style={{position:'absolute', width:this.props.size, height:this.props.size, backgroundColor: this.props.isFaded ? "rgba(52, 52, 52, 0.9)" : undefined}}/>}


  </View>

            )

           }

          </TouchableOpacity>)

  }
}



const styles = StyleSheet.create({
 
})
