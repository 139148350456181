import RAVELRY_NEEDLES from "../generator/ravelry_needles.json";




export default class RavelryNeedlesUtils {

	static getAllNeedleSizes(type, length) {
    
    var array = []

		for (var i = 0; i < RAVELRY_NEEDLES.length; i++) {
      if (RAVELRY_NEEDLES[i].length == length && RAVELRY_NEEDLES[i].type_name == type) {

        array.push(RAVELRY_NEEDLES[i]);
      }
    }

    return array.sort((a, b) => (a.metric_name > b.metric_name) ? 1 : -1);
	}

  static isInArray(array, value, field) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][field] == value) {
        return true
      }
    }

    return false
  }

  static getAllNeedleLength(type) {
    
    var array = []

    for (var i = 0; i < RAVELRY_NEEDLES.length; i++) {
      if (RAVELRY_NEEDLES[i].type_name == type) {
        if (!this.isInArray(array, RAVELRY_NEEDLES[i].length, "length"))
          array.push(RAVELRY_NEEDLES[i]);
      }
    }

    return array.sort((a, b) => (a.length > b.length) ? 1 : -1);
  }

  static getItemFromRavelryId(id) {
    
    for (var i = 0; i < RAVELRY_NEEDLES.length; i++) {
      if (RAVELRY_NEEDLES[i].id == id) {
        return RAVELRY_NEEDLES[i]
      }
    }

    return null
  }

  

}
