import React, { Component } from "react";
import strings from "../../config/strings";
import Image from "../../components/Image"
import Utils from "../../utils/utils";
import PatternUtils from "../../generator/PatternUtils";
import {getHeaders2} from "../../generator/PatternUtils";
import MyTextInput from '../../components/MyTextInput'
import {SECONDARY_COLOR} from "../../config/styles";
import { getAllSizes, getSizesFromIds, getCatFromSize } from "../../config/sizes"
import ChartSizes from "../../components/generator/ChartSizes"
import { withRouter } from "react-router";
import { SERVER_API_URL, APP_NAME, NUM_COLUMNS_CUSTOM_SYMBOL } from '../../config/Constants'
import Button from "../../components/Button"
import Dropdown from 'react-bootstrap/Dropdown'
import { LANGS } from "../../generator/PatternUtils";
import fetchClient from "../../utils/http-common";



import {
  ScrollView,
  View,
  TextInput,
  ImageBackground,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Keyboard,
  SafeAreaView,
  DeviceEventEmitter,
  PanResponder,
  BackHandler,
  Dimensions
} from 'react-native';

const MARGIN = 3

var __DEV__ = true

class EditChartSymbol extends Component {
  
  constructor(props) {
    super(props);

 

    this.patternUtils = new PatternUtils(null);
    var params = this.props.history.location.state
    this.pattern = params.pattern
    this.customSymbol = null
    var widthValue = 1
    var heightValue = 1
    if (params.customSymbol) {


      this.customSymbol = params.customSymbol;//realm.objects("CustomSymbol").filtered("uniqueIdOnClient = $0", this.props.navigation.state.params.symbolUniqueIdOnClient)[0]
    
     // alert(this.props.navigation.state.params.symbolUniqueIdOnClient + ' >>> ' + JSON.stringify(this.customSymbol))
    
      var array = []
     // var contentAll = this.customSymbol.content.split('|')
      var contentArray = this.customSymbol.content.split(',')
      widthValue = this.customSymbol.width ? this.customSymbol.width : widthValue
      heightValue = this.customSymbol.height ? this.customSymbol.height : heightValue

      var i = 0
      for (var y = 0; y < NUM_COLUMNS_CUSTOM_SYMBOL * heightValue; y++) {
        for (var x = 0; x < NUM_COLUMNS_CUSTOM_SYMBOL * widthValue; x++) {

          array.push({value: contentArray[i] ? contentArray[i] : 0})
          i++
        }
      }
     // var array = this.createArray(tmpArray, width, width)

      var rs = this.customSymbol.rs
      var ws = this.customSymbol.ws
      var rsLong = this.customSymbol.rsLong
      var wsLong = this.customSymbol.wsLong
      
    }
    else {
      this.customSymbol = null
      var rs = ''
      var ws = ''
      var rsLong = ''
      var wsLong = ''

      var array = []
      for (var y = 0; y < NUM_COLUMNS_CUSTOM_SYMBOL; y++) {
        for (var x = 0; x < NUM_COLUMNS_CUSTOM_SYMBOL; x++) {

          array.push({value: 0})
        }
      }
     
    }

    
    this.widthValue = widthValue
    this.heightValue = heightValue
    this.state = {
      array: array,
      rs: rs,
      ws: ws,
      rsLong: rsLong,
      wsLong: wsLong,
      widthValue: widthValue,
      heightValue: heightValue,
      textColor: "rgba(255,255,255,0.8)",
    };

    this.hasChangedGrid = false

  }

    componentDidMount() {
  /*  this.focusEvent = this.props.navigation.addListener(
      'didFocus',
      () => this.inFocus()
    );

    this.blurEvent = this.props.navigation.addListener(
      'willBlur',
      () => { this.onBlur() }
    );*/
    //var cellSize = Math.min(Dimensions.get('window').width, Dimensions.get('window').height ) / (NUM_COLUMNS_CUSTOM_SYMBOL * Math.max(this.widthValue, this.heightValue));
    
    // if (cellSize < 25)
    //                   cellSize = 25
    var cellSize = 20
    this.setState({ cellSize: cellSize})
  }
/*
  onBlur() {
    if (this.backHandler) {
      this.backHandler.remove()
      this.backHandler = null
    }

  }
  
  inFocus() {
    if (this.backHandler)
      this.backHandler.remove()
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
  }*/

  createArray(oldArray, oldArrayWidth, oldArrayHeight, newWidth, newHeight) {

    var array = []


    for (var y = 0; y < NUM_COLUMNS_CUSTOM_SYMBOL * newHeight; y++) {
        for (var x = 0; x < NUM_COLUMNS_CUSTOM_SYMBOL * newWidth; x++) {

          if (x < oldArrayWidth * NUM_COLUMNS_CUSTOM_SYMBOL && y < oldArrayHeight * NUM_COLUMNS_CUSTOM_SYMBOL ) {
            array.push({value: oldArray[(y * oldArrayWidth * NUM_COLUMNS_CUSTOM_SYMBOL) + x].value ? oldArray[(y * oldArrayWidth * NUM_COLUMNS_CUSTOM_SYMBOL) + x].value : 0})
            if(__DEV__) console.log("copying ", oldArray[(y * oldArrayWidth * NUM_COLUMNS_CUSTOM_SYMBOL) + x])
          }
          else
            array.push({value: 0})
          
        }
      }

    return array
  }

  checkCanQuit() {
   /* var contentArray = this.customSymbol.content.split(',')
    for (var i = 0; i < contentArray.length; i++) {
      if (contentArray[i] != this.state.array[i].value)
        return false
    }*/

    if (this.hasChangedGrid)
      return false


    return true
  }

  onBack() {
    if (this.checkCanQuit())
    this.props.history.goBack()
    else {
       Alert.alert(
        "",
        strings.discard_changes,
        [
          { text: "OK", onPress: () =>  this.props.history.goBack() },
          { text: strings.save, onPress: () => this.onSave() },
          {
            text: strings.cancel,
            style: "cancel"
          }
        ],
        { cancelable: false }
      );

    }
  }

   handleBackPress = () => {
    this.onBack(); // works best when the goBack is async
    return true;
  }

  

  getRawArray(array) {

    var str = ''
    for (var i = 0; i < array.length; i++) {
      if (i != 0)
        str += ','
      str += array[i].value
    }

    return str
  }


  async onSave() {


    var rawArray = this.getRawArray(this.state.array)
   // var pngPath = Utils.createPng(rawArray, NUM_COLUMNS_CUSTOM_SYMBOL, this.state.widthValue, this.state.heightValue)

    var user = JSON.parse(localStorage.getItem('user'));
    var hasChangedDim = false

    if (!this.customSymbol) {
      

        this.customSymbol = {
        id: null,
        rs: this.state.rs,
        ws: this.state.ws,
        rsLong: this.state.rsLong,
        wsLong: this.state.wsLong,
        //name: this.state.name,
        //description: this.state.description,
        content: rawArray,
        width: this.state.widthValue,
        height: this.state.heightValue,
        projectTool: this.pattern.projectTool,
        imgPath: null,//pngPath.replace(RNFS.DocumentDirectoryPath, "__doc_dir_path__"),
        imgVersion: 1,

        userIdOnServer: user.id,
        uniqueIdOnClient: Utils.makeId(16),
        updatedAtOnClient: Utils.getTime(),
      }

      this.pattern.updatedAtOnClient = Utils.getTime()
      
      //var objToSend = PatternUtils.getEmptyPattern(this.pattern)

      //objToSend.custom_symbols = []
      //objToSend.custom_symbols.push(this.customSymbol)

      //PatternUtils.updatePatternOnServer(objToSend, (response) => {
       // alert(JSON.stringify(response))
      //})


      let options = Object.assign({ method: "GET" }, null);
 
      options.headers = getHeaders2();


      var obj = '{"chart_custom_symbol":' + JSON.stringify(this.customSymbol) + '}'

      var response = await fetchClient.post(`${SERVER_API_URL}/chart_custom_symbols.json`, obj)
     // alert(JSON.stringify(response.data))
    }

    else {

       if (this.customSymbol.width != this.state.widthValue || this.customSymbol.height != this.state.heightValue)
        hasChangedDim = true

        this.customSymbol.rs = this.state.rs
        this.customSymbol.ws = this.state.ws
        this.customSymbol.rsLong = this.state.rsLong
        this.customSymbol.wsLong = this.state.wsLong
        this.customSymbol.projectTool = this.pattern.projectTool
       // this.customSymbol.name = this.state.name
       // this.customSymbol.description = this.state.description,
        this.customSymbol.content = this.getRawArray(this.state.array)
        this.customSymbol.width = this.state.widthValue
        this.customSymbol.height = this.state.heightValue
        this.customSymbol.imgPath = null//pngPath.replace(RNFS.DocumentDirectoryPath, "__doc_dir_path__")
        this.customSymbol.imgVersion = this.customSymbol.imgVersion + 1

        this.customSymbol.updatedAtOnClient = Utils.getTime()
        this.pattern.updatedAtOnClient = Utils.getTime()

        var obj = '{"chart_custom_symbol":' + JSON.stringify(this.customSymbol) + '}'

        var response = await fetchClient.put(`${SERVER_API_URL}/chart_custom_symbols/${this.customSymbol.idOnServer}.json`, obj)

    }

    //if (hasChangedWidth)
    //  this.props.navigation.state.params.onWidthChanged(this.customSymbol)
   // alert(JSON.stringify(this.props.navigation.state.params.onSave))




   this.props.history.goBack()

  }



 
  onCellSelected(item) {

    this.hasChangedGrid = true
    item.value = item.value == 1 ? 0 : 1

    this.setState({array: [...this.state.array]})
  }
  renderItem(item) {
    return (
      <TouchableOpacity
      onPress={() => this.onCellSelected(item)}
      >
      <View style={{width:this.state.cellSize, height: this.state.cellSize, borderWidth:0.5, borderColor:'#eee', backgroundColor: item.value == 1 ? '#000' : '#fff'}}/>

      </TouchableOpacity>

      )
  }

  renderNumberCellOption(value, isWidth, changeSize) {
    if (isWidth) {
      var isCurrentValue = value == this.state.widthValue ? true : false
    } else {
      var isCurrentValue = value == this.state.heightValue ? true : false
    }

    var btnSize = isCurrentValue ? 26 : 24
    return (
      <TouchableOpacity
      onPress={() => changeSize(value)}
      style={{
        width: btnSize, 
        height: btnSize, 
        borderRadius: btnSize / 2, 
        backgroundColor: isCurrentValue ? "rgba(43,165,247,1)" : "rgba(255,255,255,0.3)", 
        marginHorizontal: 4, 
        justifyContent: "center", 
        alignItems:"center"}}
      >
      <Text style={{ color: "white", fontWeight: "bold" }}>{value}</Text>

      </TouchableOpacity>
    )
  }
  renderGoBack(){
    return (
      <TouchableOpacity
      style={{
        position: "absolute", 
        top: 22,
        left: 24,
        justifyContent: "center", 
        alignItems: "flex-start", 
        zIndex: 1000,
        width: 32}}
      onPress={() => this.onBack()}>
        <Image style={{width: 24, height: 24, margin: 4}} urlOnServer={"/imgs/back.png"}/>
      </TouchableOpacity>
    )
}
 
  render() {


    
    return (

      <View style={{ flex: 1, backgroundColor: "#266D9C", minHeight: Dimensions.get('window').height - 72 }}
        title={strings.create_symbol}
        disableSafeArea={true}
        onBack={() => this.onBack()}>
        
        {this.renderGoBack()}

        <ScrollView style={{ flex: 1}}>
          <View style={{ flexDirection: 'row', width: '100%', marginTop: 24, justifyContent: 'space-around' }}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ color: "white", marginRight: 12 }}>{strings.width + ":"}</Text>

              {[1, 2, 3, 4, 5].map(value => this.renderNumberCellOption(
                value, 
                true,
                (value) => {
                  var newWidth = value
                  var cellSize = 20
                  this.setState({ flatlistWidth: newWidth * NUM_COLUMNS_CUSTOM_SYMBOL * cellSize, widthValue: newWidth, cellSize: cellSize, array: this.createArray(this.state.array, this.state.widthValue, this.state.heightValue, newWidth, this.state.heightValue) })
                }
              ))}

              <Text style={{ color: this.state.textColor, marginLeft: 12 }}>{strings.cell}</Text>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ color: "white", marginRight: 12 }}>{strings.height + ":"}</Text>

              {[1, 2, 3, 4, 5].map(value => this.renderNumberCellOption(
                value, 
                false,
                (value) => {
                  var newHeight = value
                  var cellSize = 20
                  this.setState({ heightValue: newHeight, cellSize: cellSize, array: this.createArray(this.state.array, this.state.widthValue, this.state.heightValue, this.state.widthValue, newHeight) })
                }
              ))}

              <Text style={{ color: this.state.textColor, marginLeft: 12 }}>{strings.cell}</Text>
            </View>

          </View>
          <ScrollView 
          contentContaineStyle={{ justifyContent: "center", alignItems: "center",}}
          style={{ flex: 1 }} >

            <View style={{ width: "100%", justifyContent: "center", alignItems: "center", marginVertical: 24}}>
              <FlatList

                key={'box_list_' + (NUM_COLUMNS_CUSTOM_SYMBOL + '_' + this.state.widthValue)}
                style={[{ width: this.state.flatlistWidth, alignSelf: 'center' }, this.props.style]}
                data={this.state.array}
                contentContainerStyle={{ justifyContent: 'center', alignItem: 'center' }}
                extraData={this.state}
                numColumns={NUM_COLUMNS_CUSTOM_SYMBOL * this.state.widthValue}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({ item, index }) => this.renderItem(item, index)}
                scrollEnabled={false}
              />
            </View>
          </ScrollView>


          <View style={{ flex: 1, padding: 8, justifyContent: "center", alignItems: "center"}}>
          <View>
            <Text style={{ marginTop: 16, marginBottom: 12, color: this.state.textColor }}>{this.pattern.projectTool == 2 ? strings.name : strings.schemaTool.rightSide}:</Text>
            <TextInput
              contentContainerStyle={{ flex: 1, justifyContent: "flex-start", alignItems: "flex-start" }}
              style={{
                width: 300,
                paddingLeft: 12,
                color: "white",
                paddingHorizontal: 18,
                textAlign: "left",
                paddingVertical: 8,
                backgroundColor: "rgba(255,255,255,0.2)",
                borderRadius: 25,
                fontSize: 14,
                marginRight: 8
              }}
              multiline={false}
              value={this.state.rs}
              onChangeText={(text) => this.setState({ rs: text })}
              returnKeyType="done"
            />
          </View>
            {this.pattern.projectTool != 2 && (
            <View>
              <Text style={{ marginTop: 16,  marginBottom: 12, color: this.state.textColor }}>{strings.schemaTool.wrongSide}:</Text>
              <TextInput
                contentContainerStyle={{ flex: 1, justifyContent: "flex-start", alignItems: "flex-start" }}
                style={{
                  width: 300,
                  paddingLeft: 12,
                  color: "white",
                  paddingHorizontal: 18,
                  textAlign: "left",
                  paddingVertical: 8,
                  backgroundColor: "rgba(255,255,255,0.2)",
                  borderRadius: 25,
                  fontSize: 14,
                  marginRight: 8
                }}
                multiline={false}
                value={this.state.ws}
                onChangeText={(text) => this.setState({ ws: text })}
                returnKeyType="done"
              />
              </View>
            )}
            <View>
              <Text style={{ marginTop: 16,  marginBottom: 12, color: this.state.textColor }}>{(this.pattern.projectTool == 2 ? '' : strings.schemaTool.rightSide + " ") + Utils.capitalizeFirstLetter(strings.description.toLowerCase())}:</Text>
              <TextInput
                contentContainerStyle={{ flex: 1, justifyContent: "flex-start", alignItems: "flex-start" }}
                style={{
                  width: 300,
                  paddingLeft: 12,
                  color: "white",
                  paddingHorizontal: 18,
                  textAlign: "left",
                  paddingVertical: 8,
                  backgroundColor: "rgba(255,255,255,0.2)",
                  borderRadius: 25,
                  fontSize: 14,
                  marginRight: 8
                }}
                multiline={false}
                value={this.state.rsLong}
                onChangeText={(text) => this.setState({ rsLong: text })}
                returnKeyType="done"
              />
            </View>


            {this.pattern.projectTool != 2 && (
            <View>
              <Text style={{ marginTop: 16,  marginBottom: 12, color: this.state.textColor }}>{ strings.schemaTool.wrongSide + " " + Utils.capitalizeFirstLetter(strings.description.toLowerCase())}:</Text>
              <TextInput
                contentContainerStyle={{ flex: 1, justifyContent: "flex-start", alignItems: "flex-start" }}
                style={{
                  width: 300,
                  paddingLeft: 12,
                  color: "white",
                  paddingHorizontal: 18,
                  textAlign: "left",
                  paddingVertical: 8,
                  backgroundColor: "rgba(255,255,255,0.2)",
                  borderRadius: 25,
                  fontSize: 14,
                  marginRight: 8
                }}
                multiline={false}
                value={this.state.wsLong}
                onChangeText={(text) => this.setState({ wsLong: text })}
                returnKeyType="done"
              />
              </View>
            )}
          </View>


          <Button title={strings.save} style={{ alignSelf: 'center', marginVertical: 32 }} onPress={() => this.onSave()} />
          {this.state.img && <Image
            style={{ width: 40, height: 40, borderWidth: 0.5, borderColor: '#ccc' }}
            source={{ uri: this.state.img }}
          />}
        </ScrollView>
      </View>
      

      )
  
  }

 
}


export const EditChartSymbolWithRouter = withRouter(EditChartSymbol);

const styles = StyleSheet.create({
  input: {
    borderBottomWidth:0.5, borderColor:"#888",
    color:"#A0A0A0",
    padding:0
  },

});
