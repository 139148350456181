import React from 'react';
import useToken from '../hooks/useToken';
import { useState, useEffect} from 'react';
import { SERVER_URL } from '../config/Constants'
import { BrowserRouter, Route, Switch, useRouteMatch, Link, useParams } from 'react-router-dom';
import Utils from '../utils/utils'
import Image from "../components/Image"
import "../config/styles.css"

export default class ProjectOption extends React.Component {


  render() {
    //alert(JSON.stringify(this.props))
    var imageStyle = {
        width: 30,
        height: 30,
        margin:5
    }
    var counterSettingsStyle = {
        width: 40,
        height: 40,
        margin:5,
    }

    var textStyle = this.props.smallText ? "OptionDetailSmall" : "OptionDetail"
    return (
      <div className="OptionContainer" id={this.props.speId} >
            <div className={textStyle}>
                <p>{this.props.detail}</p>
            </div>
            <div>
                <button className="OptionButton" onClick={this.props.onClick}>
                    <Image urlOnServer={this.props.picUrl} style={this.props.speId=="EditCounters"?counterSettingsStyle:imageStyle}/>
                </button>
            </div>
      </div>
      );
  }
}
