import React, { Component } from "react";

import strings from "../../config/strings";

import Utils from "../../utils/utils";
import Image from "../../components/Image"
import Button from "../../components/Button";

import { RecyclerListView, DataProvider, LayoutProvider } from "recyclerlistview";
import { getSymbols, getFirstSymbolSelectedKnitting, getFirstSymbolSelectedCrochet } from "../../generator/symbols"
import { SECONDARY_COLOR } from "../../config/styles";
import { NORMAL_COLORS } from "./NormalColorPicker"
import PatternUtils from "../../generator/PatternUtils";
import { searchSymbol } from "../../generator/symbols"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faSearch, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'


import {
  ScrollView,
  View,
  TextInput,

  ImageBackground,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Keyboard,
  SafeAreaView,
  DeviceEventEmitter,
  PanResponder,
  BackHandler,
  Dimensions
} from 'react-native';

const MAX_CELL_WIDTH = 50
const MIN_CELL_WIDTH = 30
const CELL_PADDING = 4
const PADDING_LEFT = 20


var __DEV__ = true
export default class SchemaPointSelector extends Component {
  handler = dims => this.setState(dims);

  constructor(props) {
    super(props);


    this.patternUtils = new PatternUtils(null);

    const arrayWidth = 16.5 
    var cellSize = Math.round(Dimensions.get('window').width / arrayWidth);
    if (cellSize > MAX_CELL_WIDTH)
      cellSize = MAX_CELL_WIDTH



    var symbolsArray = [...getSymbols().filter(obj => {
            if ((this.props.typeChart == 2 && obj.toolType == 2) || (this.props.typeChart != 2 && obj.toolType != 2))
              return true
            else
              return false
         }) ]


   // alert(JSON.stringify(this.props.typeChart))

    symbolsArray = symbolsArray.concat(this.getCustomSymbols())
    symbolsArray.push({id: -1, isAddButton: true})
    var dataProviderAll = this.cloneRow(symbolsArray);

  //  var layoutProviderAll = new LayoutProvider(dataProviderAll, arrayWidth, cellSize);

    var layoutProviderAll = this.getLayoutProvider(symbolsArray, cellSize, true)

    var maxUsedSymbols = parseInt(arrayWidth) - 1

  //  alert(JSON.stringify(this.props.array, maxUsedSymbols))
    var lastUsedSymbols = this.getUsedSymbols(this.props.array, maxUsedSymbols)
    var dataProviderSelected = this.cloneRow(lastUsedSymbols);
    

    var colorArray = [...NORMAL_COLORS].reverse()
    colorArray.unshift({id: 0, color: null})
    var dataProviderColors = this.cloneRow(colorArray);
   //  alert(JSON.stringify(this.props.array))
    //alert(JSON.stringify(lastUsedSymbols))
    //var layoutProviderSelected = new LayoutProvider(dataProviderSelected, arrayWidth, cellSize);
    var layoutProviderSelected = this.getLayoutProvider(lastUsedSymbols, cellSize, false)
    if(__DEV__) console.log('plop setting state')
    //this.setState({cellSize: cellSize, layoutProvider: layoutProvider})

    this.state = {
      arrayWidth: arrayWidth,

      cellSize: cellSize,
      layoutProviderAll: layoutProviderAll,
      dataProviderAll: dataProviderAll,
      layoutProviderSelected: layoutProviderSelected,
      dataProviderSelected: dataProviderSelected,
      lastUsedSymbols: lastUsedSymbols,
      symbolSelected: this.props.typeChart == 2 ? getFirstSymbolSelectedCrochet() : getFirstSymbolSelectedKnitting(),
      showMoreSymbols: false,
      dataProviderColors: dataProviderColors,
      maxUsedSymbols: maxUsedSymbols
    };


  //  this.props.onSymbolSelected(SYMBOLS[1])
    //alert(require("../../imgs/symbols/0_null.png"))



  }

  getCustomSymbols() {
    return [...this.props.allCustomSymbols.filter(obj => {
            if ((this.props.typeChart == 2 && obj.projectTool == 2) || (this.props.typeChart != 2 && obj.projectTool != 2))
              return true
            else
              return false
         }) ]
  }

  getLayoutProvider(array, cellSize, adjustSize = false) {

     var layoutProviderSelected = new LayoutProvider(
            index => {
                if (!adjustSize)
                  return 1
                // if(__DEV__) console.log('getLayoutProvider', index, array.length)
                if (index < array.length && array[index].width)
                  return array[index].width
                return 1;
            },
            (type, dim) => {
       
                dim.width = cellSize * type;
                dim.height = cellSize;
                  
            }
        );

     return layoutProviderSelected
  }



  cloneRow(array) {
    var dataProvider = new DataProvider((r1, r2) => {
            return r1 !== r2;
        });

    dataProvider = dataProvider.cloneWithRows(array);

    return dataProvider
  }

  componentDidMount() {
   
  }


  addToUsedSymbols(symbol) {

    for (var i = 0; i < this.state.lastUsedSymbols.length; i++) {
      if (this.state.lastUsedSymbols[i].id == symbol.id)
        return
    }
    var newUsedSymbols = [...this.state.lastUsedSymbols]

    //remove button
    newUsedSymbols.pop()

    if (newUsedSymbols.length >= this.state.maxUsedSymbols)
      newUsedSymbols.shift()
    newUsedSymbols.push(symbol)

   // newUsedSymbols.push({'isAddButton': true})

    var layoutProviderSelected = this.getLayoutProvider(newUsedSymbols, this.state.cellSize)
    this.setState({layoutProviderSelected: layoutProviderSelected, dataProviderSelected: this.cloneRow(newUsedSymbols), lastUsedSymbols: newUsedSymbols})

  }




  addToComputedSymbols(computedArray, symbol) {

    var found = false
    for (var i = 0; i < computedArray.length; i++) {
      if (computedArray[i].symbol.id == symbol.id) {
        computedArray[i].nb = computedArray[i].nb + 1
        found = true
        break
      }
    }

    if (!found) {
      computedArray.push({symbol: symbol, nb:1})
    }

  //  alert(symbolId)
  }

  getUsedSymbols(array, maxToShow) {

    var used = []
    var computedArray = [ { symbol: this.props.typeChart == 2 ? getFirstSymbolSelectedCrochet() : getFirstSymbolSelectedKnitting(), nb:0} ]
    for (var i = 0; i < array.length; i++) {
      //if(__DEV__) console.log("getUsedSymbols", array[i])
      if (array[i].symbol.id >= 0)
        this.addToComputedSymbols(computedArray, array[i].symbol)
    }
    if (this.props.customSymbolEdited) {
      //alert(JSON.stringify(this.props.customSymbolEdited))
      this.addToComputedSymbols(computedArray, this.props.customSymbolEdited)
    }

    computedArray = computedArray.sort(function(a, b) {
      if (a.nb > b.nb)
        return -1
      if (a.nb < b.nb)
        return 1
      return 0;
    })

   

    var newArray = []
    if(__DEV__) console.log('plop array', computedArray)
    for (var i = 0; i < maxToShow && i < computedArray.length; i++) {

      if(__DEV__) console.log('plop adding', computedArray[i])

      newArray.push(computedArray[i].symbol)
    }

    //newArray.push({'isAddButton': true})

    return newArray

  }

  search(text) {

    if (this.timerSearch) {
      clearTimeout(this.timerSearch)
      this.timerSearch = null
    }
    this.timerSearch = setTimeout(() => {

      var array = searchSymbol(text)

      if (this.props.allCustomSymbols) {

        var customSymbolsArray = []

        var toolCustomSymbols = this.getCustomSymbols()
        for (var i = 0; i < toolCustomSymbols.length; i++) {
          var customSymbol = toolCustomSymbols[i]


          if (customSymbol.rs.indexOf(text) != -1 || customSymbol.ws.indexOf(text) != -1 || customSymbol.rs_long.indexOf(text) != -1
          || customSymbol.ws_long.indexOf(text) != -1) {
            customSymbolsArray.push(customSymbol)
          }
      
        }
        array = array.concat(customSymbolsArray)
      }
      var dataProvider = this.cloneRow(array);


      this.setState({dataProviderSearch: dataProvider})

    }, 2000)


    

  }

  changeSelectorHeight() {
    this.props.onHideSymbols()
    this.setState({ showMoreSymbols: !this.state.showMoreSymbols, dataProviderSearch: null })
  }

  showSymbolSelectedInfo(symbol) {
    var text = (symbol.toolType != 2 ? strings.rs + " : " : '') + symbol.rs_long + '\n\n' +  (symbol.toolType != 2 ? strings.ws + " : " : '') + symbol.ws_long

    alert(text)

  }


  renderItemAll(item) {

    const borderWidth = 0.5

    // if(__DEV__) console.log('renderItemAll', item)

    var widthFactor = item.width ? item.width : 0.9 
    var heightFactor = item.height && item.height > 1 ? item.height * 0.7 : 0.9 
    if (item.isAddButton) {
      return (
      
        <TouchableOpacity style={{width: (this.state.cellSize * widthFactor) - (CELL_PADDING * 2), height: (this.state.cellSize * heightFactor)- (CELL_PADDING * 2), alignItems:'center', justifyContent:'center', margin: CELL_PADDING}}
        onPress={() => {

          this.props.onNewSymbol()
        }

        }>

         <Image urlOnServer={'/imgs/plus_bleu.png'} style={{width: (this.state.cellSize * widthFactor) - (borderWidth * 2) - (CELL_PADDING * 2), height: (this.state.cellSize * heightFactor) - (borderWidth * 2) - (CELL_PADDING * 2)}}/>
       

        </TouchableOpacity>
    )
    }
    return (
      
        <TouchableOpacity style={{ 
          width: (this.state.cellSize * widthFactor) - (CELL_PADDING * 2), 
          height: (this.state.cellSize * heightFactor) - (CELL_PADDING * 2), 
          borderColor:"#ccc", borderWidth:borderWidth, alignItems:'center', justifyContent:'center', margin: CELL_PADDING}}
        onLongPress={() => this.props.onLongPress(item)}
        onPress={() => {

          this.addToUsedSymbols(item);
          this.setState({symbolSelected: item, dataProviderSearch: null})
          this.props.onSymbolSelected(item)}

        }>

         <Image source={item.t_img}

         style={{width: (this.state.cellSize * widthFactor) - (borderWidth * 2) - (CELL_PADDING * 2), height: (this.state.cellSize * heightFactor) - (borderWidth * 2) - (CELL_PADDING * 2), backgroundColor:'#fff'}}/>
       

        </TouchableOpacity>
    )
  }


  renderItemColor(item) {
    const borderWidth = 0.5

    return (
      
        <TouchableOpacity 
            style={{
              width: 30,
              height: 30,
              borderRadius: 15,
              backgroundColor: item.color ? item.color : undefined,
              borderColor:"#ccc", 
              borderWidth:borderWidth, 
              alignItems:'center',
              marginVertical: 15,
              justifyContent:'center'}}
              onPress={() => {
                  this.setState({selectedColor: item.color, dataProviderSearch: null})
                  this.props.onColorSelected(item.color)}
        }>

        {item.color == null && <Text style={{color:'rgba(255,255,255,0.6)', fontSize:10, textAlign:'center'}}>{strings.schemaTool.noColor}</Text>}
        
        </TouchableOpacity>
    )
  }


  renderItem(item) {

    var widthFactor = item.width ? item.width : 0.9 
    var heightFactor = item.height && item.height > 1 ? item.height * 0.7 : 0.9 

    //if(__DEV__) console.log('renderItem', item)
    if (item.isAddButton) {

      return (
            <TouchableOpacity 
                style={{
                  width: (this.state.cellSize * widthFactor) - (CELL_PADDING * 2), 
                  height: (this.state.cellSize * heightFactor) - (CELL_PADDING * 2), 
                  alignItems:'center', 
                  justifyContent:'center', 
                  margin: CELL_PADDING
                }}
                onPress={() => {
                  this.setState({showMoreSymbols: true})
                }
                }>

         <Image urlOnServer={'/imgs/plus_bleu.png'} style={{width: this.state.cellSize - 10, height: this.state.cellSize - 10}}/>
       

        </TouchableOpacity>
        )

    }
    var widthFactor = 1 
    const selected = item.id == this.state.symbolSelected.id
    const borderWidth = selected ? 2 : 0.5
    return (
      
        <TouchableOpacity style={{width: (this.state.cellSize * widthFactor) - (CELL_PADDING * 2), height: this.state.cellSize - (CELL_PADDING * 2), borderColor:selected ? SECONDARY_COLOR : "#ccc", borderWidth:borderWidth, margin: CELL_PADDING}}
        
         onLongPress={() => this.props.onLongPress(item)}
        onPress={() => {

          this.setState({symbolSelected: item})
          this.props.onSymbolSelected(item)}

        }>

         <Image source={item.t_img}
         resizeMode={'contain'}
          style={{width: (this.state.cellSize * widthFactor) - (borderWidth * 2) - (CELL_PADDING * 2), height: this.state.cellSize - (borderWidth * 2) - (CELL_PADDING * 2), backgroundColor:'#fff'}}/>
       

        </TouchableOpacity>
    )
  }


  renderSelect() {
    console.log("COLORS !! ", this.state.dataProviderColors)
    var allCustomSymbols = this.getCustomSymbols()
    var showEditInfo = false
    if (allCustomSymbols[0] && allCustomSymbols.indexOf(this.state.symbolSelected) > -1) {
      showEditInfo = true
    }

    return (

      <View style={{ paddingLeft: 0 }}>
        <RecyclerListView
          style={{ width: this.state.arrayWidth * this.state.cellSize, height: this.state.cellSize, marginTop: 8,}}
          layoutProvider={this.state.layoutProviderSelected}
          dataProvider={this.state.dataProviderSelected}
          rowRenderer={(type, data, index) => this.renderItem(data)}
          extendedState={this.state.symbolSelected}
        />
        {
          showEditInfo && 
          <Text style={{ color: "rgba(255,255,255,0.6)",fontStyle: "italic", marginRight: 8,}}>{strings.schemaTool.editSymbolHelper}</Text>
        }

        <TouchableOpacity
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            height: 18 
          }}
          onPress={() => this.changeSelectorHeight() }
        >
          <Text style={{ color: "white", marginRight: 8,}}>
            {this.state.showMoreSymbols ? strings.schemaTool.hideSymbols :strings.schemaTool.showSymbols}</Text>
          <FontAwesomeIcon icon={this.state.showMoreSymbols ? faAngleUp : faAngleDown} size="2x" style={{ color: "white" }} />
        </TouchableOpacity>
        {
          this.state.showMoreSymbols &&
          <View style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center" }}>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <TextInput
                contentContainerStyle={{ flex: 1, justifyContent: "flex-start", alignItems: "flex-start" }}
                style={{
                  width: 150,
                  paddingLeft: 12,
                  color: "white",
                  paddingHorizontal: 18,
                  textAlign: "left",
                  paddingVertical: 8,
                  backgroundColor: "rgba(255,255,255,0.2)",
                  borderRadius: 25,
                  fontSize: 14,
                  marginRight: 8
                }}
                onChangeText={text => this.search(text)}

                underlineColorAndroid='transparent'
              />
              <View
                style={{ alignSelf: "center" }}
              >
                <FontAwesomeIcon icon={faSearch} size="lg" style={{ color: "white" }} />
              </View>
            </View>
            <Text style={{ color: "white", marginRight: 8, marginVertical: 12 }}>{strings.color}</Text>
            <RecyclerListView
              //style={{width:100, height: 100}} 
              style={{ width: 410, height: 60, marginVertical: 8 }}
              contentContainerStyle={{justifyContent: "center", alignItems: "center"}}
              layoutProvider={this.state.layoutProviderAll}
              isHorizontal={true}

              dataProvider={this.state.dataProviderColors}
              rowRenderer={(type, data, index) => this.renderItemColor(data)}
            />
          </View>
        }
        {
          this.state.showMoreSymbols &&
        <RecyclerListView
          //style={{width:100, height: 100}} 
          style={{ width: '100%', height: this.props.typeChart == 2 ? this.state.cellSize * 1.3 : this.state.cellSize * 1.3 }}
          layoutProvider={this.state.layoutProviderAll}
          isHorizontal={true}
          dataProvider={this.state.dataProviderSearch ? this.state.dataProviderSearch : this.state.dataProviderAll}
          rowRenderer={(type, data, index) => this.renderItemAll(data)}
        />
        }
      </View>
    )
  }


  renderInfo() {

    const borderWidth = 0.5

    const widthMultiplier = this.state.symbolSelected.width ? this.state.symbolSelected.width : 1
    
    return (

        <View 
        style={{
          flexDirection:'row', 
          alignItems:'center', 
        }}>


  

          {
            this.state.selectedColor != null && (
              <View 
                style={{width: this.state.cellSize - (CELL_PADDING * 2), height: this.state.cellSize - (CELL_PADDING * 2), backgroundColor: this.state.selectedColor, 
            borderColor:"#ccc", borderWidth:borderWidth, alignItems:'center', justifyContent:'center', margin: CELL_PADDING}}/>
          )}

          {
            this.state.selectedColor == null && (
              <View 
              style={{width: this.state.cellSize - (CELL_PADDING * 2), height: this.state.cellSize - (CELL_PADDING * 2), backgroundColor: this.state.selectedColor, 
              borderColor:"#ccc", borderWidth:borderWidth, alignItems:'center', justifyContent:'center', margin: CELL_PADDING}}>
                <Text style={{color:'rgba(255,255,255,0.6)', fontSize:11, textAlign:'center'}}>{strings.schemaTool.noColor}</Text>
              </View>
          )}

           
           <View 
              style={{width: (widthMultiplier * this.state.cellSize) - (CELL_PADDING * 2), height: this.state.cellSize - (CELL_PADDING * 2), borderColor: "#ccc", borderWidth:borderWidth, margin: CELL_PADDING}}
            >     
              <Image 
              source={this.state.symbolSelected.t_img} 
              style={{width: (this.state.cellSize * widthMultiplier) - (borderWidth * 2) - (CELL_PADDING * 2), height: this.state.cellSize - (borderWidth * 2) - (CELL_PADDING * 2), backgroundColor:'#fff'}}/>
          </View>
          <TouchableOpacity style={{flex:1, alignContent:'center', flexDirection:'row', alignItems:'center'}} onPress={() => this.showSymbolSelectedInfo(this.state.symbolSelected)}>
              <Text style={{color:'rgba(255,255,255,0.8)', fontSize:12, marginRight: 8}}>{ this.state.symbolSelected.rs == this.state.symbolSelected.ws || !this.state.symbolSelected.ws ? this.state.symbolSelected.rs : strings.rs_short + ': ' + this.state.symbolSelected.rs + ' /  ' + strings.ws_short + ': ' + this.state.symbolSelected.ws}</Text>
              <FontAwesomeIcon icon={faInfoCircle} size="lg" style={{color: "white"}} />
          </TouchableOpacity>

        


        </View>

      )

  }

  render() {

    
    const heightAllSymbols = Math.round((Math.floor(getSymbols().length / this.state.arrayWidth) + 1) * this.state.cellSize)

    return (


      <View 
        onLayout={this.props.onLayout}
        style={{
          backgroundColor: "#2D82BB", 
          position: "absolute",
          bottom: 12,
          height: this.state.showMoreSymbols ? 300 : 180, 
          width: Dimensions.get('window').width - 24,
          margin: 12, 
          borderRadius: 8,
          paddingHorizontal: 12,
          paddingVertical: 18,
          }}>


        {this.renderInfo()}

        { this.renderSelect(heightAllSymbols)}

        </View>

      )
  }
}




const styles = StyleSheet.create({


});
