import React from 'react';
import useToken from '../../hooks/useToken';
import useUser from '../../hooks/useUser';
import { useState, useEffect } from 'react';
import { SERVER_API_URL, APP_NAME } from '../../config/Constants'



import fetchClient from "../../utils/http-common";
import { getSizesFromIds, getCatFromSize } from "../../config/sizes"
import PatternUtils from "../../generator/PatternUtils";
import { LANGS } from "../../generator/PatternUtils";
import Image from "../../components/Image";

import { useParams, useHistory, useLocation } from "react-router-dom";
import strings from "../../config/strings";
import Utils from '../../utils/utils';
import Dropdown from 'react-bootstrap/Dropdown'
import { getAllSizes } from "../../config/sizes"
import CheckBox from "../../components/CheckBox"
import ImageBackground from "../../components/ImageBackground"

import EditMaterial from "../../components/generator/EditMaterial";
import EditGauge from "../../components/generator/EditGauge";
import EditTechnics from "../../components/generator/EditTechnics";
import EditGlossary from "../../components/generator/EditGlossary";
import ManagePanel from "../../components/generator/ManagePanel";
import UploadPicture from "../../components/generator/UploadPicture";
import AlertModal from "../../components/generator/AlertModal";
import EditCounterList from "../../containers/EditCounterList"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

export default function PatternDetails() {

  const history = useHistory();
  const { patternId } = useParams();
  const location = useLocation();
  const [createdPattern, setCreatedPattern] = useState(location.state.pattern)
  const [previewProject, setPreviewProject] = useState(location.state.previewProject)
  const {token, setToken} = useToken();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [allOptions, setAllOptions] = useState()
  const [availableSizes, setAvailableSizes] = useState()
  const [showCollapse, setShowCollapse] = useState(false)
  const [toEdit, setToEdit] = useState()
  const [editType, setEditType] = useState()
  const [showUploadPic, setShowUploadPic] = useState(false)
  const [showAlertPublish, setShowAlertPublish] = useState(false)
  const [showAlertPdf, setShowAlertPdf] = useState(false)
  const [showAlertPublishPdf, setShowAlertPublishPdf] = useState(false)
  const [showChoosePdfSize, setShowChoosePdfSize] = useState(false)
  const [showAlertDelete, setShowAlertDelete] = useState(false)
  const [showEditCounters, setShowEditCounters] = useState(false)
  const [enableDownloadPdf, setEnableDownloadPdf] = useState(createdPattern && createdPattern.sections && createdPattern.sections[0])

  const [pdfSize, setPdfSize] = useState(false)
  const [isPublic, setIsPublic] = useState(0)

  const getAllOptions = () => {
    return [
        {
        type: "material",
        text:Utils.capitalizeFirstLetter(strings.material),
        edit: () => renderEditMaterial(),
        done: false,
        img: '/imgs/material_circle.png'
        },
        {
        type: "glossary",
        text:strings.glossaryP.glossary,
        edit: () => renderEditGlossary(),
        done: false,
        img: '/imgs/infos_circle.png'
        },
        {
        type: "gauge",
        text:strings.gauge,
        edit: () => renderEditGauge(),
        done: false,
        img: '/imgs/gauge_circle.png'
        },
        {
        type: "technics",
        text:strings.technics,
        edit: () => renderEditTechnics(),
        done: false,
        img: '/imgs/technics_circle.png'
        },
        {
          type: "counters",
          text:Utils.capitalizeFirstLetter(strings.counters),
          edit: () => setShowEditCounters(true),
          done: false,
          img: '/imgs/counter-circle.png'
          },
      ]
  }
  useEffect(() => {

    //console.log("useEffect: current createdPattern", createdPattern)

  }, [location.state.pattern.id])

  useEffect(() => {

    //console.log("useEffect: current createdPattern", createdPattern)

    if (location.state.previewProject) {

      setPreviewProject(location.state.previewProject)
    } else {
      //PatternUtils.getPreviewProject(createdPattern.id, setPreviewProject)

      let options = Object.assign({ method: "GET" }, null);
      const token = localStorage.getItem('token')
      options.headers = Utils.getJSONHeaders(token);
      fetchClient.get(`${SERVER_API_URL}/user_projects/get_project_from_pattern?pattern_id=${createdPattern.id}`, options)
      .then(response => {
        // console.log("in then - getting preview project", response.data)
        var previewProject = response.data

        setPreviewProject(previewProject)

      })
      .catch(error => {
        console.log("in catch")
        alert('err ' + error.message);
        console.log(error) })

    }

    const options = getAllOptions()
    setAllOptions(options)
    if (createdPattern.sizeIds) {
      var sizeIds = createdPattern.sizeIds
     /* if (typeof sizeIds === 'string' ) {
        sizeIds = sizeIds.split("|")
        console.log("converted sizeIds: ", sizeIds)
      }*/
      const sizes = getSizesFromIds(sizeIds)
      setAvailableSizes(sizes)
    }

  }, [createdPattern, showCollapse])

  const editContent = () => {
    var pattern = createdPattern

    history.push({
      pathname: `/edit-pattern/`,
      state: { pattern: pattern, previewProject: previewProject }
    })
  }
  const seePreview = () => {
    // var pattern = createdPattern

    // history.push({
    //   pathname: `/pattern-preview/`,
    //   state: { pattern: pattern }
    // })


    //PatternUtils.getPreviewProject(createdPattern.id, (response) => setPreviewProject(response.data))
    // var project = previewProject
    // console.log("Preview Proj", project)

    let options = Object.assign({ method: "GET" }, null);
    const token = localStorage.getItem('token')
    options.headers = Utils.getJSONHeaders(token);
    fetchClient.get(`${SERVER_API_URL}/user_projects/get_project_from_pattern?pattern_id=${createdPattern.id}`, options)
    .then(response => {
      console.log("in then - getting preview project", response.data)
      var previewProject = response.data

      history.push({
        pathname: `/ViewProject/${previewProject.id}`,
        state: { pattern: createdPattern }
      })

    })
    .catch(error => {
      console.log("in catch")
      alert('err ' + error.message);
      console.log(error) })


    // var id = 342755
    // history.push({
    //   pathname: `/ViewProject/${id}`,
    //   rcPattern: createdPattern,
    // })

  }
  const onDownloadPdf = (checkTime = true) => {

    if (createdPattern.published == 0) {
      setShowAlertPublishPdf(true)
      return
    }
    if (checkTime && createdPattern.lastPublishedAt < createdPattern.updatedAtOnClient)
    {
          setShowAlertPdf(true)
        return
    }
    setShowChoosePdfSize(true)
  }

  const goToPdf = () => {
    if (pdfSize) {
      PatternUtils.downloadPdf(pdfSize.id, createdPattern)
      setShowAlertPublish(false)
      setShowChoosePdfSize(false)
    } else {
      alert(strings.patternCreator.selectSize)
    }
  }

  const editInit = () => {
    // console.log("preview Proj", previewProject)
    history.push({
      pathname: `/new-row-counter-pattern`,
      state: { pattern: createdPattern }
    })
  }

  const checkAndDelete = () => {
    setShowAlertDelete(true)
  }

  const deletePattern = () => {
    var updatedPattern = {...createdPattern}
    updatedPattern.deleted = 1
    PatternUtils.updatePatternOnServer(updatedPattern, (response) => {
      setCreatedPattern(updatedPattern)
      history.push({
        pathname: `/knitting-crochet-pattern-builder`,
      })
    })
  }

  const setPublishedPattern = () => {
    console.log("created pattern after publication", createdPattern)
    setEnableDownloadPdf(true)
  }

  const publishPattern = () => {
    setEnableDownloadPdf(false)
    var publishedPattern = {...createdPattern}
    var updatedTime = Utils.getTime()
    publishedPattern.updatedAtOnClient = updatedTime
    publishedPattern.isPublic = isPublic
    publishedPattern.published = 1
    publishedPattern.lastPublishedAt = updatedTime
    setCreatedPattern(publishedPattern)
    PatternUtils.publishOnServer(createdPattern, isPublic, setPublishedPattern)

    setShowAlertPublish(false)
  }

  const handleCollapse = (option) => {
    if (option.type !=  editType) {
      if (option.type == "counters") {
        option.edit()
      } else {
        setShowCollapse(true);
        setToEdit(option.edit)
        setEditType(option.type)
      }
    } else {
      setShowCollapse(!showCollapse);
      setToEdit(option.edit)
      setEditType(option.type)
    }
  }

  const setNewPic = (id) => {
    var updatedPattern = createdPattern
    updatedPattern.imgAttachmentId = id
    setCreatedPattern(updatedPattern)
  }

  const renderAlertPDF = () => {
    return (
      <AlertModal
      title={strings.patternCreator.alertChangesTitle}
      text={strings.patternCreator.alertChangesText}
      onOk={() => onDownloadPdf(false)}
      onDelete={() => setShowAlertPdf(false)}
      />
    )
  }

  const renderAlertPublishPDF = () => {
    return (
      <AlertModal
      title={strings.patternCreator.alertNotPublishedTitle}
      text={strings.patternCreator.alertNotPublishedText}
      onOk={() => setShowAlertPublishPdf(false)}
      />
    )
  }

  const renderPicSize = () => {
    return (
      <AlertModal
      title={"Choose Size"}
      text={"Please select which size version you want to download as PDF"}
      onOk={goToPdf}
      innerContent={renderChooseSize}
      onDelete={() => setShowChoosePdfSize(false)}
      />
    )
  }

  const renderAlertDelete = () => {
    return (
      <AlertModal
      title={strings.patternCreator.alertDeleteTitle}
      text={strings.patternCreator.alertDeleteText}
      onOk={deletePattern}
      onDelete={() => {
        setShowAlertDelete(false)
      }}
      />
    )
  }

  const renderAlertPublish = () => {
    return (
      <AlertModal
      title={strings.patternCreator.alertNotPublishedTitle}
      text={strings.patternCreator.alertPublicText}
      onOk={publishPattern}
      innerContent={renderChoosePublicStatus}
      onDelete={() => setShowAlertPublish(false)}
      />
    )
  }

  const renderHeader = () => {
    var languageFlag = PatternUtils.getCountryFlagFromCode(createdPattern.lang.substring(0, 2))
    return (
      <ImageBackground
        urlOnServer={createdPattern.imgAttachmentId ? Utils.getAttachementUrl(createdPattern.imgAttachmentId) : "/imgs/fond-web.png"}
        style={{ flexDirection: 'column', width: "100%", height: 200 }}>
        <View style={{ flexDirection: 'column', width: "100%", height: 200, backgroundColor: createdPattern.imgAttachmentId ? "rgba(0,0,0,0.3)" : "rgba(0,0,0,0.0)" }}>
          <div className="ButtonShadow">
            <TouchableOpacity
              className="ButtonShadow"
              onPress={() => setShowUploadPic(true)}
              style={{ width: 72, height: 72, padding: 24, borderRadius: 36, backgroundColor: "#278ACC" }}>
              <Image style={{ width: 24, height: 24, marginRight: 4 }} urlOnServer={'/imgs/camera-white.png'} />
            </TouchableOpacity>
          </div>
          <View
            style={{ flex: 1, justifyContent: "flex-end", alignItems: "center", marginBottom: 74, color: "white" }} >
            <h1>{createdPattern.name}</h1>
            <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              {languageFlag && <Image style={{ width: 24, height: 24, marginRight: 4 }} urlOnServer={languageFlag} />}
              <Text style={{ color: "white", fontStyle: "italic", fontSize: 16 }}>{!languageFlag && PatternUtils.country2emoji(createdPattern.lang.substring(0, 2))} {PatternUtils.getCategoryFromCode(createdPattern.category).value}</Text>
              {renderEditInit()}
            </View>

          </View>
        </View>

      </ImageBackground>
    )
  }

  const renderEditInit = () => {
    return (
      <TouchableOpacity
      onPress={() => editInit()}
      style={{zIndex: 0}}>
        <p className="EditInit" >{strings.edit} <FontAwesomeIcon style={{color: "#FECB2E"}} size={12} icon={ faEdit } /> </p>
      </TouchableOpacity>
    )
  }

  const renderEditCounters = () => {
    console.log("previewProject",previewProject)
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10000,
          justifyContent: "center",
          alignItems:'center',
          backgroundColor: "rgba(128, 128, 128, 0.7)",
        }}

        >
        <EditCounterList
          project={previewProject}
          onClose={() => {
            setShowEditCounters(false)
          }
        }/>

        </div>

      )
  }
  const renderManagePanel = () => {
    return (
      <ManagePanel
      key={JSON.stringify(createdPattern)}
      delete={checkAndDelete}
      pattern={createdPattern}
      seePreview={seePreview}
      enableDownloadPdf={enableDownloadPdf}
      onDownloadPdf={onDownloadPdf}
      showAlertPublish={() => setShowAlertPublish(true)}
      editContent={editContent}/>
    )
  }

  const renderChooseSize = () => {

    return (
      <View style={{flexDirection:"row", justifyContent: "space-around", alignItems:"center", marginBottom: 12, zIndex: 10000}}>
        <Dropdown >
          <Dropdown.Toggle style={{width:290, backgroundColor:"white", borderColor: "#2ba5f7", color:"#2ba5f7", borderWidth: 1}} id="dropdown-custom-1">{pdfSize ? `${getCatFromSize(pdfSize.id).name} : ${pdfSize.text}` : strings.select_size}</Dropdown.Toggle>
          <Dropdown.Menu  className="dropdown-init-pattern">
          {
            availableSizes.map((size) => {
              return (<Dropdown.Item onClick={() => setPdfSize(size)}>{getCatFromSize(size.id).name}: {size.text}</Dropdown.Item>)
            })
          }
          </Dropdown.Menu>
        </Dropdown>
      </View>
    )
  }

  const renderChoosePublicStatus = () => {
    return (
      <View style={{flexDirection:"row", justifyContent: "space-around", alignItems:"center", marginBottom: 12}}>
        <TouchableOpacity
          onPress= {() => setIsPublic(1)}
          style= {{width: 200,flexDirection:"row", alignItems:"center"}}>
          <CheckBox
            checked={isPublic == 1 ? true : false}
            tintColor={"#FECB2E"}
            style={{color:"rgba(255,255,255,0.8)" }}
            onPress={() => setIsPublic(true)}/>
          <Text
            style={{flex:1, color: "rgba(0,0,0,0.8)", fontSize:14, textAlign:"left",marginHorizontal:8}}>
            {strings.patternCreator.generatePublic}
          </Text>
      </TouchableOpacity>
      <TouchableOpacity
          onPress= {() => setIsPublic(0)}
          style= {{width: 200,flexDirection:"row", alignItems:"center"}}>
          <CheckBox
            checked={isPublic == 1 ? false : true}
            tintColor={"#FECB2E"}
            style={{color:"rgba(255,255,255,0.8)" }}
            onPress={() => setIsPublic(false)}/>
          <Text
            style={{flex:1, color: "rgba(0,0,0,0.8)", fontSize:14, textAlign:"left",marginHorizontal:8}}>
            {strings.patternCreator.generatePrivate}
          </Text>
      </TouchableOpacity>
      </View>
    )
  }

  const renderDescription = () => {
    return (
      <View
      style={{flex: 1, margin: 24, zIndex: 0}}>
        <p className="DetailTitle">{strings.description}</p>
        <Text style={{color: "rgba(255,255,255,0.8)"}}>{createdPattern.description} {renderEditInit()}</Text>
      </View>
    )
  }

  const renderAdditionalInfos = () => {
    return (
      <View
      style={{flex: 1,  margin: 24, }}>
        <p className="DetailTitle">{strings.patternCreator.additionalInfos}</p>
        <div className="row" style={{ justifyContent: "center" }}>
          { allOptions &&
            allOptions.map((option) => {
              return(
                <div className="col-lg-2 col-md-3 col-sm-6" >
                    <TouchableOpacity
                    onPress={() => handleCollapse(option)}
                    style={{justifyContent: "center", alignItems: "center", marginVertical: 8}}>
                        <Image style={{width: 50, height: 50}} urlOnServer={option.img} />
                        <Text style={{textAlign: "center", color: "rgba(255,255,255,0.8)", marginTop: 8}}>{option.text}</Text>
                    </TouchableOpacity>
                </div>
              )
            })
          }
        </div>
      </View>
    )
  }

  const renderCollapseOneInfo = (callback) => {
    return (
      <View style= {{backgroundColor: "#2D7BAE", width: "100%", height: 500, borderRadius: 12, padding: 12}}>
        {callback}
      </View>
    )
  }

  const renderEditGauge = () => {
      return (
        <EditGauge
         createdPattern={createdPattern}
         onSave={setCreatedPattern}
         showCollapse={setShowCollapse} />
       )
  }

  const renderEditMaterial = () => {
    return (
      <EditMaterial
       createdPattern={createdPattern}
       onSave={setCreatedPattern}
       showCollapse={setShowCollapse} />
     )
}

  const renderEditTechnics = () => {
    return (
      <EditTechnics
       createdPattern={createdPattern}
       onSave={setCreatedPattern}
       showCollapse={setShowCollapse} />
     )
}

const renderEditGlossary = () => {
  return (
    <EditGlossary
     createdPattern={createdPattern}
     onSave={setCreatedPattern}
     showCollapse={setShowCollapse} />
   )
}

  const renderSizes = () => {
    return (
      <View
      style={{flex: 1, margin: 24}}>
        <p className="DetailTitle">{strings.available_sizes}</p>
        <div className="row">
        {
            availableSizes.map((size) => {
              return(
                <div className="col-lg-2 col-md-3 col-sm-6">
                  <View style={{flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "center", marginVertical: 8 }}>
                      <Image style={{width: 35, height: 35, marginLeft: 20, marginRight: 12}} urlOnServer={"/imgs/size_ico.png"} />
                      <Text style={{textAlign: "left", color: "rgba(255,255,255,0.8)"}}>{getCatFromSize(size.id).name}: {size.text}</Text>
                  </View>
                </div>

              )
            })
          }
          <View style={{marginTop: 12}}>
          {renderEditInit()}
          </View>

          </div>
      </View>
    )
  }



  return(


    <View style={{flexDirection:'column', backgroundColor: "#266D9C", width: "100%", minHeight: height - 72}}>
    {createdPattern && renderHeader()}

     <div className="container rcpattern-container ">
       {createdPattern && renderManagePanel()}
       {showAlertDelete && renderAlertDelete()}
       {showAlertPublish && renderAlertPublish()}
       {showAlertPdf && renderAlertPDF()}
       {showAlertPublishPdf && renderAlertPublishPDF()}
       {showChoosePdfSize && renderPicSize()}
       {showEditCounters && renderEditCounters()}
       {showUploadPic &&
       <UploadPicture
       pattern={createdPattern}
       type={"img"}
       showUpload={setShowUploadPic}
       onUploaded={(picId) => {
         setNewPic(picId)
         setShowUploadPic(false)
       }} />
       }
     {createdPattern && renderDescription()}
     {createdPattern && renderAdditionalInfos()}

     {showCollapse && renderCollapseOneInfo(toEdit)}

     {availableSizes && renderSizes()}


    </div>

    </View>
  );
}
