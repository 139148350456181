import React, { Component } from "react";
import Image from "../components/Image"
import ImageBackground from "../components/ImageBackground"
import Button from "../components/Button"
import strings from "../config/strings";
import { SERVER_API_URL } from '../config/Constants'

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';


const IMG_SIZE = 200;
const MARGIN_SIZE = 20
export default class ProjectsList extends React.Component {


  getProjImage(proj) {

    if (proj.project_tool == 0)
      return '/imgs/counter-all.png'
    if (proj.project_tool == 1)
      return '/imgs/counter-tricot.png'
    if (proj.project_tool == 2)
      return '/imgs/counter-crochet.png'
    return '/imgs/counter-all.png'

  }

  getBackgroundImg(proj) {
    if (proj.photo_preview_url)
      return `${SERVER_API_URL}/${proj.photo_preview_url}`
    return '/imgs/background_knit.png'
  }

  renderItem(item) {
    return (

    <TouchableOpacity style={{
    backgroundColor:'#fff',
    shadowColor: "#000",
    borderRadius: 12,
    shadowOpacity: 0.2,
    shadowRadius: 2,
    shadowOffset: {width:2,height:2}, margin:MARGIN_SIZE
  }}

    onPress={() => this.props.onProjectSelected(item)}
    >
        <ImageBackground
        urlOnServer={this.getBackgroundImg(item)}
        imageStyle={{borderTopRightRadius: 12, borderTopLeftRadius: 12}}
        style={{
          width: IMG_SIZE,
          height: IMG_SIZE / 1.3,
          alignItems:'center',
          justifyContent:'center'}}>

       {!item.photo_preview_url &&
       <Image
       urlOnServer={this.getProjImage(item)}
       style={{
        width: IMG_SIZE / 2, height: (IMG_SIZE / 1.3) / 2, alignSelf:'center'}}/>}

        </ImageBackground>
        <View style={{alignItems:'center', padding:8, flexDirection:'row'}}>
         <Text style={{fontWeight:600, color:'#555'}}>{item.custom_name}</Text>
         <View style={{flex:1}}/>
          <TouchableOpacity style={{padding:5, tintColor:'#555'}} onPress={() => this.props.onProjectOptionsSelected(item)}>
            <Image style={{width:10, height:20}} urlOnServer={'/imgs/menu_grey.png'}/>

          </TouchableOpacity>
         </View>
    </TouchableOpacity>)
  }

  renderNoProject() {
    return (

        <View style={[{justifyContent:'center'}, this.props.style]}>


          <Button title={strings.start_new_project} style={{alignSelf:'center', marginRight:16}}
              onPress={() => this.props.onNewProject()}
              />
        </View>
      )
  }
  render() {

    if (this.props.projects && this.props.projects.length == 0) {
      return this.renderNoProject();
    }
    const nbColumns = parseInt((Dimensions.get('window').width - (Dimensions.get("window").height / 10)) / (IMG_SIZE + (MARGIN_SIZE * 2)))
    return (<FlatList
          style={this.props.style}
          data={this.props.projects}
          extraData={this.state}
          numColumns={nbColumns}
          key={'ProjectsList_ ' + nbColumns}
          renderItem={item => this.renderItem(item.item)}
          keyExtractor={(item, index) => item.id}
        />)


  }
}

const styles = StyleSheet.create({
    textItem: {
    textAlign: "center",
    flex:1,
    alignSelf: "center",
    marginTop: 14,
    color: "#6C6C6C",
    marginBottom: 10,
  },


});
