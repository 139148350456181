import React, { Component } from "react";
import CheckBox from "../components/CheckBox"
import strings from "../config/strings";
import EditCounter from "../components/EditCounter";
import ProjectUtils from '../utils/projectUtils'
import Utils from "../utils/utils"
import Image from "../components/Image"
import ProjectService from "../services/ProjectService";
import { HEADER_HEIGHT } from '../config/Constants'
import TextareaAutosize from 'react-textarea-autosize';
import {SECONDARY_COLOR, PURPLE_COLOR_PLAIN} from "../config/styles";
import MyTextInput from "../components/MyTextInput"
import Form from 'react-bootstrap/Form';


import {
  ScrollView,
  View,
  TextInput,
  ImageBackground,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  BackHandler,
  Linking,
  Dimensions
} from 'react-native';


const { width, height } = Dimensions.get("window");
const MARGIN_HORIZONTAL = 16
const BORDER_WIDTH = 0.5
const PADDING = 16

export default class EditComment extends Component {
  constructor(props) {
    super(props);


    var comment = this.props.data.comment;
    this.state = {
      comment: comment,
      txt: comment.txt,
      showAt: comment.show_at,
      showAtEnd: comment.show_at_end,
      isRange: comment.show_at_end > 0
    };


    this.origState = JSON.parse(JSON.stringify(this.state))
    //   alert(JSON.stringify(comment))

  }
/*
  componentDidMount() {

    this.backListener = this.props.history.listen(location => {

      if (location.action === "POP") {
        // Do your stuff
       // alert(1)
      }
    });
  }

  componentWillUnmount() {

      this.backListener();
  }
*/


  isInt(value) {
    return !isNaN(value) &&
         parseInt(Number(value)) == value &&
         !isNaN(parseInt(value, 10));
  }

  onBack() {

    if (this.state.txt != this.origState.txt || this.state.showAt != this.origState.showAt
      || this.state.showAtEnd != this.origState.showAtEnd || this.state.isRange != this.origState.isRange )
      {
        alert(strings.discard_changes, [
          {text: strings.ok, onPress: () => this.props.onClose(), style: 'cancel'},
          {text: strings.cancel}
      ],)
      }
    else
      this.props.onClose()
  }


  onSave() {


    var showAt = this.state.showAt
    var showAtEnd = this.state.showAtEnd

    if (showAt == "" || !this.isInt(showAt)) {
      alert(strings.number_required);
      this.setState({errorBegin: true})
      return
    }

    if (this.state.isRange) {
      if (showAtEnd == "" || !this.isInt(showAtEnd)) {
        alert(strings.number_required);
        this.setState({errorEnd: true})
        return
      }
    }
    else
      showAtEnd = -1

    this.props.data.onSave(this.state.txt, showAt, showAtEnd, this.props.data.commentIndex)

  }




  onEditCommentTxt(index, text) {

    this.state.comments[index].txt = text;
    this.setState({comments: this.state.comments})

  }


  onEditCommentShowAt(index, showAt) {
    if (showAt == "")
      showAt = -1
    if (!this.isInt(showAt)) {
      alert(strings.number_required)
      return
    }

    var comments = [...this.state.comments]
    comments[index].showAt = parseInt(showAt);

    this.setState({comments : comments})
  }


  onAddComment(text, showAt, showAtEnd) {
    console.debug(
      `pingoulog:onAddComment=${JSON.stringify(this.state.comments)}`
    );
    if (!text || text.length == 0)
      return
    if (!showAt) {
      alert(strings.enter_number)
      this.nbRowAddComment.focus()
      return
    }

  }

  renderTextInput() {
    if (Platform.OS === 'ios') {
      return (
              <ScrollView enabled style={{flex:1, padding:4, margin:0, fontSize:14, color:'#555', fontStyle: this.state.tmpCommentText ? 'normal' : 'italic',
               backgroundColor:'#fff', borderWidth:0.5, borderColor:'#ccc', margin: 16, marginTop: 0, padding:8}}>

          <TextInput
              multiline={true}
              onChangeText={text => this.setState({txt: text})}
              placeholder={strings.enter_comment_opt}
              // style={{ height: Dimensions.get('window').height / 0.7, fontSize:14, color:'#555', fontStyle: this.state.tmpCommentText ? 'normal' : 'italic',
              //  backgroundColor:'#fff'}}
               style={{
                color:  PURPLE_COLOR_PLAIN,
                padding: 12,
                flex: 1,
                textAlign: "left",
                backgroundColor:'rgba(43,165,247,0.15)',
                borderRadius: 8,
                margin:8
              }}
              textAlignVertical={'top'}
              value={this.state.txt}

            />

            </ScrollView>
        )
    }

    return (
       <TextareaAutosize

              style={{
                color:  PURPLE_COLOR_PLAIN,
                padding: 12,
                flex: 1,
                textAlign: "left",
                borderColor:'rgba(43,165,247,0.15)',
                backgroundColor:'rgba(43,165,247,0.15)',
                borderRadius: 8,
                margin:8
              }}
       //style={{flex:1, fontSize:14, color:'#555', fontStyle: this.state.tmpCommentText ? 'normal' : 'italic', backgroundColor:'#fff', borderWidth:0.5, borderColor:'#ccc', margin: 16, marginTop: 0, padding:8}}

        defaultValue={this.state.txt} underlineColorAndroid='transparent'
         onChange={(event) => this.setState({txt: event.target.value || ''})}
         />

      )
  }
  renderRow() {


    if (this.state.isRange) {
      return (

          <View style={{
            flexDirection: 'row',
            alignItems:'center',
            marginTop:16,
            marginLeft:MARGIN_HORIZONTAL}}>

                <Text>{strings.row}</Text>
                <TextInput
                  containerStyle={{marginLeft:MARGIN_HORIZONTAL, width: 90}}
                  style={[styles.smallTextInput, {borderColor: this.state.errorBegin ? '#f55' : '#ccc'}] }
                  multiline={false}
                  keyboardType="numeric"
                  value={this.state.showAt.toString()}
                  onChangeText={(text) => this.setState({showAt: text})
                  }
                  returnKeyType="done"
                />
                <Text style={{color:'#555', marginHorizontal:0}}>{strings.to}</Text>

                <TextInput
                  containerStyle={{marginLeft:MARGIN_HORIZONTAL, width: 90}}
                  style={[styles.smallTextInput, {borderColor: this.state.errorEnd ? '#f55' : '#ccc'}] }
                  multiline={false}
                  keyboardType="numeric"
                  value={this.state.showAtEnd <= 0 ? '' : this.state.showAtEnd.toString()}
                  onChangeText={(text) =>

                    this.setState({showAtEnd: text})
                  }
                  returnKeyType="done"
                />
              </View>
        )
    }
    else {
      return (

          <View style={{flexDirection: 'row', alignItems:'center', marginTop:16, marginHorizontal: MARGIN_HORIZONTAL}}>

                <Text>{strings.row}</Text>
                <TextInput
                  containerStyle={{marginLeft:MARGIN_HORIZONTAL, width: 90}}
                  style={[styles.smallTextInput, {borderColor: this.state.errorBegin ? '#f55' : '#ccc'}] }
                  multiline={false}
                  keyboardType="numeric"
                  value={this.state.showAt.toString()}
                  onChangeText={(text) => this.setState({showAt: text})}
                  returnKeyType="done"
                />

            </View>

        )
    }
  }

  render() {

  // alert(this.state.commentsHeights[0])
    return (
       <View style={{
         zIndex: 50000,justifyContent:'center', alignItems:'center', height: Dimensions.get('window').height - HEADER_HEIGHT}}>


      <View style={{width: Math.min(500, Dimensions.get('window').width), backgroundColor: '#f6f6f6', padding:24, borderRadius:12}}>
        <View
        title={strings.add_counter_com}
        onBack={() => {this.onBack()}}
        disableSafeArea={true}
        style={{backgroundColor:'#f6f6f6'}}
      >


        <Text style={{color:'#555', padding:16, paddingBottom:0, fontWeight:'bold'}}>{strings.appear_row}</Text>
        <Form>
          <View style={{
            flexDirection: 'row',
            alignItems:'center',
            marginTop:16,
            marginLeft:MARGIN_HORIZONTAL,
          }}>
          <Text style={{
            color: this.state.isRange ? '#a0a0a0' : SECONDARY_COLOR,
            marginRight:14,
            fontWeight:this.state.isRange ? undefined : 600,
            }}>{strings.single_row}</Text>
          <Form.Check // prettier-ignore
        type="switch"
        id="custom-switch"
        onChange={(e) => this.setState({isRange: e.target.checked})}
      />
              <Text
              style={{
                color:this.state.isRange ? SECONDARY_COLOR : '#a0a0a0' ,
                marginLeft:8,
                fontWeight:this.state.isRange ? 600 : undefined,
                }}>{strings.range_rows}</Text>
          </View>


            {/* <RadioButton labelHorizontal={true} key={"RadioButton_0"} style={{margin:10}}
              >
                <RadioButtonInput
                    obj={{value:0, label: strings.single_row}}
                    index={0}
                    isSelected={!this.state.isRange }
                    onPress={(value) => {
                      this.setState({isRange: !this.state.isRange})
                    }}
                    borderWidth={1}
                    buttonInnerColor={'#2ba5f7'}
                    buttonOuterColor={!this.state.isRange ? '#2ba5f7' : '#aaa'}
                    buttonSize={10}
                    buttonOuterSize={20}
                    buttonStyle={{}}
                    buttonWrapStyle={{marginLeft: 5}}
                  />
                  <RadioButtonLabel
                    obj={{value:0, label: strings.single_row}}
                    index={1}
                    labelHorizontal={true}
                    onPress={(value) => {this.setState({isRange: !this.state.isRange});

                }}
                    labelStyle={{color:'#555'}}
                    labelWrapStyle={{marginRight: 15}}
                  />
                </RadioButton>

                 <RadioButton labelHorizontal={true} key={"RadioButton_1"} style={{margin:10}}
                 >
                <RadioButtonInput
                    obj={{value:1, label: strings.range_rows}}
                    index={1}
                    isSelected={this.state.isRange }
                    borderWidth={1}
                    buttonInnerColor={'#2ba5f7'}
                    buttonOuterColor={this.state.isRange ? '#2ba5f7' : '#aaa'}
                    onPress={(value) => {this.setState({isRange: !this.state.isRange})

                }}
                    buttonSize={10}
                    buttonOuterSize={20}
                    buttonStyle={{}}
                    buttonWrapStyle={{marginLeft: 5}}
                  />
                  <RadioButtonLabel
                    obj={{value:1, label: strings.range_rows}}
                    index={1}
                    labelHorizontal={true}
                    onPress={(value) => {this.setState({isRange: this.state.isRange})
                  }}
                    labelStyle={{color:'#555'}}
                    labelWrapStyle={{marginRight: 15}}
                  />
                </RadioButton> */}

            </Form>



          { this.renderRow() }


          <Text style={{color:'#555', padding:16, fontWeight:'bold', paddingBottom:8}}>{strings.text}:</Text>

         {this.renderTextInput()}



            <View style={{flex:0, flexDirection:"row",justifyContent:"flex-end",alignSelf:"flex-end",paddingHorizontal:15,paddingVertical:25}}>
              <TouchableOpacity onPress={() => this.onBack()}>
                  <Text style={{marginRight:20, fontSize:16, color: "#c3c3c3", fontWeight: "bold", alignSelf:'center', textAlign:"center"}}>{strings.cancel.toUpperCase()}</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => this.onSave()}>
                  <Text style={{marginHorizontal:0, fontSize:16, color: "#2ba5f7", fontWeight: "bold", alignSelf:'center', textAlign:"center"}}>{this.state.comment.id ? strings.edit.toUpperCase() :  strings.add.toUpperCase()}</Text>
              </TouchableOpacity>
            </View>

          </View>
        </View>

        </View>
      )
  }

}

const styles = StyleSheet.create({


  subContainer: {
    borderBottomWidth:BORDER_WIDTH,
    borderColor:'#ccc'
  },

  container : {

    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 8,
    marginBottom:80
  },

  mainContainer: {
    flex:1,
    backgroundColor: '#F6F6F6'
  },

  containerRow: {
    flex:1,
    flexDirection: "row",
  },

  addCom: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignContent: "center"
  },

  addComTxt: {
    marginLeft: 5,
    alignSelf: "center",
    color: "#555"
  },

  titleText: {
    fontSize: 12,
    color: "#888"
  },

  editBox : {
    paddingHorizontal:8
  },

  textInput: {
    color:'#555',
    flex:1,
  },

  closeImg: {
    width: 20,
    height: 20,
    alignSelf: "center"
  },

  rowInput: {
    borderBottomWidth:0.5,
    borderColor:'#ccc',
    minWidth:40,
    padding:8,
    fontWeight:'bold',
    color:SECONDARY_COLOR
  },
  smallTextInput: {
    color:PURPLE_COLOR_PLAIN,
    paddingVertical: 8,
    paddingHorizontal: 12,
    width: 60,
    textAlign: "center",
    borderRadius: 24,
    marginHorizontal: 12,
    backgroundColor: "rgba(43,165,247,0.15)",
  },

});
