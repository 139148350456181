import React from 'react';
import useToken from '../hooks/useToken';
import useUser from '../hooks/useUser';
import { useState, useEffect } from 'react';
import { SERVER_API_URL } from '../config/Constants'
import ViewProject from './ViewProject';
import ProjectsList from '../components/ProjectsList';
import { BrowserRouter, Route, Switch, useRouteMatch, Link } from 'react-router-dom';
import ImageBackground from "../components/ImageBackground"
import Image from "../components/Image";
import ProfileHeader from "../components/ProfileHeader"
import { useHistory } from "react-router-dom";
import fetchClient from "../utils/http-common";
import strings from "../config/strings";
import Utils from '../utils/utils'
import { HEADER_HEIGHT } from '../config/Constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import ChatItem from "../components/ChatItem"
import Loader from "react-loader-spinner";
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';



export default function Chat() {

  const [loading, setLoading] = useState(true)
  const [chats, setChats] = useState([]);

  const [showFullScreenPic, setShowFullScreenPic] = useState(null);
  const [showLoadingPic, setShowLoadingPic] = useState(false);
  const [thumbSize, setThumbSize] = useState(80);

  const selectedColor = "#2ba5f7"
  const selectedBg = "rgba(255,255,255,0.4)"

  const defaultColor = "#525252"
  const defaultBg = "rgba(255,255,255,0.4)"

  const queryParameters = new URLSearchParams(window.location.search)
  var time = queryParameters.get("time")
  if (!time)
    time = new Date().getTime() - 7 * 24 * 60 * 60 * 1000


  const getChats = (thema) => {

    var url = `http://192.168.0.199:4242/chat_messages/get_weekly_chats.json?time=${time / 1000}&lang=${strings.lang}`
    fetch(url,
    {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        }
    })
    .then((response) => response.json())
    .then(data => {

          setChats(data) 
          setLoading(false)
      })
    .catch(error => {alert('err ' + error.message); 
    setLoading(false) })
  } 

  useEffect(() => {

    let mounted = true;
    
    getChats()

    
 
    //
    //
    return () => mounted = false;
  }, [])


  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const renderChatItem = (chat) => {
    return (
      <ChatItem
              item={chat}
              id={chat.id}
              maxIndex={chats.length}
              isDarkMode={false}
             
          
              //onLongPress={(item) => {this.props.showMenuItem(item)}}
   
              onImgClicked={(item) => {setShowFullScreenPic(item)}}
           
              onPressUser={(user) => { this.props.navigation.navigate("SocialProfileScreen", { friend: user, getInfo: true }) }}
         />
    )
  }
  const renderChats = () => {

    return (<FlatList
          style={{flex:1}}
          data={chats}
        
          numColumns={1}
        
          renderItem={item => renderChatItem(item.item)}
          keyExtractor={(item, index) => item.id}
        />)


  }

  

  const renderImgFullScreen = () => {
    
    console.log("PIC FULLSCREEN", showFullScreenPic)
    var picSize = Dimensions.get('window').height - 100
    return (
      <TouchableOpacity style={[styles.containerFullScreen,{height: Dimensions.get('window').height}]} onPress={() => setShowFullScreenPic(false)}>


      <View style={{ height: picSize, width: picSize }}>
        <Image style={{borderRadius: 8, height: picSize, width: picSize}} source={{uri:  showFullScreenPic.img_url}} resizeMode="cover"
          />
        </View>
        <View style={{flexDirection: "row", justifyContent: "space-around", width: picSize}}>
        <TouchableOpacity
            onPress={() => setShowFullScreenPic(false)}
            style={{backgroundColor: "white", borderRadius: 40, padding: 12, marginTop: 12}} >
          <FontAwesomeIcon style={{color: selectedColor}} size={16} icon={ faArrowLeft } />
          </TouchableOpacity>
       



        </View>

      </TouchableOpacity>

      )
  }

  const renderLoading = () => {
    return (
      <View style={{width: "100%", justifyContent:"center", alignItems:"center", padding: 50}}>
        <Loader
          type="ThreeDots"
          color="#2ba5f7"
          height={100}
          width={100}
          // timeout={8000} //3 secs
        />
      </View>
    )
  }

  const renderDatePicker = () => {
/*
    return (

      <div>
      <DatePicker selected={new Date()} onChange={(date) => {
        window.location.href ="/chat?time=" + date.getTime();
      }} />
      </div>
    )*/
  }

  if (loading)
    return renderLoading()
    
  return(
 

    <ImageBackground urlOnServer={'/imgs/background_knitting2.jpg'} style={{flex: 1, flexDirection:'column', Width: Dimensions.get('window').width, minHeight: Dimensions.get('window').height - 70}}>

     <div className="container mt-5 rcpattern-container">  
     <h2>Chat</h2>
       <h5 className="mb-4 mt-3">Chat subtitle</h5>
 
       {renderDatePicker()}
       <View style={{backgroundColor:defaultBg, borderRadius: 12, borderWidth: 1, borderColor:"rgba(0,0,0,0.2)", marginVertical:24}}>
       
      
       <div className="row">

       {chats && renderChats()}  
       </div>

       
      </View>
      {renderDatePicker()}
      <View style={{backgroundColor:"rgba(0,130,218,0.4)", padding: 24, marginBottom: 24}}>
        <View style={{flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
          <Image style={{width: 36, height: 36, marginRight: 16}} urlOnServer={'../imgs/information.png'} />
          <View>
            <Text style={{color: "white", fontSize: 20, letterSpacing: 1, fontWeight: "bold"}}>{strings.forum.postInForum}</Text>
            <Text style={{color: "white", fontSize: 14}}>{strings.forum.downloadApp}</Text>
          </View>
        </View>

        <View style={{marginLeft: 16, marginTop: 12, flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
        <a target="_blank" href={"https://apps.apple.com/us/app/my-row-counter-knit-crochet/id1342608792"} class="pattern-link">
          <Image style={{width: 120, height: 40, marginRight: 16}} urlOnServer={'../imgs/download-on-the-app-store.svg'} />
        </a>
        <a target="_blank" href={"https://play.google.com/store/apps/details?id=com.row_counter"} class="pattern-link">
          <Image style={{width: 136, height: 60}} urlOnServer={'../imgs/google-play-badge.png'} />
        </a>
        
        </View>
      </View>
      </div>
{showFullScreenPic && renderImgFullScreen() }  
    </ImageBackground>
  );
}


const styles = StyleSheet.create({
  containerFullScreen: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    justifyContent: "center",
    alignItems: "center",
    flex: 0,
    elevation: 4,
    zIndex: 5000,
    backgroundColor:"rgba(0,0,0,0.9)",
    padding: 12
  },

});


