import React, { useState, useRef, useEffect, useCallback } from 'react';
import {Document, Page, pdfjs} from 'react-pdf/dist/umd/entry.webpack'
import "../config/styles.css"
import Highlighter from '../components/Highlighter'
import DrawingArea from '../components/DrawingArea'

const PDFDocument = (props) => {

  const [highlighterKey, setHighlighterKey] = useState(0);
  const [width, setWidth] = useState();
  const [height, setHeight] = useState(0);

  const elementRef = useRef(null);
  const currentPage = props.page;


  const observedDiv = useRef(null);

  const updateDimensions = () => {
    if(observedDiv.current && observedDiv.current.offsetWidth !== width) {
      setHighlighterKey(Math.random());
      setWidth(observedDiv.current.offsetWidth);
    }
    if(observedDiv.current && observedDiv.current.offsetHeight !== height) {
      setHighlighterKey(Math.random());
      setHeight(observedDiv.current.offsetHeight);
    }
  }

  useEffect(() => {
    if (!observedDiv.current) {
      // we do not initialize the observer unless the ref has
      // been assigned
      return;
    }

    // we also instantiate the resizeObserver and we pass
    // the event handler to the constructor
    const resizeObserver = new ResizeObserver(() => {
      updateDimensions();
      // if(observedDiv.current && observedDiv.current.offsetWidth !== width) {
      //   setHighlighterKey(Math.random());
      //   setWidth(observedDiv.current.offsetWidth);
      // }
      // if(observedDiv.current && observedDiv.current.offsetHeight !== height) {
      //   setHighlighterKey(Math.random());
      //   setHeight(observedDiv.current.offsetHeight);
      // }
    });

    // the code in useEffect will be executed when the component
    // has mounted, so we are certain observedDiv.current will contain
    // the div we want to observe
    resizeObserver.observe(observedDiv.current);


    // if useEffect returns a function, it is called right before the
    // component unmounts, so it is the right place to stop observing
    // the div
    return function cleanup() {
      resizeObserver.disconnect();
    }
  },
  // only update the effect if the ref element changed
  [observedDiv.current])


  const onLoadSuccess = ({ numPages }) => {
    props.numPages(numPages);
    setHighlighterKey(Math.random());
    updateDimensions();
  }


    return (

      <div style={{ position: 'relative' }}>
      <Document file={props.file}

      className="PDF"
      style={{height: "100%", width: "100%"}}
      onLoadSuccess={onLoadSuccess}
      options={{
          cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true,
        }}
        //onLoadSuccess={(pdf) => alert(JSON.stringify(pdf.numPages))}
       >
          <div ref={observedDiv} id={'pdfviewer'} className="PDFPage" style={{height: "100%", width: "100%"}}>
              <Page style={{zIndex:0}} size="A4" pageNumber={currentPage} scale={props.zoom}/>



          </div>


      </Document>

      <DrawingArea
       key={'drawingArea_' + currentPage + '_' + props.userProjectId}
       pageNb={currentPage}
       userProjectId={props.userProjectId}
       pdfHeight={height} pdfWidth={width}
       enabled={props.drawingEnabled}

      />

      <Highlighter
          highlighterHorizontalEnabled={props.highlighterHorizontalEnabled}
          highlighterVerticalEnabled={props.highlighterVerticalEnabled}
          key={'highlighter_' + highlighterKey}
          pdfHeight={height}
          pdfWidth={width}
        />

      </div>

      );

}


export default PDFDocument;
