import React, { Component } from "react";
import strings from "../config/strings";
import Image from "../components/Image"
import Utils from "../utils/utils";
import {SECONDARY_COLOR} from "../config/styles";
import { BrowserRouter, Route, Switch, useRouteMatch, Link, useParams, useHistory } from 'react-router-dom';


import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

/*
const CacheableImage = imageCacheHoc(Image, {
  validProtocols: ['http', 'https'],
  cachePruneTriggerLimit: 1024 * 1024 * 40
});
*/

export default class MyTextInput extends Component {
  constructor(props) {
    super(props);

  }

  render() {
  
    
      return (
        <View style={[this.props.containerStyle, {flexDirection:'row', alignItems:'center'}]}>
        {!this.props.disabled && (<TextInput
                ref={(input) => { this.textInput = input; }}
                {...this.props}
            />)}
        {this.props.disabled && (<Text
                style={this.props.style}

            >{this.props.value}</Text>)}
        {!this.props.disabled && (<TouchableOpacity onPress={() => {
            if (this.textInput) 
              this.textInput.focus();
          }}>
          <Image source={"/imgs/pencil.png"} style={{marginLeft: 8, width:15, height:15, alignSelf:'center'}}/>
        </TouchableOpacity>)}
        </View>
      )
  }
}

const styles = StyleSheet.create({

});
