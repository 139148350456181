import React, { Component } from "react";
import strings from "../config/strings";
import Image from "../components/Image"
import Utils from "../utils/utils";
import {SECONDARY_COLOR} from "../config/styles";
import { BrowserRouter, Route, Switch, useRouteMatch, Link, useParams, useHistory } from 'react-router-dom';


import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

const { width, height } = Dimensions.get("window");

export default class TitledTextInput extends Component {
  constructor(props) {
    super(props);

    
  }

  textChanged(value) {
    if (value == "")
      return
    if (!this.isInt(value)) {
      Alert.alert(strings.number_required)
      return
    }
    this.props.valueChanged(value)
  }


  isInt(value) {
  return !isNaN(value) && 
         parseInt(Number(value)) == value && 
         !isNaN(parseInt(value, 10));
}

  render() {



    return (

      <View style={{flexDirection: 'row', alignItems: "center"}}>
      
       <Image
            style={[
                  { height: 25, width: 40 }
            ]}
            resizeMode="contain"
            urlOnServer={this.props.img}
          />


      <Text style={styles.titleText}>{this.props.title}</Text>
      
      <View style={{width: 60, flexDirection: 'column', borderBottomWidth: 1, borderColor: "#CCC", alignContent: "center", paddingBottom:0}}>
      
      <TextInput  style={{padding:2, paddingBottom:0, color:'#888'}}
        keyboardType="numeric"
              
              onChangeText={text => this.textChanged(text)}
             returnKeyType="done"
             />
      </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  
  titleText: {
    color: "#555",
    fontSize: 15,
    marginRight: 6,
    marginLeft: 6,
  },

});
