import React, { Component } from "react";

import strings from "../../config/strings";

import { PRIMARY_COLOR, SECONDARY_COLOR } from "../../config/styles";
import Utils from "../../utils/utils";


import {
  ScrollView,
  View,
  TextInput,
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Keyboard,
  SafeAreaView,
  DeviceEventEmitter,
  PanResponder,
  BackHandler,
  Dimensions
} from 'react-native';

const MARGIN = 3



export const NORMAL_COLORS = [
{id:1, color:"#000"},
{id:2, color:"#888"},
{id:3, color:"#CCC"},
{id:4, color:"#FFFFFF"},
{id:5, color:"#800"},
{id:6, color:"#F00"},
{id:7, color:"#880"},
{id:8, color:"#FF0"},
{id:9, color:"#080"},
{id:10, color:"#0F0"},
{id:11, color:"#088"},
{id:12, color:"#0FF"},
{id:13, color:"#008"},
{id:14, color:"#00F"},
{id:15, color:"#808"},
{id:16, color:"#F0F"}

]

export default class NormalColorPicker extends Component {
  constructor(props) {
    super(props);

    for (var i = 0; i < NORMAL_COLORS.length; i++) {
        NORMAL_COLORS[i].selected = false
    }
    this.state = {colors: NORMAL_COLORS}
  }



  onColorSelected(color) {
    this.props.onSelected(color)


    let newColors = [...NORMAL_COLORS];
    for (var i = 0; i < newColors.length; i++) {
      if (newColors[i].color == color)
        newColors[i].selected = true;
      else
        newColors[i].selected = false
    }
    this.setState({colors: newColors})
  }

  renderColorItem(item, size) {

   

    var selected = this.props.selectedColor && this.props.selectedColor == item.color ? true : false
    const borderWidth = 5
    return (


      <TouchableOpacity style={{padding:MARGIN, width:size, height:size,
      justifyContent:'center', alignItems:'center', backgroundColor: item.selected || selected ? SECONDARY_COLOR : 'transparent', borderRadius:4}}
        onPress={() => {
         this.onColorSelected(item.color)
       }
       }>
  
          <View style={{width:size - borderWidth * 2, height:size - borderWidth * 2, backgroundColor:item.color, borderRadius: size / 2}}/>
         

        </TouchableOpacity>

      )
  }

  


  
  render() {

    const NUM_COLUMNS = 10
   
    var size = ((Dimensions.get("window").width - ((this.props.marginHorizontal - MARGIN) * 2)) / NUM_COLUMNS) ;


//alert(this.state.colors.length)

    return (
         <FlatList style={[this.props.style, {marginHorizontal: this.props.marginHorizontal - MARGIN}]}
          data={this.state.colors}
          extraData={this.state}
          numColumns={NUM_COLUMNS}
          keyExtractor={(item, index) => {
            return item.id.toString()
          }}
           renderItem={
            (item) => this.renderColorItem(item.item, size)
          } 
        />


      )
  }



 
}

const styles = StyleSheet.create({

  textInput: {

    borderColor: "#888",
    margin: 16,
    color: SECONDARY_COLOR,
    fontSize: 20,
    width: 100,
    fontWeight:'bold',
    backgroundColor: "transparent",
    textAlign: "center"
  },
 
  column: {
    flexDirection:'row'
  },

  txt: {

  }
});
