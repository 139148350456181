import React from 'react';
import useToken from '../../hooks/useToken';
import useUser from '../../hooks/useUser';
import { useState, useEffect, useRef } from 'react';
import { SERVER_API_URL, APP_NAME } from '../../config/Constants'
import fetchClient from "../../utils/http-common";

import { getSizesFromIds, getCatFromSize } from "../../config/sizes"
import PatternUtils from "../../generator/PatternUtils";
import { LANGS } from "../../generator/PatternUtils";
import Image from "../../components/Image";

import { useParams, useHistory, useLocation } from "react-router-dom";
import ContentList from "../../components/generator/ContentList"
import AlertModal from "../../components/generator/AlertModal";

import strings from "../../config/strings";
import Utils from '../../utils/utils';
import ProjectUtils from '../../utils/projectUtils'
import Dropdown from 'react-bootstrap/Dropdown'
import { getAllSizes } from "../../config/sizes"
import CheckBox from "../../components/CheckBox"
import ImageBackground from "../../components/ImageBackground"

import ManagePanel from "../../components/generator/ManagePanel";
import UploadPicture from "../../components/generator/UploadPicture";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

export default function EditPattern() {

  const location = useLocation();
  const history = useHistory();

  const [createdPattern, setCreatedPattern] = useState(location.state.pattern)
  const [showHelp, setShowHelp] = useState(true)
  const [list, setList] = useState(PatternUtils.constructSectionsAndParts(location.state.pattern))
  const [previewProject, setPreviewProject] = useState(location.state.previewProject)
  const [newPartName, setNewPartName] = useState()
  const [showNewPart, setShowNewPart] = useState(false)
  const [showNewContent, setShowNewContent] = useState(false)
  const [showAlertDelete, setShowAlertDelete] = useState(false)
  const [showAlertPart, setShowAlertPart] = useState(false)
  const [showAlertChart, setShowAlertChart] = useState(false)
  const [maxNumPart, setMaxNumPart] = useState()
  const [toDeleteItem, setToDeleteItem] = useState()
  const [toDeleteIndex, setToDeleteIndex] = useState()
  const [partToEdit, setPartToEdit] = useState(false)
  const [listNeedsUpdate, setListNeedsUpdate] = useState(false)
  const [additionalDeleteWarning, setAdditionalDeleteWarning] = useState("")
  const [allContentToDelete, setAllContentToDelete] = useState(false)

  const {token, setToken} = useToken();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const messagesEndRef = useRef(null)


  const getAllContentType = () => {
    return (
      [
        {
          pic: 'imgs/section_types/txt.png',
          type: "text",
          name: strings.text,
        },
        {
          pic: 'imgs/section_types/chart.png',
          type: "chart",
          name: "Chart",
        },
        {
          pic: 'imgs/section_types/img.png',
          type: "img",
          name: "Image",
        },
        {
          pic: 'imgs/section_types/video.png',
          type: "video",
          name: "Video",
        },
        // {
        //   pic: 'imgs/section_types/chart.png',
        //   type: "chart",
        //   name: "Chart",

        // },
      ]
    )
  }

  const updateContentList = (pattern, updateOnServer = true) => {
    console.log("updating content list")
    setList(PatternUtils.constructSectionsAndParts(pattern))
    if (updateOnServer) {
      console.log("updating content on server")
      PatternUtils.updatePatternOnServer(pattern, (response) => {
        setCreatedPattern(response)
      })
    }
  }

  useEffect(() => {

    console.log("in use effect update preview")
    // PatternUtils.getPreviewProject(location.state.pattern.id, setPreviewProject)
    let options = Object.assign({ method: "GET" }, null);
    const token = localStorage.getItem('token')
    options.headers = Utils.getJSONHeaders(token);
    fetchClient.get(`${SERVER_API_URL}/user_projects/get_project_from_pattern?pattern_id=${location.state.pattern.id}`, options)
    .then(response => {

      //console.log(response.data)
      setPreviewProject(response.data)

    })
    .catch(error => {

      alert('err c ' + error.message);
      console.log(error) })

  }, [list, showAlertDelete])

  // useEffect(() => {

  //   updateContentList(createdPattern, false)

  // }, [createdPattern])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }



  const copyItem = (item, index) => {
    var updatedPattern = {...createdPattern}

    if (item.type == "part") {
      var initialNumPart= item.numPart
      var newNumPart = item.numPart + 1
      var oldParts = updatedPattern.parts.split('±|±')
      oldParts.splice(item.numPart, 0, item.name)
      var updatedParts = oldParts.join('±|±')
      updatedPattern.parts = updatedParts
      var copiedSections = []
      var sections = updatedPattern.sections
      sections.forEach((section) => {
        if (section.numPart == initialNumPart) {
          var newSection = {...section}
          newSection.numPart = newNumPart
          newSection.uniqueIdOnClient = Math.random().toString(36).slice(2)
          newSection.id = 0
          newSection.updatedAtOnClient = Utils.getTime()
          if (newSection.subsections) {
            var newPatternSubsections = [...newSection.subsections]
            newPatternSubsections.forEach((subsection) => {
              subsection.id = 0
              subsection.uniqueIdOnClient = Math.random().toString(36).slice(2)
              subsection.updatedAtOnClient = Utils.getTime()
              if (subsection.size_texts) {
                subsection.size_texts.forEach((size) => {
                  size.id = 0
                  size.uniqueIdOnClient = Math.random().toString(36).slice(2)
                })
              }
            })
            newSection.subsections = newPatternSubsections
            newSection.subsections = newPatternSubsections
          }
          copiedSections.push(newSection)
        } else if (section.numPart > initialNumPart) {
          section.numPart += 1
          section.updatedAtOnClient = Utils.getTime()
        }
      })

      sections.push(...copiedSections)
      updatedPattern.sections = sections
      updatedPattern.sections = sections

      updateContentList(updatedPattern)


    } else {
      var sections = updatedPattern.sections
      var maxPosition = 0
			for (var i = 0; i < sections.length; i++) {
				if (sections[i].numPart == item.section.numPart && sections[i].deleted != 1 && sections[i].position > maxPosition)
					maxPosition = sections[i].position
			}
      var newItem = {...item}
      var newItemSection = {...newItem.section}
      newItemSection.position = maxPosition + 1
      newItemSection.id = 0
      newItemSection.uniqueIdOnClient = Math.random().toString(36).slice(2)

      if (newItemSection.subsections) {
        newItemSection.subsections.forEach((subsection) => {
          subsection.id = 0
          subsection.uniqueIdOnClient = Math.random().toString(36).slice(2)
          if (subsection.size_texts) {
            subsection.size_texts.forEach((size) => {
              size.id = 0
              size.uniqueIdOnClient = Math.random().toString(36).slice(2)
            })
          }
        })
      }

      newItem.section = newItemSection
      newItem.section.subsections = newItem.section.subsections

      sections.push(newItem.section)
      updatedPattern.sections = sections
      updatedPattern.sections = sections

      updateContentList(updatedPattern)

    }
  }

  const handleDelete = (item, index, allContent) => {
    if (allContent) {
      setAdditionalDeleteWarning("All content from this part will be deleted as well.")
      setAllContentToDelete(allContent)
    }
    setToDeleteItem(item)
    setToDeleteIndex(index)
    setShowAlertDelete(true)

    window.scrollTo(0, 0)

  }
  const deleteOneItem = (item, updatedPattern) => {

    if (item.type == "part") {
      var toDeleteNumPart = item.numPart
      var oldParts = updatedPattern.parts.split('±|±')
      oldParts.splice(item.numPart,1)
      var updatedParts = oldParts.join('±|±')
      updatedPattern.parts = updatedParts
      console.log("### updatedPattern.parts", updatedPattern.parts)

      if (updatedPattern.sections[0]) {
        var sections = [...updatedPattern.sections]
        sections.forEach((section) => {
          if (section.numPart == toDeleteNumPart) {
            section.deleted = 1
            section.updatedAtOnClient = Utils.getTime()
          } else if (section.numPart > toDeleteNumPart) {
            section.numPart -= 1
            section.updatedAtOnClient = Utils.getTime()
          }
        })
        updatedPattern.sections = sections
        updatedPattern.sections = sections
      }
      var updatedPreviewProject = {...previewProject}
      if(updatedPreviewProject.counters.length > 0) {

        var counters = updatedPreviewProject.counters
        counters.forEach((counter) => {
          if (counter.num_part == toDeleteNumPart) {
            counter.deleted = 1
            counter.updatedAtOnClient = Utils.getTime()
          } else if (counter.num_part > toDeleteNumPart) {
            counter.num_part -= 1
            counter.updatedAtOnClient = Utils.getTime()
          }
        })
        updatedPreviewProject.counters = counters
        ProjectUtils.sendUpdateToServer(updatedPreviewProject, (response) => {
          console.log("### response updatedPreviewProject", response)
          setPreviewProject(response)
        })
      }

    } else {
      var sections = updatedPattern.sections
      var objIndex = sections.findIndex(section => section.id == item.section.id)
      sections[objIndex].deleted = 1
      updatedPattern.sections = sections
      updatedPattern.sections = sections



    }
    //updateContentList(updatedPattern)

    return updatedPattern
  }
  const deleteItem = (item, allContent) => {
    console.log("deleting item item", item)
    console.log("deleting item allContent", allContent)
    var updatedPattern = { ...createdPattern }
    updatedPattern = deleteOneItem(item, updatedPattern)
    if (allContent) {
      allContent.forEach((content) => {
        updatedPattern = deleteOneItem(content, updatedPattern)
      })
      setAdditionalDeleteWarning("")
      setAllContentToDelete(false)

    }
    PatternUtils.updatePatternOnServer(updatedPattern, (response) => {
      setCreatedPattern(response)
      updateContentList(response)
      console.log("### response updatedPattern", response.parts)
    })
    setShowAlertDelete(false)
    return


  }

  const getMaxPosition = (numPart) => {
    var sections = createdPattern.sections

    var allSectionsFromPart = sections.filter((section) => {
      return section.numPart == numPart
    })

    var maxPosition = -1
    for (var i = 0; i < allSectionsFromPart.length; i++) {
      if (allSectionsFromPart[i].deleted != 1 && allSectionsFromPart[i].position > maxPosition)
        maxPosition = allSectionsFromPart[i].position
    }

    return maxPosition
  }

  const moveItem = (item, direction) => {
    var updatedPattern = createdPattern

    if (item.type == "part") {


      var oldParts = updatedPattern.parts.split('±|±')
      oldParts.splice(item.numPart,1)
      oldParts.splice(item.numPart + direction,0, item.name)
      var updatedParts = oldParts.join('±|±')
      updatedPattern.parts = updatedParts

      var sections = updatedPattern.sections

      sections.forEach((section) => {
        if (section.numPart == item.numPart) {
          section.numPart = section.numPart + direction
        } else if (section.numPart == item.numPart + direction) {
          section.numPart = section.numPart - direction
        }
      })

      updatedPattern.sections = sections
      updatedPattern.sections = sections

      updateContentList(updatedPattern)

    } else {
      var sections = updatedPattern.sections

      var objIndex = sections.findIndex(section => section.uniqueIdOnClient == item.section.uniqueIdOnClient)

      var maxPosition = getMaxPosition(sections[objIndex].numPart )

      if (sections[objIndex].position == maxPosition && direction == 1) {

          var newNumPart = sections[objIndex].numPart + 1
          sections.forEach((section) => {
            if (section.numPart == newNumPart) {
              section.position += 1

            }
          })
          sections[objIndex].position = 0
          sections[objIndex].numPart = newNumPart
          sections[objIndex].updatedAtOnClient = Utils.getTime()
      } else if (sections[objIndex].position == 0 && direction == -1) {

        var newNumPart = sections[objIndex].numPart -1
        var NewNumPartmaxPosition = getMaxPosition(newNumPart )
        sections[objIndex].position = NewNumPartmaxPosition + 1
        sections[objIndex].numPart = newNumPart
        sections[objIndex].updatedAtOnClient = Utils.getTime()
        sections.forEach((section) => {
          if (section.numPart == newNumPart +1) {
            section.position = section.position - 1
          }
        })
      } else {

        var oldPosition = sections[objIndex].position
        var currentPart = sections[objIndex].numPart
        var newPosition = oldPosition + direction
        var toUpdateIndex = sections.findIndex(section => section.position == newPosition && section.numPart == currentPart)
        sections[toUpdateIndex].position = oldPosition
        sections[toUpdateIndex].updatedAtOnClient = Utils.getTime()
        sections[objIndex].position = newPosition
        sections[objIndex].updatedAtOnClient = Utils.getTime()

      }

      updatedPattern.sections = sections
      updatedPattern.sections = sections

      updateContentList(updatedPattern)


    }
  }



  const saveNewPart = () => {

    var updatedPattern = {...createdPattern}
    if (createdPattern.parts && createdPattern.parts != "" ) {
      updatedPattern.parts = createdPattern.parts + '±|±' + newPartName
      ProjectUtils.createNewPart(previewProject);
    } else {
      updatedPattern.parts = newPartName
    }
    updateContentList(updatedPattern)

    setShowNewPart(!showNewPart)
    scrollToBottom()
    setNewPartName("")

  }

  const saveUpdatedPart = () => {
    var updatedPattern = {...createdPattern}
    var oldParts = updatedPattern.parts.split('±|±')
    oldParts[partToEdit.numPart] = partToEdit.name
    updatedPattern.parts = oldParts.join('±|±')

    updateContentList(updatedPattern)


    setPartToEdit(false)
    setNewPartName("")

  }

  const onBack = () => {
    history.push({
      pathname: `/created-pattern-details/${createdPattern.id}`,
      state: { pattern: createdPattern }
    })
  }

  const onAddPart = () => {
    setShowHelp(false)
    setShowNewPart(!showNewPart)
  }

  const onAddContent = () => {
    if (!list[0]) {
      setShowAlertPart(true)
    } else {
      setShowHelp(false)
      setShowNewContent(!showNewContent)
    }
  }

  const editPart = (item) => {
    setPartToEdit(item)
    window.scrollTo(0, 0)
  }

  const goToCreateSection = (type) => {

    var numPart = createdPattern.parts == '' ? -1 : createdPattern.parts.split('±|±').length - 1
    var maxPosition = -1
    for (var i = 0; i < createdPattern.sections.length; i++) {
      if (createdPattern.sections[i].deleted != 1 && createdPattern.sections[i].numPart == numPart && createdPattern.sections[i].position > maxPosition)
        maxPosition = createdPattern.sections[i].position
    }
    maxPosition++
    var name = ''
    var pathname = `/create-section/`

    if (type == "img" ||type == "video") {
      name = type == "video" ? "Video" : "Image"
      pathname = `/create-image-section/`
    }

    var section = PatternUtils.createSection(createdPattern, name, type, numPart, maxPosition)



    if (type === 'chart') {
      var subsection = PatternUtils.createSubsection(section, section.name, null, null, null, -1);
      subsection.sizeIds = createdPattern.sizeIds
      subsection.position = -1

      section.subsections.push(subsection)
      createdPattern.sections.push(section)


      PatternUtils.updatePatternOnServer(createdPattern, (response) => {
        setListNeedsUpdate(true)
        setCreatedPattern(response)
      history.push({
        pathname: '/edit-chart',
        state: {
          patternId: createdPattern.id,
          sectionUniqueId:section.uniqueIdOnClient,
          subsectionUniqueId: subsection.uniqueIdOnClient,
         }
      })

    })


    }
    else {
      history.push({
        pathname: pathname,
        state: { pattern: createdPattern, type: type, section:section }
      })
    }
  }


  const renderAddPart = () => {
      return (
        <button className="AddElementBtn" onClick={() => onAddPart()}>
            {strings.add_part}
        </button>
      )
  }

  const renderGoBack = () => {
    return (
      <TouchableOpacity
      style={{justifyContent: "center", alignItems: "flex-start", width: 32, marginBottom: 8}}
      onPress={() => onBack()}>
        <Image style={{width: 24, height: 24, margin: 4}} urlOnServer={"/imgs/back.png"}/>
        <Text style={{color: "rgba(255,255,255,0.8)", textAlign: "center", fontSize: 10}}>{strings.back}</Text>
      </TouchableOpacity>
    )
}

  const renderAddContent = () => {
    return (
      <button className="AddElementBtn" onClick={() => onAddContent()}>
          {strings.patternCreator.addContent}
      </button>
    )
}

  const renderHelp = () => {
    return (
      <View>
        <Text style={{color: "rgba(255,255,255,0.8)", textAlign: "left", marginTop: 12}}>
        {strings.patternCreator.helpOne}
        </Text>
        <View>
          <View
          style={styles.helpContainer} >
            <Image style={styles.iconHelp} urlOnServer={"/imgs/yarn.png"} />
            <Text style={styles.helpText}>
              {strings.patternCreator.helpTwo}
            </Text>
          </View>
          <View
          style={styles.helpContainer} >
            <Image style={styles.iconHelp} urlOnServer={"/imgs/yarn.png"} />
            <Text style={styles.helpText}>
              {strings.patternCreator.helpThree}
            </Text>
          </View>
          <View
          style={styles.helpContainer} >
            <Image style={styles.iconHelp} urlOnServer={"/imgs/yarn.png"} />
            <Text style={styles.helpText}>
               {strings.patternCreator.helpfour}
            </Text>
          </View>
        </View>
      </View>
    )
  }

  const renderNewPart = () => {
    if (partToEdit) {
      return (
      <View>
            <Text style={[styles.title, {marginTop:16}]}>{strings.patternCreator.editPartName}</Text>
            <View style={{flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
              <TextInput
                      containerStyle={{height: 60,flex: 1}}
                      style={[styles.input, {height: 40, borderRadius: 20, width: 400 }]}
                      multiline={false}
                      value={partToEdit.name}
                      onChangeText={(newName) => {
                        var updatedPart = {...partToEdit}
                        updatedPart.name = newName
                        setPartToEdit(updatedPart)
                      }}
                      returnKeyType="done"
              />
              <TouchableOpacity
                onPress={() => saveUpdatedPart()}
                style={{marginLeft: 24, marginTop: 12, width: 60}}>
              <Text style={{fontWeight: "bold", color: "#FECB2E"}}>{ strings.edit }</Text>
            </TouchableOpacity>
          </View>

      </View>
    )
    } if (showNewPart) {
      return (
        <View>
              <Text style={[styles.title, {marginTop:16}]}>{strings.patternCreator.givePartName}</Text>
              <View style={{flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                <TextInput
                        containerStyle={{height: 60,flex: 1}}
                        style={[styles.input, {height: 40, borderRadius: 20, width: 400 }]}
                        multiline={false}
                        value={newPartName}
                        onChangeText={(newPartName) => setNewPartName(newPartName)}
                        returnKeyType="done"
                />
                <TouchableOpacity
                  onPress={() => saveNewPart()}
                  style={{marginLeft: 24, marginTop: 12, width: 60}}>
                <Text style={{fontWeight: "bold", color: "#FECB2E"}}>{ strings.add }</Text>
              </TouchableOpacity>
            </View>

        </View>
      )
    }
  }

  const renderChooseContentType = () => {
    const allTypes = getAllContentType()
    return (
      <View style= {{backgroundColor: "#2D7BAE", width: "100%", height: 300, borderRadius: 12, paddingVertical: 12, paddingHorizontal: 42, justifyContent: "center"}}>
        <View style={{justifyContent: "center", alignItems: "center", flexDirection: "row"}}>
          <p className="DetailTitle">{strings.patternCreator.addContentSection}</p>
        </View>
        <View style={{flexDirection: "row", justifyContent: "space-around", alignItems: "center"}} >
        <Text style={{color: "rgba(255,255,255,0.8)"}}>{strings.patternCreator.chooseContentType}</Text>

        {
          allTypes.map((type) => {
            return (
              <TouchableOpacity
              onPress={() => goToCreateSection(type.type)}
              style={{justifyContent: "center", alignItems: "center"}} >
                <View style={{
                  padding: 24,
                  margin: 12,
                  backgroundColor: "#EFDDA6",
                  borderRadius: "50%"
                }}>
                  <Image style={{width: 30, height: 30}} urlOnServer={PatternUtils.getSectionFromSectionType(type.type).img}/>
                </View>
                <Text style={{color: "rgba(255,255,255,0.8)"}}>{type.name}</Text>
              </TouchableOpacity>
            )
          })
        }
        </View>

      </View>
    )
  }

  const renderAlertDelete = () => {
    console.log("render alert delete")
    return (
      <AlertModal
      title={strings.patternCreator.alertDeleteContentTitle}
      text={strings.patternCreator.alertDeleteContentText + " " + additionalDeleteWarning}
      onOk={() => deleteItem(toDeleteItem, allContentToDelete)}
      onDelete={() => {
        setShowAlertDelete(false)
        setAdditionalDeleteWarning("")
        setAllContentToDelete(false)
      }
      }
      />
    )

  }

  const renderAlertPart = () => {
    return (
      <AlertModal
      title={strings.patternCreator.alertAddPartTitle}
      text={strings.patternCreator.alertAddPartText}
      onOk={() => setShowAlertPart(false)}
      />
    )

  }

  const renderAlertChart = () => {
    return (
      <AlertModal
      title={strings.patternCreator.alertChartTitle}
      text={strings.patternCreator.alertChartText}
      onOk={() => setShowAlertChart(false)}
      />
    )
  }


  const renderList = () => {
    return (
      <ContentList
      list={list}
      createdPattern={createdPattern}
      copyItem= {copyItem}
      deleteItem={handleDelete}
      editPart={editPart}
      moveItem={moveItem}
      getMaxPosition={getMaxPosition}
      setShowAlertChart={setShowAlertChart}
      />
    )
  }

  return(


    <View style={{flexDirection:'column', backgroundColor: "#266D9C", width: "100%", minHeight: height - 72}}>

     <div className="container mt-5 rcpattern-container ">
      <View style={{
        width: "100%",
        flexDirection: "row",
      }}>
      {renderGoBack()}
      <h5 className="text-white" style={{width: "100%"}}>{strings.patternCreator.patternContent}</h5>
      </View>

     {showAlertDelete && renderAlertDelete()}
     {showAlertChart && renderAlertChart()}
     {showAlertPart && renderAlertPart()}

       <div className=" mb-5" >
       {showHelp && !list && renderHelp()}
       <View
        style={{flexDirection: "row", justifyContent: "space-around", alignItems: "center"}}>
          {renderAddPart()}
          {renderAddContent()}
        </View>
        <View style={{justifyContent: "center", alignItems: "center"}}>
        {( showNewPart || partToEdit ) && renderNewPart()}
        {showNewContent && renderChooseContentType()}
        </View>
        {renderList()}
       </div>
       <div ref={messagesEndRef} />
    </div>

    </View>
  );
}

const styles = StyleSheet.create({
  helpText: {
    color: "rgba(255,255,255,0.8)",
    textAlign: "center",
    alignSelf: "center",
    fontSize: 16,
    letterSpacing: 1,
  },
  title: {
    color: "rgba(255,255,255,0.8)",
    textAlign: "left",
    selfAlign: "flex-start",
    flex: 1,
    width: 400,
  },
  input: {
    marginTop: 12,
    backgroundColor: "rgba(255,255,255,0.15)" ,
    color: "white",
    paddingLeft: 16,
    textAlign: "left"
  },
  iconHelp: {
    width: 24,
    height: 24,
    marginRight: 12
  },
  helpContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginVertical: 24
  },
});
