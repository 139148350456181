export const YARN_WEIGHT_RAVELRY = [

  {ravelryId:9, value:"Thread"},
  {ravelryId:8, value:"Cobweb / 1 ply"},
  {ravelryId:7, value:"Lace / 2 ply"},
  {ravelryId:13, value:"Light Fingering / 3 ply"},
  {ravelryId:5, value:"Fingering (14 wpi) / 4 ply"},
  {ravelryId:10, value:"Sport (12 wpi) / 5 ply"},
  {ravelryId:11, value:"DK (11 wpi) / 8 ply"},
  {ravelryId:12, value:"Worsted (9 wpi) / 10 ply"},
  {ravelryId:1, value:"Aran (8 wpi) / 10 ply"},
  {ravelryId:4, value:"Bulky (7 wpi) / 12 ply"},
  {ravelryId:6, value:"Super Bulky (5-6 wpi)"},
  {ravelryId:16, value:"Jumbo (0-4 wpi)"},
  
  ]
  
  
  
  export const COLORS_RAVELRY = [
    {
      selected: false,
      value:"#000",
      ravelryId: 13
    },
    {
      selected: false,
      value:"blue",
      ravelryId: 9
    },
    {
      selected: false,
      value:"#0D98BA",
      ravelryId: 10
    },
  
     {
      selected: false,
      value:"#663399",
      ravelryId: 8
    },
    {
      selected: false,
      value:"#964B00",
      ravelryId: 16
    },
   
    {
      selected: false,
      value:"grey",
      ravelryId: 15
    },
    {
      selected: false,
      value:"green",
      ravelryId: 11
    },
    {
      selected: false,
      value:"multicolored",
      ravelryId: 19
    },
    {
      selected: false,
      value:"undyed",
      ravelryId: 18
    },
    {
      selected: false,
      value:"orange",
      ravelryId: 3
    },
    {
      selected: false,
      value:"pink",
      ravelryId: 17
    },
    {
      selected: false,
      value:"#800080",
      ravelryId: 7
    },
    {
      selected: false,
      value:"rainbow",
      ravelryId: 20
    },
    {
      selected: false,
      value:"#ff5349",
      ravelryId: 4
    },
    {
      selected: false,
      value:"#F75394",
      ravelryId: 6
    },
    {
      selected: false,
      value:"#fff",
      ravelryId: 14
    },
    {
      selected: false,
      value:"yellow",
      ravelryId: 1
    },
    {
      selected: false,
      value:"#9ACD32",
      ravelryId: 12
    },
    {
      selected: false,
      value:"#ffae42",
      ravelryId: 2
    },
  
  ]
  