

export const SYMBOLS_FR = [

  {
      "id": 0,
      "rs": "Rien",
      "ws": "Rien",
      "rs_long": "Rien",
      "ws_long": "Rien",
      "img": "/imgs/symbols/0_null.png",
      "t_img": "/imgs/symbols/transparent/0_null.png",
  },
  {
      "id": 1,
      "rs": "end",
      "ws": "env",
      "rs_long": "Endroit",
      "ws_long": "Envers",
      "img": "/imgs/symbols/1_k_p.png",
      "t_img": "/imgs/symbols/transparent/1_k_p.png"
  },
  
  {
      "id": 2,
      "rs": "end",
      "ws": "env",
      "rs_long": "Envers",
      "ws_long": "Endroit",
      "img": "/imgs/symbols/2_p_k.png",
      "t_img": "/imgs/symbols/transparent/2_p_k.png"
  },
  
  {
      "id": 3,
      "rs": "Jeté",
      "ws": "Jeté",
      "rs_long": "Jeté",
      "ws_long": "Jeté",
     // "img": "/imgs/symbols/3_yo.png",
      "t_img": "/imgs/symbols/transparent/3_yo.png"
  },
  
  {
      "id": 4,
      "rs": "2m ens end",
      "ws": "2m ens env",
      "rs_long": "2 mailles endroit ensemble",
      "ws_long": "2 mailles envers ensemble",
     // "img": "/imgs/symbols/4_k2tog_p2tog.png",
      "t_img": "/imgs/symbols/transparent/4_k2tog_p2tog.png"
  },
  
  {
      "id": 5,
      "rs": "2m ens env",
      "ws": "2m ens end",
      "rs_long": "2 mailles envers ensemble",
      "ws_long": "2 mailles endroit ensemble",
      //"img": "/imgs/symbols/5_p2tog_k2tog.png",
      "t_img": "/imgs/symbols/transparent/5_p2tog_k2tog.png"
  },
  
  {
      "id": 6,
      "rs": "glisser 2, end",
      "ws": "glisser 2, env",
      "rs_long": "glisser 2 mailles, tricoter 1 maille endroit",
      "ws_long": "glisser 2 mailles, tricoter 1 maille envers",
     // "img": "/imgs/symbols/6_SSK_SSP.png",
      "t_img": "/imgs/symbols/transparent/6_SSK_SSP.png"
  },
  
  {
      "id": 7,
      "rs": "glisser 2, env",
      "ws": "glisser 2, end",
      "rs_long": "glisser 2 mailles, tricoter 1 maille envers",
      "ws_long": "glisser 2 mailles, tricoter 1 maille endroit",
      //"img": "/imgs/symbols/7_SSP_SSK.png",
      "t_img": "/imgs/symbols/transparent/7_SSP_SSK.png",
  },
  
  {
      "id": 8,
      "rs": "end. brin avant & arrière",
      "ws": "env. brin avant & arrière",
      "rs_long": "Tricoter à l'endroit le brin avant et le brin arrière de la maille",
      "ws_long": "Tricoter à l'envers le brin avant et le brin arrière de la maille",
     // "img": "/imgs/symbols/8_K1fb_p1fb.png",
      "t_img": "/imgs/symbols/transparent/8_K1fb_p1fb.png",
  },
  
  {
      "id": 9,
      "rs": "Augm 1 end",
      "ws": "Augm 1 env",
      "rs_long": "Augmenter, créer une maille à l'endroid",
      "ws_long": "Augmenter, créer une maille à l'envers",
    //  "img": "/imgs/symbols/9_m1.png",
      "t_img": "/imgs/symbols/transparent/9_m1.png"
  },
  
  {
      "id": 10,
      "rs": "Augm 1 env",
      "ws": "Augm 1 end",
      "rs_long": "Augmenter, créer une maille à l'envers",
      "ws_long": "Augmenter, créer une maille à l'endroit",
    //  "img": "/imgs/symbols/10_m1.png",
      "t_img": "/imgs/symbols/transparent/10_m1.png"
  },
  
  {
      "id": 11,
      "rs": "Augm 1 end sur le bord droit",
      "ws": "Augm 1 env sur le bord droit",
      "rs_long": "Augmenter, créer une maille à l'endroid sur le bord droit",
      "ws_long": "Augmenter, créer une maille à l'envers sur le bord droit",
    //  "img": "/imgs/symbols/11_Rslanting.png",
      "t_img": "/imgs/symbols/transparent/11_Rslanting.png"
  },
  
  {
      "id": 12,
      "rs": "Augm 1 end sur le bord gauche",
      "ws": "Augm 1 env sur le bord gauche",
      "rs_long": "Augmenter, créer une maille à l'endroid sur le bord gauche",
      "ws_long": "Augmenter, créer une maille à l'envers sur le bord gauche",
     // "img": "/imgs/symbols/12_Lslanting.png",
      "t_img": "/imgs/symbols/transparent/12_Lslanting.png"
  },
  
  {
      "id": 13,
      "rs": "Augm. interc. droite",
      "ws": "Augm. interc. droite",
      "rs_long": "Augmentaion intercalaire à droite",
      "ws_long": "Augmentation intercalaire à droite",
     // "img": "/imgs/symbols/13_RslantingLift.png",
      "t_img": "/imgs/symbols/transparent/13_RslantingLift.png"
  },
  
  
  {
      "id": 14,
      "rs": "Augm. interc. gauche",
      "ws": "Augm. interc. gauche",
      "rs_long": "Augmentaion intercalaire à gauche",
      "ws_long": "Augmentation intercalaire à gauche",
      "t_img": "/imgs/symbols/transparent/14_LslantingLift.png"
  },
  
  {
      "id": 15,
      "rs": "Glisser 1 fil derrière",
      "ws": "Glisser 1 fil devant",
      "rs_long": "Glisser 1 maille avec le fil derrière",
      "ws_long": "Glisser 1 maille avec le fil devant",
      "t_img": "/imgs/symbols/transparent/15_sl1purl.png"
  },
  {
      "id": 16,
      "rs": "Glisser 1 fil devant",
      "ws": "Glisser 1 fil derrière",
      "rs_long": "Glisser 1 maille avec le fil devant",
      "ws_long": "Glisser 1 maille avec le fil derrière",
      "t_img": "/imgs/symbols/transparent/16_sl1purl.png"
  },
  
  
  {
      "id": 17,
      "rs": "3 mailles ens. end.",
      "ws": "3 mailles ens. env.",
      "rs_long": "Tricoter 3 mailles ensemble à l'endroit",
      "ws_long": "Tricoter 3 mailles ensemble à l'envers",
      "t_img": "/imgs/symbols/transparent/17_K3tog_P3tog.png"
  },
  
  {
      "id": 18,
      "rs": "3 mailles ens. env.",
      "ws": "3 mailles ens. end.",
      "rs_long": "Tricoter 3 mailles ensemble à l'envers",
      "ws_long": "Tricoter 3 mailles ensemble à l'endroit",
      "t_img": "/imgs/symbols/transparent/18_P3tog_k3tog.png"
  },
  
  
  {
      "id": 19,
      "rs": "1 glissé, 2 ens., passer m. glissée au dessus",
      "ws": "3 glissées, 1 env.",
      "rs_long": "1 maille glissée, 2 mailles tricotées ensemble , passer la maille glissée par dessus la maille tricotée",
      "ws_long": "3 mailles glissées, 1 maille env.",
      "t_img": "/imgs/symbols/transparent/19_SK2P_SSSK_SSSP.png"
  },
  
  
  {
      "id": 20,
      "rs": "3 glissées, 1 env.",
      "ws": "3 glissées, 1 end.",
      "rs_long": "3 mailles glissées, 1 maille env.",
      "ws_long": "3 mailles glissées, 1 maille end.",
      "t_img": "/imgs/symbols/transparent/20_SSSP_SSSK.png"
  },
  
  
  {
      "id": 21,
      "rs": "glisser 2, 1 m end., pass 2",
      "ws": "Glisser 2, 1 m env. 2m. env. ens.",
      "rs_long": "2 mailles glissées, 1 maille end., passer 2 mailles",
      "ws_long": "2 mailles glissées, 1 maille env., 2 mailles ensemble à l'envers",
      "t_img": "/imgs/symbols/transparent/21_S2KP2_SSPP2.png"
  },
  
  {
      "id": 22,
      "rs": "1 m. end. brin de derrière",
      "ws": "1 m. env. brin de derrière",
      "rs_long": "1 maille à l'endroit par le brin de derrière",
      "ws_long": "1 maille à l'endroit par le brin de derrière",
      "t_img": "/imgs/symbols/transparent/22_K1_tbl_p1_tbl.png"
  },
  
  {
      "id": 23,
      "rs": "1 m. env. brin de derrière",
      "ws": "1 m. end. brin de derrière",
      "rs_long": "1 maille à l'envers par le brin de derrière",
      "ws_long": "1 maille à l'endroit par le brin de derrière",
      "t_img": "/imgs/symbols/transparent/23_P1_tbl_k1_tbl.png"
  },
  
  
  {
      "id": 24,
      "rs": "Boule",
      "ws": "Boule",
      "rs_long": "Boule",
      "ws_long": "Boule",
      "t_img": "/imgs/symbols/transparent/24_Bobble.png"
  },
  
  {
      "id": 25,
      "rs": "Augm. 1 à 3",
      "ws": "Augm. 1 à 3",
      "rs_long": "Augmenter 1 à 3",
      "ws_long": "Augmenter 1 à 3",
      "t_img": "/imgs/symbols/transparent/25_Inc1-3.png"
  },
  
  {
      "id": 26,
      "rs": "Augm. 1 à 4",
      "ws": "Augm. 1 à 4",
      "rs_long": "Augmenter 1 à 4",
      "ws_long": "Augmenter 1 à 4",
      "t_img": "/imgs/symbols/transparent/26_Inc1-4.png"
  },
  
  {
      "id": 27,
      "rs": "Augm. 1 à 5",
      "ws": "Augm. 1 à 5",
      "rs_long": "Augmenter 1 à 5",
      "ws_long": "Augmenter 1 à 5",
      "t_img": "/imgs/symbols/transparent/27_Inc1-5.png"
  },
  
  {
      "id": 28,
      "rs": "Dim. 4 à 1 (bord droit)",
      "ws": "Dim. 4 à 1 (bord droit)",
      "rs_long": "Diminuer 4 à 1 (bord droit)",
      "ws_long": "Diminuer 4 à 1 (bord droit)",
      "t_img": "/imgs/symbols/transparent/28_Dec4-1_right_slanting.png"
  },
  
  {
      "id": 29,
      "rs": "Dim. 4 à 1 (bord gauche)",
      "ws": "Dim. 4 à 1 (bord gauche)",
      "rs_long": "Diminuer 4 à 1 (bord gauche)",
      "ws_long": "Diminuer 4 à 1 (bord gauche)",
      "t_img": "/imgs/symbols/transparent/29_Dec4-1_left_slanting.png"
  },
  
  {
      "id": 30,
      "rs": "Dim. 4 à 1 (centre)",
      "ws": "Dim. 4 à 1 (centre)",
      "rs_long": "Diminuer 4 à 1 (centre)",
      "ws_long": "Diminuer 4 à 1 (centre)",
      "t_img": "/imgs/symbols/transparent/30_Dec4-1_center.png"
  },
  
  {
      "id": 31,
      "rs": "Dim. 5 à 1",
      "ws": "Dim. 5 à 1",
      "rs_long": "Diminuer 5 à 1",
      "ws_long": "Diminuer 5 à 1",
      "t_img": "/imgs/symbols/transparent/31_Dec5-1.png"
  },
  
  
  {
      "id": 32,
      "rs": "1 m end., enroulant 2 fois autour aiguille",
      "ws": "1 m end., enroulant 2 fois autour aiguille",
      "rs_long": "1 maille endroit, enroulant 2 fois le fil autour de l'aiguille",
      "ws_long": "1 maille endroit, enroulant 2 fois le fil autour de l'aiguille",
      "t_img": "/imgs/symbols/transparent/32_K1_wrapping_twice.png"
  },
  
  {
      "id": 33,
      "rs": "Rabattre",
      "ws": "Rabattre",
      "rs_long": "Rabattre",
      "ws_long": "Rabattre",
      "t_img": "/imgs/symbols/transparent/33_Bind_off.png"
  },
  
  {
      "id": 34,
      "rs": "m. reste sur aigu. droite après avoir rabattu dernière m.",
      "ws": "m. reste sur aigu. droite après avoir rabattu dernière m.",
      "rs_long": "maille reste sur aiguille droite après avoir rabattu dernière maille",
      "ws_long": "maille reste sur aiguille droite après avoir rabattu dernière maille",
      "t_img": "/imgs/symbols/transparent/34_Strem.png"
  },
  
  {
      "id": 35,
      "rs": "Monter 1m",
      "ws": "Monter 1m",
      "rs_long": "Monter 1 maille",
      "ws_long": "Monter 1 maille",
      "t_img": "/imgs/symbols/transparent/35_Cast_on.png"
  },
  
  {
      "id": 36,
      "rs": "croiser à droite",
      "ws": "",
      "rs_long": "croiser 2 mailles à droite",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/36_2st_RC.png",
      "width":2,
      "height":1
  },
  {
      "id": 37,
      "rs": "croiser à gauche",
      "ws": "",
      "rs_long": "croiser 2 mailles à gauche",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/37_2st_LC.png",
      "width":2,
      "height":1
  },
  {
      "id": 38,
      "rs": "croiser à droite, 2e m. env.",
      "ws": "",
      "rs_long": "croiser 2 mailles à droite, 2e maille env.",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/38_2stRPC.png",
      "width":2,
      "height":1
  },
  {
      "id": 39,
      "rs": "croiser à gauche, 2e m. env.",
      "ws": "",
      "rs_long": "croiser 2 mailles à gauche, 2e maille env.",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/39_2stLPC.png",
      "width":2,
      "height":1
  },
  {
      "id": 40,
      "rs": "croiser à droite (3), 1e m. env.",
      "ws": "",
      "rs_long": "croiser 3 mailles à droite, 1e maille env.",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/40_3st_RC.png",
      "width":3,
      "height":1
  },
  {
      "id": 41,
      "rs": "croiser à gauche (3), 1e m. env.",
      "ws": "",
      "rs_long": "croiser 3 mailles à gauche, 1e maille env.",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/41_3st_LC.png",
      "width":3,
      "height":1
  },
  {
      "id": 42,
      "rs": "croiser à droite (3), 1e m. env.",
      "ws": "",
      "rs_long": "croiser 3 mailles à droite, 1e maille env.",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/42_3st_RPC.png",
      "width":3,
      "height":1
  },
  {
      "id": 43,
      "rs": "croiser à gauche (3), 1e m. env.",
      "ws": "",
      "rs_long": "croiser 3 mailles à gauche, 1e maille env.",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/43_3_stLPC.png",
      "width":3,
      "height":1
  },
  {
      "id": 44,
      "rs": "croiser à droite (4)",
      "ws": "",
      "rs_long": "croiser 4 mailles à droite",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/44_4st_RC.png",
      "width":4,
      "height":1
  },
  {
      "id": 45,
      "rs": "croiser à gauche (4)",
      "ws": "",
      "rs_long": "croiser 4 mailles à gauche",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/45_4st_LC.png",
      "width":4,
      "height":1
  },
  {
      "id": 46,
      "rs": "croiser à droite (4), 2 dernière m. env",
      "ws": "",
      "rs_long": "croiser 4 mailles à droite, les 2 dernière mailles à l'envers",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/46_4st_RPC.png",
      "width":4,
      "height":1
  },
  {
      "id": 47,
      "rs": "croiser à gauche (4), 2 dernière m. env",
      "ws": "",
      "rs_long": "croiser 4 mailles à gauche, les 2 dernière mailles à l'envers",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/47_4st_LPC.png",
      "width":4,
      "height":1
  },
  {
      "id": 48,
      "rs": "croiser à droite (5), 3e m. env",
      "ws": "",
      "rs_long": "Croiser 5 mailles à droite, la 3e maille à l'envers",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/48_5st_RPC.png",
      "width":5,
      "height":1
  },
  {
      "id": 49,
      "rs": "croiser à gauche (5), 3e m. env",
      "ws": "",
      "rs_long": "Croiser 5 mailles à gauche, la 3e maille à l'envers",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/49_5st_LPC.png",
      "width":5,
      "height":1
  },
  
  
  
  
  //CROCHET
  {
      "id": 50,
      "rs": "Ch",
      "ws": "",
      "rs_long": "Chainette",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/50_chain.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  {
      "id": 51,
      "rs": "MC",
      "ws": "",
      "rs_long": "Maille coulée",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/51_slip.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  {
      "id": 52,
      "rs": "MS",
      "ws": "",
      "rs_long": "Maille serrée",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/52_single_crochet.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  {
      "id": 53,
      "rs": "Demi B",
      "ws": "",
      "rs_long": "Demi bride",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/53_half_double_crochet.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 54,
      "rs": "B",
      "ws": "",
      "rs_long": "Bride",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/54_double_crochet.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 55,
      "rs": "Dble B",
      "ws": "",
      "rs_long": "Double bride",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/55_treble_crochet.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 56,
      "rs": "Tr B",
      "ws": "",
      "rs_long": "Triple bride",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/56_double_treble_crochet.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 57,
      "rs": "2 ms ds la même m",
      "ws": "",
      "rs_long": "2 mailles serrées dans la même maille",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/57_sc2tog.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  
  {
      "id": 58,
      "rs": "3 ms ds la même m",
      "ws": "",
      "rs_long": "3 mailles serrées dans la même maille",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/58_sc3tog.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  
  {
      "id": 59,
      "rs": "2 br ds la même m",
      "ws": "",
      "rs_long": "2 brides dans la même maille",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/59_dc2tog.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 60,
      "rs": "3 br ds la même m",
      "ws": "",
      "rs_long": "3 brides dans la même maille",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/60_dc3tog.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 61,
      "rs": "Pt soufflé 3 B",
      "ws": "",
      "rs_long": "Point soufflé de 2 brides",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/61_3dc_cluster.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 62,
      "rs": "Pt soufflé 3 demi B",
      "ws": "",
      "rs_long": "Point soufflé de 3 demi brides",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/62_3hdc_cluster.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 63,
      "rs": "Pop 5 B",
      "ws": "",
      "rs_long": "Popcorn à 5 brides",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/63_5_dc_popcorn.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 64,
      "rs": "Augm. 5 B ds la même m",
      "ws": "",
      "rs_long": "Augmentation, 5 brides dans la même maille",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/64_5_dc_shell.png",
      "width":2,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 65,
      "rs": "Picot de 3ml fermé par 1 mc",
      "ws": "",
      "rs_long": "Picot de 3 mailles en l'air",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/65_ch_3_picot.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  
  {
      "id": 66,
      "rs": "B ds le brin avant",
      "ws": "",
      "rs_long": "Bride dans le brin avant",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/66_front_post.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 67,
      "rs": "B ds le brin arrière",
      "ws": "",
      "rs_long": "Bride dans le brin arrière",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/67_back_post.png",
      "width":1,
      "height":2,
      "toolType": 2
  },
  
  {
      "id": 68,
      "rs": "ds le brin arr. uniquement",
      "ws": "",
      "rs_long": "Crocheter dans le brin arrière uniquement",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/68_back_loop.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  
  {
      "id": 69,
      "rs": "ds le brin av.. uniquement",
      "ws": "",
      "rs_long": "Crocheter dans le brin avant uniquement",
      "ws_long": "",
      "t_img": "/imgs/symbols/transparent/69_front_loop.png",
      "width":1,
      "height":1,
      "toolType": 2
  },
  ]
  
  
  
//   export function searchSymbol(text) {
  
//     var newArray = []
//     for (var i = 0; i < SYMBOLS.length; i++) {
//       if (SYMBOLS[i].rs.indexOf(text) != -1 || SYMBOLS[i].ws.indexOf(text) != -1 || SYMBOLS[i].rs_long.indexOf(text) != -1
//        || SYMBOLS[i].ws_long.indexOf(text) != -1) {
//         newArray.push(SYMBOLS[i])
//       }
//     }
  
//     return newArray
//   }
  