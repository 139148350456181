import React, { Component } from "react";
import strings from "../../config/strings";
import Image from "../../components/Image"
import Dropdown from 'react-bootstrap/Dropdown'
import Utils from "../../utils/utils";
import PatternUtils from "../../generator/PatternUtils";
import Button from "../../components/Button";
import { withRouter } from "react-router";
import { TYPE_TEXT_PURE, TYPE_TEXT_ROWS, ROW_TYPE_RANGE, ROW_TYPE_ONE } from "../../generator/PatternUtils";
import {SECONDARY_COLOR} from "../../config/styles";
import SchemaPointSelector from "../../components/generator/SchemaPointSelector"
import Schema from "../../components/generator/Schema"
import Loading from "../../components/Loading"
import ChartSizes from "../../components/generator/ChartSizes"
import { getSymbols, getFirstSymbolSelectedKnitting, getFirstSymbolSelectedCrochet } from "../../generator/symbols"

import {
  ScrollView,
  View,
  TextInput,
  ImageBackground,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Keyboard,
  SafeAreaView,
  DeviceEventEmitter,
  PanResponder,
  BackHandler,
  Dimensions
} from 'react-native';


const MIN_HEIGHT_HEADER = 70
const MIN_HEIGHT_BOTTOM = 150
const MAX_CELL_WIDTH = 50


var __DEV__ = true;
class SchemaGenerator extends Component {
  handler = dims => this.setState(dims);

  constructor(props) {
    super(props);



    const arrayWidth = 10;
    const arrayHeight = 5;

    var params = this.props.history.location.state

    this.patternId = params.patternId;
    this.callback = params.callback;
    this.sectionUniqueId = params.sectionUniqueId;
    this.subsectionUniqueId = params.subsectionUniqueId;
   // alert(JSON.stringify(this.props.history.location))
    this.patternUtils = new PatternUtils(null);


    this.selectorViewHeight = 300;
    this.state = {
      loading:true,
      schemaHeight: this.getSchemaHeight(),
    }

   // alert(this.subsection.content)

   //alert(this.subsection.content + '\n' + JSON.stringify(array))
  }


  getSchemaHeight() {
    return Dimensions.get('window').height - 150 - this.selectorViewHeight;
  }

  async getChart() {
    var array = []

     var chart = this.patternUtils.getChartFromContent(this.subsection.content, this.pattern.custom_symbols)

    if (chart) {
      var allCustomSymbols = await this.patternUtils.getAllCustomSymbols(chart.customSymbols)

      var i = 0;
      for (var y = 0; y < chart.height; y++) {
        for (var x = 0; x < chart.width; x++) {


          var splitted = chart.array[i].split(',')
          var symbolId = splitted[0]
          var color = splitted[1]
          var partWidth = splitted.length > 2 && splitted[2] ? parseInt(splitted[2]) : 0
          var partHeight = splitted.length > 2 && splitted[3] ? parseInt(splitted[3]) : 0
///if(__DEV__) console.log("checking partWidth", chart.array[i], partWidth)
          array.push({
            posX: x,
            posY: y,
            symbol: symbolId < getSymbols().length ? getSymbols()[symbolId] : chart.customSymbols[symbolId],
            color: color,
            partWidth: partWidth,
            partHeight: partHeight
          })

          //if(__DEV__) console.log("checking chart", symbolId, chart.customSymbols[symbolId], chart.customSymbols)

          i++
        }
      }

    }
    else
      var allCustomSymbols = await this.patternUtils.getAllCustomSymbols()

    return {array, chart, allCustomSymbols}
  }

  componentDidMount() {

    window.onpopstate = () => { this.getChartFromServer()}
  /*  this.focusEvent = this.props.navigation.addListener(
      'didFocus',
      () => this.inFocus()
    );

    this.blurEvent = this.props.navigation.addListener(
      'willBlur',
      () => { this.onBlur() }
    );
*/


   this.getChartFromServer();

  }


  async getChartFromServer() {
    var pattern = await this.patternUtils.getPatternFromServer(this.patternId);


    pattern = PatternUtils.convertServerPatternToLocalPattern(pattern)

    this.pattern = pattern
    this.section = PatternUtils.getSection(this.pattern, this.sectionUniqueId)
    this.subsection = PatternUtils.getSubsection(this.section, this.subsectionUniqueId)

    this.user = JSON.parse(localStorage.getItem('user'));

    var {array, chart, allCustomSymbols} = await this.getChart();

   // alert(array.length)
   // alert(JSON.stringify(array))
  //  alert(this.storage.getRavelryTokenExpiration() + " " + new Date().getTime())



    var isLandscape = Utils.isLandscape()

    var paddingTop = 40//isLandscape ? Dimensions.get('window').height / 4 : Dimensions.get('window').height / 8
    if (paddingTop < MIN_HEIGHT_HEADER)
      paddingTop = MIN_HEIGHT_HEADER

    var paddingBottom = isLandscape ? Dimensions.get('window').height / 4 : Dimensions.get('window').height / 8
    if (paddingBottom < MIN_HEIGHT_BOTTOM)
      paddingBottom = MIN_HEIGHT_BOTTOM

    var schemaWidth = Dimensions.get('window').width

    //calcul du selector en bas
    const selectArrayWidth = (Utils.isLandscape() ? 16.5 : 8.5);
    var selectCellSize = Math.round(Dimensions.get('window').width / selectArrayWidth);
    if (selectCellSize > MAX_CELL_WIDTH)
      selectCellSize = MAX_CELL_WIDTH


    this.setState({
      schemaViewWidth:0,
      schemaViewHeight:0,
      symbolSelected: this.pattern.projectTool == 2 ? getFirstSymbolSelectedCrochet() : getFirstSymbolSelectedKnitting(),
      overwrite: [],
      arrayWidth: chart ? chart.width : undefined,
      arrayHeight: chart ? chart.height : undefined,
      array: chart ? array : undefined,
      showInfo: chart ? false : true,
      selectedWidth: chart ? chart.width : 5,
      selectedHeight: chart ? chart.height : 5,
      allCustomSymbols: allCustomSymbols,
      lastCustomSymbolUpdate: Date.now(),
      chartKey: Date.now(),
      worked: chart ? chart.worked : 0,
      loading:false,

      isLandscape: isLandscape, paddingTop: paddingTop, paddingBottom: paddingBottom, schemaViewWidth: schemaWidth, schemaViewHeight: this.getSchemaHeight(), canShowPreview: this.canShowPreview(array)

    })
  }



  handleBackPress = () => {
    this.onBack(); // works best when the goBack is async
    return true;
  }

  onBack() {

    if (this.origArray != this.state.array) {
      //alert('onback')
       this.save()
    }
    this.props.history.push({
      pathname: '/edit-pattern/',
      state: {
        pattern: this.pattern,
       }
    })
    //this.props.history.goBack()
    if (this.mustGoToChartList) {
      setTimeout(() => this.props.navigation.navigate("ChartListScreen", {pattern: this.pattern, section: this.section}), 1000)

      this.mustGoToChartList = false
    }

  }

  save() {

    if (!this.state.array)
      return
    var str = this.state.arrayWidth + '±>' + this.state.arrayHeight + '±>' + this.getCustomSymbolUsed() + '±>'

    for (var i = 0; i < this.state.array.length; i++) {
      if (i != 0)
        str += '|'
      str += this.state.array[i].symbol.id
      if (this.state.array[i].color)
        str += ',' + this.state.array[i].color
      else
        str += ','

      if (this.state.array[i].partWidth)
        str += ',' + this.state.array[i].partWidth
      else
        str += ','

      if (this.state.array[i].partHeight)
        str += ',' + this.state.array[i].partHeight
      else
        str += ','
    }

    str += '±>' + this.state.worked


    //alert( this.subsection.content + '\n\n' + str + JSON.stringify(this.state.array))
    //realm.write(() => {

//alert(JSON.stringify(this.pattern.sections))

      const subsection = this.getCurrentSubsection(this.subsection.uniqueIdOnClient)
      const section = this.getCurrentSubsection(this.subsection.uniqueIdOnClient)
      subsection.content = str

      //this.pattern.subsections = this.pattern.sections
      this.pattern.updatedAtOnClient = Utils.getTime()

       PatternUtils.updatePatternOnServer(this.pattern, (response) => {
          this.pattern = response;


      //})


    })

    this.origArray = this.state.array

  }

  canShowPreview(array) {

    if (!array || array.length == 0)
      return false
    var canShowPreview = false
    for (var i = 0; i < array.length; i++) {
      if (array[i].color) {
        canShowPreview = true
        break
      }
    }

    return canShowPreview
  }


  createArray(width, height, selectedWorked) {



    var array = []

    //resize
    if (this.state.arrayWidth && this.state.arrayHeight) {

      var oldPos = 0

      var missingHeight = height - this.state.arrayHeight
      if (missingHeight < 0)
        missingHeight = 0
      var missingWidth = width - this.state.arrayWidth
      if (missingWidth < 0)
        missingWidth = 0


      var superfluousHeight = this.state.arrayHeight - height
      if (superfluousHeight < 0)
        superfluousHeight = 0
      var superfluousWidth = this.state.arrayWidth - width
      if (superfluousWidth < 0)
        superfluousWidth = 0
      //alert('this.state.arrayHeight:' + this.state.arrayHeight + ' height:' + height)




      //alert(superfluousHeight +  ' ' + superfluousWidth)

      var newPosX = 0
      var newPosY = 0
      var iterateWidth = Math.max(width, this.state.arrayWidth)
      var iterateHeight = Math.max(height, this.state.arrayHeight)


      var y = 0
      if (missingHeight > 0) {


          for (; y < missingHeight; y++) {
            newPosX = 0
            for (var x = 0; x < width; x++) {
              array.push({
                posX: newPosX,
                posY: newPosY,
                symbol: getSymbols()[1],
                partWidth: 0,
                partHeight: 0
              })

              newPosX++

            }

            newPosY++
        }
      }
      for (; y < iterateHeight; y++) {



        if (y < superfluousHeight) {
          continue
        }
        newPosX = 0
        for (var x = 0; x < iterateWidth; x++) {

          var symbol = getSymbols()[1]
          var color = undefined
          var partWidth = 0
          var partHeight = 0
          if (x == 0 && missingWidth > 0) {

            while (x < missingWidth) {
               array.push({
                posX: newPosX,
                posY: newPosY,
                symbol: symbol,
                color: color,
                partWidth: 0,
                partHeight: 0
              })
              x++;
              newPosX++

            }
          }

          if (x < superfluousWidth) {
            continue
          }

          //if (x < this.state.arrayWidth && y < this.state.arrayHeight) {

            var item = this.getItemInArray(x - missingWidth, y - missingHeight, this.state.arrayWidth, this.state.array)
            symbol = item.symbol
            color = item.color
            partWidth = item.partWidth
            partHeight = item.partHeight
            if(__DEV__) console.log("adding symbol", symbol)
          //  oldPos++
          //}
         // elsfdvvffddde if (x > this.state.arrayWidth || y > this.state.arrayHeight)
          //  oldPos++
          array.push({
            posX: newPosX,
            posY: newPosY,
            symbol: symbol,
            color: color,
            partWidth: partWidth,
            partHeight: partHeight
          })
          newPosX++


        }
        newPosY++;
      }


    }
    else {
      for (var y = 0; y < height; y++) {
        for (var x = 0; x < width; x++) {
          array.push({
            posX: x,
            posY: y,
            symbol: getSymbols()[1],
            partWidth: 0,
            partHeight: 0
          })
        }
      }
    }



    this.setState({
      array: array,
      arrayWidth: width,
      arrayHeight: height,
      showInfo: false,
      chartKey: Date.now(),
      worked: selectedWorked
    })
  }


   getCustomSymbolUsed() {

    var str = ''
    var y = 0

    var addedArray = []
    for (var i = 0; i < this.state.array.length; i++) {

      if (this.state.array[i].symbol.id <= getSymbols().length)
        continue



      //if (typeof this.state.array[i].symbol.id != "string" )
      //  alert("not STRING")


      if (!addedArray.includes(this.state.array[i].symbol.id)) {

        if (y != 0)
          str += ','
        str += this.state.array[i].symbol.id + '|' + this.state.array[i].symbol.uniqueIdOnClient
        addedArray.push(this.state.array[i].symbol.id)
        y++
      }

    }

    return str
  }

  deleteChart() {
    const section = PatternUtils.getSection(this.pattern, this.sectionUniqueId)
    const subsection = this.getCurrentSubsection(this.subsection.uniqueIdOnClient)
      subsection.deleted = 1
      section.deleted = 1

      //this.pattern.subsections = this.pattern.sections
      this.pattern.updatedAtOnClient = Utils.getTime()

       PatternUtils.updatePatternOnServer(this.pattern, (response) => {
          this.pattern = response;


      //})


    })

  }





  getCurrentSubsection(uniqueIdOnClient) {
    console.log("getCurrentSubsection", )


    for (var i = 0; i < this.pattern.sections.length; i++) {

      var section = this.pattern.sections[i]

      if (!section.subsections)
        continue
      var subsections = section.subsections.filter(item => item.uniqueIdOnClient === uniqueIdOnClient)

      if (subsections.length > 0)
        return subsections[0]
    }
    return null
  }

  cancelAction() {
    var newOverwrite = [...this.state.overwrite]

    var items = newOverwrite.pop()

    var newArray = [...this.state.array]

    for (var i = 0; i < items.length; i++) {
      var item = items[i]
      newArray[(item.posY * this.state.arrayWidth) + item.posX ] = item
    }
    this.setState({
      array: newArray, overwrite: newOverwrite, canShowPreview: this.canShowPreview(newArray) }, () => this.save())

  }


  getItemInArray(x, y, maxX, array) {
    return array[(y * maxX) + x]
  }

  deleteCellsWithSymbol(uniqueIdOnClient) {

    var newArray = [...this.state.array]
    for (var i = 0; i < newArray.length; i++) {

      if (newArray[i].symbol.uniqueIdOnClient == uniqueIdOnClient) {
        if(__DEV__) console.log('RESETTING SYMB')

        var newItem= {
          ...newArray[i],
          symbol: getSymbols()[0],

          partWidth: 0,
          partHeight: 0
        }
        newArray[i] = newItem

      }
    }


    return newArray
  }


  resetCell(array, x, y, cancelArray) {
    var oldItem = array[ (y * this.state.arrayWidth) + x ]


    var alreadyCanceled = false
    for (var i = 0; i < cancelArray.length; i++) {
      if (cancelArray[i].posX == oldItem.posX && cancelArray[i].posY == oldItem.posY)
      {
        alreadyCanceled = true
        break
      }
    }
    if (!alreadyCanceled)
      cancelArray.push(oldItem)
    const newDefaultItem = {
      ...oldItem,
      symbol: getSymbols()[0],
      color: null,
      partWidth: 0,
      partHeight: 0
    }
    if(__DEV__) console.log('resetting cell', x, y)
    array[ (y * this.state.arrayWidth) + x ] = newDefaultItem
  }

  resetArea(array, x, y, cancelArray, forceDeleteCurrent = false) {

    if (x < 0 || x >= this.state.arrayWidth)
      return
    if (y < 0 || y >= this.state.arrayHeight)
      return



    var item = {...array[ (y * this.state.arrayWidth) + x ]}
    if(__DEV__) console.log('checking item', x, y, item)
    var symbolWidth = item.symbol.width ? item.symbol.width : 1
    var symbolHeight = item.symbol.height ? item.symbol.height : 1

    if (item.partWidth == 0 && item.partHeight == 0) {
      if (forceDeleteCurrent) {
        this.resetCell(array, x, y, cancelArray)
      }
      else {

      }
     // return
    }
    if (item.partWidth > 0) {
      this.resetCell(array, x, y, cancelArray)


      this.resetArea(array, x - 1, y, cancelArray, true)
    }
    if (item.partWidth < symbolWidth) {
      this.resetCell(array, x, y, cancelArray)

      if (x + 1 < this.state.arrayWidth) {
        var nextItem = array[ (y * this.state.arrayWidth) + x + 1]

        if (nextItem.partWidth != 0)
          this.resetArea(array, x + 1, y, cancelArray)
      }



    }

    if (item.partHeight > 0) {
      this.resetCell(array, x, y, cancelArray)


      this.resetArea(array, x, y - 1, cancelArray, true)
    }
    if (item.partHeight < symbolHeight) {
      this.resetCell(array, x, y, cancelArray)

      if (y + 1 < this.state.arrayHeight) {
        var nextItem = array[ ((y + 1) * this.state.arrayWidth) + x]

        if (nextItem.partHeight != 0)
          this.resetArea(array, x, y + 1, cancelArray)
      }

    }

  }
  onCellSelected(item) {

    const newItem = {
      ...item,
      symbol: this.state.symbolSelected,
      color: this.state.colorSelected,
      partWidth: 0,
      partHeight: 0
    }


    var newOverwrite = [...this.state.overwrite]
    if (newOverwrite.length > 16)
      newOverwrite.shift()


    var newCancelArray = []
    newCancelArray.push(item)



    var oldItem = this.state.array[(item.posY * this.state.arrayWidth) + item.posX ]
    var newArray = [...this.state.array]


    if (this.state.symbolSelected.width && this.state.symbolSelected.width > 1 && oldItem.posX + this.state.symbolSelected.width > this.state.arrayWidth) {
     // alert('ignore')
      return
    }
     if (this.state.symbolSelected.height && this.state.symbolSelected.height > 1 && oldItem.posY + this.state.symbolSelected.height > this.state.arrayHeight) {
     // alert('ignore')
      return
    }

    var symbolWidth = newItem.symbol.width ? newItem.symbol.width : 1
    var symbolHeight = newItem.symbol.height ? newItem.symbol.height : 1
    for (var y2 = item.posY; y2 < item.posY + symbolHeight; y2++) {
      for (var x2 = item.posX; x2 < item.posX + symbolWidth; x2++) {
        this.resetArea(newArray, x2, y2, newCancelArray)
      }
    }


   // if (newItem.symbol.width && newItem.symbol.width > 1) {

      newArray[(item.posY * this.state.arrayWidth) + item.posX ] = newItem

      var y2 = item.posY


      for (var z = 0; z < newItem.symbol.height; z++)
      {

        var x2 = item.posX
        for (var i = 0; i < newItem.symbol.width; i++) {

          var adjOldItem = newArray[(y2 * this.state.arrayWidth) + x2 ]
          var newItem2 = {
            ...adjOldItem,
            symbol: this.state.symbolSelected,
            color: this.state.colorSelected,
            partWidth: i,
            partHeight: z
          }
          newArray[(y2 * this.state.arrayWidth) + x2 ] = newItem2
          //newCancelArray.push(adjOldItem)
          x2++
        }
        y2++
      }

      /*
       while (x2 < this.state.arrayWidth) {
        var adjOldItem = newArray[(item.posY * this.state.arrayWidth) + x2 ]
        if(__DEV__) console.log('checking resetting cell', x2, this.state.symbolSelected.width, adjOldItem.partWidth)
        if ((adjOldItem.partWidth == 0 || !adjOldItem.partWidth))
          break
        const newDefaultItem = {
          ...adjOldItem,
          symbol: this.pattern.projectTool == 2 ? FIRST_SYMBOL_SELECTED_CROCHET : FIRST_SYMBOL_SELECTED_KNITTING,
          color: null,
          partWidth: 0,
          partHeight: 0
        }

        newArray[(item.posY * this.state.arrayWidth) + x2 ] = newDefaultItem
        newCancelArray.push(adjOldItem)
        x2++
        if(__DEV__) console.log('resetting cell')
      }*/

  //  }
  //  else
  //    newArray[(item.posY * this.state.arrayWidth) + item.posX ] = newItem


    newOverwrite.push(newCancelArray)
    this.setState({
      array: newArray, overwrite: newOverwrite, canShowPreview: this.canShowPreview(newArray) }, () => this.save())

 //alert(JSON.stringify(newArray))
  }


  zoom(value) {

    if (this.refs.schemaView)
      this.refs.schemaView.zoom(value)

  }

  onSymbolEdited(hasChangedDim, uniqueIdOnClient) {


    var customSymbolEdited = null
    try {
      var {array, chart, allCustomSymbols} = this.getChart();

      var array = chart ? array : undefined

      if (hasChangedDim) {
        array = this.deleteCellsWithSymbol(uniqueIdOnClient)
      }
      for (var i = 0; i < allCustomSymbols.length; i++) {
          if (allCustomSymbols[i].uniqueIdOnClient == uniqueIdOnClient) {
            customSymbolEdited = allCustomSymbols[i]
            break
          }
        }
    }
    catch(error) {alert(error.message)}

    if (!this.subsection || !this.subsection.content) {
      this.setState({lastCustomSymbolUpdate: Date.now(), customSymbolEdited: customSymbolEdited})
    }
    else {
      this.setState({array: array, allCustomSymbols: allCustomSymbols, lastCustomSymbolUpdate: Date.now(), customSymbolEdited: customSymbolEdited}, () => this.save())
    }




  }

  onHideSymbols() {
    this.setState({schemaHeight: this.getSchemaHeight()})

    /*
    console.log("change size schema!")
    if (this.state.schemaHeight == 320) {
      this.setState({schemaHeight: 420})
    } else {
      this.setState({schemaHeight: 320})
    }
    */
  }

  renderFlatRoundOption(value, text) {

    var isCurrentValue = value == this.state.selectedWorked ? true : false
    var btnSize = isCurrentValue ? 32 : 30
    return (
      <TouchableOpacity
      onPress={() => this.setState({selectedWorked:value})}
      style={{
        width: btnSize * 6,
        height: btnSize,
        borderRadius: btnSize / 2,
        backgroundColor: isCurrentValue ? "rgba(43,165,247,1)" : "rgba(0,0,0,0.05)",
        marginHorizontal: 4,
        justifyContent: "center",
        alignItems:"center"}}
      >
      <Text style={{ color: isCurrentValue ? "white" : "#a0a0a0",}}>{text}</Text>

      </TouchableOpacity>
    )
  }

  renderInfo() {


    var canValid = this.state.selectedWidth && this.state.selectedHeight
    return (

          <TouchableOpacity style={{position:"absolute", top:0, bottom:0, left:0, right:0
          , flexDirection:'row', alignItems: "center", flex:1, justifyContent:'center', backgroundColor:'rgba(128, 128, 128, 0.8)', zIndex:1000}}>

          <TouchableOpacity style={{
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOpacity: 0.3,
    shadowRadius: 2,
    shadowOffset: { width: 1, height: 1 },
    elevation: 3,
    margin: 16,
    zIndex:5000, paddingHorizontal:20}}>


        <View>
            <ScrollView style={{padding:16}}>


            <Text style={{marginTop:5, color:SECONDARY_COLOR, alignSelf:'center',fontSize:18, fontWeight:'bold'}}>{strings.chart_settings}</Text>



            <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', marginTop:30, marginBottom: 12}}>


            <View style={{justifyContent:'center', alignItems:'center', alignContent:'center'}}>
              <TextInput
                style={{width: 70, color:SECONDARY_COLOR, fontSize:28, fontWeight:'bold', marginHorizontal:16, textAlign:'center'}}
                multiline={false}
                value={this.state.selectedHeight ? this.state.selectedHeight.toString() : undefined}
                keyboardType="numeric"
                onChangeText={(text) => {

                  this.setState({selectedHeight: parseInt(text)})}
                }
                returnKeyType="done"
              />
              <Text style={{marginTop:5, color:"#888"}}>{strings.lines}</Text>
            </View>

            <View style={{width:1, backgroundColor:'#ddd', height:'70%'}}/>


            <View style={{justifyContent:'center', alignItems:'center'}}>

            <TextInput
              style={{width: 70, color:SECONDARY_COLOR, fontSize:28, fontWeight:'bold', marginHorizontal:16, textAlign:'center'}}
                multiline={false}
              multiline={false}
              value={this.state.selectedWidth ? this.state.selectedWidth.toString() : undefined}
              keyboardType="numeric"
              onChangeText={(text) => this.setState({selectedWidth: parseInt(text)})}
              returnKeyType="done"
            />

             <Text style={{marginTop:5, color:"#888"}}>{strings.columns}</Text>
            </View>

            </View>

          {
            this.pattern.projectTool != 2 &&
              <View style={{flexDirection: "row"}}>
                {this.renderFlatRoundOption(0, strings.worked_flat)}
                {this.renderFlatRoundOption(1, strings.worked_round)}
              </View>
          }

              <View style={{flexDirection:'row', justifyContent:'flex-end', alignItems:'center', marginTop:16}}>
            <TouchableOpacity
               style={{paddingVertical:10 }}
                onPress={() => {

                  if (this.state.array) {
                    console.log("canceling - setState")
                    this.setState({showInfo: false, selectedWidth: this.state.arrayWidth, selectedHeight: this.state.arrayHeight})
                  }
                  else {
                    console.log("canceling -no setState ")
                    this.deleteChart()
                    this.props.history.goBack()
                  }

                }}>
                <Text style={{color: SECONDARY_COLOR, textAlign:'center', fontWeight:'bold', fontSize:15}}>{strings.cancel.toUpperCase()}</Text>
              </TouchableOpacity>

              <TouchableOpacity
               style={{paddingLeft:20}}
               onPress={() => {
                if (canValid)
                  this.createArray(this.state.selectedWidth, this.state.selectedHeight, this.state.selectedWorked )}
              }
                >
                <Text style={{color: canValid ? SECONDARY_COLOR : "#888", fontWeight:'bold', fontSize:15}}>OK</Text>
              </TouchableOpacity>

              </View>


        </ScrollView>
        </View>
        </TouchableOpacity>
        </TouchableOpacity>
      )
  }



  renderTop() {
    return (
        <View style={{flexDirection:'row', marginTop:12}}>

          {this.renderGoBack()}
         {this.pattern.sizeIds.length > 1 && <TouchableOpacity style={{width:80, height:40, marginHorizontal:5}} onPress={() => this.setState({showSizes: true}) }>
            <Image urlOnServer={'/imgs/sizes.png'}
             style={{width:60, height:30, marginTop:6, marginLeft:10}}/>
          </TouchableOpacity>}
          <View style={{flex:1}}/>


          <TouchableOpacity style={{width:40, height:40, marginHorizontal:5}} onPress={() => this.zoom(-1) }>
            <Image urlOnServer={'/imgs/unzoom2.png'}
             style={{width:40, height:40}}/>
          </TouchableOpacity>


           <TouchableOpacity style={{width:40, height:40, marginHorizontal:5}} onPress={() => this.zoom(1) }>
            <Image urlOnServer={'/imgs/zoom2.png'}
             style={{width:40, height:40}}/>
          </TouchableOpacity>




         {this.state.overwrite.length > 0 && ( <TouchableOpacity style={{width:40, height:40, marginHorizontal:5}} onPress={() => this.cancelAction() }>
            <Image urlOnServer={'/imgs/rollback.png'}
             style={{width:40, height:40}}/>
          </TouchableOpacity>
         )}

         <TouchableOpacity style={{width:40, height:40, marginHorizontal:5}} onPress={() => this.setState({showInfo: true}) }>
            <Image urlOnServer={'/imgs/settings-rd.png'}
             style={{width:40, height:40}}/>
          </TouchableOpacity>

 {this.state.canShowPreview &&
 <TouchableOpacity style={{width:40, height:40, marginHorizontal:5}}
 onPress={() =>
  {

  this.props.history.push({
                pathname: '/chart-preview/',
                state: { pattern: this.pattern, array: this.state.array, arrayWidth: this.state.arrayWidth, arrayHeight: this.state.arrayHeight }
              })


}
}>
            <Image urlOnServer={'/imgs/eye_circle.png'}
             style={{width:40, height:40}}/>
          </TouchableOpacity>}

        </View>

      )
  }

  renderSelector() {

    return (

        <SchemaPointSelector

          onLayout={(event) => {

              var {x, y, width, height} = event.nativeEvent.layout;


              if (height > 0 && height !== this.selectorViewHeight) {
                this.selectorViewHeight = height
                this.setState({schemaHeight: this.getSchemaHeight()})
              }

            }}
            onHideSymbols={() => this.onHideSymbols()}
            key={'SchemaPointSelector_' + this.state.lastCustomSymbolUpdate}
            lastCustomSymbolUpdate={this.state.lastCustomSymbolUpdate}
            onSymbolSelected={(item)=> {
              this.setState({symbolSelected: item})
              this.onHideSymbols()
            //  alert(JSON.stringify(item))
            }}
            onColorSelected={(color)=> {
              this.setState({colorSelected: color})
              this.onHideSymbols()
            }}
            array={this.state.array}
            onNewSymbol={() =>

              {
                this.props.history.push({
                pathname: '/edit-chart-symbol',
                state: { pattern: this.pattern, customSymbolId: 0 }
              })

              }

                }
            typeChart={this.pattern.projectTool}
            allCustomSymbols={this.state.allCustomSymbols}
            customSymbolEdited={this.state.customSymbolEdited}
            onLongPress={item => {

              if (item.id > getSymbols().length && item.userIdOnServer == this.user.id) {
                  this.props.history.push({
                  pathname: '/edit-chart-symbol',
                  state: { pattern: this.pattern, customSymbol: item }
                })

              }

            }}
        />

      )
  }

  renderGoBack(){
    return (
      <TouchableOpacity
      style={{
        marginHorizontal: 12,
        marginTop: 8,
        width: 32}}
      onPress={() => this.handleBackPress()}>
        <Image style={{width: 24, height: 24, margin: 4}} urlOnServer={"/imgs/back.png"}/>
      </TouchableOpacity>
    )
}

  renderList() {
    return (
      <View style={{backgroundColor: "#266D9C", minHeight: Dimensions.get('window').height - 72, width: Dimensions.get('window').width }}>

          <View style={{paddingTop: 48, flex:1, backgroundColor: "#266D9C", justifyContent: "flex-start", alignItems: "center"}}>
            {
                this.state.array &&
                    <Schema
                        ref="schemaView"
                        key={'schema' + this.state.arrayWidth + '_' + this.state.arrayHeight + '_' + this.state.worked + '_' + this.state.schemaHeight + '_' + this.state.selectedWorked}
                        array={this.state.array}
                        width={this.state.schemaViewWidth}
                      //  height={this.state.schemaViewHeight}
                        arrayWidth={this.state.arrayWidth}
                        arrayHeight={this.state.arrayHeight}
                        onCellSelected={(item) => this.onCellSelected(item)}
                        isEditing={true}
                        selectedWorked={this.state.selectedWorked}
                        worked={this.state.worked}
                        schemaHeight={this.state.schemaHeight}
                        //selectedLine={2}
                        />}
            </View>

            <View style={{width:'100%', height:this.state.paddingTop, position:'absolute', top:0}}>
                {this.renderTop()}
            </View>



            {this.state.array && this.renderSelector()}

            {this.state.showInfo && this.renderInfo()}

            {
              this.state.showSizes &&
                  <ChartSizes
                  onClose={() => this.setState({showSizes: false})}

                  description={strings.show_chart_sizes}
                  selectedSizeIds={this.subsection.sizeIds} allSizeIds={this.pattern.sizeIds}
                  unavailableSizeIds={this.patternUtils.getUnavailableSizeIds(this.section, this.subsection)}
                  onSizesSelected={(sizes) => {Utils.realmWrite(() => {
                      if (this.subsection.sizeIds.length === this.pattern.sizeIds.length)
                        this.mustGoToChartList = true
                      this.subsection.sizeIds = sizes
                    })
                    this.setState({showSizes: false})
                  }
                  }
                  />
            }
         </View>
      )
  }




  render() {

    if (this.state.loading) {
      return <Loading />
    }
    if (this.state.image) {
      return (
          <TouchableOpacity style={{flex:1}} onPress={() => this.setState({image: null})}>
            <Image resizeMode={'contain'} style={{flex:1}} source={{uri: this.state.image}}/>

          </TouchableOpacity>

        )
    }

    //return this.renderPreview()
    if (this.state.schemaViewWidth == 0)
      return null


    return this.renderList()
  }
}


export const SchemaGeneratorWithRouter = withRouter(SchemaGenerator);

const styles = StyleSheet.create({


});
