import React, { Component } from "react";
import strings from "../config/strings";
import Image from "../components/Image"
import ImageBackground from "../components/ImageBackground"
import Button from "../components/Button"
import Utils from "../utils/utils";
import {SECONDARY_COLOR, PURPLE_COLOR_PLAIN} from "../config/styles";
import { BrowserRouter, Route, Switch, useRouteMatch, Link, useParams, useHistory } from 'react-router-dom';
import TitledTextInput from "../components/TitledTextInput";
import { HEADER_HEIGHT } from '../config/Constants'
import OptionList from "../components/OptionList";


import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions,
  Picker
} from 'react-native';

const MARGIN = 6;

const MARGIN_OUT = 6;

const TWO_TOG = strings.twoTog
const MAKE_ONE = strings.makeOne

const { width, height } = Dimensions.get("window");
const gutter = 0;


const UNITS = [
  { id: 1, text: strings.meters, multiple:100 },
  { id: 2, text: strings.centimeters, multiple:1 },
  { id: 3, text: strings.inches, multiple:2.54},
  { id: 4, text: strings.yards , multiple: 91.44 }
];

export default class DistribStitches extends Component {
  handler = dims => this.setState(dims);

  constructor(props) {
    super(props);


    this.selectedInputType = UNITS[2]
    this.selectedOutputType = UNITS[1]
    this.input = null
    this.state = {
      output: null,
      currentnb: null,
      desirednb: null,
      increase: false,
      answer:false,
      todo:"decrease",
      spread:"5",
      diff:"5",
    }
  }



     back = () => {
    this.props.navigation.goBack();
  };



takeCurrentNb = (number) => {
    if (number == "" || number == null)
      return
this.setState({currentnb:number})

}

takeDesiredNb = (number) => {
      if (number == "" || number == null)
      return
this.setState({desirednb:number})

}

checkIfEvenSpreadPossible(currentNb, desirednb, divider = 1) {

  var diff = (currentNb - desirednb) / divider
  var minSpread = diff > 0 ? 3 : 1
  var todo = diff > 0 ? strings.distribstitches.decreases : strings.distribstitches.increases
  diff = Math.abs(diff)
  var spread = Math.trunc(currentNb / diff)
  console.log("results --- diff: ", diff, "spread", spread)
  if (spread < minSpread && divider < 10) {
    console.log("spread too small")
    return this.checkIfEvenSpreadPossible(currentNb, desirednb, divider + 1 )
  }
  var remain1 = currentNb % diff
  if (remain1 == 0) {
    var spread = Math.trunc(currentNb / diff)
    var toReturn =  {
      possible: true,
      nbPerSequence: divider,
      spread: spread,
      todo: todo
    }

    return toReturn
  }
  return null
}

validateAnswer() {
  console.log("-----------------")
  console.log("Validate answer")
  var currentnb = this.state.currentnb;
  var desirednb = this.state.desirednb;
  if (currentnb == null && desirednb == null) {
    alert(strings.distribstitches.pleaseenter);
    return
  }

  var diff = Math.round(currentnb - desirednb)

  if (diff > 0) {
    var todo = strings.distribstitches.decreases
    var minus = 1;
  } else {
    diff = diff * -1;
    var todo = strings.distribstitches.increases;
    var minus = 0;
  }

    var evenResult = this.checkIfEvenSpreadPossible(currentnb, desirednb, 1)
    console.log("evenResult", evenResult)
    if (evenResult) {
      if (minus == 1) {
        var toSubstract = evenResult.nbPerSequence * (1 + minus)
        var startat = (evenResult.spread - toSubstract)
        var spread = evenResult.spread - toSubstract
      } else {
        var startat = evenResult.spread
        var spread = evenResult.spread
      }

      //console.log("Start at: ", startat, "spread: ", spread, "todo: ", evenResult.todo, "currentnb: ", currentnb, "diff: ", diff, "nbPerSequence: ", evenResult.nbPerSequence)
      var todo = evenResult.todo == strings.distribstitches.decreases ? TWO_TOG : MAKE_ONE
      var nbRed = minus == 1 ? 2 : 1
      this.setState({
        visarray: this.getVisualisationArray(startat, spread, nbRed, evenResult.nbPerSequence),
        even: true,
        diff: diff.toString(),
        spread: spread.toString(),
        nbPerSequence: evenResult.nbPerSequence,
        answer: true,
        todo: todo,
        startat: startat.toString()
       })
      return
    }
    var sequences = []
    console.log("Not Even")
    var spread = Math.trunc(currentnb / diff);  // 500 / 120 = 4.16 = 4 => Initial spread of the inc/dec
    console.log("initial spread", spread)
    var remain = currentnb % diff; // 500 - (4 * 120) = 20 => remaining stitches
    console.log("initial remain", remain)

        var spreadA = spread // => Initial spread of the inc/dec
        var nbspreadA = diff - remain // => nb of repeats of sequence


        var spreadB = spread + 1 // => spreading 1 remaining st per sequence repeat
        var nbspreadB = remain // => nb of repeat = nb of remaining stitch
        //console.log("spreadA", spreadA, "spreadB", spreadB, "nbspreadA", nbspreadA, "nbspreadB", nbspreadB)
        sequences.push({
          id: nbspreadB > nbspreadA ? "Min" : "Maj",
          spread: spreadA,
          nbspread: nbspreadA,
        })
        sequences.push({
          id: sequences[0].id == "Maj" ? "Min" : "Maj",
          spread: spreadB,
          nbspread: nbspreadB,
        })



        var majSeq = sequences.find(x => x.id == "Maj") // 120 * (1+2)
        var minSeq = sequences.find(x => x.id == "Min") // 70 * (2)


        var nbMajForMin = Math.round(majSeq.nbspread / minSeq.nbspread) // 2
        var remain2 = majSeq.nbspread - (minSeq.nbspread * nbMajForMin) // 190 - (70*2) = -20
        if (remain2 == 0) {
          var instructions = {
            minus: minus * 2,
            mainNbRepeats: minSeq.nbspread,
            sequences: sequences,
            mainNbMajForMin: nbMajForMin,
          }

        }
        if (remain2 < 0) { // total too big - need to remove some repeats of maj
          var instructions = {
            minus: minus * 2,
            mainNbRepeats: minSeq.nbspread + remain2,
            sequences: sequences,
            mainNbMajForMin: nbMajForMin,
            additionalRepeats: - remain2,
            additionalNbMajForMin: nbMajForMin - 1
          }

        }
        if (remain2 > 0) {// total too small - need to add some repeats of maj
          var instructions = {
            minus: minus * 2,
            mainNbRepeats: minSeq.nbspread - remain2,
            sequences: sequences,
            mainNbMajForMin: nbMajForMin,
            additionalRepeats: remain2,
            additionalNbMajForMin: nbMajForMin + 1
          }

        }
        console.log("### INSTRUCTIONS", instructions)


        this.setState({
          visarray: false,
          diff: diff.toString(),
          instructions: instructions,
          even: null,
          evenSpreadInfos: null,
          answer: true,
          todo: todo, // str inc/dec
         })
         return

}

getVisualisationArray(startat, spread, nbRed, nbOfActions = 1) {

  var multiplier = strings.distribstitches.increases ? 1 : 2

  if (nbRed == 1) {
    var red = [1];
    var nb = 1;
  } else {
    //startat = startat - 1;
    //spread = spread - 1;
    var red = [1, 1]
  }
  var actions = []

  for (let i = 0; i < (nbOfActions); i++) {
    actions = actions.concat(red);
  }

//console.log("red", actions)
  var array = [];
  for (let i = 0; i < startat; i++) {
    array.push(0)
  }
  console.log("array", array)
  array = array.concat(actions)
  for (let i = 0; i < spread; i++) {
    //console.log("spread", spread)
    array.push(0)
    //console.log("array", array)

  }
  array = array.concat(actions)

  if (spread == 0) {
    array.push(1)
  } else {
    array.push(0)
  }
  return array
}

singularize(term, nb) {
  if (nb === 1) {
    switch(term) {
        case strings.distribstitches.increases:
          term = strings.distribstitches.increase;
          break;
        case strings.distribstitches.decreases:
          term = strings.distribstitches.decrease;
          break;
        case strings.distribstitches.stitches:
          term = strings.distribstitches.stitch;
          break;
        default:
          break;
      }

  }
   return term
}

renderOneSetOfSequences(sequences, nbRepeats, nbMajForMin, todo, minus) {
  //console.log("renderOneSetOfSequences")

  var majSeq = sequences.find(x => x.id == "Maj")
  var minSeq = sequences.find(x => x.id == "Min")
  var majNbStitches = ~~(majSeq.spread - minus) > 0 ? `${majSeq.spread - minus} ${(majSeq.spread - minus) > 1 ? strings.distribstitches.stitches : strings.distribstitches.stitch}, ` : ""
  var minNbStitches = ~~(minSeq.spread - minus) > 0 ? `${minSeq.spread - minus} ${(minSeq.spread - minus) > 1 ?strings.distribstitches.stitches : strings.distribstitches.stitch}, ` : ""

  var openingBrackets = nbMajForMin > 1 ? <Text style={{fontWeight: "bold"}}>{"[ "}</Text> : ""
  var closingBrackets = nbMajForMin > 1 ? <Text style={{fontWeight: "bold"}}>{` ] x ${nbMajForMin}`}</Text> : ""

  return (
    <View style={{ marginLeft: 8, flex: 1, }}>
      <Text style={{ flex: 1, color: "#555", letterSpacing: 1 }} > {strings.repeat + " " + nbRepeats + " " + strings.times + ":"}</Text>
      <View style={{
        paddingHorizontal: 8,
        paddingVertical: 12,
        margin: 6,
        width: "100%",
        backgroundColor: `rgba(254,203,46, 0.3)`,
        borderRadius: 12
      }}>
      <Text style={{ marginLeft: 8, color: "#333", letterSpacing: 1, paddingBottom: 4 }} >{openingBrackets}{majNbStitches}{todo}{closingBrackets}</Text>
      <Text style={{ marginLeft: 8, color: "#333", letterSpacing: 1 }} >{minNbStitches}{todo}</Text>
      </View>
    </View>
  )
}

renderNotEvenSpreadAnwsers(instructions) {

  if (this.state.todo == strings.distribstitches.decreases)
    var todo = TWO_TOG
  else
    var todo = MAKE_ONE

  if (instructions.additionalRepeats) {
    var repAddit1 = Math.trunc(instructions.additionalRepeats / 2)
    var repAddit2 = instructions.additionalRepeats - repAddit1
    //console.log("##### repAddit1", repAddit1, "#####repAddit2", repAddit2)
  }


  return (
<View style={{ flex: 3, justifyContent: "center", paddingTop: 24 }}>
{
  (repAddit1 && repAddit1 > 0) ?
  this.renderOneSetOfSequences(
    instructions.sequences,
    repAddit1,
    instructions.additionalNbMajForMin,
    todo,
    instructions.minus
  ) : null
}
  {
  (repAddit1 && repAddit1 > 0) ?
    <Text style={{
      width: "100%",
      textAlign: "center",
      color: "#a0a0a0",
      fontStyle: "italic",
      paddingTop: 8,
      paddingBottom: 4,
    }}>{strings.distribstitches.then}</Text>
    : null
  }
    {
  this.renderOneSetOfSequences(
    instructions.sequences,
    instructions.mainNbRepeats,
    instructions.mainNbMajForMin,
    todo,
    instructions.minus
  )
}
  {
  (repAddit2 && repAddit2 > 0) ?
  <Text style={{
    width: "100%",
    textAlign: "center",
    color: "#a0a0a0",
    fontStyle: "italic",
    paddingTop: 8,
    paddingBottom: 4,
  }}>{strings.distribstitches.then}</Text>
  : null
  }
  {
  (repAddit2 && repAddit2 > 0) ?
  this.renderOneSetOfSequences(
    instructions.sequences,
    repAddit2,
    instructions.additionalNbMajForMin,
    todo,
    instructions.minus
  )
  : null
}

</View>
  )

}


renderAnswer(text1, answer1, answer2, showNbPerSequence = false) {
  var nb = parseInt(answer1)
  const answer3 = this.singularize(answer2, nb);

  var nbPerSequence = this.state.nbPerSequence ? this.state.nbPerSequence : 1;

  return (
    <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginVertical: 10 }} >
      <View style={{ flex: 1 }}>
        <Image
          style={[{ alignSelf: "center", width: 40, height: 40 }]}
          resizeMode="contain"
          urlOnServer={"../imgs/fleche-conv-rot.png"}
        />
      </View>
      <View style={{ flex: 4, justifyContent: "center", alignItems: "flex-start", marginLeft: 5 }}>
      {
            showNbPerSequence  &&
            <Text style={{ flex: 1, color: "#1B82BC", fontWeight: "bold", fontSize: 14, textAlignVertical: "bottom" }}>
            {(nbPerSequence > 1 ? (nbPerSequence + " " + strings.times) : "" )+ this.state.todo.toUpperCase()}
          </Text>
          }
        <Text style={{ flex: 1, textAlignVertical: "center", color: "#555" }}>
          {text1}
        </Text>
        <Text style={{ flex: 1, color: "#1B82BC", fontWeight: "bold", fontSize: 16, textAlignVertical: "center" }}>
          {answer1.toUpperCase() + " " + answer3.toUpperCase()}
        </Text>
      </View>
    </View>

  )

}

renderStartAt(text1, todo, text2, answer1, answer2) {
  todo = this.singularize(todo, 1);
  return (
    <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginVertical: 10 }} >
      <View style={{ flex: 1 }}>
        <Image
          style={[{ alignSelf: "center", width: 40, height: 40 }]}
          resizeMode="contain"
          urlOnServer={"../imgs/fleche-conv-rot.png"}
        />
      </View>
      <View style={{ flex: 4, justifyContent: "center", alignItems: "flex-start", marginLeft: 5 }}>
        <Text style={{ flex: 1, textAlignVertical: "center", color: "#555" }}>
          {text1 + " " + todo + " " + text2}
        </Text>
        <Text style={{ flex: 1, color: "#1B82BC", fontWeight: "bold", fontSize: 16, textAlignVertical: "center" }}>
          {answer1.toUpperCase() + " " + answer2.toUpperCase()}
        </Text>
      </View>
    </View>

  )

}
renderAnswer2(text1, answer1, answer2, every, spread, stitches) {
  var nb = parseInt(answer1)
  const answer3 = this.singularize(answer2, nb);
  var nb2 = parseInt(spread)
  const stitchesok = this.singularize(stitches, nb2);

  return (
    <View style={{ flex: 0, flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginVertical: 10 }} >
      <View style={{ flex: 0 }}>
        <Image
          style={[{ alignSelf: "center", width: 40, height: 40 }]}
          resizeMode="contain"
          urlOnServer={"../imgs/fleche-conv-rot.png"}
        />
      </View>
      <View style={{ flex: 4, justifyContent: "center", alignItems: "flex-start", marginLeft: 5, }}>
        <Text style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "flex-start", color: "#555" }} >
          <Text style={{ flex: 0, alignItems: "flex-start", color: "#555" }}>
            {text1 + " "}
          </Text>
          <Text style={{ flex: 1, color: "#1B82BC", fontWeight: "bold", fontSize: 16, textAlignVertical: "bottom" }}>
            {answer1.toUpperCase() + " " + answer3.toUpperCase()}
          </Text>
        </Text>
        <Text style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "flex-start", color: "#555" }} >
          <Text style={{ flex: 0, alignItems: "flex-start", color: "#555" }}>
            {every + " "}
          </Text>
          <Text style={{ flex: 1, color: "#1B82BC", fontWeight: "bold", fontSize: 16, textAlignVertical: "bottom" }}>
            {spread + " " + stitchesok.toUpperCase()}
          </Text>
        </Text>
      </View>
    </View>

  )
}
renderStitch(type){
  return (
      <View style={{paddingRight:5}}>
      {type==0?<Image
                style={[{alignSelf:"center",width:25,height:25 }]}
                resizeMode="contain"
                urlOnServer={"../imgs/maille.png"}
                />:<Image
                style={[{alignSelf:"center",width:25,height:25 }]}
                resizeMode="contain"
                urlOnServer={"../imgs/maillerose.png"}
                />}
      </View>
    )
}

renderVisualisation(visarray){
  return(
    <View style={{flex:1, padding:15}} >
    <Text style={{marginBottom:5,flex:1,color:"#555"}}>{strings.distribstitches.willstart}</Text>
        <FlatList
          horizontal={true}
          data={visarray}
          keyExtractor={(item, index) => index}
          renderItem={(item) => this.renderStitch(item.item)} >
        </FlatList>
    </View>
    )
}

giveNumberStitches(title, action) {
  return (
    <View style={{ justifyContent:"center", alignItems: "center", height: 90}}>
      <Text
        style={{
          alignSelf: "center",
          textAlign: "center",
          flex: 1,
          color: "#555",
          letterSpacing: 0.5
        }}>
        {title}
      </Text>
      <TextInput
        style={[
          styles.textInput,
          {
            color: PURPLE_COLOR_PLAIN
          }
        ]}
        keyboardType="numeric"

        onChangeText={text => action(text)}
        returnKeyType="done"
      />
    </View>
  )

}


  render() {

    const NB_ITEMS_PER_ROW = Utils.isTablet() || Utils.isLandscape() ? 4 : 2;
    const equalWidth =
      (Dimensions.get("window").width -
        NB_ITEMS_PER_ROW * MARGIN * 2 -
        MARGIN_OUT * 2) /
      NB_ITEMS_PER_ROW;
    var svStyle = { flexGrow: 0, justifyContent: "space-between", padding: 20 };
    var todo = this.state.todo;
    var spread = this.state.spread;
    var startat = this.state.startat;
    var visarray = this.state.visarray

    if (this.state.even == false) {
      var diff1 = this.state.diff1;
      var diff2 = this.state.diff2;
      var diff3 = this.state.diff3;

      var spreadB = this.state.spreadB;
    } else {
      var diff = this.state.diff;

    }

    const width = Math.min(Dimensions.get("window").width / 2, 800)
    return (
        <ImageBackground urlOnServer={'/imgs/background_knitting2.jpg'}
          style={{
            width:'100%',
            height: Dimensions.get("window").height - HEADER_HEIGHT,
            justifyContent:'flex-start',
            alignItems:'center',
            padding:24
           }}
        >

          <View style={{
             width:width,
             justifyContent:"flex-start",
             padding:24}}>

            <View style={{
              backgroundColor: "rgba(255,255,255,0.8)",
            borderRadius: 12,
            justifyContent: "center", alignContent: "center", paddingVertical:10 }}>
                <View
                  style={{
                    height: 140,
                    alignSelf: "center",
                    margin: 20,
                    flexDirection:"row",
                  }}
                >
                    {this.giveNumberStitches(strings.distribstitches.currentnb,this.takeCurrentNb)}
                    <Image
                      style={[{ height: 40, width: 40, alignSelf:"center", margin: 20 }]}
                      resizeMode="contain"
                      urlOnServer={"../imgs/fleche-conv-rot.png"}
                    />
                    {this.giveNumberStitches(strings.distribstitches.desirednb,this.takeDesiredNb)}
                </View>
                <View style={{paddingBottom:10}}>
              <Button title={"ok"} style={{alignSelf:'center'}} onPress={() => this.validateAnswer()}/>
          </View>
          </View>


          {this.state.answer && this.state.even ?
          <View style={{ flex: 3, justifyContent: "center", paddingVertical: 20 }}>
            {false && this.renderAnswer(strings.distribstitches.weadvise, diff, todo)}
            {this.renderAnswer(strings.distribstitches.every, spread, strings.distribstitches.stitches, true)}
            {this.renderStartAt(strings.distribstitches.thefirst, todo, strings.distribstitches.after, strings.distribstitches.stitch, startat)}
          </View> :
          this.state.answer && this.state.instructions ?
          this.renderNotEvenSpreadAnwsers(this.state.instructions) :
          this.state.answer && !this.state.instructions &&
          <View style={{ flex: 3, justifyContent: "center", paddingVertical: 20 }}>
            {this.renderStartAt(strings.distribstitches.thefirst, todo, strings.distribstitches.after, strings.distribstitches.stitch, startat)}
            {this.renderAnswer2(strings.distribstitches.then, diff1, todo, strings.distribstitches.every, spread, strings.distribstitches.stitches)}
            {this.renderAnswer2(strings.distribstitches.then, diff2, todo, strings.distribstitches.after, spreadB, strings.distribstitches.stitches)}
            <Text style={{ flex: 1, marginLeft: 15, color: "#555" }} >{strings.distribstitches.endwith}</Text>
            {this.renderAnswer2("", diff3, todo, strings.distribstitches.every, spread, strings.distribstitches.stitches)}

          </View>
          }

          {this.state.answer && this.state.visarray && this.renderVisualisation(visarray)}

          </View>
        </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  toolContainer: {
    margin: MARGIN,
    shadowColor: "#000",
    shadowOpacity: 0.6,
    shadowRadius: 3,
    elevation: 8,
    backgroundColor: SECONDARY_COLOR,
    borderRadius: 8
  },
  textItem: {
    textAlign: "center",
    color: "#fff",
    backgroundColor: "transparent"
  },
  textInput: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    alignSelf: "center",
    textAlign: "center",
    borderRadius: 24,
    marginTop: 12,
    width: 100,
    backgroundColor: "rgba(43,165,247,0.15)",
  },
});
