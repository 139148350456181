import React, { Component } from "react";
import {
  ImageBackground,
} from 'react-native';


export default class MyImageBackground extends React.Component {
  
  render() {
  
    if (this.props.urlOnServer) {
      return (
           <ImageBackground {...this.props} source={{uri: this.props.urlOnServer}}/>
        )
    }
    return (
      <ImageBackground {...this.props}/>
      );
  }
}