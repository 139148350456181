import React from 'react';
import  { useEffect } from 'react';
import useDrivePicker from 'react-google-drive-picker'


function Drive() {
  const [openPicker, authResponse] = useDrivePicker();  

  
  const setToken = (token) => {
    if (token) {
      localStorage.setItem('googleToken', token);
      localStorage.setItem('googleTokenExpiration', Date.now() + (60 * 60 * 1000));
      console.log('setting token ', token)
    }
  }
  console.log('reload', authResponse)
  if (authResponse && authResponse.access_token)
    setToken(authResponse.access_token);
  const tokenExpires = localStorage.getItem('googleTokenExpiration');
  if (tokenExpires && tokenExpires < Date.now())
    localStorage.setItem('googleToken', '');
 

  


    useEffect(() => {
      const handleLoad = () => {

        
        setTimeout(() => handleOpenPicker(), 2000);
        // this block will run after the entire page has loaded
        console.log('Yo, my page has completely loaded!');
      };
  
      window.addEventListener('load', handleLoad);
  
      // Cleanup -> Remove the event listener on unmount
      return () => {
        window.removeEventListener('load', handleLoad);
      };
    }, []); // this dep array goes empty so it runs only once

    /*
  useEffect(() => {
    let ignore = false;
    
    if (!ignore)  handleOpenPicker()
    return () => { ignore = true; }
    },[]);*/

  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  const handleOpenPicker = () => {

    alert('got token' + localStorage.getItem('googleToken2'))

/*
      const config = {
        clientId: "820617804295-mnpphp16c1d9j04p6rm5uf6t4l50aa5r.apps.googleusercontent.com",
        developerKey: "",
        viewId: "DOCS",
        token: localStorage.getItem('googleToken') ? localStorage.getItem('googleToken') : '',
        showUploadView: true,
        showUploadFolders: true,
        supportDrives: true,
        multiselect: false,
        // customViews: customViewsArray, // custom view
        callbackFunction: (data) => {
          setToken();
          if (data.action === 'cancel') {
            console.log('User clicked cancel/close button')
          }
          
          //action:'loaded'
          //
          if (data.action === 'picked') {
  
            if (data.docs)
           // alert(JSON.stringify(data))
            //window.location.href ="/drive?my_token=" + token.current + '&file_id=' + data.docs[0].id;
            console.log(data)
          }
         // alert(JSON.stringify(data))
         // window.location.href ="/drive?my_token=" + token.current + '&file_id=';
          console.log(data)
  
          console.log(authResponse)
        },
      }
      const defaultScopes = ['https://www.googleapis.com/auth/drive.readonly'];
      const client = google.accounts.oauth2.initTokenClient({
        client_id: config.clientId,
        scope: (config.customScopes
            ? [...defaultScopes, ...config.customScopes]
            : defaultScopes
        ).join(' '),
        callback: (tokenResponse) => {
          console.log(tokenResponse);
          config.token = tokenResponse.access_token;

          setToken(config.token)
          openPicker(config);
        },
      })
  
      client.requestAccessToken()
    */


    openPicker({
      clientId: "820617804295-mnpphp16c1d9j04p6rm5uf6t4l50aa5r.apps.googleusercontent.com",
      developerKey: "",
      viewId: "DOCS",
      token: localStorage.getItem('googleToken') ? localStorage.getItem('googleToken') : '',
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: false,
      // customViews: customViewsArray, // custom view
      callbackFunction: (data) => {
        setToken();
        if (data.action === 'cancel') {
          console.log('User clicked cancel/close button')
        }
        
        //action:'loaded'
        //
        if (data.action === 'picked') {

          if (data.docs)
         // alert(JSON.stringify(data))
          //window.location.href ="/drive?my_token=" + token.current + '&file_id=' + data.docs[0].id;
          console.log(data)
        }
       // alert(JSON.stringify(data))
       // window.location.href ="/drive?my_token=" + token.current + '&file_id=';
        console.log(data)

        console.log(authResponse)
      },
    })
  }


  
  return (
    <div>
        <button onClick={() => handleOpenPicker()}>Open Picker</button>
    </div>
  );
}

export default Drive;