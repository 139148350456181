import React from 'react';
import useToken from '../hooks/useToken';
import useUser from '../hooks/useUser';
import { useState, useEffect } from 'react';
import { SERVER_API_URL } from '../config/Constants'
import ViewProject from './ViewProject';
import ProjectsList from '../components/ProjectsList';
import { BrowserRouter, Route, Switch, useRouteMatch, Link } from 'react-router-dom';
import ImageBackground from "../components/ImageBackground"
import Image from "../components/Image"
import Loading from "../components/Loading"
import ProfileHeader from "../components/ProfileHeader"
import Menu from "../components/Menu"
import { useHistory } from "react-router-dom";
import strings from "../config/strings";
import Utils from '../utils/utils'
import { HEADER_HEIGHT } from '../config/Constants'
import ProjectService from "../services/ProjectService";
import ProjectUtils from '../utils/projectUtils'
import {SECONDARY_COLOR} from "../config/styles";

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';



export default function Profile() {
  let match = useRouteMatch();

  const history = useHistory();
  const [projects, setProjects] = useState();
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  const [nbProjectsCompleted, setNbProjectsCompleted] = useState(0);
  const [nbRows, setNbRows] = useState(0);
  const [nbStitches, setNbStitches] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [showMenuProject, setShowMenuProject] = useState();
  const [loading, setLoading] = useState();
  const [premiumExpiresAt, setPremiumExpiresAt] = useState(-1);


  useEffect(() => {
    let mounted = true;

    fetch(`${SERVER_API_URL}/user_projects/get_overview.json`, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         'Authorization': "Bearer " + token
       },
       
     })
   .then(data => data.json())
    .then(data => {

      //alert(JSON.stringify(user))
        if(mounted) {

          var user_projects = data.user_projects ? data.user_projects.sort((a, b) => a.updated_at_on_client < b.updated_at_on_client ? 1 : -1) : []
          
          setProjects(user_projects)
          setNbProjectsCompleted(data.user_projects.filter(item => item.finished == 1).length)
          setNbRows(data.nb_rows)
          setNbStitches(data.nb_stitches)
          setElapsedTime(data.time_elapsed)
          setPremiumExpiresAt(data.premium_expires_at)

          localStorage.setItem('premiumExpiresAt', data.premium_expires_at)
          if (user) {
            user.level = data.level
            setUser(user)
          }
          //alert(JSON.stringify(user))

        }
      })
    return () => mounted = false;
  }, [])

//{list.map(item => <div><Link key={item.id} to={`/ViewProject/${item.id}`}>{item.custom_name}</Link></div> )}


  const counters = [
      {
        pic: "/imgs/needles.png",
        number:nbStitches,
        name:strings.stitches,
      },
      {
        pic: "/imgs/rang.png",
        number: nbRows ,
        name:strings.rangs,
      },
      {
        pic: "/imgs/fini.png",
        number:nbProjectsCompleted,
        name:strings.projs_completed,
      },
      {
        pic: "/imgs/hourglass.png",
        number:Utils.convertSecondsToTimer(elapsedTime),
        name:strings.time_elapsed,
        link: true,
      },]

  const deleteProject = (project) => {
    //alert(JSON.stringify(project))


    setLoading(true)

    toggleDataDeleted(project)

    ProjectUtils.sendUpdateToServer(showMenuProject)
    .then((response) => {
      setLoading(false)
     
      var newArray = [...projects]

      newArray.splice(newArray.indexOf(project), 1) 
      setProjects(newArray)
      setShowMenuProject(null)
    
    })
    
    .catch((error) => {
      setLoading(false)
      toggleDataDeleted(project)
      alert(error.message)
      
    });

  }

  const toggleDataDeleted = (project) => {
    if (project.deleted === 1) {
     project.deleted = 0
     //setNbProjectsCompleted(nbProjectsCompleted - 1)
    }
   else {
     project.deleted = 1
     //setNbProjectsCompleted(nbProjectsCompleted - 1)
    }
  }


  const toggleDataFinish = (project) => {
    if (project.finished === 1) {
     project.finished = 0
     setNbProjectsCompleted(nbProjectsCompleted - 1)
    }
   else {
     project.finished = 1
     setNbProjectsCompleted(nbProjectsCompleted - 1)
    }
  }

  const toggleFinish = (project) => {


    setLoading(true)

    toggleDataFinish(project)

    ProjectService.sendUpdateToServer(showMenuProject, (event) => {
      //setProgress(Math.round((100 * event.loaded) / event.total));
    })
    .then((response) => {
      setLoading(false)
      setShowMenuProject(null)
    
    })
    
    .catch((error) => {
      setLoading(false)
      toggleDataFinish(project)
      alert(error.message)
     
    });

   // alert(JSON.stringify(project))
  }
 
  const buildMenuContent = () => {
      
    var menuContent = [
      {
        txt: strings.delete,
        onPress: () => deleteProject(showMenuProject),
      },
      {
        txt: showMenuProject.finished !== 1 ? Utils.capitalizeFirstLetter(strings.finish.toLowerCase()) : Utils.capitalizeFirstLetter(strings.cancel) + ' ' + strings.finish.toLowerCase(),
        onPress: () => toggleFinish(showMenuProject),
      },]
    
       
      return menuContent
  }


  if (loading) {
    return (<Loading/>)
  }
  return(
 

    <ImageBackground urlOnServer={'/imgs/background_knitting2.jpg'} style={{flexDirection:'row', width: Dimensions.get('window').width, height: Dimensions.get('window').height - HEADER_HEIGHT - 1}}>

    <View style={{flex:1, marginRight: 12}}>
      <ProjectsList 
        style={{flex:1}} 
        projects={projects} 
        onProjectSelected={(project) => history.push(`/ViewProject/${project.id}`)}
        onProjectOptionsSelected={(project) => setShowMenuProject(project) }
        onNewProject={() => history.push(`/NewProject`) }/>
      
      {premiumExpiresAt !== -1 && premiumExpiresAt < Date.now() && (
      <View style={{alignItems:'center', width:'100%', padding: 16}}><a href='https://rowcounterapp.com'>
        <Text style={{color:SECONDARY_COLOR, fontWeight:'700', padding:16}}>Get Premium to sync with the apps</Text></a>
      </View>
      )}
    </View>
    <ProfileHeader counters={counters}/>


     {showMenuProject && <Menu menuContent={buildMenuContent()} onClose={() => setShowMenuProject(null)}/>}


     
    </ImageBackground>



  );
}

