import React, { Component } from "react";
import strings from "../config/strings";
import Image from "../components/Image"
import ImageBackground from "../components/ImageBackground"
import Button from "../components/Button"
import Utils from "../utils/utils";
import {SECONDARY_COLOR} from "../config/styles";
import { BrowserRouter, Route, Switch, useRouteMatch, Link, useParams, useHistory } from 'react-router-dom';
import TitledTextInput from "../components/TitledTextInput";
import { HEADER_HEIGHT } from '../config/Constants'

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';


const MARGIN = 6;

const MARGIN_OUT = 6;

const { width, height } = Dimensions.get("window");
const gutter = 0;

const BORDER_RADIUS = 6;


export default class ComputeExample extends Component {
  handler = dims => this.setState(dims);

  constructor(props) {
    super(props);

    this.stitchesNbPattern = 0;
    this.rowsNbPattern = 0;
    this.stitchesNbProj = 0;
    this.rowsNbProj = 0;
    this.stitchesNbEch = 0;
    this.rowsNbEch = 0;

    this.state = { nbStitchesResult: null };
  }


  componentWillMount() {
    Dimensions.addEventListener("change", this.handler);
  }

  componentWillUnmount() {
    // Important to stop updating state after unmount
    Dimensions.removeEventListener("change", this.handler);
  }

  stitchesNbPatternChanged(value) {
    this.stitchesNbPattern = parseInt(value);
  }

  rowsNbPatternChanged(value) {
    this.rowsNbPattern = parseInt(value);
  }

  stitchesNbProjChanged(value) {
    this.stitchesNbProj = parseInt(value);
  }

  rowsNbProjChanged(value) {
    this.rowsNbProj = parseInt(value);
  }

  stitchesNbEchChanged(value) {
    this.stitchesNbEch = parseInt(value);
  }

  rowsNbEchChanged(value) {
    this.rowsNbEch = parseInt(value);
  }

  compute() {

    if (this.stitchesNbPattern == 0 ||
    this.rowsNbPattern == 0 ||
    this.stitchesNbProj == 0 ||
    this.rowsNbProj == 0 ||
    this.stitchesNbEch == 0 ||
    this.rowsNbEch == 0) {
      alert(strings.fill_all_fields)
      return
    }
    var adjustX = this.stitchesNbEch / this.stitchesNbPattern;
    var adjustY = this.rowsNbEch / this.rowsNbPattern;

    var nbStitches = Math.round(this.stitchesNbProj * adjustX);
    var nbRows = Math.round(this.rowsNbProj * adjustY);


    this.setState({ nbStitchesResult: nbStitches, nbRowsResult: nbRows });
  }


     back = () => {
    this.props.navigation.goBack();
  };



  onClose() {
    this.setState({nbStitchesResult : null})

  }
  renderResult() {
    return (
      <View style={styles.containerResult}>
        <View
          style={[
            styles.container,
            {
              marginLeft: 8,
              marginRight: 8,
            }
          ]}
        >
          <View style={[styles.header, { backgroundColor:"#2ba5f7"}]}>
            <Text style={[styles.headerText, {color:"white"}]}>{strings.your_project}</Text>

            <TouchableOpacity
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                width: 38,
                height: 38,
                justifyContent: "center"
              }}
              onPress={() => this.onClose()}
            >
              <Image style={styles.closeImg} urlOnServer={"/imgs/close.png"} />
            </TouchableOpacity>
          </View>
          


          <View style={{ flexDirection: "row", alignItems: "center", padding:12 }}>
            <View style={{ width: "50%"}}>
              <Text style={styles.titleResult}>{strings.must_monter}</Text>
              <View style={{ flexDirection: "row", alignItems: "center", paddingBottom:10 }}>
                <Image
                  style={[{ height: 25, width: 29 }]}
                  resizeMode="contain"
                  urlOnServer={"/imgs/echantillon/maillerose.png"}
                />
                <Text style={styles.nbText}>{this.state.nbStitchesResult}</Text>
                <Text style={{color:'#555'}}>{strings.stitches.toLowerCase()}</Text>
              </View>


                <Text style={styles.titleResult}>{strings.must_knit}</Text>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Image
                  style={[{ height: 25, width: 29 }]}
                  resizeMode="contain"
                  urlOnServer={"/imgs/echantillon/rangrose.png"}
                />
                <Text style={styles.nbText}>{this.state.nbRowsResult}</Text>
                <Text style={{color:'#555'}}>{strings.rangs.toLowerCase()}</Text>
              </View>
            </View>

            <Image
              style={[{ height: 80, width: 80, marginLeft:30, alignSelf:"center" }]}
              resizeMode="contain"
              urlOnServer={"/imgs/echantillon/sweater.png"}
            />
          </View>





        </View>




      </View>
    );
  }

  render() {
   
    return (
      <ImageBackground urlOnServer={'/imgs/background_knitting2.jpg'}
       
        style={{ width:'100%', height: Dimensions.get('window').height - HEADER_HEIGHT, justifyContent: "center", alignItems: "center"}}
      >
       
          <View
            style={[
              styles.container,
              {
                marginLeft: 8,
                marginRight: 8,
                width: Math.min(400, Dimensions.get('window').width)
              }
            ]}
          >
            <View style={styles.header}>
              <Text style={styles.headerText}>{strings.pattern}</Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10
              }}
            >
              <View>
                <TitledTextInput
                  style={{ color:'#555' }}
                  title={strings.stitches + ":"}
                  img="/imgs/echantillon/maille.png"
                  valueChanged={value => this.stitchesNbPatternChanged(value)}
                />
                <TitledTextInput
                  style={{ marginTop: 5, color:'#555' }}
                  title={strings.rangs + ":"}
                  img="/imgs/echantillon/rang.png"
                  valueChanged={value => this.rowsNbPatternChanged(value)}
                />
              </View>

              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text style={styles.textEch}>{strings.tencm}</Text>
                <View style={{ flexDirection: "column", alignItems: "center" }}>
                  <Text style={styles.textEch}>{strings.swatch}</Text>

                  <Image
                    style={[{ height: 60, width: 60 }]}
                    resizeMode="contain"
                    urlOnServer={"/imgs/echantillon/echantillon.png"}
                  />
                  <Text style={styles.textEch}>{strings.tencm}</Text>
                </View>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <View>
                <TitledTextInput
                  style={{ color:'#555' }}
                  title={strings.stitches + ":"}
                  img="/imgs/echantillon/maille.png"
                  valueChanged={value => this.stitchesNbProjChanged(value)}
                />
                <TitledTextInput

                  style={{ marginTop: 5, color:'#555' }}
                  title={strings.rangs + ":"}
                  img="/imgs/echantillon/rang.png"
                  valueChanged={value => this.rowsNbProjChanged(value)}
                />
              </View>

              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View style={{ flexDirection: "column", alignItems: "center" }}>
                  <Text style={styles.textEch}>{strings.the_project}</Text>

                  <Image
                    style={[{ height: 60, width: 60 }]}
                    resizeMode="contain"
                    urlOnServer={"/imgs/echantillon/sweater.png"}
                  />
                </View>
              </View>
            </View>
          </View>

          <View
            style={[
              styles.container,
              {
                marginLeft: 8,
                marginRight: 8,
                marginBottom: 50,
                width: Math.min(400, Dimensions.get('window').width)
              }
            ]}
          >
            <View style={styles.header}>
              <Text style={styles.headerText}>{strings.your_swatch}</Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10
              }}
            >
              <View>
                <TitledTextInput
                style={{ color:'#555' }}
                  title={strings.stitches + ":"}
                  img="/imgs/echantillon/maillerose.png"
                  valueChanged={value => this.stitchesNbEchChanged(value)}
                />
                <TitledTextInput
                  style={{ marginTop: 5, color:'#555' }}
                  title={strings.rangs + ":"}
                  img="/imgs/echantillon/rang.png"

                  valueChanged={value => this.rowsNbEchChanged(value)}
                />
              </View>

              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text style={styles.textEch}>{strings.tencm}</Text>
                <View style={{ flexDirection: "column", alignItems: "center" }}>
                  <Text style={styles.textEch}>{strings.swatch}</Text>

                  <Image
                    style={[{ height: 60, width: 60 }]}
                    resizeMode="contain"
                    urlOnServer={"/imgs/echantillon/echantillonrose.png"}
                  />
                  <Text style={styles.textEch}>{strings.tencm}</Text>
                </View>
              </View>
            </View>
          </View>



        <Button title={'OK'} onPress={() => this.compute()}/>

        {this.state.nbStitchesResult != null && this.renderResult()}
      </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  toolContainer: {
    margin: MARGIN,
    shadowColor: "#000",
    shadowOpacity: 0.6,
    shadowRadius: 3,
    elevation: 8,
    backgroundColor: "#2ba5f7",
    borderRadius: 8
  },
  textItem: {
    textAlign: "center",
    color: "white",
    backgroundColor: "transparent"
  },
  textEch: {
    fontSize: 12,
    fontStyle: "italic",
    color: "#999",
    margin: 2
  },
  containerResult: {
    padding: 20,
    width: "100%",
    height: "100%",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(52, 52, 52, 0.8)'
  },

  header: {
    paddingTop: 5,

    overflow: "hidden",
    borderTopRightRadius: BORDER_RADIUS,
    borderTopLeftRadius: BORDER_RADIUS
  },
  headerText: {
    textAlign: "center",
    color: "#AAA",
    fontSize: 18,
    borderBottomWidth: 1,
    borderColor: "#CCC",
    paddingBottom: 5
  },
  container: {
    backgroundColor: "white",
    shadowOffset: { width: 0, height: 1 },
    shadowColor: "#000",
    shadowOpacity: 0.6,
    shadowRadius: 3,
    elevation: 8,

    borderRadius: BORDER_RADIUS,

    marginTop: 15,
    marginBottom: 2,

    flexDirection: "column",
    justifyContent: "space-around"
  },
  touchableOk: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 10,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row"
  },
  closeImg: {
    width: 20,
    height: 20,
    alignSelf: "center"
  },
  nbText: {
    color: "red",
    fontSize: 17,
    fontWeight: "600",
    marginLeft:5,
    marginRight:5,
  },
  titleResult : {
    fontSize: 12,
     fontStyle: "italic",
    color: "#888",
  }
});
