
import { RAVELRY_CRAFT_FILTER, SERVER_URL } from "../config/Constants";









const RAVELRY_ROOT = 'https://api.ravelry.com'

export default class ravelryService {


  constructor(ravelryToken, ravelryUsername) {
    this.ravelryToken = ravelryToken;
    this.ravelryUsername = ravelryUsername;
  }
 
  getCurrentUser () {

  //return this.request('get', `/current_user.json`, {})
    //    .then(response => response.user)

    return this.request('get', '/current_user.json')
          .then(response => response.user);
  }

  getLibrary() {

    return this.request('get', '/people/$USERNAME_PLACEHOLDER$/library/search.json')
    //  .then(response => response);

  }



  getProjects() {

    return this.request('get', '/projects/$USERNAME_PLACEHOLDER$/list.json')
   

  }
  generateDownloadLink(productAttachmentId) {

    console.debug("pingou " + '/product_attachments/' + productAttachmentId + '/generate_download_link.json')
    //   alert("pingou " + '/product_attachments/' + productAttachmentId + '/generate_download_link.json')
  

   return this.request('post', '/product_attachments/' + productAttachmentId + '/generate_download_link.json')
      .then(response => response.download_link);

  }

  search(query) {

    //query && query.split(' ').length > 3 
    var availability = "free"
    var sort = "best"
    var craft = RAVELRY_CRAFT_FILTER
    var page_size = "300"
    return this.request('get', '/patterns/search.json', {query, availability, sort, craft, page_size})
     
  }


  searchLibrary(query) {
    
    var page_size = "300"

    return this.request('get', '/people/$USERNAME_PLACEHOLDER$/library/search.json', {query, page_size})
    //return this.GET('/people/' + username + '/library/search.json', {query, page_size})
      //.then(response => response);
  }
   getVolume(volumeId) {
    return this.request('get', '/volumes/' + volumeId + '.json')
      .then(response => response.volume);
  }

  getPattern(patternId) {

    return this.request('get', '/patterns/' + patternId + '.json')
      .then(response => response.pattern);
  }



  async request(method, path, args = {}, nbRetry = 0) {



    
alert(this.ravelryToken)
      if (path.indexOf("$USERNAME_PLACEHOLDER$"))
        path = path.replace("$USERNAME_PLACEHOLDER$", this.ravelryUsername)
     

      let options = Object.assign({ method: method }, null);
      options.headers = this.ravelryToken ? { Authorization: "Bearer " + this.ravelryToken }: {}

       if (method.toLowerCase() == "get")
        path = path + this.querystring(args);
      else

          options.body = args;
      options.timeout = 20000;

  
      let url = RAVELRY_ROOT + path;


      try {
        var response = await fetch(url, options)
     //   alert('got response ' + JSON.stringify(response))

        if (response != null && (response.status == 401 || response.status == 403)) {


              //si on a choppé un stash d'un autre utilisateur, quand on va essayer de l'updater ca va indiquer quon a pas les droits
              //dans ce cas on essaye pas de sidentifier

              if (method.toLowerCase() == "post" && url.indexOf('people') != -1 && url.indexOf('/stash') != -1 ) {
                throw {message: 'not current user'}
              }
           
            alert('unautorized')
              
            
        }
        else {

          var json = response.json()
         alert(JSON.stringify(json))
          return json
          //
        }
      }
      catch (error) {
        alert('error ' + error.message)
       

        throw error
        //alert('error ' + error.message)
      }


  }



  getNeedleList() {

    return this.request('get', `/people/$USERNAME_PLACEHOLDER$/needles/list.json`, {})
  }


  getStash(stashId) {


    return this.request('get', `/people/$USERNAME_PLACEHOLDER$/stash/${stashId}.json`, {})
  }


  getStashList() {

    return this.request('get', `/people/$USERNAME_PLACEHOLDER$/stash/list.json`, {})
  }

  getProject(id) {

    return this.request('get', `/projects/$USERNAME_PLACEHOLDER$/${id}.json`, {})
  }

  searchYarn(query) {
    return this.request('get', `/yarns/search.json`, {query})
  }

  getYarn(id) {
    return this.request('get', `/yarns/${id}.json`, {})
  }


  postStash(stashStr) {

    return this.request('post', `/people/$USERNAME_PLACEHOLDER$/stash/create.json`, stashStr)
  }

  updateStash(stashId, stashStr) {

    return this.request('post', `/people/$USERNAME_PLACEHOLDER$/stash/${stashId}.json`, stashStr)
  }

  //shouldnt be used, api doesnt work
  updatePack(packId, packStr) {

    return this.request('put', `/packs/${packId}.json`, packStr)
  }

  createPack(packStr) {

    return this.request('post', `/packs/create.json`, packStr)
  }

  querystring(query = {}) {
  // get array of key value pairs ([[k1, v1], [k2, v2]])
    const qs = Object.entries(query)
    // filter pairs with undefined value
    .filter(pair => pair[1] !== undefined)
    // encode keys and values, remove the value if it is null, but leave the key
    .map(pair => pair.filter(i => i !== null).map(encodeURIComponent).join('='))
    .join('&');

    return qs && '?' + qs;
  }




};
