import React, { useState, useEffect } from "react";
import UploadService from "../../services/FileUploadService";
import strings from "../../config/strings";
import Button from "../../components/Button";
import Image from "../../components/Image"
import Utils from '../../utils/utils';
import { SERVER_API_URL, APP_NAME } from '../../config/Constants'
import fetchClient from "../../utils/http-common";
import "../../config/styles.css"

const PicUpload = (props) => {
  
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [accept, setAccept] = useState(props.type == "img" ? "image/*" : "video/*" )
  const [pattern, setPattern] = useState(props.pattern)

  const setLoad = props.onLoading

  useEffect(() => {
    console.log("pattern passed 2", props.pattern)
    const pattern = props.pattern
    setPattern(pattern)

  }, [pattern])


  const selectFile = (event) => {
    console.log(event.target.files[0])
    setSelectedFiles(event.target.files[0]);
  };

  // uploadFile(dbPattern.idOnServer, dbPattern.idOnServer, Utils.getRealImgPath(dbPattern.imgPath), dbPattern.imgVersion)

  const uploadFile = (patternId, originelParentId, file, version) => {
     if (setLoad) {
       setLoad(true)
     }
    const data = new FormData();

    data.append(`pattern_attachment[version]`, version);
    data.append(`pattern_attachment[patternId]`, patternId);
    data.append(`pattern_attachment[originelParentId]`, originelParentId);
    data.append(`pattern_attachment[typeFile]`, 'pattern_img');


    if (file) {
      data.append(`pattern_attachment[file]`, 
        file
      );
    }

    fetchClient.post(`${SERVER_API_URL}/pattern_attachments.json`, data)
    .then(data => {
      console.log("uploaded video", data)
      if (setLoad) {
        setLoad(false)
      }
      props.onSuccess(data)
    })
    .catch(error => {
      alert(error.message)
      setProgress(0);
      setMessage("Could not upload the file!");
      setCurrentFile(undefined);
     })
      
    setSelectedFiles(undefined);
}
/*
  const upload = () => {
    let currentFile = selectedFiles[0];

    setProgress(0);
    setCurrentFile(currentFile);

    UploadService.uploadPDF(currentFile, props.projectId, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((response) => {

       // alert(JSON.stringify(response))
        //setMessage(response.data.message);
        props.onSuccess(response)
       
      })
      
      .catch((error) => {

        alert(error.message)
        setProgress(0);
        setMessage("Could not upload the file!");
        setCurrentFile(undefined);
      });

    setSelectedFiles(undefined);
  };

 */

  return (
    <div style={{display: 'flex', flexDirection:"column" ,justifyContent: 'center', alignItems: 'center'}}>
      {currentFile && (
        <div className="progress">
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: progress * 2 }}
          >
            {progress}%
          </div>
        </div>
      )}
      <label class="CustomFileUpload ">
          <input type="file" onChange={selectFile} accept={accept}  />
          <Image urlOnServer={selectedFiles?'/imgs/ok.png':'/imgs/cloud-computing.png'} style={{width: 20, height: 20, margin:5}}/>
           {selectedFiles? strings.patternCreator.fileSelected : strings.patternCreator.chooseFile}
      </label>


    {selectedFiles && <div style={{width:100}}>
      <Button
        style={{alignSelf:'center', marginTop: 30}}
        title={strings.import}
        onPress={() => uploadFile(pattern.id, pattern.id, selectedFiles, pattern.imgVersion + 1)}
      />
      </div>}


      <div role="alert">
        {message}
      </div>

      
    </div>
  );
};

export default PicUpload;

// uploadFile(pattern.id, pattern.id, selectedFiles, pattern.imgVersion + 1)}
//Cacaprout42!fi