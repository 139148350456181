export const GLOSSARY_CRO = [
  {
    id: 1,
    en: {abbr: 'inc', txt: 'increase'},
    fr: {abbr: 'augm.', txt: 'Augmenter, augmentation'},
    de: {
      abbr: 'M1',
      txt:
        ' 1 Masche zunehmen, indem man den Arbeitsfaden einmal verdreht um die rechte Nadel wickelt (kann durch rechts- oder linksgelehnte Zunahmen ersetzt werden)',
    },
  },
  {
    id: 2,
    en: {abbr: 'sl st', txt: 'slip stitch'},
    fr: {abbr: '1 m. glis.', txt: 'Maille glissée'},
    de: {abbr: '1 M abh.', txt: '1 Masche abheben'},
  },
  {
    id: 3,
    en: {abbr: 'sc2tog', txt: 'single crochet 2 stitches together'},
    fr: {abbr: '2ms ens.', txt: '2 mailles serrées ensemble'},
    de: {abbr: '2FM zus.', txt: '2 feste Maschen zusammen'},
  },
  {
    id: 4,
    en: {abbr: 'hdc2tog', txt: 'half double crochet 2 stitches together'},
    fr: {abbr: '2db ens.', txt: '2 demi-brides ensemble'},
    de: {abbr: '2 hStb zus.', txt: '2 halbes Stäbchen zusammen'},
  },
  {
    id: 5,
    en: {abbr: 'tr2tog', txt: 'treble crochet 2 stitches together'},
    fr: {abbr: '2b ens.', txt: '2 brides ensemble'},
    de: {abbr: '2Stb zus.', txt: '2 Stäbchen zusammen'},
  },
  {
    id: 6,
    en: {abbr: 'dc2tog', txt: 'double crochet 2 stitches together'},
    fr: {abbr: '2bens.', txt: '2 brides ensemble'},
    de: {
      abbr: '2 Stb. zsm. (abm.)',
      txt: '2 Stäbchen zusammenhäkeln / zusammen abmaschen/arbeiten',
    },
  },
  {
    id: 7,
    en: {abbr: 'dec', txt: 'decrease'},
    fr: {abbr: 'dim.', txt: 'Diminution'},
    de: {abbr: 'abn.', txt: 'abnehmen'},
  },
  {
    id: 1,
    en: {abbr: 'alt', txt: 'alternate'},
    fr: {abbr: 'alt', txt: 'Alterner'},
    de: {txt: 'Abwechselnd'},
  },
  {
    id: 2,
    en: {abbr: 'pat or patt', txt: 'pattern'},
    fr: {abbr: 'Mod.', txt: 'Modèle ou patron'},
    de: {abbr: 'Anl.', txt: 'Anleitung'},
  },
  {
    id: 3,
    en: {abbr: 'beg', txt: 'begin/beginning'},
    fr: {abbr: 'com.', txt: 'Commencer'},
    de: {abbr: 'beg.', txt: 'Beginnen Sie'},
  },
  {
    id: 4,
    en: {abbr: 'rem', txt: 'remaining'},
    fr: {abbr: 'rest.', txt: 'Restant/restante'},
    de: {txt: 'bleibende'},
  },
  {
    id: 5,
    en: {abbr: 'bo', txt: 'bobble'},
    fr: {abbr: 'B', txt: 'Faire des nopes'},
    de: {abbr: 'B', txt: 'Bobbles machen'},
  },
  {
    id: 6,
    en: {abbr: 'dtr', txt: 'double treble crochet'},
    fr: {abbr: 'Db', txt: 'Double bride'},
    de: {abbr: '3er-Stb / 3fach-Stb', txt: 'Dreifachstäbchen'},
  },
  {
    id: 7,
    en: {abbr: 'sc', txt: 'single crochet'},
    fr: {abbr: 'ms', txt: 'maille serrée'},
    de: {abbr: 'FM', txt: 'feste Masche'},
  },
  {
    id: 8,
    en: {abbr: 'foll', txt: 'following'},
    fr: {abbr: 'suiv.', txt: 'Suivant'},
    de: {txt: 'folgende'},
  },
  {
    id: 9,
    en: {abbr: 'BPhdc', txt: 'back post half double crochet'},
    fr: {abbr: 'dbRar ', txt: 'demi-bride relief arrière'},
    de: {abbr: 'halbes hinteres RStb', txt: 'halbes hinteres Reliefstäbchen'},
  },
  {
    id: 10,
    en: {abbr: 'hdc', txt: 'half double crochet'},
    fr: {abbr: 'db', txt: 'demi-bride'},
    de: {abbr: 'hStb', txt: 'halbes Stäbchen'},
  },
  {
    id: 11,
    en: {abbr: 'FPhdc', txt: 'front post half double crochet'},
    fr: {abbr: 'dbRav', txt: 'demi-bride relief avant'},
    de: {abbr: 'halbes vorderes RStb', txt: 'halbes vorderes Reliefstäbchen'},
  },
  {
    id: 12,
    en: {abbr: 'MC', txt: 'main color'},
    fr: {abbr: 'CP', txt: 'Couleur principale'},
    de: {abbr: 'HF', txt: 'Hauptfarbe'},
  },
  {
    id: 13,
    en: {abbr: 'RS', txt: 'right side'},
    fr: {abbr: 'Coté end.', txt: "Endroit (de l'ouvrage)"},
    de: {abbr: 'HinR', txt: 'Hinreihe'},
  },
  {
    id: 14,
    en: {abbr: 'BL or BLO', txt: 'back loop or back loop only'},
    fr: {abbr: 'b. arr.', txt: "Brin arrière d'une maille"},
    de: {abbr: 'hMgl', txt: 'hinteres Maschenglied'},
  },
  {
    id: 15,
    en: {abbr: 'BP', txt: 'back post'},
    fr: {abbr: 'Rar', txt: 'relief arrière'},
    de: {abbr: 'hinteres R', txt: 'hinteres Relief-'},
  },
  {
    id: 16,
    en: {abbr: 'BPdc', txt: 'back post double crochet'},
    fr: {abbr: 'bRar ', txt: 'bride relief arrière'},
    de: {abbr: 'hinteres RStb', txt: 'hinteres Reliefstäbchen'},
  },
  {
    id: 17,
    en: {abbr: 'CC', txt: 'contrasting color'},
    fr: {abbr: 'CC', txt: 'Couleur contrastante, différente'},
    de: {abbr: 'KF', txt: 'Kontrastfarbe'},
  },
  {
    id: 18,
    en: {abbr: 'ch', txt: 'chain stitch'},
    fr: {abbr: 'ms', txt: 'Maille Chainette'},
    de: {abbr: 'LM', txt: 'Luftmasche'},
  },
  {
    id: 19,
    en: {abbr: 'm', txt: 'marker'},
    fr: {abbr: 'M', txt: 'Marqueur'},
    de: {txt: 'Markierer'},
  },
  {
    id: 20,
    en: {abbr: 'st', txt: 'stitch'},
    fr: {abbr: 'm', txt: 'maille'},
    de: {abbr: 'm', txt: 'Masche'},
  },
  {
    id: 21,
    en: {abbr: 'lp', txt: 'loop'},
    fr: {abbr: 'b.', txt: 'Brin, boucle, bride'},
    de: {txt: 'Maschenbein'},
  },
  {
    id: 22,
    en: {abbr: 'sm or sl m', txt: 'slip marker'},
    fr: {abbr: 'glis. M', txt: 'Glisser le marqueur'},
    de: {abbr: 'Markierer abh.', txt: 'Maschenmarkierer abheben'},
  },
  {
    id: 23,
    en: {abbr: 'pm', txt: 'place marker'},
    fr: {abbr: 'PM', txt: 'Insérer un anneau marqueur'},
    de: {abbr: 'MM setzen', txt: 'Maschenmarkierer setzen'},
  },
  {
    id: 24,
    en: {abbr: 'pc', txt: 'popcorn stitch'},
    fr: {txt: 'Point popcorn'},
    de: {txt: 'Popcorn'},
  },
  {
    id: 25,
    en: {abbr: 'ps or puff', txt: 'puff stitch'},
    fr: {abbr: 'ps ou puff', txt: 'puff stitch'},
    de: {abbr: 'ps oder puff', txt: 'puff stitch'},
  },
  {
    id: 26,
    en: {abbr: 'WS', txt: 'wrong side'},
    fr: {abbr: 'env.', txt: "Envers (de l'ouvrage)"},
    de: {abbr: 'RückR', txt: 'Rücken Reihe'},
  },
  {
    id: 27,
    en: {abbr: 'rnd', txt: 'round'},
    fr: {abbr: 'T', txt: 'Tour (désigne un rang quand on tricote en rond)'},
    de: {abbr: 'Rd', txt: 'Runde'},
  },
  {
    id: 28,
    en: {abbr: 'sh', txt: 'shell'},
    fr: {abbr: 'sh', txt: 'shell'},
    de: {abbr: 'sh', txt: 'shell'},
  },
  {
    id: 29,
    en: {abbr: 'dc', txt: 'double crochet'},
    fr: {abbr: 'b', txt: 'bride'},
    de: {abbr: 'Stb', txt: 'Stäbchen'},
  },
  {
    id: 30,
    en: {abbr: 'tr', txt: 'treble crochet'},
    fr: {abbr: 'b', txt: 'bride'},
    de: {abbr: 'Stb', txt: 'Stäbchen'},
  },
  {
    id: 31,
    en: {abbr: 'sk', txt: 'skip'},
    fr: {txt: 'Passer, ignorer, ne pas tenir compte de…'},
    de: {txt: 'Überspringen'},
  },
  {
    id: 32,
    en: {abbr: 'yo', txt: 'yarn over'},
    fr: {txt: 'Jeté'},
    de: {abbr: 'U', txt: 'Umschlag'},
  },
  {
    id: 33,
    en: {abbr: 'yoh', txt: 'yarn over hook'},
    fr: {txt: 'Jeté'},
    de: {abbr: 'U', txt: 'Umschlag'},
  },
  {
    id: 34,
    en: {abbr: 'approx', txt: 'approximately'},
    fr: {abbr: 'approx', txt: 'Approximativement'},
    de: {txt: 'Ungefähr'},
  },
  {
    id: 35,
    en: {abbr: 'tbl', txt: 'through back loop'},
    fr: {abbr: 'b. arr.', txt: "Dans l'arrière de la maille"},
    de: {abbr: 'verschr', txt: 'verschränkt'},
  },
  {
    id: 36,
    en: {abbr: 'FL or FLO', txt: 'front loop or front loop only'},
    fr: {abbr: 'b. av.', txt: "Brin avant d'une maille"},
    de: {txt: 'vordere Maschenbein'},
  },
  {
    id: 37,
    en: {abbr: 'FPdtr', txt: 'front post double treble crochet'},
    fr: {abbr: 'DbRav', txt: 'Double bride relief avant'},
    de: {abbr: 'vorderes dRStb', txt: 'vorderes doppeltes Reliefstäbchen'},
  },
  {
    id: 38,
    en: {abbr: 'FPsc', txt: 'front post single crochet'},
    fr: {abbr: 'msRav', txt: 'maille serrée relief avant'},
    de: {txt: 'vorderes Relief feste Masche'},
  },
  {
    id: 39,
    en: {abbr: 'FP', txt: 'front post'},
    fr: {abbr: 'Rav', txt: 'relief avant'},
    de: {abbr: 'vorderes R', txt: 'vorderes Relief-'},
  },
  {
    id: 40,
    en: {abbr: 'FPdc', txt: 'front post double crochet'},
    fr: {abbr: 'bRav', txt: 'bride relief avant'},
    de: {abbr: 'vorderes RStb', txt: 'vorderes Reliefstäbchen'},
  },
  {
    id: 41,
    en: {abbr: 'FPtr', txt: 'front post treble crochet'},
    fr: {abbr: 'bRav', txt: 'bride relief avant'},
    de: {abbr: 'vorderes RStb', txt: 'vorderes Reliefstäbchen'},
  },
  {
    id: 42,
    en: {abbr: 'cont', txt: 'continue'},
    fr: {abbr: 'cont', txt: 'Continuer'},
    de: {txt: 'Weiter gehen'},
  },
  {
    id: 43,
    en: {abbr: 'rep', txt: 'repeat'},
    fr: {abbr: 'rep.', txt: 'Répéter'},
    de: {abbr: 'wdh', txt: 'wiederholen'},
  },
  {
    id: 44,
    en: {abbr: 'tog', txt: 'together'},
    fr: {abbr: 'ens.', txt: 'Ensemble (mailles)'},
    de: {abbr: 'Zus.', txt: 'Zusammen'},
  },
  {
    id: 45,
    en: {abbr: 'bet', txt: 'between'},
    fr: {abbr: 'ent.', txt: 'Entre'},
    de: {abbr: 'Zwis.', txt: 'Zwischen'},
  },
  {
    id: 46,
    en: {abbr: 'BPsc', txt: 'back post single crochet'},
    fr: {abbr: 'msRar', txt: 'maille serrée relief arriere'},
    de: {abbr: 'hinteres RFMhinteres Relief feste Masche'},
  },
  {
    id: 47,
    en: {abbr: 'BPtr', txt: 'back post treble crochet'},
    fr: {abbr: 'bRar', txt: 'bride relief arrière'},
    de: {abbr: 'hinteres RStb', txt: 'hinteres Reliefstäbchen'},
  },
  {
    id: 48,
    en: {
      abbr: 'ch-',
      txt: 'refer to chain or space previously made, e.g., ch-1 space',
    },
    fr: {
      abbr: 'ch-1',
      txt: "Fait référence à la chaîne ou à l'espace précédemment réalisé.",
    },
    de: {
      txt:
        'bezieht sich auf eine Kette oder einen Raum, die/der vorher gemacht wurde, z. B. ch-1 Raum',
    },
  },
  {
    id: 49,
    en: {abbr: 'ch-sp', txt: 'chain space'},
    fr: {abbr: 'ch-esp', txt: 'espace de la chainette'},
    de: {txt: 'Kettenraum'},
  },
  {
    id: 50,
    en: {abbr: 'CL', txt: 'cluster'},
    fr: {txt: 'plusieurs mailles dans un espace'},
    de: {txt: 'cluster'},
  },
  {
    id: 51,
    en: {abbr: 'prev', txt: 'previous'},
    fr: {abbr: 'prec.', txt: 'précédant'},
    de: {txt: 'vorheriges'},
  },
  {
    id: 52,
    en: {abbr: 'sp', txt: 'space'},
    fr: {abbr: 'esp.', txt: 'espace'},
    de: {txt: 'Raum'},
  },
];
