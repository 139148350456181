import React, { Component } from "react";
import strings from "../config/strings";
import Image from "../components/Image"
import Button from "../components/Button"
import Utils from "../utils/utils";
import {SECONDARY_COLOR} from "../config/styles";


import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';


const MARGIN = 3;


export default class OptionList extends Component {
  
  constructor(props) {
    super(props);

    this.state = { selectedOption: props.selected != null ? props.selected : null };
  }

componentDidMount() {
    if (this.props.ref) {
      this.props.ref(this)
    }
  }
  componentWillUnmount() {
    if (this.props.ref) {
      this.props.ref(undefined)
    }
  }


  onOptionSelected = (option) => {
    this.props.onOptionSelected(option);
    this.setState({ selectedOption: option });

    

  }


  select(option) {
    this.setState({ selectedOption: option });

  }

  isItemSelected(item) {
    return this.state.selectedOption && this.state.selectedOption.id == item.id
  }


  getStyle(isSelected) {

    if (isSelected)
      return [styles.item, styles.itemSelected, {width:this.itemWidth, fontSize: this.props.fontSize ? this.props.fontSize : 14}]
    return [styles.item, styles.itemNotSelected, {width:this.itemWidth, fontSize: this.props.fontSize ? this.props.fontSize : 14}]
  }


  renderItem(item) {

    return (

       <TouchableOpacity  onPress={() => this.onOptionSelected(item)}>
      <View style={[{backgroundColor:'transparent'}]}>
        <Text style={this.getStyle(this.isItemSelected(item))}>{item.text} </Text> 
       </View>
       </TouchableOpacity>
      );
  }

  render() {
    this.width = this.props.width ? this.props.width : Dimensions.get('window').width;


    if (this.props.itemWidth)
      this.itemWidth = this.props.itemWidth
    else
      this.itemWidth = (this.width - (MARGIN * 2 * this.props.nbColumns)) / this.props.nbColumns

    return (


      <View style={styles.container}>
        <FlatList style={styles.list}
          data={this.props.options}
          extraData={this.state}
          numColumns={this.props.nbColumns}
          keyExtractor={(item, index) => index}
           renderItem={
    (item) => this.renderItem(item.item)
  } 
        />
 
           </View>

    );
  }
}

const styles = StyleSheet.create({
  list: {
  
    alignSelf:'center',
  },

  item : {
  

    borderWidth: 1,
   
    borderRadius: 5,
    margin: MARGIN,
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight:1,
    paddingLeft: 1,
    borderColor: "#ccc",

    textAlign:'center',
     fontSize: 16,
     overflow:'hidden',

     shadowColor: "#000",
     shadowOpacity: 0.3,
    shadowRadius: 2,
    shadowOffset: { width: 1, height: 1 },
    elevation: 3,
  },
  itemSelected: {
    
    backgroundColor:'#2ba5f7',
   color:'white',
  },
  itemNotSelected: {
    backgroundColor:'white',
    color:'#888',

  },
  container: {
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
});
