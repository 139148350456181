import React from 'react';
import useToken from '../../hooks/useToken';
import useUser from '../../hooks/useUser';
import { useState, useEffect } from 'react';
import { SERVER_API_URL, APP_NAME } from '../../config/Constants'
import ProjectsList from '../../components/ProjectsList';
import { useHistory, Link } from 'react-router-dom';
import ImageBackground from "../../components/ImageBackground"
import PatternUtils from "../../generator/PatternUtils";
import Image from "../../components/Image";
import fetchClient from "../../utils/http-common";
import strings from "../../config/strings";
import Utils from '../../utils/utils'
import { HEADER_HEIGHT } from '../../config/Constants'
import Loader from "react-loader-spinner";


import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

export default function PatternList() {
  //const [myRcPatterns, setMyRcPatterns] = useState(PatternUtils.getAllPatterns())
  const history = useHistory();
  const { token, setToken } = useToken();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [userPatterns, setUserPatterns] = useState()
  const [isLoading, setIsLoading] = useState(true)


  const getHeaders2 = (authToken) => {
    return {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
        "APISUPPORT": 2,
        "APPNAME": APP_NAME
    };
}
 const getPatterns = () => {
    //return realm.objects("Pattern").filtered("deleted != 1");
    let options = Object.assign({ method: "GET" }, null);
    options.headers = getHeaders2(token);
    //console.log("TOKEN", token)
    var myPatterns = fetchClient.get(`${SERVER_API_URL}/patterns.json`)
    .then(response => {
      //console.log("in then:", response.data)
      var editablePatterns = []
      response.data.forEach((obj) => {
        if (obj.editable == true && obj.deleted == 0) {

          obj = PatternUtils.convertServerPatternToLocalPattern(obj)
          editablePatterns.push(obj)
        }
      })

      editablePatterns.sort((a,b) => 
        b.updatedAtOnClient - a.updatedAtOnClient
      )
      setIsLoading(false)
      setUserPatterns(editablePatterns)
    })
    .catch(error => {
      console.log("in catch")
      alert('err ' + error.message); 
      setIsLoading(false)
      console.log(error) })
    return myPatterns
  };

  useEffect(() => {
    getPatterns()
  }, [history, isLoading])


  const getImage = (pattern) => {
    if(pattern.imgAttachmentId) {
      return Utils.getAttachementUrl(pattern.imgAttachmentId)
    } else if (pattern.type == 0 ) {
      return "/imgs/tricot-ph.png"
    } else {
      return "/imgs/crochet-ph.png"
    }
  }

  const goToPatternDetails = (pattern) => {
    history.push({
      pathname: `/created-pattern-details/${pattern.id}`,
      state: { pattern: pattern }
    })
  }

  const renderLoading = () => {
    return (
      <View style={{width: "100%", justifyContent:"flex-start", alignItems:"center", padding: 50}}>
        <Loader
          type="ThreeDots"
          color="#2ba5f7"
          height={100}
          width={100}
          // timeout={8000} //3 secs
        />
      </View>
    )
  }
  
  const renderOnePattern = (pattern) => {
    console.log("render one")
    //let patternLink = `${SERVER_API_URL}/patterns/${pattern.sourcePatternId}/get_pattern`
    return (
      <TouchableOpacity
      onPress={() => goToPatternDetails(pattern)}>
      <div class="card-trip mb-3 ml-3 mr-3">
        <Image style={{width:300, height:200}} urlOnServer={getImage(pattern)} />
        <div class="card-trip-infos">
          <div class="card-trip-text">
            <h2>{pattern.name}</h2>
          </div>
          <h2 class="card-trip-pricing"><Image style={{width:20, height:20, tintColor: "#2ba5f7"}} urlOnServer={pattern.projectTool == 1 ? "/imgs/tricot_selected.png" : "/imgs/crochet_selected.png"}/></h2>
        </div>
      </div>
      </TouchableOpacity>


    )

  }

  const renderAllPatterns = (patterns) => {
    let isMobile: boolean = (width <= 768);
    let isTablet: boolean = (width <= 900);
    let nbColumn = isMobile ? 1 : isTablet ? 2 : 3
    return (
      <FlatList
        style={{flex: 1}}
        contentContainerStyle={{alignItems: "center"}}
        data={patterns}
        extraData={patterns}
        numColumns={nbColumn}
        key={'ProjectsList_ ' + nbColumn}
        renderItem={item => renderOnePattern(item.item)}
        keyExtractor={(item, index) => item.id}
      />
    )
  }
  return(
 

    <ImageBackground urlOnServer={'/imgs/background_knitting2.jpg'} style={{flexDirection:'column', width: "100%", minHeight: height - 72}}>

     <div className="container mt-5 rcpattern-container ">
       <h2>{strings.patternCreator.patternListTitle}</h2>
       <h5>{strings.patternCreator.patternListText}</h5>
       <div className="mt-4" >
          <div  className="NewPatternBtns" >
          <Link to={`./new-row-counter-pattern`}>
              <button className="ImportPatternBtn">
              {strings.patternCreator.createPattern}
              </button> 
            </Link>
          </div>
      </div> 
      <h4>{strings.patternCreator.yourPatterns}</h4>
      {isLoading && renderLoading()}
      <div className="mt-4 row">
        {userPatterns && renderAllPatterns(userPatterns)}
      </div>      
    </div>

    </ImageBackground>
  );
}