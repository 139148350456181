import React, { Component } from "react";
import strings from "../../config/strings";
//import Image from "../../components/Image"
import Dropdown from 'react-bootstrap/Dropdown'
import Utils from "../../utils/utils";
import PatternUtils from "../../generator/PatternUtils";
import Button from "../../components/Button";
import { withRouter } from "react-router";
import { TYPE_TEXT_PURE, TYPE_TEXT_ROWS, ROW_TYPE_RANGE, ROW_TYPE_ONE } from "../../generator/PatternUtils";
import {SECONDARY_COLOR} from "../../config/styles";
import SchemaPointSelector from "../../components/generator/SchemaPointSelector"
import Schema from "../../components/generator/Schema"
import ChartSizes from "../../components/generator/ChartSizes"
import { getSymbols, getFirstSymbolSelectedKnitting, getFirstSymbolSelectedCrochet } from "../../generator/symbols"



import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
} from 'react-native';

const MARGIN = 3
const MIN_CELL_WIDTH = 15
const MAX_CELL_WIDTH = 20
const NB_REPEAT_WIDTH = 3
const NB_REPEAT_HEIGHT = 2

const NB_IMGS_TO_LOAD = 16
const MAX_RAND_PADDING = 2


var __DEV__ = true;


export default class ShowChartPreview extends Component {
  
  constructor(props) {
    super(props);

 
    this.patternUtils = new PatternUtils(null);

    var params = this.props.history.location.state


    this.pattern = params.pattern
    this.array = params.array
    this.arrayWidth = params.arrayWidth
    this.arrayHeight = params.arrayHeight
   // this.subsection = this.section.subsections[0]

    this.nbImageLoaded = 0

    this.state = {
      
    };

    
  }
/*
  loadColorImages() {
    for (var i = 0; i < this.array.length; i++) {
      var color = this.array[i].color
      if (color) {

      }
    }
  }
*/

  handleCanvas = (canvas) => {

    if (canvas === null) {
        return
    }

    
    
/*
    const image = new CanvasImage(canvas, 50, 50);

    image.src = 'imgs/done.png')).uri;

    //alert(JSON.stringify(image.src))
   // image.addEventListener('load', () => {
        this.drawAndChangeColor(ctx, image, 0, 0, 20, 20)
    //});

    */

    this.canvas = canvas
    //this.canvasCtx = ctx
  }


  drawCanvas() {
    var canvas = this.canvas
    //var ctx = this.canvasCtx

    if (!canvas)
      return

    const ctx = canvas.getContext('2d');
    this.canvasCtx = ctx
    ctx.fillStyle = 'black';
    ctx.globalCompositeOperation = "source-over";

    var width = this.arrayWidth * this.state.lineWidth * NB_REPEAT_WIDTH
    var height = (this.arrayHeight * this.state.lineHeight * NB_REPEAT_HEIGHT) + (MAX_RAND_PADDING * 3)

    canvas.width = width
    canvas.height = height
    ctx.fillRect(0, 0, width, height);


    //this.chart = this.patternUtils.getChartFromContent(this.subsection.content)

    this.imgColorMap = {}

    this.loadImages(canvas)
    

  }

  getImage(canvas, color) {
     if (color.toLowerCase() in this.imgColorMap)
      return this.imgColorMap[color.toLowerCase()]
    else
      alert(color + ' not found')



   

/*

     const item = {}
     const image = new CanvasImage(canvas, this.state.cellWidth, this.state.cellHeight);
     item.image = image
     image.addEventListener('load', () => {
        item.isLoaded = true
        this.drawAndChangeColor(ctx, image, 0, 0, 20, 20)
      })

     if (color.toLowerCase() == '#000')
       image.src = 'stitches/000.png')).uri;
     else if (color.toLowerCase() == '#888')
      image.src = 'stitches/888.png')).uri;
    else if (color.toLowerCase() == '#ccc')
      image.src = 'stitches/CCC.png')).uri;
    else if (color.toLowerCase() == '#ffffff')
      image.src = 'stitches/FFF.png')).uri;
    else if (color.toLowerCase() == '#800')
      image.src = 'stitches/800.png')).uri;
    else if (color.toLowerCase() == '#f00')
      image.src = 'stitches/F00.png')).uri;
    else if (color.toLowerCase() == '#880')
      image.src = 'stitches/880.png')).uri;
    else if (color.toLowerCase() == '#ff0')
      image.src = 'stitches/FF0.png')).uri;
    else if (color.toLowerCase() == '#080')
      image.src = 'stitches/080.png')).uri;
    else if (color.toLowerCase() == '#0f0')
      image.src = 'stitches/0F0.png')).uri;
    else if (color.toLowerCase() == '#088')
      image.src = 'stitches/088.png')).uri;
    else if (color.toLowerCase() == '#0ff')
      image.src = 'stitches/0FF.png')).uri;
    else if (color.toLowerCase() == '#008')
      image.src = 'stitches/008.png')).uri;
    else if (color.toLowerCase() == '#00F')
      image.src = 'stitches/00F.png')).uri;
    else if (color.toLowerCase() == '#808')
      image.src = 'stitches/808.png')).uri;
    else
      image.src = 'stitches/F0F.png')).uri;
    
     
     this.imgColorMap[color] = image
     return image
     */
  }



  /*
   async loadImage(canvas, color, uri) {
   const image = new CanvasImage(canvas, this.state.cellWidth, this.state.cellHeight);

 
    var path = RNFetchBlob.fs.asset(uri);

    if(__DEV__) console.log('path', RNFetchBlob.wrap(path))

    try {

    if (Platform.OS === 'android') {
      var b64Data = await RNFetchBlob.fs.readFile(path, 'base64')




      if(__DEV__) console.log('b64Data', b64Data)
      image.src = 'data:image/png;base64,' + b64Data
    }
    else
      image.src = uri
    this.imgColorMap[color.toLowerCase()] = image

   
   image.addEventListener('load', async () => {
    if(__DEV__) console.log('image loaded', this.imgColorMap.length)
        
        
        this.nbImageLoaded++
        if (this.nbImageLoaded == NB_IMGS_TO_LOAD)
        {
          if(__DEV__) console.log("DRAWIIIING")
          this.drawSchema(canvas, 0, 0)

           var width = this.state.cellWidth * this.arrayWidth
           var height = this.state.lineHeight * this.arrayHeight
           const data = await this.canvasCtx.getImageData(0, 0, Math.ceil(PixelRatio.get() * width), Math.ceil(PixelRatio.get() * height))


           //this.canvasCtx.fillRect(0, 0, width, height);
          
         for (var y = 0; y < NB_REPEAT_HEIGHT; y++) {
            for (var x = 0; x < NB_REPEAT_WIDTH; x++) {
              this.canvasCtx.putImageData(data, Math.ceil(x * PixelRatio.get() * width), y * Math.ceil(PixelRatio.get() * height));
            }
          }
           

          this.canvasCtx.strokeStyle = "#ff5";
          this.canvasCtx.strokeRect((this.arrayWidth * this.state.cellWidth), 0, this.arrayWidth * this.state.cellWidth, this.arrayHeight * this.state.lineHeight);


        }
    })

  }
  catch (error) {

    alert(error.message)
    if(__DEV__) console.log("error", error)
  }

   

   
  }
  */

  loadImage(canvas, color, uri) {
   //const image =  new CanvasImage(canvas, this.state.cellWidth, this.state.cellHeight);
   const image = new Image();


   if(__DEV__) console.log('loading image')
   image.src = uri
   /*
   if (Platform.OS === 'ios')
      image.src = Image.resolveAssetSource(require(uri)).uri
    else
      image.src = '/imgs/' + uri*/
   this.imgColorMap[color.toLowerCase()] = image
   image.onload = () => {

    if(__DEV__) console.log('image loaded', this.imgColorMap.length)
        
        
        this.nbImageLoaded++
        if (this.nbImageLoaded == NB_IMGS_TO_LOAD)
        {
         

          for (var y = 0; y < NB_REPEAT_HEIGHT && y * this.arrayHeight < 50; y++) {
            for (var x = 0; x < NB_REPEAT_WIDTH && x * this.arrayWidth < 50; x++) {
              this.drawSchema(canvas, x * this.state.lineWidth * this.arrayWidth, y * this.state.lineHeight * this.arrayHeight)
            }
          }
       
        }
    }
    image.onError = (error) => {console.log(error)}
   
  }


  loadImages(canvas) {
   

    if (this.pattern.projectTool == 2) {

      this.loadImage(canvas, '#000', '/imgs/stitches/crochet/000.png')
      this.loadImage(canvas, '#888', '/imgs/stitches/crochet/888.png')
      this.loadImage(canvas, '#CCC', '/imgs/stitches/crochet/CCC.png')
      this.loadImage(canvas, '#ffffff', '/imgs/stitches/crochet/FFF.png')
      this.loadImage(canvas, '#800', '/imgs/stitches/crochet/800.png')
      this.loadImage(canvas, '#F00', '/imgs/stitches/crochet/F00.png')
      this.loadImage(canvas, '#880', '/imgs/stitches/crochet/880.png')
      this.loadImage(canvas, '#FF0', '/imgs/stitches/crochet/FF0.png')
      this.loadImage(canvas, '#080', '/imgs/stitches/crochet/080.png')
      this.loadImage(canvas, '#0F0', '/imgs/stitches/crochet/0F0.png')
      this.loadImage(canvas, '#088', '/imgs/stitches/crochet/088.png')
      this.loadImage(canvas, '#0FF', '/imgs/stitches/crochet/0FF.png')
      this.loadImage(canvas, '#008', '/imgs/stitches/crochet/008.png')
      this.loadImage(canvas, '#00F', '/imgs/stitches/crochet/00F.png')
      this.loadImage(canvas, '#808', '/imgs/stitches/crochet/808.png')
      this.loadImage(canvas, '#F0F', '/imgs/stitches/crochet/F0F.png')
    }
    else {
      this.loadImage(canvas, '#000', '/imgs/stitches/knitting/000.png')
      this.loadImage(canvas, '#888', '/imgs/stitches/knitting/888.png')
      this.loadImage(canvas, '#CCC', '/imgs/stitches/knitting/CCC.png')
      this.loadImage(canvas, '#ffffff', '/imgs/stitches/knitting/FFF.png')
      this.loadImage(canvas, '#800', '/imgs/stitches/knitting/800.png')
      this.loadImage(canvas, '#F00', '/imgs/stitches/knitting/F00.png')
      this.loadImage(canvas, '#880', '/imgs/stitches/knitting/880.png')
      this.loadImage(canvas, '#FF0', '/imgs/stitches/knitting/FF0.png')
      this.loadImage(canvas, '#080', '/imgs/stitches/knitting/080.png')
      this.loadImage(canvas, '#0F0', '/imgs/stitches/knitting/0F0.png')
      this.loadImage(canvas, '#088', '/imgs/stitches/knitting/088.png')
      this.loadImage(canvas, '#0FF', '/imgs/stitches/knitting/0FF.png')
      this.loadImage(canvas, '#008', '/imgs/stitches/knitting/008.png')
      this.loadImage(canvas, '#00F', '/imgs/stitches/knitting/00F.png')
      this.loadImage(canvas, '#808', '/imgs/stitches/knitting/808.png')
      this.loadImage(canvas, '#F0F', '/imgs/stitches/knitting/F0F.png')
    }
  }
   
  drawSchema(canvas, offsetX = 0, offsetY = 0) {

    var ctx = this.canvasCtx
    if(__DEV__) console.log("drawSchema", offsetX, offsetY)
    var i = 0
    for (var y = 0; y < this.arrayHeight; y++) {
        for (var x = 0; x < this.arrayWidth; x++) {
          var color = this.array[i].color
          if (color) {
            var image = this.getImage(canvas, color)

           // if(__DEV__) console.log("found image", image)
            var randomVerticalPadding = Math.floor(Math.random() * MAX_RAND_PADDING)
            var randomHorizontalPadding = Math.floor(Math.random() * MAX_RAND_PADDING)
            this.drawAndChangeColor(ctx, image, (x * this.state.lineWidth) + offsetX + randomHorizontalPadding
              , (y * this.state.lineHeight) + offsetY + randomVerticalPadding
              , this.state.cellWidth, this.state.cellHeight)
          }
        
          i++
        }
    }

     ctx.strokeStyle = "#ff5";

    var startRectX = this.arrayWidth >= 50 ? 0 : this.arrayWidth * this.state.lineWidth

    
    ctx.strokeRect(startRectX, 0, this.arrayWidth * this.state.lineWidth, this.arrayHeight * this.state.lineHeight);

  }

  drawAndChangeColor(ctx, image, x, y, w, h) {

    //alert(x + ' ' + y + ' ' + w)
    //ctx.fillStyle = "#09f";
    //ctx.fillRect(0, 0, w, h);
    
    // set composite mode
   // ctx.globalCompositeOperation = "destination-in";

    ctx.drawImage(image, x, y, w, h)
    //ctx.globalCompositeOperation = "source-over";

  }


  componentDidMount() {
  
    var cellWidth = Math.min(Dimensions.get('window').width, Dimensions.get('window').height) / NB_REPEAT_WIDTH / this.arrayWidth;

    if (cellWidth > MAX_CELL_WIDTH)
      cellWidth = MAX_CELL_WIDTH
    else if (cellWidth < MIN_CELL_WIDTH)
      cellWidth = MIN_CELL_WIDTH
    this.setState({ cellWidth: cellWidth,
                    cellHeight:cellWidth * 1.3,
                    lineHeight: this.pattern.projectTool == 2 ? cellWidth * 0.9 : cellWidth * 0.75,
                    lineWidth: this.pattern.projectTool == 2 ? cellWidth * 0.8 : cellWidth * 0.95
                    }, () => this.drawCanvas())

    
  }
  renderGoBack(){
    return (
      <TouchableOpacity
      style={{
        position: "absolute", 
        top: 22,
        left: 24,
        justifyContent: "center", 
        alignItems: "flex-start", 
        zIndex: 1000,
        width: 32}}
      onPress={() => this.props.history.goBack()}>
        <img src="/imgs/back.png" alt="back" width="24" height="24" />
      </TouchableOpacity>
    )
}
 
  render() {


    
    return (


         <View style={{flex:1, backgroundColor: "#266D9C",  minHeight: Dimensions.get('window').height - 72, justifyContent: "center", alignItems: "center", padding: 24}}
        title={strings.chart_preview}
        disableSafeArea={true}
        onBack={() => this.props.navigation.goBack()}>
          {this.renderGoBack()}
          <ScrollView 
          contentContainerStyle={{flex: 1, alignSelf:"center", justifyContent: "center", alignItems: "center",}}
          style={{backgroundColor:'#266D9C'}}>
            <canvas style={{width:'100%'}} ref={this.handleCanvas}/>
           </ScrollView>

           <Text style={{position:'absolute', bottom: 20, color:'#fff', width:'100%', textAlign:'center'}}>{strings.only_colors_shown}</Text>
        </View>
      

      )
  
  }

 
}

export const ShowChartPreviewWithRouter = withRouter(ShowChartPreview);

const styles = StyleSheet.create({
  input: {
    borderBottomWidth:0.5, borderColor:"#888",
    color:'#555',
    padding:0
  },

});
