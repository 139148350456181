import React from 'react';
import useToken from '../../hooks/useToken';
import useUser from '../../hooks/useUser';
import { useState, useEffect } from 'react';
import PatternUtils from "../../generator/PatternUtils";
import { LANGS } from "../../generator/PatternUtils";
import Image from "../../components/Image";
import { useParams, useHistory, useLocation } from "react-router-dom";
import strings from "../../config/strings";
import Utils from '../../utils/utils';
import { getSizesFromIds, getCatFromSize } from "../../config/sizes"

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

export default function SizeSpecBox(props) {
  const [createdPattern, setCreatedPattern] = useState(props.pattern)
  const [availableSizes, setAvailableSizes] = useState()
  const [text, setText] = useState()
  const onSizeSpecSave = props.onSizeSpecSave


  useEffect(() => {
    var pattern = props.pattern
    if (pattern.sizeIds) {
      var sizeIds = pattern.sizeIds
      const sizes = getSizesFromIds(sizeIds)
      setAvailableSizes(sizes)
      console.log(sizes)
    }
    
  }, [])   

  const renderOneSize = (size) => {
    return (
      <View
      style={{flexDirection: "row", width: "100%", justifyContent: "space-around", alignItems: "center"}} >
        <View
        style={{flexDirection: "row", justifyContent: "flex-start",alignItems: "center", width: 200}} >
        <Image 
        style={{width:24, height: 24, marginRight: 12, tintColor: "white"}}
        urlOnServer={"/imgs/size_ico.png"}/>
        <Text 
        style={{color:"white", marginRight: 12, }}>
          {size.text}
        </Text>
        </View>
      <View style={{width: 400}}>
      <TextInput
          containerStyle={{height: 40, flex: 1, }}
          style={[styles.input, { borderRadius: 12,height: 40,  width: "100%", flex: 1 , padding: 12}]}
          multiline={false}
          value={text}
          onChangeText={(text) => setText(text)}
          returnKeyType="done"
        />
      </View>

      </View>
    )
  }
   
  return(
 

    <View style={{flex: 1, width: "100%"}}>
      {
        availableSizes && availableSizes.map((size) => {
          return (
            renderOneSize(size)
          )
        })
      }
      <View style={{width: "100%", justifyContent: "center", alignItems: "center", marginVertical: 24}}>
        <TouchableOpacity>
          <Text style={{fontSize: 16, fontWeight: "bold", color:"#FECB2E"}}>{strings.add}</Text>
        </TouchableOpacity>
      </View>

    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginTop: 12,
    backgroundColor: "rgba(255,255,255,0.15)" , 
    color: "white",
    paddingLeft: 16,
    textAlign: "left"
  },
  title: {
    color: "rgba(255,255,255,0.8)", 
    textAlign: "left",
    selfAlign: "flex-start",
  },

});