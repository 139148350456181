import React, { Component } from "react";

import {
  ScrollView,
  View,
  TextInput,
  ImageBackground,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';


export default class Button extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      
            <TouchableOpacity
            style={[styles.btn, this.props.style]}
            onPress={() => this.props.onPress()}
          >
            <Text style={{ color:'#fff', fontSize:15, fontWeight:'500', minWidth:80, textAlign:'center'}}>
              {this.props.title}
            </Text>
          </TouchableOpacity>
      )
  }
}

const styles = StyleSheet.create({
btn: {
    backgroundColor: "#2ba5f7",
    borderRadius: 45,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 18,
    paddingRight: 18,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
    elevation:5, alignItems:'center'
  }
});
