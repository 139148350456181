import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import FileUpload from "../components/FileUpload";

import useToken from '../hooks/useToken';
import { SERVER_API_URL } from '../config/Constants'
import ViewProject from './ViewProject';
import ProjectUtils from '../utils/projectUtils'
import { BrowserRouter, Route, Switch, useRouteMatch, Link } from 'react-router-dom';
import ProjectService from "../services/ProjectService";
import Image from "../components/Image"
import ImageBackground from "../components/ImageBackground"
import { HEADER_HEIGHT } from '../config/Constants'
import Button from "../components/Button"
import Utils from "../utils/utils";
import {SECONDARY_COLOR} from "../config/styles";
import strings from "../config/strings";
import "../config/styles.css"

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions,
  Picker
} from 'react-native';


function UploadLocalFile(props) {

  let history = useHistory();
  const { projectId } = useParams();


  return (
    <ImageBackground  urlOnServer={'/imgs/background_knitting2.jpg'}
          style={{ width:'100%', height: Dimensions.get("window").height - HEADER_HEIGHT, justifyContent:'center', alignItems:'center' }}
        >
          <h2 className="PageTitle" style={{
            marginBottom: 24,
          }}>{strings.import_pattern}</h2>
          <p style={{
            fontStyle: "italic",
            textAlign: "center",
            color: "#555",
            width: "50%",
            marginBottom: 36,
            }} >{strings.importWarning1} <a href="https://rowcounterapp.com/emulator.html" target="_blank" >{strings.importWarning2}</a> {strings.importWarning3}</p>
      <FileUpload className="Upload" projectId={projectId}
      accept={"application/pdf"}
      onSuccess={(response) => history.goBack()} />
    </ImageBackground>
  );
}

export default UploadLocalFile;
