import React, { Component } from "react";
import { withRouter } from "react-router";
import strings from "../config/strings";
import Button from "../components/Button";
import ProjectUtils from '../utils/projectUtils'
import Utils from "../utils/utils"
import Image from "../components/Image"
import CheckBox from "../components/CheckBox"
import { HEADER_HEIGHT } from '../config/Constants'
import { SECONDARY_COLOR, PURPLE_COLOR_PLAIN } from "../config/styles";
import ImageBackground from "../components/ImageBackground"
import EditComment from "../containers/EditComment"
import { BrowserRouter, Route, Switch, useRouteMatch, Link, useParams, useHistory } from 'react-router-dom';
//import Alert from 'react-bootstrap/Alert';


import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  BackHandler,
  Linking,
  Dimensions
} from 'react-native';


const { width, height } = Dimensions.get("window");
const BORDER_WIDTH = 0.5
const PADDING = 12

class EditCounter extends Component {
  constructor(props) {
    super(props);


    //  alert(JSON.stringify(props.test2))
    var counter = this.props.counter;


    var commentsHeights = []

    for (var i = 0; i < counter.comments.length; i++) {
      commentsHeights.push(35)
    }
    commentsHeights.unshift(35);

    this.state = {
      counter: counter,
      comments: counter.comments,
      commentsHeights: commentsHeights,
      nbRows: counter.max_num,
      errorAddCounterRow: false,
    };

    this.name = counter.name;
    this.nbRows = counter.max_num;
    this.autoInc = counter.auto_inc;
    this.showReset = counter.show_reset;
    this.averageStitches = counter.average_stitches;
    this.begin = counter.begin_num;


  }




  onNameSet(text) {
    this.name = text;
  }

  onNbRowsSet(value) {

    if (value == "")
      value = -1;
    if (!this.isInt(value)) {
      alert(strings.number_required)
      return
    }

    this.nbRows = parseInt(value);

    this.setState({ nbRows: this.nbRows })
  }

  onNbBeginSet(value) {

    if (value == "")
      return;
    if (!this.isInt(value)) {
      alert(strings.number_required)
      return
    }

    this.begin = parseInt(value);

    this.setState({ begin: this.begin })
  }


  toggleAutoInc() {
    this.autoInc = this.autoInc === 1 ? 0 : 1;

    this.setState({ autoInc: this.autoInc });
  }

  toggleShowReset() {
    this.showReset = !this.showReset;

    this.setState({ showReset: this.showReset });
  }

  onSave() {

    if (this.nbRows != -1 && this.begin > this.nbRows) {
      alert("error: " + strings.nb_row + " < " + strings.starts_at)
      return
    }


    var comments = [...this.state.comments];
    /*
        if (this.state.tmpCommentText)
        {
          if (!this.state.tmpCommentRow || this.state.tmpCommentRow == '') {
            Alert.alert("", strings.number_required);
            this.setState({errorAddCounterRow: true})
            return
          }
          comments.push({ showAt: parseInt(this.state.tmpCommentRow), txt: this.state.tmpCommentText, uniqueIdOnClient: Math.random().toString(36).slice(2) });
        }

        comments = comments.filter(function(value, index, arr){

            return (value.txt && value.txt.length > 0);

        });
    */

    this.props.onSave(
      this.name,
      this.nbRows,
      this.autoInc,
      this.showReset,
      comments,
      this.averageStitches,
      this.begin
    );
  }

  isInt(value) {
    return !isNaN(value) &&
      parseInt(Number(value)) == value &&
      !isNaN(parseInt(value, 10));
  }




  hasChanged() {


    var commentsModified = false;
    for (var i = 0; i < this.state.comments.length && i < this.props.counter.comments.length; i++) {
      var stateComment = this.state.comments[i];
      var propsComment = this.props.counter.comments[i];

      if (stateComment.txt !== propsComment.txt || stateComment.show_at !== propsComment.show_at || stateComment.show_at_end !== propsComment.show_at_end) {
        commentsModified = true;
        break
      }
    }
    //if(__DEV__) console.log('onback', commentsModified, this.nbRows != this.props.counter.max, this.autoInc != this.props.counter.autoInc, this.showReset != this.props.counter.showReset
    //, this.averageStitches != this.props.counter.averageStitches, this.showReset != this.props.counter.showReset)
    if (commentsModified
      || this.state.comments.length != this.props.counter.comments.length
      || this.name != this.props.counter.name
      || this.nbRows != this.props.counter.max_num
      || this.autoInc != this.props.counter.auto_inc
      || this.showReset != this.props.counter.show_reset
      || this.averageStitches != this.props.counter.average_stitches
      || this.showReset != this.props.counter.show_reset
      || this.begin != this.props.counter.begin_num) {

      return true
    }


    return false
  }

  onEditAverageStitches(nb) {
    if (nb == "")
      return
    if (!this.isInt(nb)) {
      alert(strings.number_required)
      return
    }
    this.averageStitches = parseInt(nb);
  }

  onAddNewComment() {

    var tmpComment = { txt: '', show_at: this.begin, show_at_end: -1 }

    /* var data = {comment: tmpComment, commentIndex: -1}
     this.props.history.push({
        pathname: `${this.props.match.path}/EditComment`,
        state: data // your data array of objects
      })*/
    this.commentIndex = -1
    this.setState({ editComment: tmpComment })
  }

  onEditComment(comment, commentIndex) {

    this.commentIndex = commentIndex
    this.setState({ editComment: comment })
    // this.props.navigation.navigate("EditCommentScreen",  {comment: comment, commentIndex: commentIndex, onSave: (txt, showAt, showAtEnd, commentIndex) => this.onCommentEdited(txt, showAt, showAtEnd, commentIndex)})
  }


  onCommentEdited(txt, showAt, showAtEnd, commentIndex) {

    var commentToEdit
    var commentsTmp = [...this.state.comments];

    if (commentIndex == -1) {
      commentToEdit = ProjectUtils.createEmptyComment()

      commentsTmp.push(commentToEdit);
    }
    else
      commentToEdit = this.state.comments[commentIndex]
    commentToEdit.txt = txt;
    commentToEdit.show_at = parseInt(showAt);
    commentToEdit.show_at_end = parseInt(showAtEnd);



    this.setState({ comments: commentsTmp, editComment: null })
  }

  onDeleteComment(commentIndex) {

    if (window.confirm(strings.delete + '?')) {
      // Save it!
      var commentsTmp = [...this.state.comments];
      //this.state.comments[commentIndex].deleted = 1
      console.log('deleting comment', commentsTmp[commentIndex])
      commentsTmp[commentIndex].deleted = 1
      this.setState({ comments: commentsTmp })
    } else {
      // Do nothing!
      console.log('doesnt delete');
    }


  }

  renderAutoLink() {
    return (


      <TouchableOpacity style={{ marginVertical: 12, flexDirection: 'row', alignItems: 'center' }}
        onPress={() => this.toggleAutoInc()}>
        <View style={{ flex: 1 }}><Text style={styles.titleText}>{strings.auto_inc}</Text></View>

        <Image
          style={{ width: 30, height: 30, marginRight: 12}}
          urlOnServer={this.autoInc === 1 ? "../imgs/linked.png" : "../imgs/notlinked.png"}
        />
      </TouchableOpacity>
    );
  }

  renderAverageStitches() {
    return (


      <TouchableOpacity onPress={() => this.avgStitchTextInput.focus()}
      style={{ marginVertical: 12, flexDirection: 'row',  flex: 1,  justifyContent: "center", alignItems: 'center' }}
              >
        <View style={{ flex: 1 }}>
          <Text style={styles.titleText}>{strings.average_stitches}</Text>
        </View>
        <TextInput
          style={[styles.textInput, { width: 40, marginHorizontal: 5, textAlign: 'right', color: "#555" }]}
          multiline={false}
          keyboardType="numeric"
          ref={(input) => { this.avgStitchTextInput = input; }}
          onChangeText={text => this.onEditAverageStitches(text)}
          defaultValue={
            this.averageStitches != -1
              ? this.averageStitches.toString()
              : undefined
          }

          returnKeyType="done"
        />

        <Image urlOnServer={'/imgs/pencil.png'} style={{ width: 18, height: 18 }} />

      </TouchableOpacity>
    );
  }

  renderNbResets() {
    if (this.state.nbRows < 1)
      return null;

    return (

      <TouchableOpacity
      style={{ marginVertical: 12, flexDirection: 'row',  flex: 1,  justifyContent: "center", alignItems: 'center' }}
        onPress={() => this.toggleShowReset()}>
        <View style={{ flex: 1 }}>
          <Text style={[styles.titleText, {  }]}>{strings.show_reset}</Text>
        </View>
        <CheckBox
          onPress={() => this.toggleShowReset()}
          checked={this.showReset}
          style={{ marginRight: 12 }}
          ref={(input) => { this.checkBoxRef = input; }} />
      </TouchableOpacity>
    );
  }

  renderCommentItem(item) {

    return (


      <TouchableOpacity
        style={{ flexDirection: 'row', flex: 1, paddingRight: PADDING, paddingTop: 12, paddingBottom: 12, paddingBottom: 8, alignItems: 'center' }}
        onPress={() => this.onEditComment(item, item.tmpIndex)}
      >

        <TouchableOpacity onPress={() => this.onDeleteComment(item.tmpIndex)}>
          <Image urlOnServer={'/imgs/bin.png'} style={{ width: 18, height: 20, padding: 8, marginLeft: PADDING, marginRight: 8, tintColor: '#2ba5f7' }} />
        </TouchableOpacity>

        <Image urlOnServer={'/imgs/pencil.png'} style={{ width: 18, height: 18, padding: 8, marginLeft: 8, tintColor: '#2ba5f7' }} />
        <View
          style={{ backgroundColor: "rgba(0,0,0,0.04)", padding: 8, borderRadius: 6, flex: 1, marginLeft: 12 }} >
          <Text
            style={{ color: "#a0a0a0", fontSize: 12, fontStyle: "italic" }}
            key={'comment_edittext_' + item.tmpIndex}
            multiline={true}

          >{this.state.comments[item.tmpIndex].txt}</Text>
        </View>

        <View>


          <Text
            style={{ width: 60, textAlign: 'right', paddingVertical: 4, paddingHorizontal: 0, margin: 0, fontSize: 14, color: '#555' }}
          >{this.state.comments[item.tmpIndex].show_at != -1
            ? this.state.comments[item.tmpIndex].show_at.toString()
            : ""}{this.state.comments[item.tmpIndex].show_at_end > 0
              ? ' - ' + this.state.comments[item.tmpIndex].show_at_end.toString()
              : ""}</Text>



        </View>

      </TouchableOpacity>



    );
  }




  renderComments() {


    for (var i = 0; i < this.state.comments.length; i++) {
      this.state.comments[i].tmpIndex = i
    }
    var nonDeletedComments = !this.state.comments ? [] : this.state.comments.filter(function (object, index, arr) {

      return (object.deleted != 1);

    });
    return (
      <View style={{ flex: 1, paddingBottom: 8 }}>

        {nonDeletedComments.length > 0 && (
          <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'flex-end', padding: PADDING }}>
            <Text style={styles.titleText}>{strings.show_com_at_row}</Text>
          </View>
        )}
        <ScrollView
        contentContainerStyle={{
          maxHeight: 200,
        }}>
        {nonDeletedComments && nonDeletedComments.length > 0 && (
          <FlatList
            style={{ flexGrow: 1 }}
            contentContainerStyle={{ flexGrow: 1 }}
            data={nonDeletedComments}
            extraData={this.state}
            numColumns={1}
            keyExtractor={(item, index) => index}
            renderItem={item => this.renderCommentItem(item.item)}
          />
        )}
        </ScrollView>


        <TouchableOpacity
          style={{ alignSelf: 'flex-end', marginRight: PADDING, marginTop: PADDING, flexDirection: 'row' }}
          onPress={() => { this.onAddNewComment() }}
        >
          <Text style={[styles.titleText, { marginRight: 8 }]}>{strings.add_counter_com}</Text>
          <Image
            style={{ width: 20, height: 20 }}
            urlOnServer={'/imgs/plus_bleu.png'}
          />

        </TouchableOpacity>

      </View>
    )


  }

  renderEditComment() {
    return (
    <EditComment
    data={{
      comment: this.state.editComment,
      commentIndex: this.commentIndex,
      onSave: (txt, showAt, showAtEnd, commentIndex) => this.onCommentEdited(txt, showAt, showAtEnd, commentIndex)
    }}
      onClose={() => this.setState({ editComment: null })}
    />)
  }

  renderRowStart() {
    return (

      <TouchableOpacity onPress={() => this.beginTextInput.focus()}
        style={{ flexDirection: 'row', borderRightWidth: BORDER_WIDTH, borderColor: '#ccc', flex: 1, paddingHorizontal: PADDING, paddingVertical: 12, alignItems: 'center' }}>

        <View style={{ flex: 1 }}><Text style={styles.titleText}>{strings.starts_at}</Text></View>
        <TextInput
          style={[styles.textInput, { width: 40, height: 30, marginHorizontal: 5, textAlign: 'right' }]}
          multiline={false}
          keyboardType="numeric"
          ref={(input) => { this.beginTextInput = input; }}

          onChangeText={text => this.onNbBeginSet(text)}
          defaultValue={this.begin.toString()}
        />


      </TouchableOpacity>

    )
  }

  render() {

    if (this.state.editComment)
      return this.renderEditComment()

    console.debug(`pingoulog:rendering counter=${JSON.stringify(this.state)}`);
    return (

      <View style={{
        zIndex: 20000,
        flex: 1, justifyContent: 'center', alignItems: 'center', height: Dimensions.get('window').height - HEADER_HEIGHT
      }}>

        <View style={{ width: 600, backgroundColor: '#f6f6f6', borderRadius: 12, padding: 12, marginTop: 12}}>
          <View style={styles.container}>
            <Text style={{ fontSize: 10, color: '#a0a0a0' }}>{strings.options}</Text>
            <TouchableOpacity
              onPress={() => this.nameTextInput.focus()}
              style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center", flex: 1, marginVertical: 12 }}>

              <Text style={styles.titleText}>{strings.name_optional}</Text>
              <View style={styles.containerRow}>
                <TextInput
                  style={[styles.textInput, {flex: 1}]}
                  multiline={false}
                  ref={(input) => { this.nameTextInput = input; }}
                  onChangeText={text => this.onNameSet(text)}
                  defaultValue={this.name}
                  maxLength={16}
                />
              </View>


            </TouchableOpacity>

            <TouchableOpacity onPress={() => this.nbRowsTextInput.focus()}
              style={{ flexDirection: 'row', alignItems: 'center' }}>

              <View style={{ flex: 1 }}>
                <Text style={styles.titleText}>{this.props.isGlobalCounter
                  ? strings.nb_row
                  : strings.reinit_at}</Text>
              </View>
              <TextInput
                style={[styles.textInput, { width: 40, marginHorizontal: 5, textAlign: 'right' }]}
                multiline={false}
                keyboardType="numeric"
                ref={(input) => { this.nbRowsTextInput = input; }}

                onChangeText={text => this.onNbRowsSet(text)}
                defaultValue={this.nbRows != -1 ? this.nbRows.toString() : ""}
              />

            </TouchableOpacity>

            {
              this.props.isGlobalCounter ?
                this.renderAverageStitches() :
                this.renderNbResets()
            }

            {!this.props.isGlobalCounter && this.renderAutoLink()}



          </View>

          <View style={styles.container}>
              <Text style={{ fontSize: 10, color: '#a0a0a0' }}>{strings.comments}</Text>
            {this.renderComments()}
            </View>


          <View style={{ flex: 0, flexDirection: "row", justifyContent: "flex-end", alignSelf: "flex-end", paddingHorizontal: 15, paddingVertical: 25 }}>

            <TouchableOpacity onPress={() => this.onSave()}>
              <Text style={{ marginHorizontal: 0, fontSize: 16, color: "#2ba5f7", fontWeight: "bold", alignSelf: 'center', textAlign: "center" }}>{strings.save.toUpperCase()}</Text>
            </TouchableOpacity>
          </View>


        </View>
      </View>
    );
  }



}

export default EditCounter;

const styles = StyleSheet.create({


  subContainer: {
    paddingHorizontal: 8,
    paddingVertical: 8,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },

  container: {
    flex: 1,
    backgroundColor: "#fff",
    marginHorizontal: 12,
    borderRadius: 12,
    marginVertical: 8,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 12,
    padding: 18,
  },

  mainContainer: {
    flex: 1,
    backgroundColor: '#F6F6F6',
  },

  containerRow: {
    flex: 1,
    flexDirection: "row",
  },

  addCom: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignContent: "center"
  },

  addComTxt: {
    marginLeft: 5,
    alignSelf: "center",
    color: "#555"
  },

  titleText: {
    fontSize: 12,
    letterSpacing: 0.5,
    textAlign: "left",
    color: "#555"
  },

  rangeText: {
    fontSize: 12,
    color: "#2ba5f7",
    textAlign: "right",
    fontWeight: "bold",
  },
  editBox: {
    paddingHorizontal: 8
  },

  textInput: {
    color: PURPLE_COLOR_PLAIN,
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 24,
    marginLeft: 12,
    minWidth: 60,
    backgroundColor: "rgba(43,165,247,0.15)",
  },

  closeImg: {
    width: 20,
    height: 20,
    alignSelf: "center"
  }
});


// const styles = StyleSheet.create({


//   subContainer: {
//     borderBottomWidth:BORDER_WIDTH,
//     borderColor:'#ccc'
//   },

//   container : {

//     backgroundColor: "white",
//     marginBottom:20
//   },

//   mainContainer: {
//     flex:1,
//     backgroundColor: '#F6F6F6'
//   },

//   containerRow: {
//     flex:1,
//     flexDirection: "row",
//   },

//   addCom: {
//     flexDirection: "row",
//     justifyContent: "flex-end",
//     alignContent: "center"
//   },

//   addComTxt: {
//     marginLeft: 5,
//     alignSelf: "center",
//     color: "#555"
//   },

//   titleText: {
//     fontSize: 12,
//     color: "#888"
//   },

//   editBox : {
//     paddingHorizontal:8
//   },

//   textInput: {
//     color:'#555',
//     flex:1,
//   },

//   closeImg: {
//     width: 20,
//     height: 20,
//     alignSelf: "center"
//   }
// });
