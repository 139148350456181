import React from 'react';
import {Document, Page, pdfjs} from 'react-pdf/dist/umd/entry.webpack'
import "../config/styles.css"
import Draggable from 'react-draggable';
import DrawingOptions from './DrawingOptions';
import { SERVER_API_URL } from '../config/Constants'
import Utils from '../utils/utils'
import fetchClient from "../utils/http-common";

const MAX_IMG_SIZE = 3000;

export default class DrawingArea extends React.Component {

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();


    //alert(JSON.stringify(this.props))
    this.state = {
      color: 'black',
      erasing: false,
      brushSize: 5,
      transparent: true
    }

    this.drawingObjectOnServer = null

    this.lastPositionX = -42;
    this.lastPositionY = -42;
    this.hasDrawn = false
    this.imgUrlOnServer = null;
    this.imgDrawn = false
    this.saveIfNeededIn10s()
  }

  componentDidUpdate(prevProps) {
    // Typical usage, don't forget to compare the props
    if (this.props.pdfWidth !== prevProps.pdfWidth || this.props.pdfHeight !== prevProps.pdfHeight) {
      console.log("pdfWidth or pdfHeight changed", this.props.pdfWidth , prevProps.pdfWidth, this.props.pdfHeight, prevProps.pdfHeight)
      this.sizeChanged()
    }
    if (this.props.enabled !== prevProps.enabled) {
      console.log("enabled changed", this.props.enabled)
      if (this.hasDrawn) {
        this.updateDrawingOnServer();
      }
    }
  }

   componentWillUnmount() {
    if (this.timerSave) {
      clearTimeout(this.timerSave);
    }
    if (this.hasDrawn) {
       this.updateDrawingOnServer();
    }

  }

  resetSaveTimer() {
    if (this.timerSave) {
      clearTimeout(this.timerSave);
    }
    this.timerSave = setTimeout(() => this.saveIfNeededIn10s(), 10000); // 10s
  }

  saveIfNeededIn10s() {
    if (this.hasDrawn) {
      this.updateDrawingOnServer();
    }
    this.resetSaveTimer();
  }

  sizeChanged() {
    const canvas = this.canvasRef.current;
    const context = canvas.getContext("2d");

    this.context = context;

    // get device pixel ratio
    let dpr = window.devicePixelRatio || 1;

    // fix for issue with some browsers like Firefox that returns dpr as integer
    dpr = Math.max(dpr, 1);

    // get canvas actual size
    let rect = canvas.getBoundingClientRect();

    // scale it
    //canvas.width = this.props.pdfWidth * dpr;
    //canvas.height = this.props.pdfHeight * dpr;

    var canvasWidth = this.props.pdfWidth > this.props.pdfHeight ? MAX_IMG_SIZE : this.props.pdfWidth * MAX_IMG_SIZE / this.props.pdfHeight;
    var canvasHeight = this.props.pdfHeight > this.props.pdfWidth ? MAX_IMG_SIZE : this.props.pdfHeight * MAX_IMG_SIZE / this.props.pdfWidth;

    canvas.width = canvasWidth
    canvas.height = canvasHeight

    console.log("canvas.width", canvas.width, "props width", this.props.pdfWidth)

    this.canvasScaleWidth = canvasWidth / this.props.pdfWidth;
    this.canvasScaleHeight = canvasHeight / this.props.pdfHeight;

    // scale context
    context.scale(this.canvasScaleWidth, this.canvasScaleWidth);

    // adjust css canvas size
    canvas.style.width = this.props.pdfWidth+ 'px';
    canvas.style.height = this.props.pdfHeight + 'px';

   // context.globalCompositeOperation='destination-out';
    context.globalAlpha = 1;


    if (this.imgUrlOnServer) {
      this.loadImage()
    }
    else
      console.log("not drawing img", "hasdrawnimg", this.imgDrawn)
  }

  componentDidMount() {
   //this.sizeChanged()
   this.loadDrawingFromServer()

   let drawing = false;
   const canvas = this.canvasRef.current;
   canvas.addEventListener("mousedown", () => { drawing = true;  });
    canvas.addEventListener("mouseup", () => { drawing = false });
    canvas.addEventListener("mousemove", (e) => {
      if (drawing) {

        this.resetSaveTimer()
        if (this.state.isErasing) {
          this.handleErase(e);
        }
        else
          this.handlePath(e);
      }
    });

    canvas.addEventListener("mousedown", (e) => {
      const rect = canvas.getBoundingClientRect();
      var x = (e.clientX - rect.left )
      var y = (e.clientY - rect.top)

      this.lastPositionX = x;
      this.lastPositionY = y;
       // context.beginPath();
       // context.moveTo(x, y);
    });
  }


  async loadDrawingFromServer() {
    var params = {
      "user_project_id": this.props.userProjectId,
      "pageNb": this.props.pageNb - 1,
  }

    var result = await fetchClient.post(`${SERVER_API_URL}/drawings/getDrawingForPage.json`, params)

    //alert(JSON.stringify(result.data))

    if (result.data) {
      this.drawingObjectOnServer = result.data
      if (result.data.imgUrlOnServer) {
        this.imgUrlOnServer = result.data.imgUrlOnServer + "?" + Date.now()
        this.loadImage()
      }
    }
  }

  async loadImage() {

    if (this.props.pdfWidth < 200 || this.props.pdfHeight < 200) {
      console.log("pdfWidth or pdfHeight too small", "hasdrawnimg", this.imgDrawn)
      return
    }


    console.log("drawing img")
    var url = this.imgUrlOnServer
    if (!this.canvasRef.current)
      return
    const canvas = this.canvasRef.current;
    if (!canvas)
      return
    const ctx = canvas.getContext('2d');

    const img = new Image();
    img.crossOrigin = "anonymous"
    img.src = url;  // Replace this with the URL of the image

    const pdfWidth = this.props.pdfWidth
    const pdfHeight = this.props.pdfHeight
    img.onload = function() {
      ctx.clearRect(0, 0, pdfWidth, pdfHeight); // clear the canvas
      ctx.drawImage(img, 0, 0, pdfWidth, pdfHeight); // position the img to top left corner and scale to canvas size
    }
    this.imgDrawn = true

  }

  async updateDrawingOnServer() {
  /*  const canvas = this.canvasRef.current;
    var url = canvas.toDataURL("image/png");


    var link = document.createElement('a');
  link.download = 'filename.png';
  link.href = url;
  link.click();*/


    var drawing = {
    "user_project_id": this.props.userProjectId,
    "startX": 0.0,
    "startY": 0.0,
    "endX": 100,
    "endY": 100,
    "pageNb": this.props.pageNb - 1,
    "size": 0,
    "color": "black",
    "platformOrig": "android",
    "deleted": 0,
    "updatedAtOnClient": Date.now(),
    "support": 2,
    "uniqueIdOnClient": Utils.getUID()}

    var result
    if (this.drawingObjectOnServer && this.drawingObjectOnServer.id)
      result = await fetchClient.put(`${SERVER_API_URL}/drawings/${this.drawingObjectOnServer.id}.json`, drawing)
    else
      result = await fetchClient.post(`${SERVER_API_URL}/drawings.json`, drawing)

      //alert(JSON.stringify(result.data))

    this.drawingObjectOnServer = result.data
    await this.uploadImage()
  }

  async uploadImage() {
    const canvas = this.canvasRef.current;

    this.hasDrawn = false;
    const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));

    const formData = new FormData();
    formData.append('image', blob, 'canvas.png');

    // POST request to server
    try {
        const response = await fetchClient.post(`${SERVER_API_URL}/drawings/${this.drawingObjectOnServer.id}/addImg.json`, formData);
        this.imgUrlOnServer = response.data.imgUrlOnServer + "?" + Date.now()
        console.log(response);
    } catch (error) {
        console.log(error);
    }
};




  handleErase(e) {
    if (!this.props.enabled)
      return
    this.hasDrawn = true;
    const canvas = this.canvasRef.current;
    const context = canvas.getContext("2d");

    const rect = canvas.getBoundingClientRect();
    var x = (e.clientX - rect.left )
    var y = (e.clientY - rect.top)


    context.clearRect(x - (this.state.brushSize / 2), y - (this.state.brushSize / 2), this.state.brushSize, this.state.brushSize);

    context.save();

    context.restore();
  }


  handlePath(e) {


    if (!this.props.enabled)
      return
    //console.log('handlePath1');
    const canvas = this.canvasRef.current;

    const context = canvas.getContext("2d");
    this.hasDrawn = true;

    const rect = canvas.getBoundingClientRect();
    var x = (e.clientX - rect.left)
    var y = (e.clientY - rect.top)
    if (this.lastPositionX === -42) {

      this.lastPositionX = x;
      this.lastPositionY = y;
    } else {


      context.globalAlpha = 0.3
      context.lineWidth = this.state.brushSize;
      context.lineJoin = 'round';
      context.lineCap = 'round';

      context.beginPath();
      context.moveTo(this.lastPositionX, this.lastPositionY);
      context.lineTo(x, y);


      if (this.state.transparent) {
        context.globalCompositeOperation='destination-out';
        context.globalAlpha = 1
        context.strokeStyle = 'black';
        context.stroke();

        context.globalCompositeOperation='source-over';
        context.globalAlpha = 0.4
        context.strokeStyle = this.state.color;
        context.stroke();
      }
      else {
        context.globalAlpha = 1
        context.strokeStyle = this.state.color;
        context.stroke();
      }
    }

    this.lastPositionX = x;
    this.lastPositionY = y;

    context.save();

    context.restore();
  }


  render() {
    return <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 10, width: '100%', height: '100%'}}>
      <canvas ref={this.canvasRef} style={{ position: 'absolute', top: 0, left: 0, zIndex: 10, width: '100%', height: '100%'}} />
     {this.props.enabled &&
     <DrawingOptions
     setErasing={(erasing) => {
        const canvas = this.canvasRef.current;
        const context = canvas.getContext("2d");


        if (erasing)
          context.globalCompositeOperation = 'destination-out';
        else
          context.globalCompositeOperation = 'source-over';

          this.setState({isErasing: erasing});
        }}

        setColor={(color) => {
          const canvas = this.canvasRef.current;
          const context = canvas.getContext("2d");
          context.strokeStyle = color;
         // context.globalCompositeOperation = 'source-over';
          this.setState({color: color, isErasing: false});
        }}
        setTransparent={(transparent) => {
          this.setState({transparent: transparent});
        }}

        setBrushSize={(size) => {
          this.setState({brushSize: size});
        }}
        onSave={() => {
          this.updateDrawingOnServer();
        }}
        />}
      </div>
  }
}
