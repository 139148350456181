import React from 'react';
import useToken from '../hooks/useToken';
import useUser from '../hooks/useUser';
import { useState, useEffect } from 'react';
import { SERVER_API_URL } from '../config/Constants'
import ViewProject from './ViewProject';
import ProjectsList from '../components/ProjectsList';
import { BrowserRouter, Route, Switch, useRouteMatch, Link } from 'react-router-dom';
import ImageBackground from "../components/ImageBackground"
import Image from "../components/Image";
import ProfileHeader from "../components/ProfileHeader"
import { useHistory } from "react-router-dom";
import fetchClient from "../utils/http-common";
import strings from "../config/strings";
import Utils from '../utils/utils'
import { HEADER_HEIGHT } from '../config/Constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Loader from "react-loader-spinner";

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';
import { render } from '@testing-library/react';



export default function Forum() {

  const [loading, setLoading] = useState(true)
  const [themas, setThemas] = useState([]);
  const [currentThema, setCurrentThema] = useState([]);
  const [topics, setTopics] = useState([]);
  const [currentType, setCurrentType] = useState("Topics");
  const [currentTopic, setCurrentTopic] = useState([]);
  const [posts, setPosts] = useState([]);
  const [showTopics, setShowTopics] = useState(true);
  const [allRcPatterns, setAllRcPatterns] = useState([])
  const [showFullScreenPic, setShowFullScreenPic] = useState(false);
  const [showMaxFullScreenPic, setShowMaxFullScreenPic] = useState(false);
  const [showLoadingPic, setShowLoadingPic] = useState(false);
  const [thumbSize, setThumbSize] = useState(80);

  const selectedColor = "#2ba5f7"
  const selectedBg = "rgba(255,255,255,0.4)"

  const defaultColor = "#525252"
  const defaultBg = "rgba(255,255,255,0.4)"


  const getTopics = (thema) => {
    setLoading(true)
    fetch(`${SERVER_API_URL}/themas/${thema.id}/topics.json?lang=${strings.lang}`,
    {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        }
    })
    .then((response) => response.json())
    .then(data => {
      const topics = data
      console.log("topics")
      console.log(topics)
          setTopics(topics) 
          setLoading(false)
      })
    .catch(error => {alert('err ' + error.message); 
    setLoading(false) })
  } 

  const getPatterns = () => {

    fetch(`${SERVER_API_URL}/patterns/get_patterns.json?lang=${strings.lang}`,
    {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        }
    })
    .then((response) => response.json())
    .then(data => {

      const rcPatterns = data
      console.log("Patterns!")
      console.log(data[0])
      setAllRcPatterns(data)
      setLoading(false)
    })
    .catch(error => {alert('err ' + error.message);
    setLoading(false) })  
  }

  const getPosts = (currentTopic) => {

    setLoading(true)
    fetch(`${SERVER_API_URL}/topics/${currentTopic.id}.json`,
    {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        }
    })
    .then((response) => response.json())
    .then(data => {
  //debugger;
      const topic = data
      console.log(topic.posts)
       
      setPosts(topic.posts)
    
      setLoading(false)

      })
    .catch(error => {alert('err ' + error.message);
    setLoading(false) })
  }
    
  useEffect(() => {

    let mounted = true;
    
    getPatterns()

    fetch(`https://app.rowcounterapp.com/themas.json?lang=${strings.lang}`,
    {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        }
    })
    .then((response) => response.json())
    .then((data) => {
  //debugger;
      console.log("themas")
      const themas = data
      //const page = 1
      
      console.log(themas)
      
        if(mounted) {
          setThemas(themas)
          setCurrentThema(themas[0])
          //setLoading(false)
          //alert(JSON.stringify(themas[0]))
          getTopics(themas[0])
        }
      })
    .catch(error => {alert('err ' + error.message);
    setLoading(false) })
    //
    //
    return () => mounted = false;
  }, [])

  const goToThema = (selectedThema) => {
    setCurrentThema(selectedThema)
    getTopics(selectedThema)
    setShowTopics(true)
  } 

  const goToTopic = (topic) => {
    setCurrentTopic(topic)
    getPosts(topic)
    setShowTopics(false)
  } 

  const isLast = (item, items) => {
    if (item == items[items.length - 1]) {
      return true
    }
    return false
  }

  const renderAttachedPattern = (id) => {
    let pattern = allRcPatterns.find(x => x.id === id)
    console.log(pattern)
    
    if (pattern) {
      let patternLink = `${SERVER_API_URL}/patterns/${pattern.sourcePatternId}/get_pattern`
      return (
        <a target="_blank" href={patternLink} class="pattern-link">
        <View
          style={{flexDirection: "row", marginTop: 8, justifyContent: "flex-end"}} >
            <Image style={{marginRight: 4, width: 12, height: 12}} urlOnServer={'../imgs/paper-clip.png'} />
          <Text style={{color:selectedColor, fontSize: 12, marginRight: 8}}>{Utils.cutString(pattern.name, 12)}</Text>
          
        </View>
        </a>
            )
    }

  } 

  const renderAllAttachedPatterns = (patterns) => {
    return (
      <FlatList style={{flex:0}}
      data={patterns}
      contentContainerStyle={{justifyContent:'center', alignItem:'center', marginHorizontal: 12}}
      numColumns={1}
      horizontal={true}
      extraData={posts}
      keyExtractor={(item, index) => item.toString()}
      renderItem={
        (item) => renderAttachedPattern(item.item)
      } 
    />
    )
  }

  const renderPostInfos = (postId) => {
    let userToRespond = posts.find(x => x.id === postId).user
    return (
      <View style={{flexDirection:"row", justifyContent: "flex-start"}}>
        <Image style={{marginHorizontal: 8, width: 24, height: 24}} urlOnServer={'../imgs/right-arrows.png'} />
        <Image style={{width: 24, height: 24}} urlOnServer={'../imgs/default_user_img.png'} />
        <Text style={{marginLeft: 8, color: "#a0a0a0"}}>{userToRespond.name}</Text>
      </View>
    )
}

  const renderTopics = () => {
    return (topics.map( (topic) => {
      return (
        <div class="col-12">
          <TouchableOpacity
          onPress={() => goToTopic(topic)}>
          <View style={{
            borderBottomWidth: isLast(topic, topics) ? 0 : 0.5, 
            borderColor:"rgba(0,0,0,0.2)", 
            padding:12,
            flexDirection:"row",
            justifyContent:"space-between",
            alignItems:"center"}}>
           <Text style={{textAlign: "left", color:"#525252"}}>{topic.title}</Text>
           <View style={{flexDirection:"row", justifyContent:"flex-end", alignItems:"center"}} >
              <Text style={{textAlign: "left", color:"#525252"}}>{topic.nb_posts}</Text>
              <Image style={{marginLeft: 8, width: 18, height: 18, tintColor:"#c4c4c4"}} urlOnServer={'../imgs/right-arrow.png'} />
           </View>
          </View>
          </TouchableOpacity>

        </div>
      )
    })
    )
  }

  const renderPosts = () => {
    return (posts.map( (post) => {
      return (
        <div class="col-12">
          <View style={{borderBottomWidth: isLast(post, posts) ? 0 : 0.5, borderColor:"rgba(0,0,0,0.2)", padding:12}}>
            <View
              style={{flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginBottom: 8}}>
              <Image style={{width: 24, height: 24}} urlOnServer={'../imgs/default_user_img.png'} />
              <Text style={{marginLeft: 8, textAlign: "left", color:selectedColor}}>{post.user.name}</Text>
              {post.respond_to_post_id && renderPostInfos(post.respond_to_post_id)}
            </View>
           <Text style={{textAlign: "left", color:"#525252"}}>{post.txt}</Text>
           {post.patterns[0] && renderAllAttachedPatterns(post.patterns)}
           {post.images && post.images[0] &&
            <View style={{marginTop: 8}} >
              {renderPicsPreview(post.images)}
            </View>
             }
          </View>
        </div>
      )
    })
    )
  }

  const renderOnePic = (pic, editPics) => {
    console.log("PIC SMALL", pic)
    var myPic = {...pic}
    myPic.editPics = editPics
    var picSize = editPics ? 48 : thumbSize

    var uri

    if (pic.thumbnail)
      uri = pic.thumbnail
    else if (pic.uri)
      uri = pic.uri
    else
      uri = pic.url
    return (
      <TouchableOpacity

      onPress={(pic) => setShowFullScreenPic(myPic) } >
        <Image
          style={{width: picSize, height: picSize, borderRadius: 8, marginHorizontal: 4, marginBottom: 8}}
          source={{uri: uri }} />
      </TouchableOpacity>

    )
  }

  const renderImgFullScreen = () => {
    console.log("PIC FULLSCREEN", showFullScreenPic)
    var picSize = Dimensions.get('window').height - 100
    return (
      <TouchableOpacity style={[styles.containerFullScreen,{height: Dimensions.get('window').height}]} onPress={() => setShowFullScreenPic(false)}>


      <TouchableOpacity style={{ height: picSize, width: picSize }} onPress={() => setShowMaxFullScreenPic(showFullScreenPic)}>
        <Image style={{borderRadius: 8, height: picSize, width: picSize}} source={{uri: showFullScreenPic.uri ? showFullScreenPic.uri : showFullScreenPic.url}} resizeMode="cover"
          />
        </TouchableOpacity>
        <View style={{flexDirection: "row", justifyContent: "space-around", width: picSize}}>
        <TouchableOpacity
            onPress={() => setShowFullScreenPic(false)}
            style={{backgroundColor: "white", borderRadius: 40, padding: 12, marginTop: 12}} >
          <FontAwesomeIcon style={{color: selectedColor}} size={16} icon={ faArrowLeft } />
          </TouchableOpacity>
       



        </View>

      </TouchableOpacity>

      )
  }

  const renderPicsPreview = (allPics, editPics = false) => {
    return (

      <FlatList
      style={{flexGrow: 0, marginHorizontal: 12}}
      data={allPics}
      containerStyle={{flexGrow: 0, justifyContent:'center', alignItem:'center'}}
      horizontal={true}
      keyExtractor={(item, index) => item.toString()}
      renderItem={
        (item) => renderOnePic(item.item, editPics)
      }
    />

    )
  }

  const renderLoading = () => {
    return (
      <View style={{width: "100%", justifyContent:"center", alignItems:"center", padding: 50}}>
        <Loader
          type="ThreeDots"
          color="#2ba5f7"
          height={100}
          width={100}
          // timeout={8000} //3 secs
        />
      </View>
    )
  }

  if (loading)
    return renderLoading()

  return(
 

    <ImageBackground urlOnServer={'/imgs/background_knitting2.jpg'} style={{flex: 1, flexDirection:'column', Width: Dimensions.get('window').width, minHeight: Dimensions.get('window').height - 70}}>

     <div className="container mt-5 rcpattern-container">  
     <h2>{strings.forum.forum}</h2>
       <h5 className="mb-4 mt-3">{strings.forum.subtitle}</h5>
       <div className="row">
       {
       themas.map( (thema) => {
         var color = thema == currentThema ? selectedColor : defaultColor
         var bg = thema == currentThema ? selectedBg : defaultBg
         var border = thema == currentThema ? selectedColor : "rgba(0,0,0,0.2)"
         return (
           <div class="col-3">
             <TouchableOpacity
             onPress={() => goToThema(thema)}>
              <View style={{backgroundColor:bg, borderRadius: 12, borderWidth: 0.5, borderColor:border, padding:4}}>
                <Text style={{textAlign: "center", color:color}}>{thema.title}</Text>
              </View>
             </TouchableOpacity>

           </div>
         )
       })
      }   
       </div>
       {!showTopics && 
        <View style={{marginTop: 24, paddingHorizontal: 12, flexDirection: "row", justifyContent: "flex-start", alignItems: "center",}}>
          <TouchableOpacity
          onPress={() => setShowTopics(true)} >
            <Image style={{width: 20, height: 20, marginRight: 12}} urlOnServer={'../imgs/left-arrow.png'} />
          </TouchableOpacity>
          
          <Text style={{color: selectedColor, fontWeight: "bold", fontSize: 16}}>{currentTopic.title}</Text>
        </View>
       }
       <View style={{backgroundColor:defaultBg, borderRadius: 12, borderWidth: 1, borderColor:"rgba(0,0,0,0.2)", marginVertical:24}}>
       <div className="row">
       {topics && showTopics && renderTopics()}  
       {posts && !showTopics && renderPosts()}  

       </div>
      </View>
      <View style={{backgroundColor:"rgba(0,130,218,0.4)", padding: 24, marginBottom: 24}}>
        <View style={{flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
          <Image style={{width: 36, height: 36, marginRight: 16}} urlOnServer={'../imgs/information.png'} />
          <View>
            <Text style={{color: "white", fontSize: 20, letterSpacing: 1, fontWeight: "bold"}}>{strings.forum.postInForum}</Text>
            <Text style={{color: "white", fontSize: 14}}>{strings.forum.downloadApp}</Text>
          </View>
        </View>

        <View style={{marginLeft: 16, marginTop: 12, flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
        <a target="_blank" href={"https://apps.apple.com/us/app/my-row-counter-knit-crochet/id1342608792"} class="pattern-link">
          <Image style={{width: 120, height: 40, marginRight: 16}} urlOnServer={'../imgs/download-on-the-app-store.svg'} />
        </a>
        <a target="_blank" href={"https://play.google.com/store/apps/details?id=com.row_counter"} class="pattern-link">
          <Image style={{width: 136, height: 60}} urlOnServer={'../imgs/google-play-badge.png'} />
        </a>
        
        </View>
      </View>
      </div>
{showFullScreenPic && !showMaxFullScreenPic && renderImgFullScreen() }  
    </ImageBackground>
  );
}


const styles = StyleSheet.create({
  containerFullScreen: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    justifyContent: "center",
    alignItems: "center",
    flex: 0,
    elevation: 4,
    zIndex: 5000,
    backgroundColor:"rgba(0,0,0,0.9)",
    padding: 12
  },

});


