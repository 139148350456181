import React, { Component } from "react";
import strings from "../../config/strings";
import Utils from "../../utils/utils";

import Button from "../../components/Button";
import Cell from "./Cell"
//import LayoutProvider from "../../components/generator/LayoutProvider"

import { RecyclerListView, DataProvider, LayoutProvider } from "recyclerlistview";


import {
  ScrollView,
  View,
  TextInput,
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Keyboard,
  SafeAreaView,
  DeviceEventEmitter,
  PanResponder,
  BackHandler,
  Dimensions
} from 'react-native';


const MIN_CELL_WIDTH = 20
const MAX_CELL_WIDTH = 50
const PADDING_LEFT = 20
const PADDING_TOP = 35

var __DEV__ = true;

const ZOOM_LEVELS = [20, 30, 40, 60, 80]
export default class Schema extends Component {
  handler = dims => this.setState(dims);

  constructor(props) {
    super(props);

   

    const arrayWidth = this.props.arrayWidth;
    const arrayHeight = this.props.arrayHeight;


    var columnArray = []
    for (var y = arrayHeight; y > 0; y--) {
      columnArray.push(y)

    }

    this.dataProvider = this.cloneRow(this.props.array);


  //  alert(this.storage.getRavelryTokenExpiration() + " " + new Date().getTime())


    this.offsetX = 0
    if (this.props.preview)
      var cellSize = 40
    else {
      var cellSize = Math.round((this.props.width - 50) / (arrayWidth + 2) );
    


        var zoomLevel = 0
        if (cellSize < ZOOM_LEVELS[0]) {
          cellSize = ZOOM_LEVELS[0]
        }
        else {
          for (var i = 0; i < ZOOM_LEVELS.length; i++) {
            if (cellSize < ZOOM_LEVELS[i]) {
             // cellSize = ZOOM_LEVELS[i]
              zoomLevel = i
              break;
            }
          }
      } 
    }

    if (cellSize > MAX_CELL_WIDTH)
      cellSize = MAX_CELL_WIDTH;


    //alert(cellSize)
    var layoutProvider = new LayoutProvider(
            index => {
                return 1
            },
            (type, dim) => {
                dim.width = cellSize
                dim.height = cellSize
            }
        );

    if(__DEV__) console.log('plop setting state')
    //this.setState({cellSize: cellSize, layoutProvider: layoutProvider})


    this.state = {
      arrayWidth: arrayWidth,
      arrayHeight: arrayHeight,
      columnLabels: columnArray,
      cellSize: cellSize,
      layoutProvider: layoutProvider,
      selectedLine: this.props.selectedLine,
      offsetX: 0,
      viewHeight: this.props.height ? this.props.height : 0,
      zoomLevel: zoomLevel
    };

  
  }

  shouldComponentUpdate(nextProps, nextState) {                                     
    const { array, width, height, selectedLine } = nextProps                                          
    const { array: oldArray, width: oldWidth, height: oldHeight, selectedLine: oldSelectedLine} = this.props                 
                    

    const { offsetX, viewHeight } = nextState                                          
    const { offsetX: oldOffsetX, viewHeight: oldViewHeight} = this.state    
                   // alert(JSON.stringify(nextProps))


                    //return true

    if(__DEV__) console.log('plop checking shouldComponentUpdate', width !== oldWidth || height !== oldHeight || selectedLine !== oldSelectedLine)
    if (array !== oldArray) {
      this.dataProvider = this.cloneRow(nextProps.array)


      return true
    }


    if (this.state.cellSize != nextState.cellSize || this.state.layoutProvider != nextState.layoutProvider)
      return true

    if (offsetX < oldOffsetX - 20 || offsetX > oldOffsetX + 20)
        return true

    if (viewHeight < oldViewHeight|| viewHeight > oldViewHeight)
        return true

    return width !== oldWidth || height !== oldHeight || selectedLine !== oldSelectedLine         
  }   



  cloneRow(array) {

    if (Platform.OS === 'android')
      return null
    var dataProvider = new DataProvider((r1, r2) => {
            return r1 !== r2;
        });

    dataProvider = dataProvider.cloneWithRows(array);

    return dataProvider
  }

  componentDidMount() {
   
  }


  renderOverview() {

    var maxHeight = Dimensions.get('window').height / 4
    var maxWidth = Dimensions.get('window').width / 4


   
  }


  zoom(value) {
    if (value > 0) {

      
      if (this.state.zoomLevel < ZOOM_LEVELS.length - 1) {
        var cellSize = ZOOM_LEVELS[this.state.zoomLevel + 1]



        if (Platform.OS === 'ios') {
          var layoutProvider = new LayoutProvider(
              index => {
                  return 1
              },
              (type, dim) => {
                  dim.width = cellSize
                  dim.height = cellSize
              }
          );
        }
        else
          var layoutProvider = null
        this.setState({zoomLevel: this.state.zoomLevel + 1, cellSize: cellSize, layoutProvider: layoutProvider})
      }


     
      
    }
    else {
     
      if (this.state.zoomLevel > 0) {
        var cellSize = ZOOM_LEVELS[this.state.zoomLevel - 1]

        if (Platform.OS === 'ios') {
          var layoutProvider = new LayoutProvider(
              index => {
                  return 1
              },
              (type, dim) => {
                  dim.width = cellSize
                  dim.height = cellSize
              }
          );
        }
        else
          var layoutProvider = null
        this.setState({zoomLevel: this.state.zoomLevel - 1, cellSize: cellSize, layoutProvider: layoutProvider})
      }

    }
  }

  renderRowNum(num, left) {
    
    if (left) {
      if (this.props.selectedWorked == 1) {
        var toShow = ''
      } else {
        var numRow = num
        var toShow = numRow % 2 == 0 ? numRow : ''
      }

    } else {
      if (this.props.selectedWorked == 1) {
        var toShow = num + 1
      } else {
        var numRow = num + 1
        var toShow = numRow % 2 == 0 ? "" : numRow
      }
    }
    return(
      <View style={{width:20,justifyContent:'center', alignItems:'center'}}>
          <Text style={{textAlign:'center', fontSize:12, color: '#fff'}}>{toShow}</Text>
      </View>
    )
  } 

  renderItem(item, index, allRowNum) {

    return (
      <View
        style={{flexDirection: "row"}}>
        {
        allRowNum.includes(index) && this.renderRowNum(this.props.arrayHeight - allRowNum.indexOf(index), true)
        }
        <Cell
        key={"cell_" + item.posX + '_' + item.posY + '_' + item.selected}
        size={this.state.cellSize}
        item={item}
        //symbol={item.symbol}
        color={item.color ? item.color : undefined}
        //isFaded={item.posY == this.props.selectedLine ? false : true}
        isFaded={!item.selected && !this.props.isEditing && this.props.selectedLine != -1}
        onPress={() => {
          this.props.onCellSelected(item)
      }}/>
      {
        allRowNum.includes(index + 1) && this.renderRowNum(this.props.arrayHeight - allRowNum.indexOf(index + 1), false)
      }
      </View>

    )
  }


  renderRowNumbers() {

     // alert(this.state.viewHeight)
    return (


      <View style={{height: this.state.viewHeight - this.state.cellSize, width:this.state.arrayWidth * this.state.cellSize + 50, position:'absolute', left:0, right:0, top: 20, flexDirection:'row', justifyContent:'space-between'}}
      pointerEvents="none">
     { this.props.worked != 1 && <FlatList

              ref={(ref) => { this.rowNumbersView = ref; }} 
              style={{width:20, paddingBottom:20, backgroundColor: "green"}}
              data={this.state.columnLabels}
              
              extraData={this.state}
              numColumns={1}
              keyExtractor={(item, index) => "SCHEMA_CELL_LEFT" + index.toString()}
              renderItem={item => <View style={{width:20, height: this.state.cellSize, justifyContent:'center', alignItems:'center'}}>
                <Text style={{textAlign:'center', fontSize:12, color: this.props.isEditing ? '#555' : '#fff'}}>{item.item % 2 == 0 ? item.item : ''}</Text>
                </View>}
            />}

            { this.props.worked == 1 && <View style={{width:20}}/>}


          <FlatList

              ref={(ref) => { this.rowNumbersView2 = ref; }} 
              style={{marginLeft: this.state.arrayWidth * this.state.cellSize, width:20, backgroundColor: "red"}}
              data={this.state.columnLabels}
              
              extraData={this.state}
              numColumns={1}
              keyExtractor={(item, index) => "SCHEMA_CELL_RIGHT" + index.toString()}
              renderItem={item => <View style={{width:20, height: this.state.cellSize, justifyContent:'center', alignItems:'center'}}>
                <Text style={{textAlign:'center', fontSize:12, color: this.props.isEditing ? '#555' : '#fff'}}>{item.item % 2 != 0 || this.props.worked == 1 ? item.item : ''}</Text>
                </View>}
            />
     
      </View>


      )
  }

// IL Y A DEUX FONCTIONS DIFFERENTES CAR CA BUG SUR ANDROID, PARFOIS CA MET UNE PETITE MARGE ENTRE LES CELLS ET CA DECALE TOUT


  renderAndroidList() {
    var numCol = this.props.arrayWidth
    var numRow = this.props.arrayHeight
    var allRowNum = []

    for (let i = 0; i < numRow + 1; i++) {
      allRowNum.push( i * numCol)
    }
    console.log("allRowNum", allRowNum)

    //console.log("renderAndroidList", this.state.cellSize)
    //console.log("this.props.array", this.props.array)
    return ( 
        <FlatList 
            ref={(ref) => { this.scrollListRef = ref; }}
            key={"plop_" + this.state.cellSize + '_' + this.state.arrayHeight + '_' + this.state.arrayWidth + '_' + this.state.zoomLevel}
            //listKey="SCHEMA_LIST"
            extendedState={this.state.offsetX}
            keyExtractor={(item, index) => "SCHEMA_CELL_" + item.posX + '_' + item.posY}
            style={{ width: this.state.arrayWidth * this.state.cellSize + 60, height: this.state.viewHeight - this.state.cellSize, marginLeft: PADDING_LEFT, marginRight: PADDING_LEFT }}
            // layoutProvider={this.state.layoutProvider} 
            // dataProvider={this.dataProvider} 
            //renderAheadOffset={1}
            renderItem={(item) => this.renderItem(item.item, this.props.array.indexOf(item.item), allRowNum)}
            onMomentumScrollEnd={(event) => {

                if (__DEV__) console.log('event', event.nativeEvent.contentOffset.x, event.nativeEvent.contentOffset.y, event.nativeEvent)
                // alert('onScroll ' + offsetX)
                if (this.rowNumbersView) {
                    this.rowNumbersView.scrollToOffset({ offset: event.nativeEvent.contentOffset.y })
                }
                if (this.rowNumbersView2) {
                    this.rowNumbersView2.scrollToOffset({ offset: event.nativeEvent.contentOffset.y })
                }
                //  alert(offsetX)
            }
            }
            data={this.props.array}
            extraData={this.state}
            numColumns={this.state.arrayWidth}
        />

            )
  }

  renderList() {
    console.log("Schema height", this.props.schemaHeight )
    //if(__DEV__) console.log('renderList', this.state.cellSize)
//alert('renderList')
    //var listHeight = this.props.height - this.state.paddingTop - this.state.paddingBottom) / 1.3
 
    var columnLabels = []
   // var rowLabels = []

    /*for (var i = 0; i < this.state.arrayHeight; i++) {
      rowLabels.push(
        <View style={{width: 15, height: this.state.cellSize, justifyContent:'center', alignItems:'center'}}>
        <Text>{i}</Text>
        </View>)
    }*/


    if (!this.props.preview) {
    for (var i = this.state.arrayWidth; i > 0; i--) {
      columnLabels.push(
        <View style={{width: this.state.cellSize, height: 20, justifyContent:'center', alignItems:'center'}}>
        <Text style={{textAlign:'center', fontSize:12, textAlign: "center", color: '#fff'}}>{i}</Text>
        </View>)
    }
  }

  // alert(JSON.stringify(this.props.array))


    var listHeight = this.props.height > this.state.arrayHeight * this.state.cellSize ? this.state.arrayHeight * this.state.cellSize : this.props.height
    return (




<ScrollView
    key={"mainScrollView_" + this.state.cellSize}
    style={{marginBottom: 12 }}
    contentContainerStyle={{height: this.props.schemaHeight }}
    horizontal={true}
    showsHorizontalScrollIndicator={false}
    onScrollEndDrag={() => {
        //alert('f')
        if (this.timerOffset) {
            clearTimeout(this.timerOffset)
            this.timerOffset = null
        }
        this.timerOffset = setTimeout(() => {
            this.setState({ offsetX: this.offsetX })
        }, 500)
    }
    }
    scrollEventThrottle={64}
    onScroll={(event) => {
        this.offsetX = event.nativeEvent.contentOffset.x
    }}>
    <View style={{ flex: 1 }}>

        <View style={{ flexDirection: 'column', flex: 1 }}>


            {!this.props.preview && this.props.worked != 1 && (<View style={{ flexDirection: 'row',  alignItems: "center", justifyContent: "center" }}>
                {columnLabels}
            </View>)}

            <View style={{ flex: 1 }}
                onLayout={(event) => {
                    var { x, y, width, height } = event.nativeEvent.layout;
                    if (this.state.viewHeight != height || height > this.state.arrayHeight * this.state.cellSize) {
                        //alert(height + ' ' + this.state.arrayHeight * this.state.cellSize)
                        if (height > this.state.arrayHeight * this.state.cellSize)
                            height = (this.state.arrayHeight * this.state.cellSize) + this.state.cellSize
                        this.setState({ viewHeight: height })
                    }
                    // alert(height)
                }}
            >

                <View style={{  height: this.state.viewHeight }}>
                    {this.state.viewHeight != 0 && this.renderAndroidList()}

                    {!this.props.preview && (<View style={{ flexDirection: 'row', alignItems: "center", justifyContent: "center" }}>
                        {columnLabels}
                    </View>)}


                </View>
            </View>

        </View>
        {!this.props.preview && false && this.renderRowNumbers(this.props.height)}


    </View>

</ScrollView>
      )
  }

  render() {

    
    if (this.state.image) {
      return (
          <TouchableOpacity style={{flex:1}} onPress={() => this.setState({image: null})}>
            <Image resizeMode={'contain'} style={{flex:1}} source={{uri: this.state.image}}/>

          </TouchableOpacity>

        )
    }


   /* return (

       <ScrollView ref="scrollviewShot" nestedScrollEnabled={true}>
     <RecyclerListView ref={(ref) => { this.scrollListRef = ref; }} 
            key={"plop" + this.state.cellSize + '_' + this.state.arrayHeight + '_' + this.state.arrayWidth}


            style={{width: this.state.arrayWidth * this.state.cellSize, height: this.state.arrayHeight * this.state.cellSize, marginLeft: PADDING_LEFT, backgroundColor:'green'}} 
            layoutProvider={this.state.layoutProvider} 
            dataProvider={this.dataProvider} 
            rowRenderer={(type, data, index) => this.renderItem(data)} 
            onScroll={(event, offsetX, offsetY) => {

              if (this.rowNumbersView) {
                this.rowNumbersView.scrollToOffset({offset: offsetY})
              }
              if (this.rowNumbersView2) {
                this.rowNumbersView2.scrollToOffset({offset: offsetY})
              }
              if(__DEV__) console.log("event", offsetX, offsetY)}
            }/>
        </ScrollView>


      )*/

    if(__DEV__) console.log('plop cellsize', this.state.cellSize)
    if (this.state.cellSize == 0)
      return null

    return this.renderList()
   /*var views = []

      for (var i = 0; i < 30; i++) {
        
        const z = i;
        views.push(
          <TouchableOpacity style={{width:100, height:100, borderColor:'#555', borderWidth:1, backgroundColor: 'rgba(' + (i *5) + ', ' + (i *8) + ', ' + (i *3) + ', 1)'}}

          onPress={() => alert(z)}
          />

          )
      }
      return (
        <ScrollView style={{flexDirection:'row'}} horizontal={true}
        contentOffset={{x: 180, y:0}}
        pointerEvents={'box-none'}
        {...this.panResponder.panHandlers}
        >
         
        <View style={{flexDirection:'row', width:Dimensions.get('window').width * 2, height:100}}>
            {views}
        </View>

        </ScrollView>
      );

*/
  }
}




const styles = StyleSheet.create({


});
