import React, { Component } from "react";
import strings from "../config/strings";
import Image from "../components/Image"
import Button from "../components/Button"
import Utils from "../utils/utils";
import {SECONDARY_COLOR} from "../config/styles";
import { BrowserRouter, Route, Switch, useRouteMatch, Link, useParams, useHistory } from 'react-router-dom';
import ProjectUtils from '../utils/projectUtils'

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

/*
const CacheableImage = imageCacheHoc(Image, {
  validProtocols: ['http', 'https'],
  cachePruneTriggerLimit: 1024 * 1024 * 40
});
*/

export default class SelectPart extends Component {
  constructor(props) {
    super(props);


    this.state = {selectedPart: this.props.project.current_partie}
  }

  render() {

    var parties = [];


    for (let i = 0; i < ProjectUtils.getNbParts(this.props.project); i++) {
      parties.push({value:i, label: i + 1})
    }
    return (
       <TouchableOpacity  style={{zIndex:3000, position:"absolute", top:0, bottom:0, left:0, right:0, flexDirection:'row', alignItems: "center", flex:1, justifyContent:'center', backgroundColor:'rgba(128, 128, 128, 0.8)'}}
       onPress={() => this.props.onClose()}>

          <TouchableOpacity style={{borderRadius:8,
            backgroundColor: "white",
            shadowColor: "#000",
            shadowOpacity: 0.3,
            shadowRadius: 2,
            shadowOffset: { width: 1, height: 1 },
            elevation: 3,
            margin: 16,
            minWidth:'40%',
            zIndex:5000,
            padding:16,
           justifyContent:'center',
            alignItems:'center',
            alignContent:'center',}}
            >


<Text style={{color:'#888', marginVertical:16, fontSize:16}}>{strings.select_part.toUpperCase()}</Text>



          {
            parties.map((obj, i) => (
              <View>

              <TouchableOpacity style={{flexDirection:'row', alignItems:'center', marginBottom:12}}
              onPress={() => this.setState({selectedPart: i})}>
                <input type="radio" checked={this.state.selectedPart === i ? "checked" : ""}/>
                
                <Text style={{color:'#555', marginLeft:10}}>{strings.part + ' ' + `${i + 1}`}</Text>

              </TouchableOpacity>
              </View>
            ))
          }
       

          <Button style={{alignSelf:'center', margin:16}} title={strings.select.toUpperCase()}  onPress={() => this.props.onChangePart(this.state.selectedPart)}/>

          
          </TouchableOpacity>


          </TouchableOpacity>

      )
  }
}



const styles = StyleSheet.create({
  list_image: {
    marginLeft: 8,
    marginRight: 8,
    flex: 1
  },
  headerBox: {
    backgroundColor: SECONDARY_COLOR,
    padding: 5,

    overflow: "hidden",
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8
  },
  headerText: {
    textAlign: "center",
    color: "white",
    fontSize: 17,
    fontWeight: "300",
    marginTop: 10,
    backgroundColor: "transparent"
  },
  done: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  doneItem: {
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 15
  },
  doneItemTextTitle: {
    textAlign: "center"
  },
  doneItemTextNb: {
    fontSize: 16,
    fontWeight: "300",
    textAlign: "center"
  },
  imgDone: {
    alignSelf: "center",
    marginBottom:5
  },
  border: {
    alignSelf: "stretch",
    height: 1,
    backgroundColor: "#CCC",
    marginTop: 10,
    marginBottom: 10
  },
  header: {
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    elevation: 5
  },

  headerBackgroundImg: {
    width: "100%"
  },
  badge: {
    alignSelf: "center"
  },


  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between"
  },

  touchableCreateProj: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },

  trigger: {
    borderRadius: 4,
    padding: 10,
    margin: 5,
    backgroundColor: "#CCC"
  },
  menuOptionText: {
    color:'#888',
    textAlign:'center',
    marginHorizontal: 5,
  },

  menuElem: {
    borderBottomWidth:0.5, 
    padding:20, 
    borderColor:"#ccc",
    flex: 1,
  }



});
