import { useState } from 'react';

export default function useRavelryToken() {
  const getRavelryToken = () => {
    const token = localStorage.getItem('ravelryToken');
   // const userToken = tokenString;

    // alert(token)
    return token
  };

  const [ravelryToken, setRavelryToken] = useState(getRavelryToken());

  const saveRavelryToken = ravelryToken => {
    localStorage.setItem('ravelryToken', ravelryToken);

    //alert('token set ' + userToken)
    setRavelryToken(ravelryToken);
  };
  return {
    setRavelryToken: saveRavelryToken,
    ravelryToken
  }
}