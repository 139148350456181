import React from 'react';
import useToken from '../hooks/useToken';
import { useState, useEffect} from 'react';
import { SERVER_URL } from '../config/Constants'
import { BrowserRouter, Route, Switch, useRouteMatch, Link, useParams } from 'react-router-dom';
import Utils from '../utils/utils'
import Image from "../components/Image"
import "../config/styles.css"
import { Circle } from 'rc-progress';
import strings from "../config/strings";

export default class Counter extends React.Component {

getPrct(current_num,max_num) {
    var prct = 0
    if (max_num > 0) {
        prct = current_num / max_num * 100
    }
    return prct
}

renderNumbReset(show_reset) {
    if (show_reset) {
        return (
            <div className="RepeatCount" >
                    <p>{this.props.nbResets}</p>
            </div>
        )
    } else {
        return (
            <div style={{width: 30, height: 30, margin:5}} >
            </div>
        )
    }
}
renderSideButtons(isMain) {
    console.log("Counter", this.props.isMain, this.props.name, this.props.value, this.props.maxNum )
    if (!isMain) {
        return (
            <div className="ToggleContainer" >
                <Image urlOnServer={ this.props.isLinked ? '/imgs/linked.png' : '/imgs/notlinked-white.png'} style={{width: 30, height: 30, margin:5}} onClick={() => this.props.onToggleLinked()}/>
                {this.renderNumbReset(this.props.showReset)}
            </div>
        )
    }
    else {
        return (
            <div className="ToggleContainer" >
                <div style={{width: 30, height: 30, margin:5}} >
                </div>
            </div>
        )
    }
}

getCounterName(name, isMain) {
    if (name) {
        return name
    } else if (isMain) {
        return strings.global_counter
    }
    else {
        return strings.secondary_counter
    }
}

  render() {
    //alert(JSON.stringify(this.props))
    return (
      <div className="CounterContainer" id={this.props.isMain && "MainCounterContainer"} >
        <h3 className="CounterName" >{this.getCounterName(this.props.name, this.props.isMain)}</h3>
        <div className="CounterBtnContainer">
            {this.renderSideButtons(this.props.isMain)}
            <div className="ProgressContainer" >
                <Circle className="Progress" id={this.props.isMain && "MainCounter"} percent={this.getPrct(this.props.value, this.props.maxNum)} strokeWidth="10" trailWidth="10" strokeLinecap="square" trailColor ="#cccccc" strokeColor={this.props.isMain?"#0E71B4":"#FFB800"} >
                </Circle>
                <p className="CounterNumber"  id={this.props.isMain && "MainCounterNb"} >{this.props.value}</p>
            </div>
            <div className="ToggleContainer" >
                <button className="Toggle" onClick={() => this.props.onChange(1, this.props.position)}>
                    <Image urlOnServer={'/imgs/plus-new-bt2.png'} style={{width: 20, height: 20, margin:0}}/>
                </button>
                <button className="Toggle" onClick={() => this.props.onChange(-1, this.props.position)}>
                    <Image urlOnServer={'/imgs/minus-new-bt2.png'} style={{width: 20, height: 20, margin:0}}/>
                </button>
            </div>
        </div>
      </div>
      );
  }
}
