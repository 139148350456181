import React from 'react';
import useToken from '../hooks/useToken';
import useUser from '../hooks/useUser';
import { useState, useEffect } from 'react';
import { SERVER_API_URL } from '../config/Constants'
import ViewProject from './ViewProject';
import ProjectsList from '../components/ProjectsList';
import { BrowserRouter, Route, Switch, useRouteMatch, Link } from 'react-router-dom';
import ImageBackground from "../components/ImageBackground"
import Image from "../components/Image";
import ProfileHeader from "../components/ProfileHeader"
import { useHistory } from "react-router-dom";
import fetchClient from "../utils/http-common";
import strings from "../config/strings";
import Utils from '../utils/utils'
import { HEADER_HEIGHT } from '../config/Constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'


import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';



export default function RcPatterns() {

  const history = useHistory();
  const { user, setUser } = useUser();
  const [ rcPatterns, setRcPatterns ] = useState();
  const [loading, setLoading] = useState(true)
  const [selectedTool, setSelectedTool] = useState();
  const [filteredPatterns, setFilteredPatterns] = useState();
  const [width, setWidth] = useState(window.innerWidth);

  //this.projInProgress = this.props.location.project;


  
  const getPatterns = (mounted) => {
    let options = Object.assign({ method: "GET" }, null);
    options.headers = Utils.getJSONHeadersWithoutAuth();
    console.log("in function getPatterns")
    fetchClient.get(`${SERVER_API_URL}/patterns/get_patterns.json?lang=${strings.lang}`)
    .then(data => {
      //console.log("in then")
      console.log(data)
      if (mounted) {
        setRcPatterns(data.data)
        setLoading(false)
      }
      })
    .catch(error => {
      console.log("in catch")
      alert('err ' + error.message); 
      setLoading(false)
      console.log(error) })
    }

    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    }

    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

    
    useEffect(() => {
      let mounted = true
      getPatterns(mounted)
      console.log(rcPatterns)
      return () => mounted = false;
    },[])

    const getImage = (pattern) => {
      if(pattern.imgAttachmentId) {
        return Utils.getAttachementUrl(pattern.imgAttachmentId)
      } else if (pattern.type == 0 ) {
        return "/imgs/tricot-ph.png"
      } else {
        return "/imgs/crochet-ph.png"
      }
    }



    const renderOnePattern = (pattern) => {
      console.log("render one")
      let patternLink = `${SERVER_API_URL}/patterns/${pattern.sourcePatternId}/get_pattern`
      return (
        
        <div class="card-trip mb-3 ml-3 mr-3">
          <a target="_blank" href={patternLink} >
          <Image style={{width:300, height:200}} urlOnServer={getImage(pattern)} />
          <div class="card-trip-infos">
            <div class="card-trip-text">
              <h2>{pattern["name"]}</h2>
              <p>{pattern["author"]}</p>
            </div>
            <h2 class="card-trip-pricing"><Image style={{width:20, height:20, tintColor: "#2ba5f7"}} urlOnServer={pattern.projectTool == 1 ? "/imgs/tricot_selected.png" : "/imgs/crochet_selected.png"}/></h2>
          </div>
          </a>
        </div>

      )

    }

    const filterByTool = (tool) => {
      if (selectedTool == tool) {
        setSelectedTool(false)
        setFilteredPatterns(false)
      } else {
        setSelectedTool(tool)
        let selectedPatterns = rcPatterns.filter( (pattern) => {
          return pattern.projectTool == tool
        })
        setFilteredPatterns(selectedPatterns)
        console.log(filteredPatterns)
      }
    }

    const renderOneFilter = (tool) => {
      var border = {flex:1,marginVertical:10,marginHorizontal:5, borderRadius:15, borderColor:"#A0A0A0",borderWidth:1}
      var text = {fontSize:16, textAlign:"center",marginHorizontal:20, color:"#545454",marginVertical:5}
      if (selectedTool == tool) {
        border = {flex:1,marginVertical:10,marginHorizontal:5, borderRadius:15,  borderColor:"#2ba5f7",borderWidth:2}
        text = {fontSize:16, textAlign:"center",marginHorizontal:20, color:"#2ba5f7", fontWeight:"bold", marginVertical:5}
      }
      let toolName = tool == 1 ? "Knitting" : "Crochet"
      return (
        <TouchableOpacity 
        style= {border}
        onPress= {() => filterByTool(tool) }>
         <Text
              style={text}>{toolName}</Text>
        </TouchableOpacity>
      )
    }
    const renderAllFilters = (tools) => {
      return (
        <FlatList
          contentContainerStyle={{flexGrow: 1, alignItems: "center", justifyContent: "center"}}
          data={tools}
          extraData={selectedTool}
          numColumns={1}
          horizontal={true}
          key={'ToolList_ ' + 3}
          renderItem={item => renderOneFilter(item.item)}
          keyExtractor={(item, index) => item.id}
        />
      )
    }


    const renderAllPatterns = (patterns) => {
      let isMobile: boolean = (width <= 768);
      let isTablet: boolean = (width <= 900);
      let nbColumn = isMobile ? 1 : isTablet ? 2 : 3
      return (
        <FlatList
          style={{flex: 1}}
          contentContainerStyle={{alignItems: "center"}}
          data={patterns}
          extraData={rcPatterns}
          numColumns={nbColumn}
          key={'ProjectsList_ ' + nbColumn}
          renderItem={item => renderOnePattern(item.item)}
          keyExtractor={(item, index) => item.id}
        />
      )
    }

  return(
 

    <ImageBackground urlOnServer={'/imgs/background_knitting2.jpg'} style={{flexDirection:'column', width: Dimensions.get('window').width, height: "100%"}}>

     <div className="container mt-5 rcpattern-container">
       <h2>Rc Patterns</h2>
       <h5>Patterns created by our community, optimized to be followed in the Row Counter App</h5>
       <div className="mt-4 filters">
        {renderAllFilters([1,2])}
       </div>
      <div className="mt-4" >
        {filteredPatterns ? renderAllPatterns(filteredPatterns) : renderAllPatterns(rcPatterns)}
        </div>       
      </div>

    </ImageBackground>
  );
}