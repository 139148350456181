import strings from "../config/strings";
import { SYMBOLS_EN } from "./symbols_en";
import { SYMBOLS_FR } from "./symbols_fr";
import { SYMBOLS_DE } from "./symbols_de";

export function getSymbols() {
    if (strings.lang === 'fr')
        return SYMBOLS_FR
    if (strings.lang === 'de')
        return SYMBOLS_DE

    return SYMBOLS_EN
}

export function getFirstSymbolSelectedKnitting() {
    return getSymbols()[1]
}

export function getFirstSymbolSelectedCrochet() {
    return getSymbols()[50]
}

export function searchSymbol(text) {

    var SYMBOLS = getSymbols()
	var newArray = []
	for (var i = 0; i < SYMBOLS.length; i++) {
		if (SYMBOLS[i].rs.indexOf(text) != -1 || SYMBOLS[i].ws.indexOf(text) != -1 || SYMBOLS[i].rs_long.indexOf(text) != -1
		 || SYMBOLS[i].ws_long.indexOf(text) != -1) {
			newArray.push(SYMBOLS[i])
		}
	}

	return newArray
}
