import React, { Component } from "react";
import strings from "../config/strings";
import Image from "../components/Image"
import Utils from "../utils/utils";
import {SECONDARY_COLOR} from "../config/styles";
import { BrowserRouter, Route, Switch, useRouteMatch, Link, useParams, useHistory } from 'react-router-dom';
 

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

/*
const CacheableImage = imageCacheHoc(Image, {
  validProtocols: ['http', 'https'],
  cachePruneTriggerLimit: 1024 * 1024 * 40
});
*/

export default class Menu extends Component {
  constructor(props) {
    super(props);

  }

   

  renderOneMenuItem(txt, onPress, bold) {

    return (
          <TouchableOpacity
              style={styles.menuElem}
              onPress={onPress}
            >
              <Text style={[styles.menuOptionText, {fontWeight: bold? "bold" : "normal", fontSize: bold? 17 : 16 }]}>{txt}</Text>
          </TouchableOpacity>
      )

  }


  render() {
  
    var menuContent = this.props.menuContent
    //alert(JSON.stringify(menuContent))
    return (
        <TouchableOpacity
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              justifyContent: "center",
              alignItems:'center',
              backgroundColor: "rgba(128, 128, 128, 0.7)",
              zIndex:4000,
            }}
            onPress={() => { if (this.props.onClose) this.props.onClose() } }
            >
            <View
                style={{
                  flexGrow: 0,
                  backgroundColor: "white",
                  borderWidth: 1,
                  borderColor: "#ccc",
                  borderRadius: 8,
                  margin:16
                }}
                >
                <FlatList 
                data={menuContent}
                renderItem={item => this.renderOneMenuItem(item.item.txt, item.item.onPress, item.item.bold)}
                keyExtractor={(item) => item.id}
                contentContainerStyle = {{flexGrow:0}}
                style= {{flexGrow:0}}
                />
            </View>
      </TouchableOpacity>

      )
  }
}



const styles = StyleSheet.create({
  list_image: {
    marginLeft: 8,
    marginRight: 8,
    flex: 1
  },
  headerBox: {
    backgroundColor: SECONDARY_COLOR,
    padding: 5,

    overflow: "hidden",
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8
  },
  headerText: {
    textAlign: "center",
    color: "white",
    fontSize: 17,
    fontWeight: "300",
    marginTop: 10,
    backgroundColor: "transparent"
  },
  done: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  doneItem: {
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 15
  },
  doneItemTextTitle: {
    textAlign: "center"
  },
  doneItemTextNb: {
    fontSize: 16,
    fontWeight: "300",
    textAlign: "center"
  },
  imgDone: {
    alignSelf: "center",
    marginBottom:5
  },
  border: {
    alignSelf: "stretch",
    height: 1,
    backgroundColor: "#CCC",
    marginTop: 10,
    marginBottom: 10
  },
  header: {
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    elevation: 5
  },

  headerBackgroundImg: {
    width: "100%"
  },
  badge: {
    alignSelf: "center"
  },


  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between"
  },

  touchableCreateProj: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },

  trigger: {
    borderRadius: 4,
    padding: 10,
    margin: 5,
    backgroundColor: "#CCC"
  },
  menuOptionText: {
    color:'#888',
    textAlign:'center',
    marginHorizontal: 5,
  },

  menuElem: {
    borderBottomWidth:0.5, 
    padding:20, 
    borderColor:"#ccc",
    flex: 1,
  }



});
