import React from 'react';
import PropTypes from 'prop-types';
import { SERVER_API_URL } from '../config/Constants'
import useCurrentProject from '../hooks/useCurrentProject';
import { useState, useEffect } from 'react';
import { getTokenFromUrl } from "../utils/ravelryOauth"
import useRavelryToken from '../hooks/useRavelryToken';
import RavelryService from '../services/ravelryService'

export default function RavelryAuth({ }) {
  const { currentProjectId, setCurrentProjectId } = useCurrentProject();
  const { ravelryToken, setRavelryToken } = useRavelryToken();
  
  useEffect(() => {
    let mounted = true;

    //alert(window.location.href)
    getTokenFromUrl(window.location.href).then(token => {
      setRavelryToken(token)

      getUsername()
    })



    return () => mounted = false;
  }, [])


  const getUsername = () => {
    var ravelryService = new RavelryService(ravelryToken, null);


    ravelryService.getCurrentUser().then(username => {alert(username)})
  }

  return(
    <div >
      <h1>RavelryAuth</h1>
     
    </div>
  )
}

