import React, { Component } from "react";
var ClientOAuth2 = require('client-oauth2')
var base64 = require('base-64');

const clientSecret = '96po/cZlm9Wph46OfxltJizN6t7NLsXN_ieNzq9N'
const redirectUri = 'https://tricoton2.pingou.fr/ravelry_auth'
const clientId = '89edc8b1ef419a547c55c8244fe9ad7c'
const tokenUri = 'https://www.ravelry.com/oauth2/token'
var ravAuth = new ClientOAuth2({
  clientId: clientId,
  clientSecret: clientSecret,
  accessTokenUri: tokenUri,
  authorizationUri: 'https://www.ravelry.com/oauth2/auth',
  redirectUri: redirectUri,
  scopes: ['offline', 'library-pdf'],
  state: 'jnjk989d776j'
})

const authorizationUrl = (url, appId, secretId, callback, scope, responseType = 'token') =>
  `${url}?scope=${encodeURIComponent(scope)}&
  redirect_uri=${encodeURIComponent(callback)}&
  response_type=${responseType}&
  client_id=${appId}&
  secret_id=${secretId}&
  state=q1qs23456ds789`
  .replace(/\s+/g, '')

export function getLoginUri() {

	//var computedUrl = authorizationUrl('https://www.ravelry.com/oauth2/auth', '89edc8b1ef419a547c55c8244fe9ad7c', clientSecret, 'https://tricoton2.pingou.fr/ravelry_auth', 'library-pdf')
  //return computedUrl
  return ravAuth.code.getUri()//.replace('response_type=code', 'response_type=token')//.replace('auth2/auth', 'auth2/token')
}


async function authenticate(authCode) {
  
    const tokenRequest = {
      code: authCode,
      client_id: clientId,
      secret_id: clientSecret,
      redirect_uri: redirectUri,
      grant_type: 'authorization_code'
    }
    let s = []
    for (let key in tokenRequest) {
      if (tokenRequest.hasOwnProperty(key)) {
        s.push(`${encodeURIComponent(key)}=${encodeURIComponent(tokenRequest[key])}`)
      }
    }

    var response = await fetch(tokenUri, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': "Basic " + base64.encode(clientId + ":" + clientSecret)
      },
      body: s.join('&'),
    })
    
    alert(JSON.stringify(response))
     
  }



export async function getTokenFromUrl(url) {

/*	url = url.replace('http://127.0.0.1:3000/ravelry_auth/', redirectUri)
	alert(JSON.stringify(url))
   var user = await ravAuth.code.getToken(url)
   

   user = user.refresh()


alert(user.accessToken)
   return user.accessToken
   
*/


   var start = url.indexOf("=") + 1
   var code = url.substring(start, url.indexOf("&"))

   await authenticate(code)
   return code
}
