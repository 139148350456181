import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import FileUpload from "../components/FileUpload";

import useToken from '../hooks/useToken';
import { SERVER_API_URL } from '../config/Constants'
import ViewProject from './ViewProject';
import ProjectUtils from '../utils/projectUtils'
import { BrowserRouter, Route, Switch, useRouteMatch, Link } from 'react-router-dom';
import ProjectService from "../services/ProjectService";
import Image from "../components/Image"
import ImageBackground from "../components/ImageBackground"
import { HEADER_HEIGHT } from '../config/Constants'
import Button from "../components/Button"
import Utils from "../utils/utils";
import {SECONDARY_COLOR} from "../config/styles";
import strings from "../config/strings";
import "../config/styles.css"
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions,
  Picker
} from 'react-native';

const ALL_RESULTS = {
  social: {
    title: "The Social Stitcher",
    text: "Crafting is a social activity for you, and you love sharing your passion with others. Whether it's joining a knit-along, attending a crafting group, or simply chatting with fellow crafters in the forum or live chat, you thrive on community and connection. You might have more WIP’s than Netflix has shows and more fiber friends than yarn skeins (which is saying something) but your friendly nature makes you a joy to be around and your enthusiasm for crafting is contagious."
  },
  magician: {
    title: "The Messy Magician",
    text: "Only the genius rules the chaos! Maybe you lost a hook in the crease of your blanket while crocheting in bed and that one skein won’t untangle so you had to make an impromptu color change… But you are a self-confident crafter that is not afraid to make mistakes and adapt if your project turns out differently than the pattern. Your motto is “Trust the process” and your superpower is flexibility! This beanie for your dad turned into a phone pouch for your niece (wrong yarn),  this striped sweater you started is now a cosy pillow decorating your sofa (these -In the meantime- decreases are so evil, better to just skip them). Somehow all your projects come together in the end and tell a unique story that makes them all the more valuable."
  },
  creative: {
    title: "The Creative Crafter",
    text: "You are drawn to vibrant projects that let your creativity shine. Your motto is „If you can think it, you can stitch it“. You frequently surprise your friends and fellow crafters with funny amigurumi, unusual designs for clothing and the occasional unhinged project that no one asked for. You love creating your own patterns and can magically make items out of scrap yarn. You are not afraid to try new color combinations and stitch patterns. Your cheerful personality is reflected in your work and your finished pieces put a smile on everyone who gets to see them."
  },
  maker: {
    title: "The Meticulous Maker",
    text: "You love the process and take pride in perfect yarn tension and even stitches. You enjoy intricate projects and appreciate the beauty in detailed work. Before you start a project you carefully read the pattern, highlight important parts and set up multiple counters. Once you start the project, you work focused and with attention to detail. Your patience is your superpower and your projects always turn out the definition of perfection… even when they take you months to complete.",
  },
  cozy: {
    title: "The Cozy Crafter",
    text: "Light the fireplace, grab a hot beverage, turn on your favorite podcast and get comfortable: It’s time to craft! You enjoy comfort and warmth, and your projects reflect your love for all things cozy. You can’t wait to break out the chunky yarns for crafting season and you enjoy making practical, wearable items that bring comfort to yourself and others. Your friends might occasionally get lost in that loop scarf or a gigantic blanket, but they will never be cold. Your nurturing personality and love for coziness shine through in every piece you make.",
  },
  beginner: {
    title: "The Bewildered Beginner",
    text: "You’ve just entered the parallel universe of crafting and everything feels new and exciting… but also a bit scary. Patterns seem to be as easy to understand as ancient hieroglyphs and every mistake feels like a mini-crisis you might need a therapist for. But you will not give up the fight and abandon your project even if you have to google “how to increase for beginners… and without crying”. Your magic ring might not be very magic yet and for some reason that invisible seam is strangely still very visible, but you can be proud of your first completed projects and call yourself a crafter! Hang in there, the crafting community has your back and soon you will be laughing about your initial confusion!",
  },
  }



function TestResult(props) {
  const { type } = useParams();

  let history = useHistory();
let location = window.location;
  const [picUrl, setPicUrl] = useState(null);
  const [title, setTitle] = useState(null);
  const [text, setText] = useState(null);
  const [copied, setCopied] = useState(false);

  const width = Dimensions.get("window").width;
  const height = Dimensions.get("window").height;

  const isMobile = width < 768;
  const isTablet = width >= 768 && width < 1024;
  const picWidth = isMobile ? ((width*0.8)-48 ) : ((width*0.8)-92 )/ 2;

  console.log("TestResult picWidth", picWidth);



    useEffect(() => {

        // assign pic url depending on url parameter ?type=...
        //const searchParams = new URLSearchParams(location.search);
        //const type = searchParams.get('type');
        if (type) {
            setPicUrl(`/imgs/test_results/type_${type}.jpg`);
            setTitle(ALL_RESULTS[type].title);
            setText(ALL_RESULTS[type].text);
        }
    }
    , [location]);

    useEffect(() => {

      // assign pic url depending on url parameter ?type=...
      //const searchParams = new URLSearchParams(location.search);
      //const type = searchParams.get('type');
      if (copied) {
        alert("URL copied to clipboard! Share it with your friends to find out what type of crafter they are!");
        setCopied(false);
      }
  }
  , [copied]);


    console.log("TestResult useEffect type", type);

    const pinkText = (text) => {
      return <Text style={{fontSize: 16, color: "#FF5258" , fontWeight: 600}}>{text}</Text>
    }

    const renderDlBtn = (img, txt, link) => {
      return (
        <a className="testBtn" href={link} target="_blank">
        <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          margin: 12,
          backgroundColor: "white",
          paddingHorizontal: 24,
          paddingVertical: 12,
          borderRadius: 24
        }}>
          <Image
          urlOnServer={img}
          style={{
            width: 24,
            height:  24,
            marginRight: 12,
            tintColor: "#FF2D34",
            opacity: 0.8
             }} />

                           <p style={{
                color: "#FF2D34",
                opacity: 0.6,
                fontSize: 14,
                fontWeight: 800,
                letterSpacing: 2,
                margin: 0,
            }}>{txt} ></p>
        </View>
        </a>
      )
    }

  return (
    <ImageBackground  urlOnServer={'/imgs/background_knitting2.jpg'}
          style={{ width:'100%', height: Dimensions.get("window").height, justifyContent:'center', alignItems:'center' }}
        >
          <ScrollView contentContainerStyle={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>

        <View style={{
            display: 'flex',
            flexDirection: isMobile ? "column" : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 24,
            borderRadius: 24,
            backgroundColor: 'rgba(255,255,255,0.8)',
            margin: 24,
            width: '80%',
        }}>
          <Image urlOnServer={picUrl} style={{
            width: picWidth,
            height: isMobile ? picWidth : picWidth / 1.5,
            marginRight: isMobile ? 0 : 12,
            borderRadius: 12,
             }} />
          <View style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: isMobile ? '100%' : '50%',
        }}>
          <h1 style={{
                color: SECONDARY_COLOR,
                opacity: 0.6,
                fontSize: 24,
                fontWeight: 'bold',
                textAlign: 'left',
                letterSpacing: 2,
                padding: 0,
                fontSize: 42,
                margin: 0,
                marginTop: isMobile ? 18 : 0,
                paddingLeft: isMobile ? 0 : 12,
                paddingBottom: 8,
            }}>{title}</h1>
              <p style={{
                color: 'black',
                opacity: 0.8,
                fontSize: 16,
                fontWeight: 'normal',
                letterSpacing: 2,
                padding: 0,
                paddingLeft: isMobile ? 0 : 12,
                margin: 0,
            }}>{text}</p>
          </View>


        </View>

        <View style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 24,
            borderRadius: 24,
            backgroundColor: 'rgba(255,255,255,0.8)',
            margin: 24,
            marginTop: 0,
            width: '80%',
        }}>
                      <p style={{
                color: 'black',
                fontSize: 16,
                fontWeight: 'normal',
                letterSpacing: 2,
                padding: 0,
                margin: 0,
            }}>Is it you? 🙂<br/>
            Download the picture above and share it on {pinkText("Instagram")}!</p>
            <a className="testBtn" href={picUrl} download>
                <View style={{
                    backgroundColor: SECONDARY_COLOR,
                    paddingHorizontal: 24,
                    paddingVertical: 12,
                    borderRadius: 24,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 12,

                }}>
                    <p
                    className="testBtnTxt"
                    style={{
                        color: 'white',
                        fontSize: 16,
                        fontWeight: 'bold',
                        letterSpacing: 2,
                        padding: 0,
                        margin: 0,
                    }}
                    >Download image</p>
                </View>
            </a>

        </View>
        <View style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 24,
            borderRadius: 24,
            backgroundColor: 'rgba(255,255,255,0.8)',
            margin: 24,
            marginTop: 0,
            width: '80%',
        }}>
                      <p style={{
                color: 'black',
                fontSize: 16,
                fontWeight: 'normal',
                letterSpacing: 2,
                padding: 0,
                margin: 0,
            }}>Want to know what type of crafter your friends are? Share the <a href="https://tally.so/r/wbkabe" about="blank">Yarn Lover Personality Test</a> with them! </p>
                                  <p style={{
                color: 'black',
                opacity: 0.6,
                fontSize: 16,
                fontWeight: 'normal',
                letterSpacing: 2,
                padding: 0,
                margin: 0,
                marginTop: 12,
                marginBottom: 12,
            }}><b>URL:</b> <span style={{fontStyle: "italic"}}>https://tally.so/r/wbkabe</span></p>
                    <CopyToClipboard text={"https://tally.so/r/wbkabe"}
          onCopy={() => setCopied(true)}>
            <div  id="copyUrlBtn">
            <View
            style={{
                    backgroundColor: SECONDARY_COLOR,
                    paddingHorizontal: 24,
                    paddingVertical: 12,
                    borderRadius: 24,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 12,

                }}>
                    <p
                    className="testBtnTxt"
                    style={{
                        color: 'white',
                        fontSize: 16,
                        fontWeight: 'bold',
                        letterSpacing: 2,
                        padding: 0,
                        margin: 0,
                    }}
                    >Copy URL to share with friends</p>
            </View>
            </div>
            </CopyToClipboard>
        </View>
                    <View style={{
            display: 'flex',
            flexDirection: isMobile ? "column" : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 24,
            borderRadius: 24,
            backgroundColor: '#FFEEB3',
            margin: 24,
            marginTop: 0,
            width: '80%',
        }}>
                    <Image urlOnServer={"/logo512.png"} style={{
            width: picWidth / 6,
            height: picWidth / 6,
            marginRight: isMobile ? 0 : 12,
             }} />
          <View
          style={{
            flex: 1
          }}>
          <h1 style={{
                color: "#FF2D34",
                opacity: 0.8,
                fontSize: 24,
                fontWeight: 'bold',
                textAlign: 'left',
                letterSpacing: 2,
                padding: 0,
                fontSize: 24,
                margin: 0,
                marginTop: isMobile ? 18 : 0,
                paddingLeft: isMobile ? 0 : 12,
                paddingBottom: 8,
            }}>Discover My Row Counter App</h1>
              <p style={{
                color: 'black',
                opacity: 0.8,
                fontSize: 14,
                fontWeight: 'normal',
                letterSpacing: 2,
                padding: 0,
                paddingLeft: isMobile ? 0 : 12,
                margin: 0,
            }}><b>Free Mobile App to keep track of your rows while knitting</b><br/>
            Follow several knitting projects, Row Counter with multiple counters, <br/>
            Import your pattern in the app, Connect to Ravelry, Knitting/Crochet tools...<br/>
            </p>

          </View>
          <View
            style={{
              flexDirection: "column",
              marginTop: isMobile ? 12 : 0,
            }} >
          {
              renderDlBtn(
                "/imgs/android.png",
                "Check on Android",
                "https://play.google.com/store/apps/details?id=com.row_counter"
              )
            }
                      {
              renderDlBtn(
                "/imgs/apple.png",
                "Check on iOS",
                "https://apps.apple.com/us/app/my-row-counter-knit-crochet/id1342608792"
              )
            }
          </View>


        </View>
</ScrollView>

    </ImageBackground>
  );
}

export default TestResult;
