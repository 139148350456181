import fetchClient from "../utils/http-common";
import { SERVER_API_URL } from '../config/Constants'

const uploadPDF = (file, projectId, projectPatternId, onUploadProgress) => {
  let formData = new FormData();

  formData.append("project_pattern[pdf_pattern]", file);
  formData.append("project_pattern[user_project_id]", projectId);

  var url 
  return fetchClient.put(`${SERVER_API_URL}/project_patterns/${projectPatternId}.json`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};



export default {
  uploadPDF,
};
