import React, { Component } from 'react';
import strings from "../config/strings";
import Utils from "../utils/utils";
import Image from "../components/Image"
import { HEADER_HEIGHT } from '../config/Constants'

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';


export default class ProfileCounters extends Component {

  constructor(props) {
    super(props);

  }

  onResetTimer() {
    this.props.onResetTimer(true)
  }

  renderGlobalCounters(pic, number, name, link) {
  
      var doneItemStyle = styles.doneItem;
      var circleStyle = [styles.circle,  {                  
          width: Dimensions.get("window").height / 12,
          height: Dimensions.get("window").height / 12,
          borderRadius: Dimensions.get("window").height / 12,
          }];
      var imgDoneStyle = [styles.imgDone,{                  
          width: Dimensions.get("window").height / 20,
          height: Dimensions.get("window").height / 20
          }
        ]
      
   /* if (link) {
      return (
          <TouchableOpacity style={doneItemStyle} onPress={() => {}}>
          <View style={circleStyle} >
              <Image
                style={imgDoneStyle}
                urlOnServer={pic}
                resizeMethod= "resize"
                resizeMode="contain"
              />
            </View>
              <Text style={styles.doneItemTextNb}>
                {number}
              </Text>
              <Text style={styles.doneItemTextTitle}>
                {name}
              </Text>
          </TouchableOpacity>

        )
    } else {*/
          return(
            <View style={doneItemStyle}>
            <View style={circleStyle} >
              <Image
                style={imgDoneStyle}
                urlOnServer={pic}
                resizeMethod= "resize"
                resizeMode="contain"
              />
              </View>
              <Text style={styles.doneItemTextNb}>
                {number}
              </Text>
              <Text style={styles.doneItemTextTitle}>{name}</Text>
            </View>
      )

    //}

  } 


  render() {

    const counters = this.props.counters;

    return (


        <FlatList 
          style={{flexGrow:0, marginRight:20}}
          data={counters}
          nbColumn={1}
          renderItem={item => this.renderGlobalCounters(item.item.pic, item.item.number, item.item.name, item.item.link)}
          keyExtractor={(item, index) => item.id}
          contentContainerStyle={{height: Dimensions.get("window").height - HEADER_HEIGHT - 50, justifyContent:'space-around'}}
        />

    );
  }
}




const styles = StyleSheet.create({
  header: {
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    elevation: 5
  },
  menuItems: {
    flex: 1,
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  menuItemsLand: {
    flex: 1,
    justifyContent: "space-around",
    alignItems: "center",
  },
  circle: {
    alignSelf: "center",
    backgroundColor: 'rgba(250,250,250,0.5)', 
    alignItems: "center", 
    padding: 5, 
    marginBottom: 5,
  },
    imgDone: {
    flex:1,
    alignSelf: "center",
    margin: 10,
  },
  headerText: {
    textAlign: "center",
    color: "white",
    fontSize: 14,
    fontWeight: "300",
    marginTop: 10,
    backgroundColor: "transparent"
  },
  counterBadgesContainer: {
    flex: 1,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  done: {
    flex: 1,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  doneItem: {
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 15,
  },
  doneItemTextTitle: {
    textAlign: "center",
    color: "#fff",
    fontSize: 13,
    fontWeight: 'normal',
    fontStyle: "italic",
  },
  doneItemTextNb: {
    fontSize: 16,
    fontWeight: "600",
    textAlign: "center",
    fontWeight: 'normal',
    color: "#fff",
  },
  border: {
    alignSelf: "stretch",
    height: 1,
    backgroundColor: "#CCC",
    marginTop: 10,
    marginBottom: 10
  },

  headerBackgroundImg: {
    width: "100%"
  },
  badge: {
    alignSelf: "center"
  },
})
