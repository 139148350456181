import React, { useState, useEffect } from "react";
import UploadService from "../services/FileUploadService";
import strings from "../config/strings";
import Button from "../components/Button";
import Image from "../components/Image"
import "../config/styles.css"
import { SERVER_API_URL } from '../config/Constants'
import Utils from '../utils/utils'
import fetchClient from "../utils/http-common";

const UploadFiles = (props) => {

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");




  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };

   const upload = async () => {
    let currentFile = selectedFiles[0];

    setProgress(0);
    setCurrentFile(currentFile);

    //create a new project_pattern
    var project_pattern = {
      "user_project_id": props.projectId,
      "patternType": 1,
      "position": 0,
      "updatedAtOnClient": Date.now(),
      "uniqueIdOnClient": Utils.getUID()}

      var result = await fetchClient.post(`${SERVER_API_URL}/project_patterns.json`, project_pattern)

      var projectPatternId = result.data.id

    UploadService.uploadPDF(currentFile, props.projectId, projectPatternId, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((response) => {

       // alert(JSON.stringify(response))
        //setMessage(response.data.message);
        props.onSuccess(response)

      })

      .catch((error) => {

        alert(error.message)
        setProgress(0);
        setMessage("Could not upload the file!");
        setCurrentFile(undefined);
      });

    setSelectedFiles(undefined);
  };



  return (
    <div style={{display: 'flex', flexDirection:"column" ,justifyContent: 'center', alignItems: 'center'}}>
      {currentFile && (
        <div className="progress">
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: progress * 2 }}
          >
            {progress}%
          </div>
        </div>
      )}
      <label class="CustomFileUpload ">
          <input
          type="file"
          accept={props.accept ? props.accept : undefined}
          onChange={selectFile}
           />
          <Image urlOnServer={selectedFiles?'/imgs/ok.png':'/imgs/cloud-computing.png'} style={{width: 20, height: 20, margin:5}}/>
           Choose File to Upload
      </label>


    {selectedFiles && <div style={{width:100}}>
      <Button
        style={{alignSelf:'center', marginTop: 30}}
        title={strings.import}
        onPress={upload}
      />
      </div>}


      <div role="alert">
        {message}
      </div>


    </div>
  );
};

export default UploadFiles;
