import { useState } from 'react';

export default function useCurrentProject() {
  const getCurrentProjectId = () => {
    const currentProjectId = localStorage.getItem('currentProjectId');
   // const userToken = tokenString;

    // alert(token)
    return currentProjectId
  };

  const [currentProjectId, setCurrentProjectId] = useState(getCurrentProjectId());

  const saveCurrentProjectId = currentProjectId => {
    localStorage.setItem('currentProjectId', currentProjectId);

    //alert('token set ' + userToken)
    setCurrentProjectId(currentProjectId);
  };
  return {
    setCurrentProjectId: saveCurrentProjectId,
    currentProjectId
  }
}