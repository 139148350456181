import React from 'react';
import useToken from '../../hooks/useToken';
import useUser from '../../hooks/useUser';
import { useState, useEffect } from 'react';
import { SERVER_API_URL, APP_NAME } from '../../config/Constants'

import { getSizesFromIds, getCatFromSize } from "../../config/sizes"
import PatternUtils from "../../generator/PatternUtils";
import { LANGS } from "../../generator/PatternUtils";
import Image from "../../components/Image";
import Switch from "react-switch";

import { useParams, useHistory, useLocation } from "react-router-dom";
import strings from "../../config/strings";
import Utils from '../../utils/utils';
import Dropdown from 'react-bootstrap/Dropdown'
import { getAllSizes } from "../../config/sizes"
import CheckBox from "../../components/CheckBox"

import SizeSpecBox from "../../components/generator/SizeSpecBox";

import PicUpload from "../../components/generator/PicUpload";
import { Player } from 'video-react';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ReactHlsPlayer from 'react-hls-player';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'


import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

export default function CreateImageSection() {

  const history = useHistory();
  const location = useLocation();
  const [createdPattern, setCreatedPattern] = useState(location.state.pattern)
  const [type, setType] = useState(location.state.type)
  const [section, setSection] = useState(location.state.section)
  const [showSave, setShowSave] = useState(false)
  const [showReplace, setShowReplace] = useState(false)
  const [assetId, setAssetId] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [videoUrl, setVideoUrl] = useState()

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  
  const playerRef = React.useRef();



  useEffect(() => {
    if (location.state.edit) {
      if (type == "img") {
        setAssetId(location.state.section.imgAttachmentId)
        
      }
      else if (type == "video") {
        setAssetId(location.state.section.videoAttachmentId)
        // var tmpUrl = Utils.getAttachementUrl(location.state.section.videoAttachmentId)
        // console.log("tmpUrl", tmpUrl)
        // Utils.getRedirectUrl(tmpUrl, (url) => {
        //   setVideoUrl(url)
        //   console.log("video url", url)
        // })
      }
      setShowReplace(true)
    }
    console.log("is loading", isLoading )
  }, [])


  const saveChanges = () => {
    var updatedPattern = createdPattern

    if (!location.state.edit) {
      updatedPattern.sections.push(section)
      updatedPattern.sections = updatedPattern.sections
    } else {
      var sections = updatedPattern.sections
      var sectionIndex = sections.findIndex(oldSection => oldSection.id == section.id)

      if (type == "img") {
        sections[sectionIndex].imgAttachmentId = section.imgAttachmentId
        sections[sectionIndex].imgVersion += 1
      }
      else if (type == "video") {
        sections[sectionIndex].videoAttachmentId = section.videoAttachmentId
        sections[sectionIndex].videoVersion += 1
      }

      sections[sectionIndex].updatedAtOnClient = Utils.getTime()
      updatedPattern.sections = sections
      updatedPattern.sections = sections
    }
    PatternUtils.updatePatternOnServer(updatedPattern, (response) => {
      setCreatedPattern(response)
    })
    var path = location.state.backPath ? location.state.backPath : `/edit-pattern/`
    history.push({
      pathname: path,
      state: { pattern: updatedPattern }
    })
  }



  const onUploaded = (id) => {
    var tmpSection = {...section}
    if (type == "img") {
      tmpSection.imgAttachmentId = id
    }
    else if (type == "video") {
      tmpSection.videoAttachmentId = id
      // var tmpUrl = Utils.getAttachementUrl(id)
      // Utils.getRedirectUrl(tmpUrl, (response) => {
      //   setVideoUrl(response.config.url)
      //   console.log("video url", response.config.url)
      // })
    }
    setAssetId(id)
    console.log("attachement URL", Utils.getAttachementUrl(id))
    setShowSave(true)
    setSection(tmpSection)
  }

  const onChangePicture = () => {
    setShowReplace(false)
    var tmpSection = {...section}
    if (type == "img") {
      tmpSection.imgAttachmentId = 0
    }
    if (type == "video") {
      tmpSection.videoAttachmentId = 0
    }
    setShowSave(false)
    setSection(tmpSection)
  }

  const onBack = () => {
    var path = location.state.backPath ? location.state.backPath : `/edit-pattern/`
  history.push({
    pathname: path,
    state: { pattern: createdPattern }
  })
}

  const renderGoBack = () => {
    return (
      <TouchableOpacity
      style={{justifyContent: "center", alignItems: "flex-start", width: 32, marginBottom: 8}}
      onPress={() => onBack()}>
        <Image style={{width: 24, height: 24, margin: 4}} urlOnServer={"/imgs/back.png"}/>
        <Text style={{color: "rgba(255,255,255,0.8)", textAlign: "center", fontSize: 10}}>{strings.patternCreator.backToContent}</Text>
      </TouchableOpacity>
    )
}

const renderImage = () => {
  return (
    <View style={{width: "100%", justifyContent:"center", alignItems:"center", padding: 24}}>
        <Image style={{width: 400, height: 300, marginRight: 4, borderRadius: 12}} urlOnServer={Utils.getAttachementUrl(assetId)} />
    </View>   
  )
}

const renderVideo = () => {
  return (
    <View style={{width: "100%", justifyContent:"center", alignItems:"center", padding: 50}}>
        <Player
          playsInline
          poster="/imgs/icon_android.png"
          fluid={false}
          height={600}
          src={Utils.getAttachementUrl(assetId)}
        />
        {/* <ReactHlsPlayer
          src={Utils.getAttachementUrl(assetId)}
          autoPlay={false}
          controls={true}
          hlsConfig={{
            autoStartLoad: true,
            startPosition: -1,
            debug: false,
            // ...
          }}
          width="100%"
          height="auto"
        /> */}
    </View>
  )
}

const renderLoading = () => {
  return (
    <View style={{width: "100%", justifyContent:"center", alignItems:"center", padding: 50}}>
      <Loader
        type="ThreeDots"
        color="#2ba5f7"
        height={100}
        width={100}
        // timeout={8000} //3 secs
      />
    </View>
  )
}

const renderUpload = () => {
  return (
    <View style={{flex: 1, justifyContent: "center", alignItems: "center", height: 300}}>
      <PicUpload 
        className="Upload" 
        projectId={"1"} 
        type={type}
        pattern={createdPattern} 
        onLoading={setIsLoading}
        onSuccess={(response) => onUploaded(response.data.id)} />
    </View>
  )
}



  return(
 

    <View style={{flexDirection:'column', backgroundColor: "#266D9C", width: "100%", minHeight: height - 72}}>
        <div className="container mt-5 rcpattern-container ">
        {renderGoBack()}
        <h2 className="text-white" style={{textAlign: "center", marginBottom:40}}>{type == "img" ? strings.patternCreator.imageSection : strings.patternCreator.videoSection}</h2>
        { 
          section.imgAttachmentId && section.imgAttachmentId != 0 ? renderImage()
           : section.videoAttachmentId && section.videoAttachmentId != 0 ? renderVideo()
           : isLoading ? renderLoading()
           : renderUpload()
        }

        <View style={{flex: 1}}>

            <View style={{justifyContent: "space-around", alignItems: "center", marginBottom: 24}}>
            {
            (showSave || showReplace) &&
              <TouchableOpacity 
                onPress={() => onChangePicture()}>
                  <Text 
                  style={{color: "rgba(255,255,255,0.8)"}} >
                    
                    {type == "img" ? `${strings.patternCreator.replacePic} >` : `${strings.patternCreator.replaceVid} >`}
                  </Text>
              </TouchableOpacity>
             }
             {
               showSave &&
               <button className="ImportPatternBtn" onClick={() => saveChanges()}>
                {strings.save}
              </button> 
             }

            </View> 
          </View>

      </div>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    backgroundColor: "rgba(255,255,255,0.15)" , 
    color: "white",
    textAlign: "center",
    borderRadius: 15, 
    width: 60,
    height: 30,  
    padding: 12
  },
  title: {
    color: "rgba(255,255,255,0.8)", 
    textAlign: "left",
    selfAlign: "flex-start",
  },
  editImg: {
    tintColor:'rgba(255,255,255,0.8)', 
    width:8, 
    height:20
  },
  row: {
    flexDirection:'row', 
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    borderColor:'rgba(255,255,255,0.6)', 
    borderBottomWidth:0.5, 
    paddingVertical:12,
    marginHorizontal:12,
  } ,
  rowText: {
    color: "rgba(255,255,255,0.8)",
    marginRight: 12,
  },
  rowInfosContainer: {
    flexDirection: "row", 
    justifyContent:"flex-start", 
    alignItems: "center",
    marginBottom: 12,
  }

});