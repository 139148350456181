import React, { Component } from "react";
import strings from "../../config/strings";
import Image from "../../components/Image"
import Dropdown from 'react-bootstrap/Dropdown'
import Utils from "../../utils/utils";
import PatternUtils from "../../generator/PatternUtils";
import Button from "../../components/Button";
import { withRouter } from "react-router";
import { TYPE_TEXT_PURE, TYPE_TEXT_ROWS, ROW_TYPE_RANGE, ROW_TYPE_ONE } from "../../generator/PatternUtils";
import {SECONDARY_COLOR} from "../../config/styles";
import SchemaPointSelector from "../../components/generator/SchemaPointSelector"
import Schema from "../../components/generator/Schema"
import ChartSizes from "../../components/generator/ChartSizes"
import { getSymbols, getFirstSymbolSelectedKnitting, getFirstSymbolSelectedCrochet } from "../../generator/symbols"

import {
  ScrollView,
  View,
  TextInput,
  ImageBackground,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Keyboard,
  SafeAreaView,
  DeviceEventEmitter,
  PanResponder,
  BackHandler,
  Dimensions
} from 'react-native';


const MIN_HEIGHT_HEADER = 70
const MIN_HEIGHT_BOTTOM = 150
const HEIGHT_DESC = 160


var __DEV__ = true;

export default class ViewSchema extends Component {
  handler = dims => this.setState(dims);

  constructor(props) {
    super(props);

   

    const arrayWidth = 10;
    const arrayHeight = 5;

    

    this.patternUtils = new PatternUtils(null);
    this.pattern = this.props.pattern
    this.section = this.props.section
    this.subsection = null

    for (var i = 0; i < this.section.subsections.length; i++) {
      this.subsection = this.section.subsections[i]
      if (this.subsection.deleted == 1)
        continue

      if ([...this.subsection.sizeIds].includes(this.props.selectedSizeId))
        break
    }


    this.state = {loading: true}
    

 
  }


  async getChart() {
    var array = []
    var chart = this.patternUtils.getChartFromContent(this.subsection.content, this.pattern.custom_symbols)
    if (chart) {


      this.allCustomSymbols = await this.patternUtils.getAllCustomSymbols(chart.customSymbols)
      var i = 0;
      for (var y = 0; y < chart.height; y++) {
        for (var x = 0; x < chart.width; x++) {

          var splitted = chart.array[i].split(',')
          var symbolId = splitted[0]
          var color = splitted[1]
          var partWidth = splitted.length > 2 && splitted[2] ? parseInt(splitted[2]) : 0
          var partHeight = splitted.length > 3 && splitted[3] ? parseInt(splitted[3]) : 0
          array.push({
            posX: x,
            posY: y,
            symbol: symbolId < getSymbols().length ? getSymbols()[symbolId] : chart.customSymbols[symbolId],
            color: color,
            partWidth: partWidth,
            partHeight: partHeight
          })

//          if(__DEV__) console.log("checking chart", symbolId, chart.customSymbols[symbolId], chart.customSymbols)

          i++
        }
      }

    }
  //  alert(JSON.stringify(array))
  //  alert(this.storage.getRavelryTokenExpiration() + " " + new Date().getTime())

     for (var i = 0; i < array.length; i++) {
      if (array[i].posY == this.subsection.position || this.subsection.position == -1) {
        array[i].selected = true;
      }
      else
        array[i].selected = false;
    }
  
   
    this.setState({
      schemaViewWidth:0,
      schemaViewHeight:0,
      overwrite: [],
      arrayWidth: chart ? chart.width : undefined,
      arrayHeight: chart ? chart.height : undefined,
      array: chart ? array : undefined,
      selectedWidth: chart ? chart.width : 0,
      selectedHeight: chart ? chart.height : 0,
      selectedLine: this.subsection.position,
      selectedSymbolsOnLine: this.getUsedSymbols(array,this.subsection.position, null),
      canShowColorPreview: this.canShowColorPreview(array),
      loading: false
    });
  }


  componentDidMount() {

    this.getChart();


  }

  computeSchemaDim(width, height) {

  
    var isLandscape = Utils.isLandscape()

    var paddingTop = 0

    var paddingBottom = 10

    var schemaWidth = Dimensions.get('window').width / 2
    var schemaHeight = height - HEIGHT_DESC;
   // alert(height + ' ' + schemaHeight)

    this.setState({ isLandscape: isLandscape, paddingTop: paddingTop, paddingBottom: paddingBottom, schemaViewWidth: schemaWidth, schemaViewHeight: schemaHeight})
  }


  canShowColorPreview(array) {

    if (!array)
      return false
    var canShowPreview = false
    for (var i = 0; i < array.length; i++) {
      if (array[i].color) {
        canShowPreview = true
        break
      }
    }

    return canShowPreview
  }  

  getItemInArray(x, y, maxX, array) {
    return array[(y * maxX) + x]
  }

  onCellSelected(item) {


   var newArray = []
   for (var i = 0; i < this.state.array.length; i++) {

    var newItem = {... this.state.array[i]}

    if (this.state.selectedLine == item.posY) {
      newItem.selected = true;
    }
    else {
      if (newItem.posY == item.posY && this.state.selectedLine != newItem.posY) {
        newItem.selected = true;
      }
      else
        newItem.selected = false;
    }
    newArray.push(newItem)
   }



   var selectedLine = this.state.selectedLine == item.posY ? -1 : item.posY

  // Utils.realmWrite(() => {
    this.subsection.position = selectedLine

    //if (hasValidSubscription())
      this.pattern.updatedAtOnClient = Utils.getTime()
   //})
   this.setState({selectedLine: selectedLine, selectedSymbolsOnLine: this.getUsedSymbols(this.state.array, item.posY, item.symbol), array: newArray})
    
    //Alert(JSON.stringify(this.getLineSymbols(this.state.array, item.posY, item.symbol)))

  }



  getUsedSymbols(array, line, selectedSymbol) {

    var used = []
    var computedArray = selectedSymbol ? [ selectedSymbol ] : []

 // alert(JSON.stringify(array))
    for (var i = 0; i < array.length; i++) {

      if (line == -1 || array[i].posY == line) {

        var found = false

        if(__DEV__) console.log('computedArray check', computedArray)
        for (var y = 0; y < computedArray.length; y++) {
          if(__DEV__) console.log('computedArray', y)
          if (computedArray[y].id == array[i].symbol.id) {
            found = true
          }
        }

        if (found)
          continue

        computedArray.push(array[i].symbol)
        
      }
       

      if (line != -1 && array.posY > line)
        break
    }
    
    //alert(JSON.stringify(array))
    return computedArray

  }

  zoom(value) {
    
    if (this.refs.schemaView)
      this.refs.schemaView.zoom(value)

  }

  showSymbolInfo(symbol) {

    if (this.pattern.projectTool == 2)
      var text = symbol.rs_long
    else
      var text = strings.rs + " : " + symbol.rs_long + '\n\n' + strings.rs + " : " + symbol.ws_long

    alert(text)
  }

  renderInfoItem(item) {

    const widthMultiplier = item.width && item.width != 1 ? item.width * 0.7 : 1

    var text

    if (this.pattern.projectTool == 2)
      text = item.rs
    else
      text = item.rs == item.ws ? item.rs : strings.rs + ': ' + item.rs + ' / ' + strings.ws + ': ' + item.ws
    return (

        <TouchableOpacity style={{flexDirection:'row', alignItems:'center', width:Dimensions.get('window').width / 2, alignContent:'center'}} onPress={() => this.showSymbolInfo(item)}>

         <Image resizeMode='contain'  source={item.t_img} style={{width: (40 * widthMultiplier), height: 40, margin:5, backgroundColor:'#fff'}}/>
       
         <View style={{flexDirection:'row', width:(Dimensions.get('window').width / 2) - (40 * widthMultiplier ) - 25}}>
         <Text style={{marginLeft:5, color:'#fff'}} numberOfLines={2}>{text}</Text>
         
         <Image urlOnServer={"/imgs/info.png"} style={{marginLeft: 5, width:20, height:20}} />
          </View>
         <View style={{flex:1}}/>
        </TouchableOpacity>

      )
  }


  renderInfo() {


    
    return (

          <View style={{width:"100%", height:HEIGHT_DESC}}>

          <View style={{flexDirection:'row', justifyContent:'flex-end', marginTop:5}}>

          <TouchableOpacity style={{width:40, height:40, marginHorizontal:5}} onPress={() => this.zoom(-1) }>
            <Image urlOnServer={"/imgs/unzoom2.png"}
             style={{width:40, height:40}}/>
          </TouchableOpacity>


           <TouchableOpacity style={{width:40, height:40, marginHorizontal:5}} onPress={() => this.zoom(1) }>
            <Image urlOnServer={"/imgs/zoom2.png"}
             style={{width:40, height:40}}/>
          </TouchableOpacity>
         

        {this.state.canShowColorPreview &&  <TouchableOpacity style={{width:40, height:40, marginHorizontal:5}} onPress={() => {

            
            this.props.history.push({
                pathname: '/chart-preview/',
                state: { pattern: this.pattern, array: this.state.array, arrayWidth: this.state.arrayWidth, arrayHeight: this.state.arrayHeight }
              })


          }
          }>
            <Image urlOnServer={"/imgs/eye_circle.png"}
             style={{width:40, height:40}}/>
          </TouchableOpacity> }



         {this.state.overwrite.length > 0 && ( <TouchableOpacity style={{width:40, height:40, marginHorizontal:5}} onPress={() => this.cancelAction() }>
            <Image urlOnServer={"/imgs/rollback.png"}
             style={{width:40, height:40}}/>
          </TouchableOpacity>
         )}


        </View>


         {this.state.selectedSymbolsOnLine && (<FlatList 
            listKey="SCHEMA_INFO"
            style={{flex:1}}
            contentContainerStyle={{paddingBottom:80}}
            keyExtractor={(item, index) => "SCHEMA_CELL_" + index.toString()}
            numColumns={2}
            data={this.state.selectedSymbolsOnLine}
           
            renderItem={(item, index) => this.renderInfoItem(item.item)} 
           

            />)}

            </View>
      )
  }


  renderList() {

    return (
        <View style={{flex:1, backgroundColor:'#000'}}>



      <View style={{paddingTop: this.state.paddingTop, flex:1}}>
           <Schema 
           ref="schemaView"
           array={this.state.array}
            width={this.state.schemaViewWidth}
            //height={this.state.schemaViewHeight}
            arrayWidth={this.state.arrayWidth}
            arrayHeight={this.state.arrayHeight}
            onCellSelected={(item) => this.onCellSelected(item)}
            selectedLine={this.state.selectedLine}
           />

        </View>
    
         

        
            {this.renderInfo()}


          <TouchableOpacity style={{position:'absolute', top:10, right:10}} onPress={() => this.props.onClose()}>
            <Image
              resizeMode="contain"
              style={{ width: 30, height: 30 }}
              urlOnServer={"/imgs/close.png"}
            />
          </TouchableOpacity>

         </View>
      )
  }




  render() {

    if (this.state.loading)
      return (<Text>Loading</Text>)

    //return this.renderPreview()
    if (this.state.schemaViewWidth == 0)
      return (
        <View style={{flex:1}}

        onLayout={(event) => {
        
          
          var {x, y, width, height} = event.nativeEvent.layout;

          this.computeSchemaDim(width, height)
        
        }}
       />

       )

    return this.renderList()
  }
}


const styles = StyleSheet.create({


});
