import React, { Component } from "react";
import strings from "../config/strings";
import Image from "../components/Image"
import ImageBackground from "../components/ImageBackground"
import Button from "../components/Button"
import Utils from "../utils/utils";
import {SECONDARY_COLOR} from "../config/styles";
import { BrowserRouter, Route, Switch, useRouteMatch, Link, useParams, useHistory } from 'react-router-dom';
import TitledTextInput from "../components/TitledTextInput";
import { HEADER_HEIGHT } from '../config/Constants'
import OptionList from "../components/OptionList";


import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions,
  Picker
} from 'react-native';

const MARGIN = 6;

const MARGIN_OUT = 6;

const { width, height } = Dimensions.get("window");
const gutter = 0;


const UNITS = [
  { id: 1, text: strings.meters, multiple:100 },
  { id: 2, text: strings.centimeters, multiple:1 },
  { id: 3, text: strings.inches, multiple:2.54},
  { id: 4, text: strings.yards , multiple: 91.44 }
];

export default class ConvertUnits extends Component {
  handler = dims => this.setState(dims);

  constructor(props) {
    super(props);

    this.selectedInputType = UNITS[2]
    this.selectedOutputType = UNITS[1]
    this.input = null
    this.state = {output: null }
  }

  componentDidMount() {
    //Orientation.lockToPortrait();
  }

  componentWillMount() {
    Dimensions.addEventListener("change", this.handler);
  }

  componentWillUnmount() {
    // Important to stop updating state after unmount
    Dimensions.removeEventListener("change", this.handler);
  }

  textChanged(value) {
    if (value == "")
      return
    if (!this.isInt(value)) {
      Alert.alert(strings.number_required);
      return;
    }
    this.input = parseInt(value)
    this.compute()

      }

  isInt(value) {
    return (
      !isNaN(value) &&
      parseInt(Number(value)) == value &&
      !isNaN(parseInt(value, 10))
    );
  }

  inputTypeSelected(option) {
    this.selectedInputType = option
    this.compute()
  }

  outputTypeSelected(option) {
    this.selectedOutputType = option
    this.compute()
  }

  compute() {
    if (!this.input)
      return
    this.setState( { output: (this.input * this.selectedInputType.multiple / this.selectedOutputType.multiple).toFixed(2) })



  }



   back = () => {
    this.props.navigation.goBack();
  };

  render() {
    const NB_ITEMS_PER_ROW = 4
  
    const width = Math.min(Dimensions.get("window").width / 4, 400)
    return (
      <ImageBackground urlOnServer={'/imgs/background_knitting2.jpg'}
        style={{ flex: 1, height: Dimensions.get("window").height - HEADER_HEIGHT, alignItems:'center' }}

      >


         <View style={{ flex: 1, width: width, justifyContent: "center", alignContent: "center" }}>
        <View
          style={{
            alignSelf: "center",
            width: 100,
            borderBottomWidth: 1,
            borderColor: "#999",
            margin: 20
          }}
        >
          <TextInput
            style={{ padding: 2, textAlign: "center", color:'#555', fontWeight:'700', fontSize:16 }}
            keyboardType="numeric"
            placeholder={0}
            onChangeText={text => this.textChanged(text)}
            returnKeyType="done"
          />
        </View>

        <OptionList

          width={width}
          nbColumns={4}
          options={UNITS}
          onOptionSelected={option => this.inputTypeSelected(option)}
          selected={this.selectedInputType}
        />

        <Image
                    style={[{ height: 60, width: 60, alignSelf:"center", margin: 20 }]}
                    resizeMode="contain"
                    urlOnServer={"/imgs/fleche-conv.png"}
                  />

        <View
          style={{
            alignSelf: "center",
            width: 100,
            borderBottomWidth: 1,
            borderColor: "#999",
            marginBottom: 20
          }}
        >
          <Text
            style={{ padding: 2, textAlign: "center", color:"#555", fontWeight:'700', fontSize:16 }}
          >{this.state.output}</Text>
        </View>

        <OptionList
          width={width}
          nbColumns={4}
          options={UNITS}
          onOptionSelected={option => this.outputTypeSelected(option)}
          selected={this.selectedOutputType}
        />

        </View>

      </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  toolContainer: {
    margin: MARGIN,
    shadowColor: "#000",
    shadowOpacity: 0.6,
    shadowRadius: 3,
    elevation: 8,
    backgroundColor: SECONDARY_COLOR,
    borderRadius: 8
  },
  textItem: {
    textAlign: "center",
    color: "white",
    backgroundColor: "transparent"
  }
});
