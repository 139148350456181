import strings from "../config/strings";




export function getSizesArray() {
	return [
		    		{
		    			catId: "newborn",
		    			name: strings.patterns_sizes.newborn, 
		    			sizes: [
		    			{id:"newbornOneSize", text:strings.patterns_sizes.one_size},
		    			{
		    				id: "newborn0",
		    				text: "0-1 " + strings.month
		    			}, 
		    			{
		    				id:"newborn1",
		    				text:"1-2 " + strings.patterns_sizes.months
		    			},
		    			{
		    				id:"newborn2",
		    				text:"2-4 " + strings.patterns_sizes.months},
{id:"newborn4", text:"4-6 " + strings.patterns_sizes.months},
{id:"newborn6", text:"6-9 " + strings.patterns_sizes.months},
{id:"newborn9", text:"9-12 " + strings.patterns_sizes.months},
{id:"newborn12", text:"12-18 " + strings.patterns_sizes.months},
{id:"newborn18", text:"18-24 " + strings.patterns_sizes.months}
],
		    		},
		    		{
		    			catId: "kid", 
		    			name: strings.patterns_sizes.kid, 
		    			sizes: [
		    			{id:"kidOneSize", text:strings.patterns_sizes.one_size},
		    			{id:"kid3", text:"3 " + strings.patterns_sizes.years},
{id:"kid4", text:"4 " + strings.patterns_sizes.years},
{id:"kid5", text:"5 " + strings.patterns_sizes.years},
{id:"kid6", text:"6 " + strings.patterns_sizes.years},
{id:"kid7", text:"7 " + strings.patterns_sizes.years},
{id:"kid8", text:"8 " + strings.patterns_sizes.years},
{id:"kid9", text:"9 " + strings.patterns_sizes.years},
{id:"kid10", text:"10 " + strings.patterns_sizes.years}],
		    		},
		    		{
		    			catId: "teenager", 
		    			name: strings.patterns_sizes.teenager, 
		    			sizes: [{id:"teenagerOneSize", text:strings.patterns_sizes.one_size},
		    			{id:"teenager11", text:"11 " + strings.patterns_sizes.years},
{id:"teenager12", text:"12 " + strings.patterns_sizes.years},
{id:"teenager13", text:"13 " + strings.patterns_sizes.years},
{id:"teenager14", text:"14 " + strings.patterns_sizes.years},
{id:"teenager15", text:"15 " + strings.patterns_sizes.years},
{id:"teenager16", text:"16 " + strings.patterns_sizes.years},
{id:"teenager17", text:"17 " + strings.patterns_sizes.years},
{id:"teenager18", text:"18 " + strings.patterns_sizes.years}],
		    		},
		    		{
		    			catId: "men", 
		    			name: strings.patterns_sizes.men, 
		    			sizes: [{id:"menOneSize", text:strings.patterns_sizes.one_size},
		    			{id: "menXXS", text:"XXS"},
{id:"menXS", text:"XS"},
{id:"menS", text:"S"},
{id:"menM", text:"M"},
{id:"menL", text:"L"},
{id:"menXL", text:"XL"},
{id:"menXXL", text:"XXL"},
{id:"menXXXL", text:"XXXL"}],
		    		},
		    		{
		    			catId: "women", 
		    			name: strings.patterns_sizes.women, 
		    			sizes: [{id:"womenOneSize", text:strings.patterns_sizes.one_size},
		    			{id:"womenXXS", text:"XXS"},
{id:"womenXS", text:"XS"},
{id:"womenS", text:"S"},
{id:"womenM", text:"M"},
{id:"womenL", text:"L"},
{id:"womenXL", text:"XL"},
{id:"womenXXL", text:"XXL"},
{id:"womenXXXL", text:"XXXL"}],
		    		},
		    		{
		    			catId: "mixed", 
		    			name: strings.patterns_sizes.mixed, 
		    			sizes: [{id:"mixedOneSize", text:strings.patterns_sizes.one_size},
		    			{id:"mixedXXS", text:"XXS"},
{id:"mixedXS", text:"XS"},
{id:"mixedS", text:"S"},
{id:"mixedM", text:"M"},
{id:"mixedL", text:"L"},
{id:"mixedXL", text:"XL"},
{id:"mixedXXL", text:"XXL"},
{id:"mixedXXXL", text:"XXXL"}],
		    		},
		    		{
		    			catId: "cat",
		    			name: strings.patterns_sizes.cat, 
		    			sizes: [{id:"catOneSize", text:strings.patterns_sizes.one_size},
		    			{id:"catXXS", text:"XXS"},
{id:"catXS", text:"XS"},
{id:"catS", text:"S"},
{id:"catM", text:"M"},
{id:"catL", text:"L"},
{id:"catXL", text:"XL"},
{id:"catXXL", text:"XXL"},
{id:"catXXXL", text:"XXXL"}],
		    		},
		    		{
		    			catId: "dog", 
		    			name: strings.patterns_sizes.dog, 
		    			sizes: [{id:"dogOneSize", text:strings.patterns_sizes.one_size},
		    			{id:"dogXXS", text:"XXS"},
{id:"dogXS", text:"XS"},
{id:"dogS", text:"S"},
{id:"dogM", text:"M"},
{id:"dogL", text:"L"},
{id:"dogXL", text:"XL"},
{id:"dogXXL", text:"XXL"},
{id:"dogXXXL", text:"XXXL"}],
		    		},
		    		{
		    			catId: "other", 
		    			name: strings.other, 
		    			sizes: [{id:"otherOneSize", text:strings.patterns_sizes.one_size},
		    			{id:"otherXXS", text:"XXS"},
{id:"otherXS", text:"XS"},
{id:"otherS", text:"S"},
{id:"otherM", text:"M"},
{id:"otherL", text:"L"},
{id:"otherXL", text:"XL"},
{id:"otherXXL", text:"XXL"},
{id:"otherXXXL", text:"XXXL"}],
		    		},
	    		]

}


export function getAllSizes(selectedSizeIds) {

	var SIZES = getSizesArray();
	for (var i = 0; i < SIZES.length; i++) {

		var cat = SIZES[i]
		for (var y = 0; y < cat.sizes.length; y++) {
			cat.sizes[y].selected = false
		}
	}

	
	if (!selectedSizeIds)
		return SIZES


	for (var i = 0; i < SIZES.length; i++) {

		var cat = SIZES[i]
		for (var y = 0; y < cat.sizes.length; y++) {
			for (var x = 0; x < selectedSizeIds.length; x++) {
				if (cat.sizes[y].id == selectedSizeIds[x])
					cat.sizes[y].selected = true
			}
		}
	}

	return SIZES
}

export function getSizesFromIds(sizeIds) {


	var SIZES = getSizesArray();
	var array = []
	for (var i = 0; i < SIZES.length; i++) {

		var cat = SIZES[i]
		for (var y = 0; y < cat.sizes.length; y++) {
			for (var x = 0; x < sizeIds.length; x++) {
				if (cat.sizes[y].id == sizeIds[x])
					array.push(cat.sizes[y])
			}
		}
	}	
	//alert(array.length)
	return array
}

export function getCatFromSize(sizeId) {
	var SIZES = getSizesArray();
	for (var i = 0; i < SIZES.length; i++) {

		var cat = SIZES[i]
		for (var y = 0; y < cat.sizes.length; y++) {
			
		if (cat.sizes[y].id == sizeId)
			return cat
		}
	}	
}