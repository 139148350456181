import React, { Component } from "react";
import strings from "../config/strings";
import Image from "../components/Image"
import ImageBackground from "../components/ImageBackground"
import Utils from "../utils/utils";
import {SECONDARY_COLOR} from "../config/styles";
import { BrowserRouter, Route, Switch, useRouteMatch, Link, useParams, useHistory } from 'react-router-dom';
import { HEADER_HEIGHT } from '../config/Constants'

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

const MARGIN = 12;

const MARGIN_OUT = 24

const { width, height } = Dimensions.get("window");
const gutter = 0;

export default class Tools extends Component {
  handler = dims => this.setState(dims);

  constructor(props) {
    super(props);

    this.lastShownAd = 0;


    this.state = {adLoaded:false};

  }

  getTools() {
    return [
      {id: 1, img: "./imgs/tools/abacus.png", title: strings.compute_swatch, url:'knitting-swatch-adapter'},
      {id: 2, img: "./imgs/tools/pelote.png", title: strings.woolball_convertor, url:'yarn-skein-converter'},
      /*{id: 3, img: "translator", title: strings.convert_units},*/
      {id: 4, img: "./imgs/tools/ruler.png", title: strings.convert_units, url:'knitting-unit-converter'},
      {id: 5, img: "./imgs/convert_size.png", title: strings.needle_size.replace(' :', ':').replace(':', '') + '/' + strings.wool_size.replace(' :', ':').replace(':', ''), url:'knitting-needles-yarn-converter'},
      {id: 6, img: "./imgs/tools/distrib.png", title: strings.distribstitches.tooltitle, url:'knitting-stitches-inc-dec-distribution'},
      ]
  }

  replaceSpaceWithCR(str) {
    return str.replace(" ", "\n")
  }

  renderItem(item, width) {

    return (

      <Link className="oneTool" to={`${item.url}`}>
        <View

        style={[
              styles.toolContainer,
              {
                width: width,
                height: width
              }
            ]}

            >
          <Image
            style={[
              styles.imgItem,
              {
                marginTop: 32,
                marginBottom: 4,
                width: width - 100,
                height: width - 100,
                alignSelf: 'center',
              }
            ]}
            urlOnServer={ item.img }
          />

          <p
          style={{
    textAlign: "center",
    textDecoration: "none",
    color: "#a0a0a0",
    margin:8,
    fontSize: 14,
    backgroundColor:"transparent",
  }}>
           {this.replaceSpaceWithCR(item.title)}
          </p>

        </View>
      </Link>
    );
  }

  goToTool = tool => {

  if (tool.id == 4)
      this.props.navigation.navigate("ConvertUnitsScreen");
    else if (tool.id == 2)
      this.props.navigation.navigate("ComputeWoolScreen");
    else if (tool.id == 1)
      this.props.navigation.navigate("ComputeExampleScreen");
    else if (tool.id == 6)
    this.props.navigation.navigate("DistribStitchesScreen");

    else {
      this.props.navigation.navigate("ConvertSizesScreen");
    }

  };

  render() {

    const NB_ITEMS_PER_ROW = 3
    var equalWidth = (Dimensions.get("window").width - (NB_ITEMS_PER_ROW * MARGIN * 2) - (MARGIN_OUT * 2)) / NB_ITEMS_PER_ROW;

    equalWidth = Math.min(equalWidth, 200)


    return (
      <ImageBackground urlOnServer={'/imgs/background_knitting2.jpg'} style={{ flex: 1, height: Dimensions.get("window").height - HEADER_HEIGHT, justifyContent: 'center', alignContent: 'center'}} disableSafeArea={true}>
        <FlatList
          style={{ margin: MARGIN_OUT}}
          contentContainerStyle={{alignItems: 'center'}}
          data={this.getTools()}
          extraData={this.state}
          numColumns={NB_ITEMS_PER_ROW}
          keyExtractor={(item, index) => index}
          key={equalWidth}
          renderItem={item => this.renderItem(item.item, equalWidth)}
        />
      </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({

  toolContainer: {
    margin: MARGIN,
    shadowColor: "rgba(0,0,0,0.3)",
    shadowOpacity: 0.6,
    shadowRadius: 3,
    shadowOffset: {width:0,height:1},
    elevation: 8,
    backgroundColor: "#fff",
    borderRadius: 8
  },
  textItem: {
    textAlign: "center",
    color: "#a0a0a0",
    marginTop:5,
    backgroundColor:"transparent",
  }
});
