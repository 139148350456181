import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SERVER_API_URL } from '../config/Constants'
import queryString from 'query-string';
import Loading from "../components/Loading"
import {

  useParams
} from "react-router-dom";
import { useHistory } from "react-router-dom";

async function request(credentials, userId) {
 return fetch(`${SERVER_API_URL}/users/${userId}/confirm_email.json`, {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(credentials)
 })
   .then(data => data.json())
}



export default function ConfirmEmail({ setToken, setUser }) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const history = useHistory();

  useEffect(() => {
    let mounted = true;

    



    const args = queryString.parseUrl(window.location.href).query
  
 // alert(JSON.stringify(args))

      request({ token: args.token }, args.userId)
      .then(data => {

       // alert(JSON.stringify(data))
        setToken(data.auth_token)
        setUser(data.user)
        history.push(`/Profile`);
      })



    return () => mounted = false;
  }, [])


  return(
    <div className="login-wrapper">
      <Loading />
    
    </div>
  )
}
ConfirmEmail.propTypes = {
  setToken: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired
}